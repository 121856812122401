// import React from "react";

// export default function SelectField(props) {
//   const { classes } = props;
//   return (
//     <div className="fieldset">
//       {props.label ? (
//         <label
//           className={
//             props.error ? "form-label-error control-label" : "control-label"
//           }
//         >
//           {props.label}
//           {props.tooltip ? (
//             <span className="tooltip-icon" title={props.tooltip}>
//               <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
//             </span>
//           ) : (
//             ""
//           )}
//         </label>
//       ) : (
//         ""
//       )}
//       <div className={props.required ? "mandatory input-group" : "input-group"}>
//       {props.error ? (
//         <select {...props} className={`${props.classes.fieldStyle} form-control formerror`}>
//           <option value="">
//             {props.optionlabel ? props.optionlabel : "Select"}
//           </option>
//           {props.data &&
//             props.data.map((item) => (
//               <option key={item.key} value={item.key}>
//                 {item.value}
//               </option>
//             ))}
//         </select>
//       ) : (
//         <select {...props} className={`${props.classes.fieldStyle} form-control `}>
//           <option value="">
//             {props.optionlabel ? props.optionlabel : "Select"}
//           </option>
//           {props.data &&
//             props.data.map((item) => (
//               <option key={item.key} value={item.key}>
//                 {item.value}
//               </option>
//             ))}
//         </select>
//       )}

//       </div>
//     </div>
//   );
// }
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import CustomIcons from "./commonIcons";
import BlockingLoader from "./loader/blockingloader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function Loader(props) {
  // const [show, setOpen] = useState(false);
  return <BlockingLoader isActive={true} />;
}

export default function SelectField(props) {
  let loader = "loading...";
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [valueLabel, setValueLabel] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleOpen = () => {
    if (!props.data || props.data.length === 0) {
      if (props.getData) {
        setLoading(true);
        props.getData();
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue(props.value);
    setValueLabel(props.valueLabel);
    setData(props.data);
    setLoading(false);
  }, [props.value, props.valueLabel, props.data]);

  let dumData = [{ key: value, value: valueLabel }];
  const valdata = data ? data : dumData ? dumData : [];

  const handleClear = () => {
    let data = { target: { value: "", name: props.name } };
    props.onChange(data);
  };

  return (
    <div className="fieldset selectbox-fieldset">
      {props.label ? (
        <label
          id="demo-multiple-chip-label"
          className={
            props.error ? "form-label-error control-label" : "control-label"
          }
        >
          {props.label}
          {props && props.has_tooltip ? (
            <span
              className="icon-padding"
              data-toggle="tooltip"
              title={props.tooltip_text}
            >
              <CustomIcons iconName="help" iconClass="header-action-icons" />
            </span>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}
      <div
        className={
          props.required
            ? "mandatory input-group muiselect"
            : "input-group muiselect"
        }
      >
        {props.error ? (
          props.value ? (
            <Select
              {...props}
              title={props.title ? "" : ""}
              placeholder="test"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              open={open}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              className={
                props.disabled
                  ? "form-control formerror disabled "
                  : "form-control formerror"
              }
              onChange={props.onChange}
              displayEmpty={true}
            >
              {/* {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>} */}
              {valdata &&
                valdata.map((item) => (
                  // <Tooltip
                  //   key={item.key}
                  //   value={item.key ?? item[props.key_id]}
                  //   name={item.value}
                  //   title={item.value ?? item[props.key_name]}
                  // >
                  <MenuItem
                    key={item.key}
                    value={item.key ?? item[props.key_id]}
                    name={item.value}
                    className={props.menuClass}
                  >
                    {item.value ?? item[props.key_name]}
                  </MenuItem>
                  // </Tooltip>
                ))}
            </Select>
          ) : (
            <Select
              {...props}
              title={props.title ? "" : ""}
              placeholder="test"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              open={open}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              className={
                props.disabled
                  ? "form-control formerror disabled "
                  : "form-control formerror"
              }
              onChange={props.onChange}
              // loadingText={"test"}
              displayEmpty={true}
              renderValue={(val) =>
                !props.value ? (
                  <span style={{ color: "#888" }}>{props.placeholder}</span>
                ) : (
                  value
                )
              }
            >
              {/* {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>} */}
              {isLoading && <MenuItem>loading...</MenuItem>}
              {valdata &&
                !isLoading &&
                valdata.map((item) => (
                  // <Tooltip
                  //   key={item.key}
                  //   value={item.key ?? item[props.key_id]}
                  //   name={item.value}
                  //   title={item.value ?? item[props.key_name]}
                  // >
                  <MenuItem
                    key={item.key}
                    value={item.key ?? item[props.key_id]}
                    name={item.value}
                    className={props.menuClass}
                  >
                    {item.value ?? item[props.key_name]}
                  </MenuItem>
                  // </Tooltip>
                ))}
            </Select>
          )
        ) : (
          <>
            {!props.value ? (
              <Select
                {...props}
                title={props.title ? "" : ""}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={
                  props.disabled ? "form-control  disabled " : "form-control "
                }
                onChange={props.onChange}
                displayEmpty={true}
                renderValue={(value) => (
                  <span style={{ color: "#888" }}>{props.placeholder}</span>
                )}
              >
                {/* {(!valdata || valdata.length === 0) && (
                  <MenuItem>{loader}</MenuItem>
                )} */}
                {isLoading && <MenuItem>loading...</MenuItem>}
                {valdata &&
                  !isLoading &&
                  valdata.map((item) => (
                    // <Tooltip
                    //   key={item.key}
                    //   value={item.key ?? item[props.key_id]}
                    //   name={item.value}
                    //   title={item.value ?? item[props.key_name]}
                    // >
                    <MenuItem
                      key={item.key}
                      value={item.key ?? item[props.key_id]}
                      name={item.value}
                      className={props.menuClass}
                    >
                      {item.value ?? item[props.key_name]}
                    </MenuItem>
                    // </Tooltip>
                  ))}
              </Select>
            ) : (
              <Select
                {...props}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={
                  props.disabled ? "form-control  disabled " : "form-control "
                }
                onChange={props.onChange}
                displayEmpty={true}
                endAdornment={
                  // <InputAdornment position="end">
                  //   <button
                  //     onClick={(e) => handleClear()}
                  //     className="btn no-margin"
                  //     style={{
                  //       padding: 0,
                  //       position: "relative",
                  //       top: 1,
                  //       right: 20,
                  //     }}
                  //   >
                  //     <CustomIcons iconName="close" iconClass="icon" />
                  //   </button>
                  // </InputAdornment>
                  !props.Hideclear &&
                  !props.disabled && (
                    <InputAdornment position="end">
                      <button
                        onClick={(e) => handleClear()}
                        className="btn no-margin"
                        style={{
                          padding: 0,
                          position: "absolute",
                          top: "8px",
                          right: "30px",
                        }}
                      >
                        <CustomIcons iconName="close" iconClass="icon" />
                      </button>
                    </InputAdornment>
                  )
                }
              >
                {/* {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>} */}
                {valdata &&
                  valdata.map((item) => (
                    // <Tooltip
                    //   key={item.key}
                    //   value={item.key ?? item[props.key_id]}
                    //   name={item.value}
                    //   title={item.value ?? item[props.key_name]}
                    // >
                    <MenuItem
                      key={item.key}
                      value={item.key ?? item[props.key_id]}
                      name={item.value}
                      className={props.menuClass}
                    >
                      {item.value ?? item[props.key_name]}
                    </MenuItem>
                    // </Tooltip>
                  ))}
              </Select>
            )}
          </>
        )}
      </div>
    </div>
  );
}
