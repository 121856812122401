import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { Component } from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styleTheme = (data) => {
  if (data) {
    const themestyle = createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              opacity: "1",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              borderRadius: "12px !important",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
              color: "#222222 !important",
              borderWidth: "1px",
              borderStyle: "solid",
              alignItems: "center",
              padding: "5px 8px !important",
              minWidth: "350px",
              maxWidth: "450px",
              textAlign: "left",
              borderColor:
                data.variant === "success"
                  ? "#42A75E !important"
                  : data.variant === "error"
                    ? "#DC7676 !important"
                    : "unset",
              backgroundColor:
                data.variant === "success"
                  ? "rgba(66, 167, 94, 0.2) !important"
                  : data.variant === "error"
                    ? "rgba(220, 118, 118, 0.2) !important"
                    : "unset",
            },
          },
        },
      },
    });
    return themestyle;
  }
};
class SnackComp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { snackData, hasHtml } = this.props;
    let snackMessages = snackData?.message?.split("\n") ?? [];


    return (
      <div>
        <ThemeProvider theme={styleTheme(snackData)}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackData.open}
            autoHideDuration={6000}
            onClose={this.props.handleClose}
            message={snackData.message}
            // message={"123 \n 123"}
            severity={snackData.variant}
            key={snackData.message}
          >
            <Alert
              onClose={this.props.handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {snackMessages.map((item) => (
                hasHtml ? (
                  <div dangerouslySetInnerHTML={{__html: item}} />
                ) : (
                  <span>{item}<br></br></span>
                )
                
              ))}

            </Alert>
          </Snackbar>
        </ThemeProvider>
      </div>
    );
  }
}

export default SnackComp;
