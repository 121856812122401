import { IconButton } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Component } from "react";
import { getdynData } from "../../page_container/core_components/leads/api.functions";
import { FormatError } from "../../utils/formValidate";
import ButtonSml from "../buttonsml";
import CustomIcons from "../commonIcons";
import MultiSearchSelectBox from "../searchSelectBox/multiSearchSelectBox";
import SnackComp from "../snackbar/snack.component";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
class StatiForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackData: {},
      data: [],
      value: [],
      submit: false,
      selectiondata: [],
      formData: [],
      searchText: "",
      submoduleDataUrl: props.submoduleDataUrl,
    };
  }
  componentWillReceiveProps = (props) => {
    console.log("Received props", props)
    this.setState({
      // formData: props.formData,
      formData: [],
      submit: false,
      submoduleDataUrl: props.submoduleDataUrl,
    });
  };

  clearSelection() {
    this.setState({
      selectiondata: [],
      searchText: "",
    });
  }
  handleClose = () => {
    this.clearSelection();
    this.props.handleClose();
    this.setState({ submit: false });
  };
  handleSubmit = (e) => {
    this.setState({ submit: true });
    e.preventDefault();
    this.props.submit(this.state.selectiondata);
    this.clearSelection();
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  // getAttendies = (searchKey = "") => {
  //   if (this.state.attendies.length == 0) {
  //     this.setState({
  //       showLoader: true,
  //     });
  //   }

  //   get_Activity_Attendies({ search: searchKey }).then((res) => {
  //     this.setState({ attendies: res.data });
  //     if (!this.props.edit) {
  //       this.setState({
  //         showLoader: false,
  //       });
  //     }
  //   });
  // };

  handleMultiDataChange = (e) => {
    if (e.length > 0) {
      this.setState({
        selectiondata: e.map((item) => {
          return item.key;
        }),
        searchText: "",
      });
    } else {
      this.setState({
        formData: [],
        selectiondata: [],
        searchText: e.name,
      });
    }
    //
  };

  // getData = (search = "") => {
  //   const { formData } = this.state;
  //   getMasterData(this.props.master_key, "accounts", search).then((res) => {
  //     formData[this.props.index]["options"] = res.data
  //       ? res.data[this.props.item.api_name]
  //       : [];
  //     setTimeout(() => {
  //       this.setState({
  //         formData,
  //       });
  //     }, 150);
  //   });
  // };

  handleMultydataChange = (value) => {
    this.setState({
      selectiondata: value,
    });
  };

  getData = (search = "") => {
    console.log("inside getdata", this.state.submoduleDataUrl);
    if (this.state.submoduleDataUrl === "") return;
    this.setState({ submit: false });
    getdynData(this.state.submoduleDataUrl, search)
      .then((res) => res.data)
      .then((res) => {
        if (res.data.length !== 0) {
          this.setState({
            // open: true,
            // showLoader: false,
            formData: res.data,
            // relation: item,
            // edit: false,
            // isActive: false,
          });
        } else {
          this.setState({
            formData: [],
            // showLoader: false,
            snackData: {
              open: true,
              showLoader: false,
              // message: `No more ${item.name} available to assign`,
              message: `No data found`,
              variant: "error",
            },
          });
        }
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          showLoader: false,
          snackData: {
            open: true,
            showLoader: false,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  render() {
    const { title, classes, open, addData } = this.props;
    const { formData, data, value } = this.state;

    return (
      <div>
        <BootstrapDialog
          open={open}
          backdrop="static"
          className="assign_selectmodal"
        >
          <DialogTitle>
            {" "}
            <div className="row">
              <div className="col-10">
                <p className="dialogHeader">{"Assign " + title}</p>
              </div>
              <div className="col-2">
                <IconButton
                  className="closeButtonStyle"
                  onClick={this.handleClose}
                >
                  <CustomIcons iconName="close" />
                </IconButton>
              </div>
            </div>{" "}
          </DialogTitle>
          <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <DialogContent className="quickcreate-form">
              {/* <MultipleSelectChip
                {...this.props}
                className={`form-control`}
                // key={localeData.applied_filter}
                label={title}
                name="applied_filter"
                id="applied_filter"
                placeholder={"Select " + title}
                key_name="value"
                key_id="key"
                handleSelect={(e) => this.handleMultydataChange(e)}
                data={addData}
                // onChange={this.handleChange}
                value={this.state.selectiondata}
                tooltip="You will be able to manage the Account module & fields from here "
              /> */}
              <MultiSearchSelectBox
                {...this.props}
                id="select-applied_filter"
                required
                className={`form-control`}
                async={true}
                // readOnly
                name="applied_filter"
                defaultValue={[]}
                placeholder={"Search and Select " + title}
                fullWidth
                getData={this.getData}
                value={[]}
                inputValue={this.state.searchText}
                // onKeyDownEvent={this._handleKeyDown}
                onChange={this.handleMultiDataChange}
                // error={this.state.error["user_value"]}
                key_id="key"
                key_name="value"
                margin="normal"
                Data={formData}
              />
            </DialogContent>
            <DialogActions>
              <ButtonSml
                {...this.props}
                type="button"
                onClick={this.handleClose}
                className={`${classes.secondaryBtn} btn btn-secondary`}
                label="Cancel"
              />
              <ButtonSml
                {...this.props}
                disabled={this.state.selectiondata.length <= 0}
                type="submit"
                className={`${classes.primaryBtn} btn btn-primary`}
                label="Save"
              />
            </DialogActions>
          </form>
        </BootstrapDialog>
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default StatiForm;
