

export const cache_key_prefix = 'crm_cache_';

export const addToCache = (key, value) => {
    const myProfile = JSON.parse(localStorage.getItem('my_profile'));

    localStorage.setItem(`${cache_key_prefix}${key}_${myProfile.id}`, JSON.stringify(value));
};

export const getFromCache = (key) => {
    const myProfile = JSON.parse(localStorage.getItem('my_profile'));
    try {
        return localStorage.getItem(`${cache_key_prefix}${key}_${myProfile.id}`) ? JSON.parse(localStorage.getItem(`crm_cache_${key}_${myProfile.id}`)) : null;
    }
    catch {
        return null;
    }
};

export const clearCache = () => {
    const myProfile = JSON.parse(localStorage.getItem('my_profile'));

    Object.keys(localStorage).map((key, value) => {
        if (key.includes(cache_key_prefix) && key.includes(myProfile.id)) {
            localStorage.removeItem(key);
        }
    });
}

export const removeFromCache = (key) => {
    const myProfile = JSON.parse(localStorage.getItem('my_profile'));

    return localStorage.removeItem(`${cache_key_prefix}${key}_${myProfile.id}`);
};

// export const clearServiceWorkerCache = (key=null) => {
//     self.addEventListener('activate', function(event) {
//         event.waitUntil(
//           caches.keys().then(function(cacheNames) {
//             return Promise.all(
//               cacheNames.filter(function(cacheName) {
//                   if (key) {
//                       if (cacheName == key) {
//                           return true;
//                       }
//                       return false;
//                   }
//                   else {
//                       return true;
//                   }
//                 // Return true if you want to remove this cache,
//                 // but remember that caches are shared across
//                 // the whole origin
//               }).map(function(cacheName) {
//                 return caches.delete(cacheName);
//               })
//             );
//           })
//         );
//       });
// }

