export const configNOCN = {
    nav_footer_title: "NOCN",
    nav_footer_logo: "/assets/images/logo.png",

    nav_header_title: "/assets/images/tescalade-text.png",
    nav_header_logo: "/assets/images/tescalade.svg",

    profile_popup_name: "Tescalade CRM",
    profile_popup_logo: "",

    login_image: "./assets/images/login/tescalade.png",
    login_logo: "./assets/images/login/login-logo.png",

    rightMenu: [
        {
            name: "Timeline",
            code: "timeline"

        },
        {
            name: "Activity",
            code: "activity"
        },
        {
            name: "Notes",
            code: "notes"
        },
        {
            name: "Email",
            code: "email"
        },
        {
            name: "Form",
            code: "forms"
        },
        // {
        //     name: "Calls",
        //     code: "calls"
        // },
        // {
        //     name: "Communication Log",
        //     code: "communication_logs"
        // },
        // {
        //     name: "Attachments",
        //     code: "attachments"
        // },

    ],
    showTopCalendar: true,
    showCalendarSync: true

};

export const configASIAM = {
    nav_footer_title: "ASIAM",
    nav_footer_logo: "/assets/images/asiam/ASIAM.png",

    nav_header_title: "/assets/images/tescalade-text.png",
    nav_header_logo: "/assets/images/tescalade.svg",

    profile_popup_name: "Tescalade CRM",
    profile_popup_logo: "",

    login_image: "./assets/images/login/tescalade.png",
    login_logo: "./assets/images/asiam/Asiam_login.png",

    rightMenu: [
        {
            name: "Timeline",
            code: "timeline"
        },
        {
            name: "Calls",
            code: "calls"
        },
        {
            name: "Email",
            code: "email"
        },

        {
            name: "Communication Logs",
            code: "communication_logs"
        },
        {
            name: "Notes",
            code: "notes"
        },
        {
            name: "Attachments",
            code: "attachments"
        },

    ],

    showTopCalendar: false,
    showCalendarSync: false,


    callListFieldMapping: {
        "from_name": "name",
        "from_number": "from",
        "to_number": "to",
        "call_date": "call_date",
        "duration": "duration",
        "call_status": "call_status_id",
        "call_agent": "call_agent",
        "direction": "direction_id"
    },

    callDetailsFieldMapping: {
        "name": "name",
        "from_number": "from",
        "to_number": "to",
        "direction_id_key": "direction_id_key",
        "call_direction": "direction_id",
        "date_time": "created_at",
        "handled_by": "name",
        "answerd_by": "name",
        "created_by": "created_by",
        "call_outcome_remarks": "description",
        "callout_come": "description",
        "call_status_id": "call_status_id",
        "call_date": "call_date",
        "description": "description",
        "duration": "duration",
    },

    logListFieldMapping: {
        "created_by": "created_by",
        "created_at": "created_at",
        "notes": "notes",
        "type": "communication_type_id",
        "direction": "direction_id",
        "count": "attachments_count",
        "log_date": "log_date"
    },
    logDetailsFieldMapping: {
        "call_direction": "direction_id",
        "log_date": "log_date",
        "handled_by": "handled_by",
        "answerd_by": "created_by",
        "created_by": "created_by",
        "created_at": "created_at",
        "notes": "notes",
        "duration": "duration",
        "type": "communication_type_id"
    },
};

export const configAMTCE = {
    nav_footer_title: "AMTCE",
    nav_footer_logo: "/assets/images/logo.png",

    nav_header_title: "/assets/images/tescalade-text.png",
    nav_header_logo: "/assets/images/tescalade.svg",

    profile_popup_name: "Tescalade CRM",
    profile_popup_logo: "",

    login_image: "./assets/images/login/tescalade.png",
    login_logo: "./assets/images/login/login-logo.png",

    rightMenu: [
        {
            name: "Timeline",
            code: "timeline"

        },
        {
            name: "Activity",
            code: "activity"
        },
        {
            name: "Notes",
            code: "notes"
        },
        {
            name: "Email",
            code: "email"
        },
        {
            name: "Form",
            code: "forms"
        },
        // {
        //     name: "Calls",
        //     code: "calls"
        // },
        // {
        //     name: "Communication Log",
        //     code: "communication_logs"
        // },

    ],
    showTopCalendar: false,
    showCalendarSync: false,

};

export const getProjectItem = (key, value = "") => {

    const project = process.env.REACT_APP_PROJECT;
    // console.log("project", project);
    try {
        switch (project) {
            case "nocn":
                return configNOCN[key] ?? "--";
            case "asiam":
                return configASIAM[key] ?? "--";
            case "amtce":
                return configASIAM[key] ?? "--";
            default:
                return "--"
        }
    }
    catch (e) {
        return "";
    }
};

export const getMappedValue = (entity_key, key, item) => {
    try {
        const keys = getProjectItem(entity_key)[key].split(".")
        var value = item;
        keys.forEach((splitKey) => {
            value = value[splitKey]
        })
        return value;
    }
    catch {
        return null;
    }

}

