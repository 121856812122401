import React from "react";
import CustomIcons from "./commonIcons";

export default function Iconbutton(props) {
  return (
    <div className="iconbutton">
      <button {...props}>
        {props.iconName && (
          <CustomIcons
            iconName={props.iconName}
            iconClass={props.iconClass ? props.iconClass : ""}
          />
        )}
        &nbsp;{props.label ? props.label : null}
      </button>
    </div>
  );
}
