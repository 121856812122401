import React from "react";
import CustomIcons from "./commonIcons";
export default function TextareaField(props) {
  return (
    <div className="fieldset textarea-fieldset">
      <label
        className={
          props.error ? "form-label-error control-label" : "control-label"
        }
      >
        {props.label}
        {(props && props.has_tooltip) ? (
          <span className="icon-padding" data-toggle="tooltip" title={props.tooltip_text}>
            <CustomIcons
              iconName="help"
              iconClass="header-action-icons"
            />
          </span>
        ) : ""
        }
      </label>

      <div className={props.required ? "mandatory input-group" : "input-group"}>
        {props.error ? (
          <textarea
            {...props}
            title={props.title ? (
              ""
            ) : (
              ""
            )}
            autoComplete="off"
            rows="4"
            className={`${props.classes.fieldStyle} form-control formerror`}
          ></textarea>
        ) : (
          <textarea
            {...props}
            title={props.title ? (
              ""
            ) : (
              ""
            )}
            autoComplete="off"
            rows="4"
            className={`${props.classes.fieldStyle} form-control `}
          ></textarea>
        )}
        <span className="icon">
          <img src={props.endicon} alt={props.eniconTitle} />
        </span>
      </div>
    </div>
  );
}
