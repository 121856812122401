import React from "react";
export default function CheckboxField(props) {
  const { classes } = props;
  return (
    <div className="fieldset checkbox-box">
      {" "}
      {props.label && !props.formlabel ? (
        <label
          className={
            props.error ? "form-label-error control-label" : "control-label"
          }
        >
          {props.label}{" "}
        </label>
      ) : (
        ""
      )}{" "}
      <div className="input-group">
        <input
          // className={`${classes.checked} `}
          {...props}
          type="checkbox"
          id={`login-check${props.id}`}
        />{" "}
        <label
          className={
            props.error ? "form-label-error control-label" : "control-label"
          }
          for={`login-check${props.id}`}
        >
          {props.label}{" "}
        </label>{" "}
      </div>{" "}
    </div>
  );
}
