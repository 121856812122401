/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";

import { getdynForm } from "../../page_container/core_components/api.functions";
import { DynFormValidate, FormatError, FormValidate } from "../../utils/formValidate";
import ButtonSml from "../buttonsml";
import CustomIcons from "../commonIcons";
import DatePickerField from "../datepicker";
import BlockingLoader from "../loader/blockingloader";
import SearchSelectBox from "../searchSelectBox/searchSelectBox";
import SelectField from "../selectfield";
import SnackComp from "../snackbar/snack.component";
import TextareaField from "../textareafield";
import TextField from "../textfield";
import DynamicForm from "./dynamicform";

// import {
//   createCall,
//   getCallDetails,
//   updateCall,
// } from "../activityComponent/callslist/call.apis";

import "./modal_style.css";

let required = ["title", "description"];
let error = false;
let dropKey = 0;

// const fieldsData = [
//   {
//     api_name: "contact_name",
//     attribute_group_id: "3452053d-b33c-4563-b225-390bf0c1b93d",
//     attribute_type_slug: "text",
//     data_from: null,
//     default_value: null,
//     display_name: "Contact Name",
//     has_tooltip: false,
//     id: "9d95ffd6-729c-4312-8f67-47403e15b827",
//     is_mandatory: true,
//     is_readonly: false,
//     is_unique: false,
//     max: "60",
//     min: null,
//     placeholder_text: "Enter Contact Name",
//     sort_order: 5,
//     tooltip_text: null,
//   },
//   {
//     api_name: "contact_no",
//     attribute_group_id: "3452053d-b33c-4563-b225-390bf0c1b93d",
//     attribute_type_slug: "text",
//     data_from: null,
//     default_value: null,
//     display_name: "Contact Number",
//     has_tooltip: false,
//     id: "9d95ffd6-729c-4312-8f67-47403e15b827",
//     is_mandatory: true,
//     is_readonly: false,
//     is_unique: false,
//     max: "60",
//     min: null,
//     placeholder_text: "Enter Contact Number",
//     sort_order: 5,
//     tooltip_text: null,
//   },
//   {
//     api_name: "direction_id",
//     attribute_group_id: "3452053d-b33c-4563-b225-390bf0c1b93d",
//     attribute_type_slug: "select",
//     data_from: "directions",
//     default_value: null,
//     display_name: "Call Direction",
//     has_tooltip: false,
//     id: "74c9f9f6-91c6-4c0d-87e5-7ed880805ab0",
//     is_mandatory: false,
//     is_readonly: false,
//     is_unique: false,
//     max: "200",
//     min: null,
//     placeholder_text: "Select Call Directon",
//     sort_order: 4,
//     tooltip_text: null,
//   }
//   ,
// ];
export default function DynamicEntityModal(props) {
  const [formData, setFormData] = useState([]);
  const [selected_files, setSelectedFiles] = useState({});
  const [fieldsData, setFieldsData] = useState();
  const [snackData, setSnackData] = useState({ open: false });
  const [submit, setSubmit] = useState(true);
  const [canAssign, setCanAssign] = useState(false);
  const [master_key, setMasterKey] = useState();
  const [detailData, setDetailData] = useState({});
  const [open, setOpen] = useState(false);
  const [loderopen, setLoderopen] = useState(props.loader || props.edit ? true : false);


  const clearData = () => {
    setFormData([]);
    setSelectedFiles({});
    setFieldsData();
    setSubmit(true);
    setMasterKey();
    setDetailData({});
  }
  // const getDetails = () => {
  //   getCallDetails(props.detailId)
  //     .then((res) => res.data)
  //     .then((res) => {
  //       //
  //       setFormData({
  //         ...formData,
  //         title: res.title,
  //         description: res.description,
  //       });
  //     })
  //     .catch((err) => { });
  // };

  const getCreateFields = () => {
    // this.setState({ isActive: false });
    // this.setState({ showLoader: true });
    getdynForm(master_key)
      .then((res) => {
        if (res.data) {
          let data = res.data.sort(function (a, b) {
            return a.sort_order - b.sort_order;
          });

          setFieldsData(data);

          // this.setState(
          //   {
          //     open: true,
          //     edit: false,
          //     createData: data,
          //     isActive: false,
          //     showLoader: false,
          //   },
          //   () => { }
          // );
        }
      })
      .catch((error) => {
        var errorString = FormatError(error);
        // this.setState({
        //   isActive: false,
        //   showLoader: false,
        //   snackData: {
        //     open: true,
        //     message: errorString,
        //     variant: "error",
        //   },
        // });
      });
  };

  useEffect(() => {
    // setOpen(props.open); 
    setSnackData({
      open: false,
      message: null,
      variant: null,
    });
    setFormData({
      ...formData,
      title: "",
      description: "",
      entity: props.maindata.entity_name,
      entity_id: props.maindata.entity_id,
    });
    setMasterKey(props.master_key);
    if (props.open && props.edit && !open) {
      // getDetails();
      if (props.getDetails) {
        props.getDetails(props.detailId);
      }
    }
    //
  }, [open]);

  useEffect(() => {
    setOpen(props.open)
  }, [props.open]);
  
  useEffect(() => {
    setSubmit(props.disable);
  }, [props.disable]);

  useEffect(() => {
    if (master_key) {
      // setMasterKey(props.master_key);
      getCreateFields();
    }
    //
  }, [master_key]);

  useEffect(() => {
    // console.log("master key init props", props);

    if (props.master_key) {
      // getCreateFields();
      setMasterKey(props.master_key);
    }
    //
  }, [props.master_key]);

  useEffect(() => {
    setTimeout(() => {
      setLoderopen(false)
    }, 14000)
    
    // setDetailData(props.detailsData);
    // if (fieldsData && props.detailsData) {
    //   console.log("fields data", fieldsData);
    //   console.log("detail data", props.detailsData);
    //   const tempFieldsData = fieldsData.map((item) => {
    //     console.log("item", item);
    //     console.log("api data", props.detailsData[item.api_name]);
    //     if (item.attribute_type_slug === 'files' || item.attribute_type_slug === 'file') {

    //       var tempSelectedFiles = { ...selected_files }
    //       if (props.detailsData.files && props.detailsData.files[item.api_name] && !selected_files[item.api_name]) {
    //         tempSelectedFiles[item.api_name] = props.detailsData.files[item.api_name];
    //         console.log("files", props.detailsData.files[item.api_name])
    //         // setTimeout(() => {
    //         setSelectedFiles(
    //           tempSelectedFiles
    //         );

    //         // }, 250);

    //       }

    //       // tempSelectedFiles = [];


    //     }
    //     else {
    //       item["api_value"] = props.detailsData[item.api_name];
    //       item["api_id"] = props.detailsData[`${item.api_name}_key`]
    //         ? props.detailsData[`${item.api_name}_key`]
    //         : null;


    //     }
    //     return item;
    //   });

    //   setFieldsData(tempFieldsData);
    // }
    setData(props.detailData);
  }, [props.detailsData]);

  const setData = (detailsData) => {
    console.log("set data");
    setDetailData(props.detailsData);
    if (fieldsData && props.detailsData) {
      // console.log("fields data", fieldsData);
      // console.log("detail data", props.detailsData);
      const tempFieldsData = fieldsData.map((item) => {
        // console.log("item", item);
        // console.log("api data", props.detailsData[item.api_name]);
        if (item.attribute_type_slug === 'files' || item.attribute_type_slug === 'file') {

          var tempSelectedFiles = { ...selected_files }
          if (props.detailsData.files && props.detailsData.files[item.api_name]) {
            tempSelectedFiles[item.api_name] = selected_files[item.api_name] ? selected_files[item.api_name].filter((file) => file.isNew === true) : [];

            tempSelectedFiles[item.api_name].push(...props.detailsData.files[item.api_name]);
            // console.log("files", ...props.detailsData.files[item.api_name])
            // console.log("tempSelectedFiles[item.api_name]", tempSelectedFiles[item.api_name])
            // setTimeout(() => {
            setSelectedFiles(
              tempSelectedFiles
            );

            // }, 250);

          }

          // tempSelectedFiles = [];


        }
        else {
          item["api_value"] = props.detailsData[item.api_name];
          item["api_id"] = props.detailsData[`${item.api_name}_key`]
            ? props.detailsData[`${item.api_name}_key`]
            : null;


        }
        return item;
      });

      setFieldsData(tempFieldsData);
    }
  }

  const handleClose = () => {
    setOpen(false);
    clearData();
    setTimeout(function () {
      error = false;
      props.handleClose(false);
    }, 1000);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    const fields = fieldsData.map((field) => {
      if (field.attribute_type_slug === 'files' || field.attribute_type_slug === 'file') {
        field.api_value = selected_files[field.api_name];
      }
      return field;
    });

    setSubmit(false);
    e.preventDefault();
    let data = DynFormValidate(fields);

    let flag = false;
    var error = "";
    data.forEach((item) => {
      if (item.error) {
        flag = true;
        setSubmit(true);
        //
        if (error === "") {
          error = item.error_message
            ? item.error_message
            : "Please fill all required fields";
        } else {
          error =
            error + ", " + item.error_message
              ? item.error_message
              : "Please fill all required fields";
        }
      }
    });
    if (!flag) {
      // let datas = {};
      const form = new FormData();
      // form.append("entity", this.props.data.entity_name ?? "");
      // form.append("entity_id", this.props.data.entity_id ?? "");

      data.forEach((item) => {
        if (item && item.api_value && (item.attribute_type_slug === 'files' || item.attribute_type_slug === 'file')) {
          // datas = {
          // ...datas,
          // }
          item.api_value.forEach(
            (file, index) => {
              if (file.isNew) {
                form.append(
                  `${item.api_name}[${index}]`,
                  file.file,
                  `${file.display_name}.${file.extension}`
                );
              }

            }
          );
        }
        else {
          form.append(item.api_name, item.api_id ? item.api_id : item.api_value ? item.api_value : '')
        }
        // datas = {
        //   ...datas,
        //   [item.api_name]: item.api_id ? item.api_id : item.api_value,
        // };
      });
      props.submit(form);
    } else {
      setSubmit(true);
      setSnackData({
        open: true,

        variant: "error",
        // message: "Please fill all required fields",
        message: error ? error : "Please fill all required fields",
      });
      // this.setState({
      //   submit: false,
      //   snackData: ,
      // });
    }
  };

  const onSnackclose = () => {
    setSnackData({ open: false });
  };

  const addFiles = (e, field) => {
    const tempSelectedFiles = selected_files[field.api_name] ? selected_files[field.api_name].slice() : [];

    let files = [...e.target.files];
    if (files.length > 0) {
      files.forEach((item) => {
        var filePath = item.name.split(".");
        const fileExtension = filePath[filePath.length - 1];
        const fileName = filePath.slice(0, filePath.length - 1).join(".");

        const file_data = {
          display_name: fileName,
          name: fileName,
          file: item,
          isNew: true,
          extension: fileExtension
        };
            tempSelectedFiles.push(file_data);
      }); 
      setSelectedFiles({ ...selected_files, [field.api_name]: tempSelectedFiles });
    }
    e.target.value = null;
  };

  const handleFileData = (e, field) => {
    addFiles(e, field);
  };

  const handleTextChange = (index, e, field) => {
    // e.preventDefault();
    const tempSelectedFiles = selected_files[field.api_name].slice();
    const selected_file = tempSelectedFiles[index];
    // .splice(index, 1);
    selected_file["display_name"] = e.target.value;
    tempSelectedFiles[index] = selected_file;
    setSelectedFiles({ ...selected_files, [field.api_name]: tempSelectedFiles });
  };

  const handleRemoveAttachments = (item, index, field) => {
    if (item.isNew) {
      let tempArray = selected_files[field].filter((item, i) => {
        return i !== index;
      });
      setSelectedFiles({ ...selected_files, [field]: tempArray });
    }
    else {

      if (props.handleDeleteAttachment) {
        props.handleDeleteAttachment(item.id);
      }
    }
  };
  return (
    <>
      {/* <div className="item flex-btn"> */}
      {/* <button
          onClick={handleClickOpen}
          className={`${ props.classes.primaryBtn } btn btn - primary  btnsml`}
        >
          Create
        </button>
      </div> */}
      <Dialog
        className={
          canAssign
            ? "activity-twocolumn attachment_modal custom_Popup_Height"
            : "activity-singlecolumn attachment_modal custom_Popup_Height"
        }
        open={open}
        onClose={handleClose}
      >
        {loderopen && (
        <div className="page_overlay">
          <BlockingLoader isActive={props.edit || props.loader} />
        </div>
      )}
        <DialogTitle>
          {" "}
          <div className="row">
            <div className="col-12 p-0">
              <p className="m-0">
                {props.edit ? `Edit ${props.title}` : `Create ${props.title}`}
              </p>
            </div>
            <div className="col-2 pr-0">
              <IconButton className="closeButtonStyle" onClick={handleClose}>
                <CustomIcons iconName="close" />
              </IconButton>
            </div>
          </div>{" "}
        </DialogTitle>
        <form onSubmit={handleSubmit} noValidate>
          <DialogContent className="activity-dialogcontent">
            <div className="activity-wrap row">
              <div className="activity-form col-md-8 col-xs-12">
                <div className="row">
                  {fieldsData &&
                    fieldsData.filter((field) => field.attribute_type_slug !== 'files' || field.attribute_type_slug !== 'file').sort((a, b) => a.sort_order < b.sort_order).map((item, index) => (
                      <div div className="col-md-6 col-xs-12" >
                        {/* {console.log("item fields", item)} */}

                        <DynamicForm
                          {...props}
                          key={item.id}
                          item={item}
                          index={index}
                          formData={fieldsData}
                          master_key={master_key}
                          edit={props.edit}
                        />
                      </div>
                    ))}
                </div>

                {fieldsData &&
                  fieldsData
                    .filter((field) => {
                      return (
                        field.attribute_type_slug === "files" ||
                        field.attribute_type_slug === "file"
                      );
                    })
                    .map((field) => {
                      return (
                        <div className="row">
                          <div className="attch_Block">
                            <p className="control-label">{field.display_name}</p>
                            <a className="control-label" href="#">
                              <label for="file-inputE">
                                <svg
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.2948 7.57838L8.16816 13.7051C7.4176 14.4556 6.39962 14.8773 5.33816 14.8773C4.27671 14.8773 3.25872 14.4556 2.50816 13.7051C1.7576 12.9545 1.33594 11.9365 1.33594 10.8751C1.33594 9.8136 1.7576 8.79561 2.50816 8.04505L8.63483 1.91838C9.1352 1.41801 9.81386 1.1369 10.5215 1.1369C11.2291 1.1369 11.9078 1.41801 12.4082 1.91838C12.9085 2.41876 13.1896 3.09741 13.1896 3.80505C13.1896 4.51269 12.9085 5.19134 12.4082 5.69172L6.27483 11.8184C6.02464 12.0686 5.68531 12.2091 5.3315 12.2091C4.97768 12.2091 4.63835 12.0686 4.38816 11.8184C4.13797 11.5682 3.99742 11.2289 3.99742 10.8751C3.99742 10.5212 4.13797 10.1819 4.38816 9.93172L10.0482 4.27838"
                                    stroke="#0077BE"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Attach file
                              </label>
                            </a>
                          </div>
                          <div className="table-section">
                            <input
                              id="file-inputE"
                              type="file"
                              multiple={field.attribute_type_slug === "files"}
                              onChange={(e) => handleFileData(e, field)}
                              style={{ display: "none" }}
                            />
                            <div className="table-responsive">
                              <table className="custom_Table MuiTable-root">
                                <thead>
                                  <tr>
                                    <th>File Name</th>
                                    <th>Original File Name</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {console.log("selected files", selected_files)} */}
                                  {/* {selected_files[field.api_name] && (selected_files[field.api_name]).map((item, index) => {
                                    return (
                                      <tr>
                                        <td>
                                          <TextField
                                            {...props}
                                            name="disp_name"
                                            placeholder="Text Field"
                                            required
                                            onChange={(e) => handleTextChange(index, e, field)}
                                            key={index}
                                            value={item.display_name}
                                          />
                                        </td>
                                        <td>
                                          <p className="text">{item.name}</p>
                                        </td>
                                        <td>
                                          <a href="#" onClick={(e) =>
                                            handleRemoveAttachments(item, index, field.api_name)
                                          }>
                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M2.09375 4H3.42708H14.0938" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M5.42578 3.9987V2.66536C5.42578 2.31174 5.56626 1.9726 5.81631 1.72256C6.06635 1.47251 6.40549 1.33203 6.75912 1.33203H9.42578C9.7794 1.33203 10.1185 1.47251 10.3686 1.72256C10.6186 1.9726 10.7591 2.31174 10.7591 2.66536V3.9987M12.7591 3.9987V13.332C12.7591 13.6857 12.6186 14.0248 12.3686 14.2748C12.1185 14.5249 11.7794 14.6654 11.4258 14.6654H4.75911C4.40549 14.6654 4.06635 14.5249 3.81631 14.2748C3.56626 14.0248 3.42578 13.6857 3.42578 13.332V3.9987H12.7591Z" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </a>
                                        </td>
                                      </tr>
                                    )

                                  })} */}
                                  {selected_files[field.api_name] && selected_files[field.api_name].length !== 0 ? (
                                    <>
                                      {console.log("selected files", selected_files)}
                                      {selected_files[field.api_name] && (selected_files[field.api_name]).map((item, index) => {
                                        return (
                                          <tr>
                                            <td>
                                              <TextField
                                                {...props}
                                                hideClear
                                                name="disp_name"
                                                placeholder="Enter attachement name"
                                                required
                                                disabled={!item.isNew}
                                                onChange={(e) => handleTextChange(index, e, field)}
                                                key={index}
                                                value={item.display_name}
                                              />
                                            </td>
                                            <td>
                                              <p className="text">{item.name}</p>
                                            </td>
                                            <td>
                                              <a href="#" onClick={(e) =>
                                                handleRemoveAttachments(item, index, field.api_name)
                                              }>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M2.09375 4H3.42708H14.0938" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M5.42578 3.9987V2.66536C5.42578 2.31174 5.56626 1.9726 5.81631 1.72256C6.06635 1.47251 6.40549 1.33203 6.75912 1.33203H9.42578C9.7794 1.33203 10.1185 1.47251 10.3686 1.72256C10.6186 1.9726 10.7591 2.31174 10.7591 2.66536V3.9987M12.7591 3.9987V13.332C12.7591 13.6857 12.6186 14.0248 12.3686 14.2748C12.1185 14.5249 11.7794 14.6654 11.4258 14.6654H4.75911C4.40549 14.6654 4.06635 14.5249 3.81631 14.2748C3.56626 14.0248 3.42578 13.6857 3.42578 13.332V3.9987H12.7591Z" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                              </a>
                                            </td>
                                          </tr>
                                        )

                                      })}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>
                                        <div className="empty_Text">
                                          <p className="text">Please select files to upload</p>
                                        </div>
                                      </td>
                                    </tr>
                                  )}

                                </tbody>
                              </table>
                            </div>
                            {/* <span className={`${props.classes.primaryBtn} btn btn-primary`} onChange={(files) => handleFileData(files)}>Browse</span> */}
                            {/* <div className="attachment_List">
                              {console.log("selected files", selected_files)}
                              {selected_files[field.api_name] && (selected_files[field.api_name]).map((item, index) => {
                                return (
                                  <div
                                    className={`${props.classes.multiselectStyle} attachment_Item`}
                                  >
                                    <div className="icon">
                                      <svg
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10"
                                          stroke="#222222"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M5.16699 6.66602L8.50033 9.99935L11.8337 6.66602"
                                          stroke="#222222"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M8.5 10V2"
                                          stroke="#222222"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <TextField
                                      {...props}
                                      name="disp_name"
                                      placeholder="Text Field"
                                      required
                                      onChange={(e) => handleTextChange(index, e, field)}
                                      key={index}
                                      value={item.display_name}
                                      className={`${props.classes.linkColor} text`}
                                    />
                                     <p className={`${props.classes.linkColor} text`}>https://olivesafety.sandbox.crm.mykasafsa</p> 
                                    <a
                                      href="#"
                                      className="close_icon"
                                      onClick={(e) =>
                                        handleRemoveAttachments(item, index, field)
                                      }
                                    >
                                      <svg
                                        width="11"
                                        height="10"
                                        viewBox="0 0 11 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.2286 0.978553C10.4238 0.783291 10.4238 0.466709 10.2286 0.271447C10.0333 0.0761845 9.71671 0.0761845 9.52145 0.271447L10.2286 0.978553ZM0.771447 9.02145C0.576184 9.21671 0.576184 9.53329 0.771447 9.72855C0.966709 9.92382 1.28329 9.92382 1.47855 9.72855L0.771447 9.02145ZM1.47855 0.271447C1.28329 0.0761845 0.966709 0.0761845 0.771447 0.271447C0.576184 0.466709 0.576184 0.783291 0.771447 0.978553L1.47855 0.271447ZM9.52145 9.72855C9.71671 9.92382 10.0333 9.92382 10.2286 9.72855C10.4238 9.53329 10.4238 9.21671 10.2286 9.02145L9.52145 9.72855ZM9.52145 0.271447L0.771447 9.02145L1.47855 9.72855L10.2286 0.978553L9.52145 0.271447ZM0.771447 0.978553L9.52145 9.72855L10.2286 9.02145L1.47855 0.271447L0.771447 0.978553Z"
                                          fill="#222222"
                                        />
                                      </svg>
                                    </a>
                                  </div>
                                );
                              })}
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
              </div>
              {canAssign && (
                <div className="assignee-block col-md-4 col-xs-12">
                  <div className="activity-item">
                    <p className="head">Related to</p>
                    <SearchSelectBox
                      {...props}
                      id="select-pagesize"
                      required
                      async={true}
                      // readOnly
                      name="user_value"
                      // defaultValue={""}
                      placeholder="Add Attendees"
                      fullWidth
                      getData=""
                      // value=""
                      onKeyDownEvent=""
                      onChange=""
                      error={""}
                      key_id="userid"
                      key_name="username"
                      margin="normal"
                    />

                    <ul className="assignee-list">
                      <li>
                        <div className="img-box">
                          <img src="/assets/images/avatar-sm-default.svg" />
                        </div>
                        <p className="text">Esther Howard</p>
                        <span
                          className="close_icon"
                        // onClick={(e) =>
                        //   this.handleRemoveAttendee(ids)
                        // }
                        >
                          {/* {formData.activity_owner !== ids && (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3536 2.35355C12.5488 2.15829 12.5488 1.84171 12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12.3536 2.35355ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536C1.84171 12.5488 2.15829 12.5488 2.35355 12.3536L1.64645 11.6464ZM2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L2.35355 1.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 1.64645L1.64645 11.6464L2.35355 12.3536L12.3536 2.35355L11.6464 1.64645ZM1.64645 2.35355L11.6464 12.3536L12.3536 11.6464L2.35355 1.64645L1.64645 2.35355Z"
                                  fill="#222222"
                                />
                              </svg>
                            )} */}
                        </span>
                      </li>
                      {/* <ul className="assignee-list">
                        {formData.external_attendees?.map((item) => (
                          <li>
                            <div className="img-box">
                              <img src="/assets/images/guest-icon.svg" />
                            </div>
                            <p className="text">{item}</p>
                            <span
                              className="close_icon"
                              onClick={(e) =>
                                this.handleRemoveExternalAttendee(item)
                              }
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3536 2.35355C12.5488 2.15829 12.5488 1.84171 12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12.3536 2.35355ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536C1.84171 12.5488 2.15829 12.5488 2.35355 12.3536L1.64645 11.6464ZM2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L2.35355 1.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 1.64645L1.64645 11.6464L2.35355 12.3536L12.3536 2.35355L11.6464 1.64645ZM1.64645 2.35355L11.6464 12.3536L12.3536 11.6464L2.35355 1.64645L1.64645 2.35355Z"
                                  fill="#222222"
                                />
                              </svg>
                            </span>
                          </li>
                        ))}
                      </ul> */}
                    </ul>
                    {/* <p className="head">External</p> */}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="activity-wrap row">
              <div className="activity-form col-md-12 col-xs-12 pt-0">
                <div className="row">
                  <div className="col-md-4 col-xs-12">
                    <SelectField
                      {...props}
                      data=""
                      key_id="id"
                      key_name=""
                      error={""}
                      placeholder="Call Outcome"
                      onChange=""
                      getData=""
                      valueLabel=""
                      name=""
                      value=""
                      label="Call Outcome"
                    // tooltip={`${ act_Type } Outcome`}
                    />
                  </div>
                  <div className="col-md-8 col-xs-12">
                    <TextField
                      {...props}
                      placeholder="Call Outcome Remarks"
                      error={""}
                      onChange=""
                      value=""
                      label="Call Outcome Remarks"
                      name="outcome_desc"
                      tooltip=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </DialogContent>
          <DialogActions>
            <ButtonSml
              {...props}
              type="button"
              onClick={handleClose}
              className={`${props.classes.secondaryBtn} btn btn-secondary`}
              label="Cancel"
            />
            <ButtonSml
              {...props}
              disabled={!submit}
              type="submit"
              className={`${props.classes.primaryBtn} btn btn-primary`}
              label={props.edit ? "Update" : "Create"}
            />
          </DialogActions>
        </form>{" "}
        <SnackComp snackData={snackData} handleClose={onSnackclose} />
      </Dialog>
    </>
  );
}
