import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { getTimelineDetail } from "./common.apis";
import "./drawer_style.css";
class TimelineDetailsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timelineData: [],
      isActive: true,
      // formSearch: {
      //   entity: props.data.entity_name ?? "",
      //   entity_id: props.data.entity_id ?? "",

      //   // location: props.data.location ?? "",
      // },
    };
  }
  componentWillReceiveProps = (props) => {
    // this.setState({
    //   formSearch: {
    //     entity: props.data.entity_name ?? "",
    //     entity_id: props.data.entity_id ?? "",
    //     // location: props.data.location ?? "",
    //   },
    // });
  };
  componentDidMount = () => {
    // this.getDetails();
    if (this.props.opentimelinedetail) this.getDetails();
  };

  // componentDidMount = () => {
  //
  //   // if (this.props.opentimelinedetail) this.getDetails();
  //   this.getDetails();
  // };
  getDetails = () => {
    this.setState({ isActive: true });
    getTimelineDetail(this.props.detailId)
      // getTimelineDetail('866a6e90-3303-44b3-baf8-0448837d4b45')
      .then((res) => res.data)
      .then((res) => {
        this.setState({ timelineData: res, isActive: false });
      });
  };
  // handleTimelineDetails = (item) => {
  //   this.setState({ opentimelinedetail: true });
  // };
  // handleClose = (id) => {
  //   this.setState({ open: false });
  // };
  // filterClose = () => {
  //   this.setState({ opentimelinedetail: false });
  // };
  getChangeValue = (field, value) =>{
    if(field == "Log Date" || field == "Call Date"){  
      if(value !== "")
        return moment(new Date(value)).format("DD-MM-YYYY")
      else
        return "--"
    }else{
      if(value !== null){
        return value.toString() || "";
      }
      else
        return "--"
    }                                     
 }


  render() {
    const { classes } = this.props;
    const { timelineData, isActive } = this.state;
    const created_at_date = timelineData?.created_at
      ? moment.utc(timelineData?.created_at).format("DD-MM-YYYY")
      : "";
    const created_at_time = timelineData?.created_at
      ? moment.utc(timelineData?.created_at).format("hh:mm A")
      : "";
    const additionalData = timelineData.additional_data
      ? JSON.parse(timelineData.additional_data)
      : {};
    const conversionData = timelineData.conversion_data
      ? JSON.parse(timelineData.conversion_data)
      : {};
    //
    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opentimelinedetail}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head flex-left">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a>
            </div>
            <div className="activity-list activity-detail forms-detail ">
              <div className="item">
                {isActive ? (
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        <Skeleton variant="text" />
                      </p>
                    </div>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <>
                        <div className="row-block full-block">
                          <div className="attribute">
                            <span className="sub">
                              <Skeleton variant="text" width={100} />
                            </span>
                            <span className="text">
                              <Skeleton variant="text" width={350} />
                            </span>
                          </div>
                          <div className="attribute">
                            <span className="sub">
                              <Skeleton variant="text" width={100} />
                            </span>
                            <span className="text">
                              <Skeleton variant="text" width={350} />
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">
                          <Skeleton variant="text" width={250} />{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="details">
                    <div className="row-block">
                      <p className="text">{timelineData?.event}</p>
                    </div>
                    {/* <div className="row-block full-block">
                      <div className="attribute">
                        <span className="sub">
                          Title
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Event Title"
                          >

                          </span>
                        </span>
                        <span className="text">{timelineData?.event_title}</span>
                      </div>
                      <div className="attribute">
                        <span className="sub">
                          Description
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >

                          </span>
                        </span>
                        <span className="text">{timelineData?.event_message}</span>
                      </div>
                    </div> */}
                    {}
                    {(timelineData.event_type == "submodule_create_list" || timelineData.event_type == "attachment_list")  && (
                      <div className="row-block full-block">
                        <div className="attribute">
                          <span
                            className="sub"
                            dangerouslySetInnerHTML={{
                              __html: timelineData.relations.replaceAll(
                                ",",
                                "</br>"
                              ),
                            }}
                          >
                            {/* <span
                             className="icon-padding"
                             data-toggle="tooltip"
                             title={changes.field}
                           >
                             {timelineData.relation}
                           </span> */}
                          </span>
                          <span className="text">
                            {/* {changes.new_value ? changes.new_value : "--"} */}
                          </span>
                        </div>
                      </div>
                    )}
                    {conversionData.changes &&
                      Object.keys(conversionData.changes).length > 0 &&
                      conversionData.changes.map((changes) => (
                        <div className="row-block full-block">
                          <div className="attribute">
                            <span className="sub">
                              {changes.entity}
                              <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title={changes.name}
                              >
                                {/* <CustomIcons
                                    iconName="help"
                                    iconClass="header-action-icons"
                                  /> */}
                              </span>
                            </span>
                            <span className="text">
                              {changes.name ? changes.name.toString() : "--"}
                            </span>
                          </div>
                          {/* <div className="attribute">
                            <span className="sub">
                              {changes.field} Old Value
                              <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title={changes.field}
                              >
                                 <CustomIcons
                                      iconName="help"
                                      iconClass="header-action-icons"
                                    /> 
                              </span>
                            </span>
                            <span className="text">
                              {changes.old_value ? changes.old_value.toString() : "--"}
                            </span>
                          </div> */}
                        </div>
                      ))}
                    {additionalData.changes &&
                      Object.keys(additionalData.changes).length > 0 &&
                      additionalData.changes.map((changes) => (
                        <div className="row-block full-block">
                          <div className="attribute">
                            <span className="sub">
                              {changes.field}
                              <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title={changes.field}
                              >
                                {/* <CustomIcons
                                    iconName="help"
                                    iconClass="header-action-icons"
                                  /> */}
                              </span>
                            </span>
                            <span className="text">
                            {/* {changes.field === "Log Date" || "Call Date" ?  
                             changes.new_value = moment(new Date(changes.new_value)).format("DD-MM-YYYY")
                             : 
                              changes.new_value = changes.new_value
                                                                   
                            }

                            {changes.new_value
                                ? changes.new_value.toString()
                                : "--"} */}
                                {changes.new_value = this.getChangeValue(changes.field, changes.new_value)}
                            </span>
                          </div>
                          <div className="attribute">
                            <span className="sub">
                              {changes.field} Old Value
                              <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title={changes.field}
                              >
                                {/* <CustomIcons
                                      iconName="help"
                                      iconClass="header-action-icons"
                                    /> */}
                              </span>
                            </span>
                            <span className="text">
                            {/* {changes.field === "Log Date" || "Call Date" ?  
                             changes.old_value = moment(new Date(changes.old_value)).format("DD-MM-YYYY")
                             : 
                              changes.old_value
                                  ? changes.old_value.toString()
                                  : "--"
                                   
                            } */}


                              {/* {changes.old_value
                                ? changes.old_value.toString()
                                : "--"} */}
                              
                              {changes.old_value = this.getChangeValue(changes.field, changes.old_value)}
                            </span>
                          </div>
                        </div>
                      ))}
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">Updated by </span>
                        <span className="t-box">
                          {timelineData?.event_created_by}
                        </span>
                        <span className="t-box">{created_at_date}</span>
                        <span className="t-box">{created_at_time} </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default TimelineDetailsDrawer;
