import { withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Iconbutton from "../../common_components/IconButton";
import styles from "../../theme_style/theme.style";
import MaterialTable from "../table";
import MTableBodyRow from "./m-table-body-row";
import MTableToolbar from "./m-table-toolbar";
import MTableCell from "./tablecell";
import MTableHeader from "./tableheader";
import MTableBody from "./table_body";

const TabHeader = (
  props,
  rowData,
  e,
  orderBy,
  orderDirection,
  classes,
  fitWidth,
  data
) => {
  return (
    <React.Fragment>
      <MTableHeader
        {...props}
        {...rowData}
        classesStyle={classes}
        orderBy={orderBy}
        orderDirection={orderDirection}
        fitWidth={fitWidth}
        data={data}
      />
    </React.Fragment>
  );
};
const customBody = (props, rowData) => {
  return (
    <React.Fragment>
      <MTableBody {...props} {...rowData} />
    </React.Fragment>
  );
};
const MatCell = (
  rowData,
  columStatWidth,
  columns,
  options,
  e,
  classes,
  fitWidth,
  data
) => {
  return (
    <MTableCell
      classesStyle={classes}
      {...rowData}
      columStatWidth={columStatWidth}
      columns={columns}
      options={options}
      fitWidth={fitWidth}
      data={data}
    />
  );
};
// const TableToolbar = (
//   rowData,
//   columStatWidth,
//   columns,
//   options,
//   e,
//   classes,
//   filterData,
//   handlepagination
// ) => {
//   return (
//     <MTableToolbar
//       filterData={filterData}
//       classesStyle={classes}
//       {...rowData}
//       columStatWidth={columStatWidth}
//       columns={columns}
//       options={options}
//       handlepagination={handlepagination}
//     />
//   );
// };
class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      orderDirection: "",
      fitWidth: this.props.fitWidth,
      orderBy: -1,
      tableKey: props.tableKey,
      filterData: this.props.filterData,
    };
  }
  onChangeRowsPerPage = (pages) => {
    let data = { per_page: pages, page: 1 };

    if (this.props.custompagination && pages) {
      this.props.handlepagination(data);
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      filterData: nextProps.filterData,
      tableKey: nextProps.tableKey,
      fitWidth: nextProps.fitWidth,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.data !== nextProps.data ||
      this.state.filterData !== nextProps.filterData ||
      this.state.tableKey !== nextProps.tableKey ||
      this.state.fitWidth !== nextProps.fitWidth

      // this.state.tableKey !== nextProps.tableKey
      // this.state.fitWidth === 1 ||
      // this.state.fitWidth === 2
    ) {
      return true;
    }
    return false;
  }
  handleDynamicFilter = (order, direct) => {
    let data = {
      sort_order: direct,
      sort_by:
        order !== null && order !== -1 ? this.props.columns[order].field : "",
      per_page: this.props.options.pageSize ? this.props.options.pageSize : 15,
    };

    this.setState({ orderBy: order, orderDirection: direct });

    if (this.props.custompagination) {
      this.props.handlepagination(data);
    }
  };
  onChangePage = (page) => {
    let data = {
      page: page + 1,
    };

    if (this.props.custompagination) {
      this.props.handlepagination(data);
    }
  };

  // handleLinkCallback = (url, e) => {
  //
  //   // e.preventDefault();
  //   this.props.tablelinkAction.handleLinkCallback ?? this.props.tablelinkAction.handleLinkCallback(url);
  // };

  handleColums = () => {
    if (this.props.dyn_columns && this.props.columns) {
      let sortcolumns = this.props.columns.sort(function (a, b) {
        return a.sort_order - b.sort_order;
      });
      if (sortcolumns.length !== 0) {
        sortcolumns.forEach((item, index) => {
          if (this.props.tablelinkAction) {
            if (index === 0 || index === "0")
              sortcolumns[index] = {
                ...sortcolumns[index],
                render: (rowData) => (
                  <>
                    {this.props.tablelinkAction.pathname ? (
                      <Link
                        onClick={(e) =>
                          this.props.tablelinkAction.handleLinkCallback
                            ? this.props.tablelinkAction.handleLinkCallback(
                              rowData,
                              e
                            )
                            : ""
                        }
                        to={{
                          pathname: `${this.props.tablelinkAction.pathname}/${rowData[this.props.tablelinkAction.id]
                            }${this.props.tablelinkAction.params1
                              ? "/" + this.props.tablelinkAction.params1
                              : ""
                            }${this.props.tablelinkAction.params2
                              ? "/" +
                              rowData[this.props.tablelinkAction.params2]
                              : ""
                            }`,
                          state: rowData,
                        }}
                      // this.props.tablelinkAction.action
                      //   ? this.props.tablelinkAction.action(rowData, e)
                      //   : ""
                      >
                        {rowData[item.api_name]}
                      </Link>
                    ) : (
                      <Link
                        onClick={(e) =>
                          this.props.tablelinkAction.action
                            ? this.props.tablelinkAction.action(rowData, e)
                            : ""
                        }
                      >
                        {rowData[item.api_name]}
                      </Link>
                    )}
                  </>
                ),
              };
          }
          sortcolumns[index]["type"] =
            item.attribute_type_slug === "boolean"
              ? "boolean"
              : item.attribute_type_slug === "date"
                ? "date"
                : item.attribute_type_slug === "date_time"
                  ? "date_time"
                  : item.attribute_type_slug === "phone"
                    ? "numeric"
                    : "text";

          sortcolumns[index]["align"] =
            item.attribute_type_slug === "boolean"
              ? "center"
              : item.attribute_type_slug === "date"
                ? "center"
                : "left";
          sortcolumns[index]["title"] = item.display_name;
          sortcolumns[index]["field"] = item.api_name;
          sortcolumns[index]["sorting"] = item.is_sortable;
        });

        return sortcolumns;
      } else {
        return [];
      }
    } else return this.props.columns;
  };
  checkIcon = () => {
    const { classes } = this.props;
    return (
      <Iconbutton
        // label="Filter"
        title="Save"
        iconName="check"
        iconClass="tableButtonIcons"
        className=" icon-only"
      />
    );
  };
  clearICon = () => {
    const { classes } = this.props;
    return (
      <div style={{ marginLeft: "8px" }}>
        <Iconbutton
          // label="Filter"
          title="Clear"
          iconName="close"
          iconClass="tableButtonIcons"
          className=" icon-only"
        />
      </div>
    );
  };
  editIcon = () => {
    const { classes } = this.props;
    return (
      <Iconbutton
        // label="Filter"
        title="Edit"
        iconName="edit"
        iconClass="tableButtonIcons"
        className=" icon-only"
      />
    );
  };
  handlefitWidth = (fitWid) => {
    let id = fitWid === 0 ? 2 : fitWid === 1 ? 2 : fitWid === 2 ? 1 : 0;
    this.props.handleFitWidth(id);
  };

  render() {
    const {
      columns,
      title,
      showLoader,
      columStatWidth,
      icons,
      options,
      inLineFitWidth,
      classes,
    } = this.props;
    const { data, orderBy, orderDirection, fitWidth } = this.state;

    const hasFilter =
      (this.props.filterValues &&
        Object.keys(this.props.filterValues).length !== 0) ||
      this.props.searchValue;
    const isEmpty = data.length === 0;
    // const showEmptyFilter = isEmpty && hasFilter;
    const showEmptyFilter = isEmpty && hasFilter;
    console.log(this.state.fitWidth);
    return (
      <>
        {showLoader || !isEmpty || showEmptyFilter ? (
          <div className="table-section">
            <MaterialTable
              {...this.props}
              columns={
                this.props.columns && this.props.columns.length !== 0
                  ? this.handleColums()
                  : []
              }
              data={data}
              icons={{
                ...icons,

                Check: () => this.checkIcon(),
                Clear: () => this.clearICon(),
                Edit: () => this.editIcon(),
              }}
              title={title}
              options={{
                showEmptyFilter: { showEmptyFilter },
                currentPage:
                  options.currentPage > 0 ? options.currentPage - 1 : 0,

                dynamicFilter: true,
                totalCount: options.totalCount
                  ? options.totalCount
                  : data.length,
                ...options,
                emptyRowsWhenPaging: true,
                pageSize: this.props.options.pageSize
                  ? this.props.options.pageSize
                  : 15,
                // pageSizeOptions: this.props.options.pageSizeOptions
                //   ? this.props.options.pageSizeOptions
                //   : [15, 25, 50],
                pageSizeOptions:
                  this.props.data?.length > 0
                    ? this.props.options.pageSizeOptions
                      ? this.props.options.pageSizeOptions
                      : [15, 25, 50]
                    : [],
                // maxBodyHeight: 280,
                // actionsColumnIndex: -1,
              }}
              components={{
                Header: (rowData, e) =>
                  TabHeader(
                    this.props,
                    rowData,
                    e,
                    orderBy,
                    orderDirection,
                    classes,
                    fitWidth,
                    data,
                    this.handleColums()
                  ),
                Body: (rowData, e) => customBody(this.props, rowData, e),
                Cell: (rowData, e) =>
                  MatCell(
                    rowData,
                    columStatWidth,
                    columns,
                    options,
                    e,
                    classes,
                    fitWidth,
                    data
                  ),
                Row: (rowData, e) => (
                  <MTableBodyRow
                    classesStyle={classes}
                    {...this.props}
                    {...rowData}
                  />
                ),
                Toolbar: (rowData, e) =>
                  this.props.options.hideToolbar ? (
                    <></>
                  ) : (
                    <MTableToolbar
                      filterData={this.props.filterData}
                      {...rowData}
                      handlefitWidth={this.handlefitWidth}
                      fitWidth={fitWidth}
                      data={this.props.data}
                      master_key={this.props.master_key}
                      searchValue={this.props.searchValue}
                      handleTableFilter={this.props.handleTableFilter}
                      classesStyle={classes}
                      columStatWidth={columStatWidth}
                      filterValues={
                        this.props.filterValues ? this.props.filterValues : {}
                      }
                      columns={columns}
                      options={options}
                      handleClearSeach={this.props.handleClearSeach}
                      handlepagination={this.props.handlepagination}
                      handleRefreshData={this.props.handleRefreshData}
                      inLineFitWidth={this.props.inLineFitWidth}
                    />
                  ),
              }}
              hasFilter={hasFilter}
              onOrderChange={(orderid, direction) =>
                this.handleDynamicFilter(orderid, direction)
              }
              onChangeRowsPerPage={(page) => this.onChangeRowsPerPage(page)}
              onChangePage={(pageno) => this.onChangePage(pageno)}
            />
          </div>
        ) : (
          <div className="stretch_display">
            <div className="noRecords-block">
              <div className="fullwidth">
                <div className="img-box">
                  <img src={this.props.emptyContent?.emptyImage} />
                </div>
                <p className="text">{this.props.emptyContent?.emptyMessage}</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withStyles(styles)(DataTable);
