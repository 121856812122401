import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const UserRoute = ({
  component: Component,
  layout: Layout,
  classes,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            {Component ? (
              <Layout classes={classes} {...rest} {...props}>
                <Component
                  key={rest.computedMatch?.params?.id}
                  {...props}
                  {...rest}
                  classes={classes}
                />
              </Layout>
            ) : (
              <Layout {...rest}>
                <Redirect to="/error404" />
              </Layout>
            )}
          </>
        ) : (
          <>
            {sessionStorage.setItem("nav_path", props.location.pathname)}
            <Redirect to="/login" />
          </>
        )
      }
    />
  );
};

UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default UserRoute;
