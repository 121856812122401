import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const AdminRuote = ({
  isAuthenticated,
  component: Component,
  classes,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated &&
        (rest.user_type === "Systemadmin" || rest.user_type === "Admin") ? (
          <>
            {Component ? (
              <Layout classes={classes} {...rest} {...props}>
                <Component
                  key={rest.computedMatch?.params?.id}
                  {...props}
                  {...rest}
                  classes={classes}
                />
              </Layout>
            ) : (
              <Layout classes={classes} {...rest} {...rest}>
                <Redirect to="/error" />
              </Layout>
            )}
          </>
        ) : (
          <>
            {sessionStorage.setItem(
              "nav_path",
              rest.user_type === "Admin"
                ? props.location.pathname
                : "/user-dashboard"
            )}
            <Redirect to="/login" />
          </>
        )
      }
    />
  );
};

AdminRuote.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default AdminRuote;
