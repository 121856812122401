import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import DataTable from "../../../libraries/tablecomp/datatable";
import { FormatError, parseExpQueryParams } from "../../../utils/formValidate";
import ActionDialog from "../../actiondialogs/actiondialog.component";
import BreadCrumbsComp from "../../breadcrumbs/breadcrumbs.component";
import CommonActionMenu from "../../commonActionMenu";
import CustomIcons from "../../commonIcons";
import { delete_submodule, getDynTable } from "../../dyntable/dynapi.functions";
import BlockingLoader from "../../loader/blockingloader";
import SnackComp from "../../snackbar/snack.component";
let list_id = "";
let url = "";
let tableData = {};
export default class SubModuleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      columns: [],
      data: [],
      snackData: {},
      showLoader: false,
      master_key: "",
      type: "",
      isActive: false,
      fitWidth: 2,
      openAction: false,
      disableConfirm: false,
      actionData: {
        title: `Remove ${this.props.location.state?.name}`,
        message: "Are you sure you want to remove?",
        primary_action: "Yes",
        secondary_action: "No",
      },
      type: this.props.type,
      profile_popup_name: localStorage.getItem("profile_popup_name"),
      mainId: "",
      total: 0,
      per_page: 0,
      anchorEl: null,
      actionButton: false,
      formSearch: {
        per_page: 15,
        sort_by: "",
        sort_order: "",
        page: 0,
      },
      showTable: true,
    };
    if (this.props.leadData) {
      list_id = this.props.leadData.id;
    } else {
      list_id = this.props.match.params.id;
    }
    url = this.props.location.state?.api_url;
    tableData = this.props.location.state ?? {};
  }
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  componentWillReceiveProps = () => {
    this.setState({
      actionData: {
        title: `Remove ${this.props.location.state?.name}`,
        message: "Are you sure you want to remove?",
        primary_action: "Yes",
        secondary_action: "No",
      },
    });
  };

  componentDidMount = () => {
    if (this.props.location.state)
      this.getData(this.props.location.state?.api_url);
    this.handleBreadcrumb();
  };

  handleData(data) {
    if (this.props.handleData) {
      this.props.handleData(data);
    }
  }
  getData = (id) => {
    this.setState({ isActive: true, showLoader: true });
    getDynTable(id)
      .then((res) => res)
      .then((res) => {
        this.setState(
          {
            totalCount: res.data?.total ?? res.data?.length,
            data: res.data?.data ?? res.data,
            total: res.data?.total,
            per_page: res.data?.per_page,
            columns: res?.columns,
            isActive: false,
            master_key: res.model_key,
            showTable: true,
            showLoader: false,
          },
          () => {
            this.handleData(this.state.data);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            isActive: false,
            showLoader: false,
            snackData: {
              open: true,
              message:
                error?.response?.data?.message ??
                "Failed to perform the operation",
              variant: "error",
            },
          },
          () => {
            this.handleData([]);
          }
        );
      });
  };

  handleDeleteOpen = () => {
    this.setState({
      openAction: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      actionButton: false,
    });
  };

  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };

  handleManageActions = (event, rowData) => {
    this.setState({
      mainId: rowData.id,
      actionButton: true,
      anchorEl: event.currentTarget,
    });
  };

  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleSelectedMenu = (item) => {
    this.handleDeleteOpen();

    this.handleMenuClose();
  };

  handleDelete = () => {
    let form = {
      parent_entity: tableData.master_key,
      related_entity: tableData?.relation_model,
      parent_id: tableData?.detail_Id,
      related_ids:
        this.state.mainId instanceof Array
          ? this.state.mainId
          : [this.state.mainId],
      relation_name: tableData.relation_name,
    };
    //
    delete_submodule(form)
      .then((res) => res)
      .then((res) => {
        this.handleActionClose();
        this.setState(
          {
            disableConfirm: false,
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
          },
          () => {
            this.getData(tableData.api_url);
          }
        );
      })
      .catch((error) => {
        this.handleActionClose();
        var errorString = FormatError(error);

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleLink = (data) => {
    let user_type = localStorage.getItem("user_type");

    const url =
      tableData.url_slug && tableData.url_slug.length > 0
        ? `${tableData.url_slug}/${data.id}/1`
        : user_type !== "user"
          ? `${tableData.admin_url_slug}/${data.id}`
          : null;
    // if (url) {
    //.history.push(url);
    // }
    window.open(url);

    // return url;
    //
  };

  handleLinkCallback = (data) => {
    // let user_type = localStorage.getItem("user_type");
    //
    // const url =
    //   tableData.url_slug && tableData.url_slug.length > 0
    //     ? `${tableData.url_slug}/${data.id}/1`
    //     : user_type !== "user"
    //     ? `${tableData.admin_url_slug}/${data.id}`
    //     : null;
    //
    // this.props.history.push(url);
    // if (this.props.history.location.pathname != this.props.location.pathname) {
    // window.open(url, "_self");
    // }
  };

  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handlepagination = (data) => {
    this.setState(
      {
        formSearch: { ...this.state.formSearch, ...data },
      },
      () => {
        let Urls = url + "&" + parseExpQueryParams(this.state.formSearch);
        this.getData(Urls);
      }
    );
  };
  handleRefreshData = () => {
    const childRef = this.managedList.current;
    childRef.refreshData();
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleImport = () => {
    this.setState({
      openImport: true,
    });
  };
  handleCloseImport = () => {
    this.setState({
      openImport: false,
    });
  };
  tabChange = (id) => {
    let profile_popup_name = this.state.profile_popup_name;
    if (id === 0) {
      if (tableData.master_key.slice(-1) == 'y') {
        let master_key = tableData.master_key.slice(0, -1);

      if (profile_popup_name == 'Admin Portal') {
      this.props.history.push(`/admin/${master_key}ies/list/`);
      }
      else{
      this.props.history.push(`/${master_key}ies/list/`);
      }
    }
    else{
      if (profile_popup_name == 'Admin Portal') {
        this.props.history.push(`/admin/${tableData.master_key}s/list/`);
        }
        else{
        this.props.history.push(`/${tableData.master_key}s/list/`);
        }
    }
    } else {
      if (profile_popup_name == 'Admin Portal') {
      this.props.history.push(`/admin/${tableData.master_key}-management/${tableData.detail_Id}/1`);
      }
      else{
        this.props.history.push(`/${tableData.master_key}/${tableData.detail_Id}/1`);
      }
    }
  };
  handleFitWidth = (data) => {
    this.setState({ fitWidth: data });
  };

  handleBreadcrumb = () => {
    let profile_popup_name = this.state.profile_popup_name;

    if (tableData.master_key.slice(-1) == 'y') {
      let title = tableData.master_key.slice(0, -1);
      if (profile_popup_name == 'Admin Portal') {
        this.setState({
          type: `admin_${tableData.master_key}_list`,
        });
      }
      else {
        let type = `${title}ies`;
        this.setState({
          type: type,
        });
      }
    }
    else {
      let title = tableData.master_key;
      if (profile_popup_name == 'Admin Portal') {
        this.setState({
          type: `admin_${tableData.master_key}_list`,
        });
      }
      else {
        let type = `${title}s`;
        this.setState({
          type: type,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { data, isActive, showLoader, anchorEl, actionButton } = this.state;
    const user_type = localStorage.getItem("user_type");
    console.log("{this.state.type}", this.state.type)
    return (
      <div className="detailBg listdetails">
        <Grid className="tabview-list" container>
          <Grid className={`usertab ${"inactive-tab"}`}>
            <p
              style={{ textTransform: "capitalize" }}
              onClick={(e) => this.tabChange(0, {})}
              className="tab-title"
            >
              {tableData.master_key}
            </p>
          </Grid>

          <Grid className={`usertab`}>
            <p
              style={{ textTransform: "capitalize" }}
              onClick={(e) => this.tabChange(1, {})}
              className="tab-title"
            >
              {tableData.master_key + " Details"}{" "}
            </p>
          </Grid>
          <Grid className={`usertab ${"active-tab"}`}>
            <p style={{ textTransform: "capitalize" }} className="tab-title">
              {tableData.name}
            </p>

            <div onClick={(e) => this.tabChange(1, {})}>
              <CustomIcons iconName="close" iconClass="tab-close" />
            </div>
          </Grid>
        </Grid>
        <BreadCrumbsComp type={this.state.type == "asiam-users" ? "submodules" : this.state.type } />
        <div className="content">
          <div className="card card-content">
            <div className="card-body ">
              <Grid className="row">
                <Grid className="col-6">
                  <Grid className="column-flex">
                    <h4
                      className="title"
                      style={{ textTransform: "capitalize" }}
                    >
                      {`${tableData.name} for ${tableData.master_key} `}
                    </h4>
                    <Grid className="item flex-btn flexDisplay"></Grid>
                  </Grid>
                </Grid>
                <Grid className="col-6">
                  {/* <ButtonSml
                    {...this.props}
                    iconData={{
                      name: "add",
                      className: "primary-button-icon",
                    }}
                    onClick={(e) => this.handleAddNew()}
                    className={`${classes.primaryBtn} btn btn-primary`}
                    label="Create Lead"
                  /> */}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="card card-table tab-table-container">
            <div className="card-body ">
              <>
                {showLoader && (
                  <div className="page_overlay">
                    <BlockingLoader isActive={showLoader} />
                  </div>
                )}
                <DataTable
                  {...this.props}
                  // showLoader={showLoader}
                  columns={this.state.columns}
                  emptyContent={{
                    emptyImage: "/assets/images/illustrations/no-records.png",
                    emptyMessage:
                      "There are no records to display. Get started by adding new records to Tescalade. ",
                  }}
                  emptyBlock={true}
                  master_key={this.state.master_key}
                  dyn_columns={true}
                  tablelinkAction={{
                    link: "",
                    // action: this.handleLink,
                    id: "id",
                    name: "first_name",
                    pathname:
                      tableData.url_slug && tableData.url_slug.length > 0
                        ? `${tableData.url_slug}`
                        : user_type !== "user"
                          ? `${tableData.admin_url_slug}`
                          : null,
                    params1:
                      tableData.url_slug && tableData.url_slug.length > 0
                        ? "1"
                        : "",
                    handleLinkCallback: this.handleLinkCallback,
                  }}
                  data={data}
                  localization={{
                    header: {
                      actions: (
                        <CustomIcons
                          iconName="settings"
                          iconClass="table-action-icon-header"
                        />
                      ),
                    },
                  }}
                  actions={[
                    {
                      icon: (props) => (
                        <CustomIcons
                          iconName="menu"
                          iconClass="table-action-icons"
                        />
                      ),
                      tooltip: "Actions",
                      position: "row",
                      onClick: (event, rowData) => {
                        this.handleManageActions(event, rowData);
                      },
                    },
                  ]}
                  options={{
                    tableLayout: "fixed",
                    search: true,
                    refresh: false,
                    edit: false,
                    dynamicMenu: false,
                    list: true,
                    print: false,
                    seconderyFilter: false,
                    columnsButton: false,
                    selection: false,
                    columnResizable: true,

                    dynamicFilter: false,

                    fixedColumns: {
                      left: "",
                      right: 1,
                    },
                    currentPage: this.state.formSearch.page,
                    pageSize: this.state.formSearch.per_page,

                    totalCount: this.state.totalCount,
                    minBodyHeight: "calc(100vh - 312px)",
                    maxBodyHeight: "calc(100vh - 312px)",
                  }}
                  handleFitWidth={this.handleFitWidth}
                  fitWidth={this.state.fitWidth}
                  custompagination={true}
                  searchValue={this.state.formSearch.search}
                  filterData={this.state.filterData}
                  filterValues={this.state.filterValues}
                  handlepagination={(e) => this.handlepagination(e)}
                />
              </>
            </div>
          </div>
        </div>
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        <ActionDialog
          {...this.props}
          open={this.state.openAction}
          data={this.state.actionData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />

        <CommonActionMenu
          {...this.props}
          key={this.state.mainId}
          anchorEl={anchorEl}
          open={actionButton}
          menudata={[
            {
              id: 4,
              name: "Remove",
            },
          ]}
          showMenu={[4]}
          handleMenuClose={this.handleMenuClose}
          handleMenu={this.handleSelectedMenu}
        />
      </div>
    );
  }
}