/* eslint-disable no-unused-vars */
import withStyles from "@material-ui/core/styles/withStyles";
import Pagination from "@mui/material/Pagination";
import PropTypes from "prop-types";
import * as React from "react";
/* eslint-enable no-unused-vars */

class MTablePaginationInner extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  handleLastPageClick = (e, page) => {
    this.props.onChangePage(e, page - 1);
  };
  render() {
    const {
      classes,
      count,
      page,
      rowsPerPage,
      theme,
      showFirstLastPageButtons,
    } = this.props;

    const localization = {
      ...MTablePaginationInner.defaultProps.localization,
      ...this.props.localization,
    };

    if (count && count > 0) {
      return (
        <div className={classes.root}>
          <Pagination
            count={Math.ceil(count / rowsPerPage)}
            showFirstButton
            page={this.props.page === 0 ? 1 : this.props.page}
            onChange={this.handleLastPageClick}
            showLastButton
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    display: "flex",
    // lineHeight: '48px'
  },
});

MTablePaginationInner.propTypes = {
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  classes: PropTypes.object,
  localization: PropTypes.object,
  theme: PropTypes.any,
  showFirstLastPageButtons: PropTypes.bool,
};

MTablePaginationInner.defaultProps = {
  showFirstLastPageButtons: true,
  localization: {
    firstTooltip: "First Page",
    previousTooltip: "Previous Page",
    nextTooltip: "Next Page",
    lastTooltip: "Last Page",
    labelDisplayedRows: "{from}-{to} of {count}",
    labelRowsPerPage: "Rows per page:",
  },
};

const MTablePagination = withStyles(actionsStyles, { withTheme: true })(
  MTablePaginationInner
);

export default MTablePagination;
