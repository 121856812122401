import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
// import Iconbutton from "../../IconButton";
// import SearchBoxSecondary from "../../secondarysearchBox";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";

import { truncateField } from "../../../../utils/commonUtils";
import { getMappedValue, getProjectItem } from "../../../../utils/config";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import DynamicEntityModal from "../../../dynamicform/dynamicEntityCreateModal";
import BlockingLoader from "../../../loader/blockingloader";
import SnackComp from "../../../snackbar/snack.component";
import { deleteAttachment } from "../../attachmentlist/attachment.apis";
import CommunicationDetailDrawer from "../components/logDetail";
import { createLog, deleteLog, getLog, getLogDetails, updateLog } from "../log.apis";

import "../log_style.css";

let refreshkey = 0;
let createkey = 0;
let detailKey = 0;

class CommunicationListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logListFieldMapping: getProjectItem("logListFieldMapping") ?? {},
      logData: [],
      open: false,
      logId: "",
      detailId: "",
      isActive: true,
      edit: false,
      disableConfirm: false,
      disable:true,
      master_key: "communication-log",
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        page: 1,
        per_page: 15,
      },
      lastPage: 1,
      Sroll_Top: 1,
      onload: false,
      currentPage: 1,
      confirmData: {},
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
      logDetailsData: {},
      detailKeyvalue: false,
      showLoader: false,
      title: "",
      maxDate:new Date()
    };
    this.listInnerRef = React.createRef();
    // this.createModalRef = React.createRef();
    // this.getDetails = this.getDetails.bind(this);
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
      },
    });
  };
  componentDidMount = () => {
    if (this.props.opencommunication) this.getLog(true);
  };

  getMappedValue = (key, item) => {
    return getMappedValue("logListFieldMapping", key, item);

    // const keys = this.state.logListFieldMapping[key].split(".");
    // var value = item;
    // keys.forEach((splitKey) => {
    //   value = value[splitKey]
    // })
    // return value;
  }

  getLog = () => {
    this.setState({
      // isActive
      showLoader: true
    });
    getLog(this.state.formSearch)
      .then((res) => {
        //
        // console.log("logs list",res.data?.data);
        this.setState(
          {
            logData: res.data?.data,
            title: res.display_name,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            showLoader: false,
          },
          () => { }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false, showLoader: false, });
      });
  };
  getpagedLog = () => {
    const { logData } = this.state;
    getLog(this.state.formSearch)
      .then((res) => {
        let glbData = [...res.data?.data];
        glbData?.forEach((item) => {
          logData?.forEach((elm, ind) => {
            if (item.id === elm.id) {
              delete logData[ind];
            }
          });
          logData.push(item);
        });

        this.setState(
          {
            logData,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            onload: false,
          },
          () => {
            if (this.state.Sroll_Top > 1) {
              this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  // getpagedNote = () => {
  //   const { logData } = this.state;
  //   getLog(this.state.formSearch)
  //     .then((res) => {
  //       let glbData = [...res.data?.data];
  //       glbData?.forEach((item) => {
  //         logData?.forEach((elm, ind) => {
  //           if (item.id === elm.id) {
  //             delete logData[ind];
  //           }
  //         });
  //         logData.push(item);
  //       });

  //       this.setState(
  //         {
  //           logData,
  //           isActive: false,
  //           lastPage: res?.data?.last_page,
  //           currentPage: res?.data?.current_page,
  //           onload: false,
  //         },
  //         () => {
  //           if (this.state.Sroll_Top > 1) {
  //             this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
  //           }
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       this.setState({ isActive: false });
  //     });
  // };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };

  handleDeleteOpen = (e, item) => {
    this.setState({
      detailId: item.id,
      openAction: true,
      confirmData: {
        confirm: this.handleConfirm,
        title: getMessageForKey("delete_log_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_log_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  // handleManageActions = (event, rowData) => {
  //   this.setState({
  //     logId: rowData.id,
  //     actionButton: true,
  //     anchorEl: event.currentTarget,
  //   });
  // };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleDelete = () => {
    //
    deleteLog(this.state.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getLog();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  getDetails = (logId) => {
    getLogDetails(logId)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          logDetailsData: res,
          // snackData: {
          //   open: true,
          //   message: res.message,
          //   variant: "success",
          // },
        });
        // console.log("log details", this.createModalRef)
        // if (this.createModalRef) {
        //   console.log("log details ref")

        //   this.createModalRef.setData(res);
        // }
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleLogDetails = (item) => {
    this.setState({ openLogdetails: true, logId: item.id }, () => {
      detailKey = detailKey + 1;
    });
  };
  filterClose = () => {
    this.setState({ openLogdetails: false });
    this.getLog();
  };

  handleClickOpen = () => {
    this.setState(
      {
        edit: false,
        open: true,
      },
      () => {
        // createkey = createkey + 1;
      }
    );
  };

  handleEdit = (e, item) => {
    console.log("edit clicked")
    // createkey = createkey + 1;
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      // createkey = createkey + 1;
    });
  };

  handleAddLog = (data) => {
    this.setState({
      disable: false,
      })
    if (data) {
      // data = {
      //   ...data,
      //   entity: this.props.data.entity_name ?? "",
      //   entity_id: this.props.data.entity_id ?? "",
      // }
      data.append("entity", this.props.data.entity_name ?? "");
      data.append("entity_id", this.props.data.entity_id ?? "");

      if (this.state.edit) {
        updateLog(this.state.detailId, data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              disable: true,
              snackData: {
                open: true,
                message: (res && res?.message) ? res.message : "Log updated successfully",
                variant: "success",
              },
            });
            this.getLog();
            this.handleClose();
          })
          .catch((error) => {
            console.log("error", error);
            if (error) {
              var errorString = FormatError(error);

              this.setState({
                disableConfirm: false,
                disable: true,

                snackData: {
                  open: true,
                  message: errorString,
                  variant: "error",
                },
              });
            }

          });
      } else {
        createLog(data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              disable: true,
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });

            this.handleClose();

            this.getLog();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              disableConfirm: false,
              disable: true,
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      }
      // this.getCall();
    }
    else {
      this.setState({
        disable: true,
        disableConfirm: false,
        snackData: {
          open: true,
          message: "Could not add log",
          variant: "error",
        },
      });
    }
  }

  handleClose = (id) => {
    console.log("close", id);
    if (id && !this.state.edit) {
      this.setState({ formSearch: { ...this.state.formSearch, page: 1 } });
      this.getLog();
    } else if (id && this.state.edit) {
      this.getpagedLog();
    }

    this.setState({ open: false }, () => {
      createkey = createkey + 1;
    });
  };

  truncateDesc = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  formatDate = (date) => {
    const formattedDate = date
      ? moment.utc(date).format("DD-MM-YYYY | hh:mm A")
      : "--";
    return formattedDate;
  };
  handleSearch = (page, scrolTop) => {
    this.setState(
      {
        onload: true,
        formSearch: {
          ...this.state.formSearch,
          page: page,
          // per_page: this.state.formSearch.per_page + 10,
        },
        Sroll_Top: scrolTop,
      },
      () => {
        this.getpagedLog();
      }
    );
  };
  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;

      const { currentPage, lastPage } = this.state;
      this.listInnerRef.current.scrollTop = scrollTop;
      if (!this.state.onload) {
        if (lastPage > currentPage) {
          if (Math.trunc(scrollTop) + clientHeight === scrollHeight) {
            setTimeout(() => {
              this.handleSearch(currentPage + 1, scrollHeight);
            }, 1000);
          }
        }
      }
    }
  };
  getCallDateForItem = (item) => {
    const date_time = this.getMappedValue('log_date', item);
    const date = date_time
      ? moment.utc(date_time).format("DD/MM/YYYY")
      : "--";
    return date;
  };

  handleDeleteAttachment = () => {
    let idarray = [];
    idarray.push(this.state.attachmentId);
    let element = { "attachments_ids": idarray }
    deleteAttachment(element)
      .then((res) => res)
      .then((res) => {

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getDetails(this.state.detailId);
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleConfirmAttachmentDelete = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDeleteAttachment();
  };

  handleAttachmentDeleteOpen = (item) => {
    console.log("delete attachement")
    this.setState({
      attachmentId: item,
      openAction: true,
      confirmData: {
        confirm: this.handleConfirmAttachmentDelete,
        title: getMessageForKey("delete_attachment_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_attachment_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { logData, isActive, showLoader } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opencommunication}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        >
          <div className="note-popup">
            <div className="note-head">
              <div className="dropdown drop_left">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.title}
                  {/* <span className="icon ml-1">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3091 3.91546L1.69069 3.91546L5.99992 10.1399L10.3091 3.91546Z" fill="#0077BE" stroke="#0077BE" stroke-width="1.2" />
                    </svg>
                  </span> */}
                </a>

                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    All
                  </a>
                  <a className="dropdown-item" href="#">
                    Email
                  </a>
                  <a className="dropdown-item" href="#">
                    Letter
                  </a>
                </div> */}
              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                <div className="item ">
                  <button
                    onClick={this.handleClickOpen}
                    className={`${this.props.classes.primaryBtn} btn btn-primary  btnsml`}
                  >
                    Create
                  </button>
                </div>

              </div>
            </div>
            {isActive ? (
              <>
                <div className="note-list">
                  {[1, 2, 3].map((item) => (
                    <div className="item notes-item">
                      <div className="note_details">
                        <div className="skeltron-head">
                          <Skeleton variant="text" animation="wave" />
                        </div>
                        <div className="details">
                          <Skeleton variant="text" height={32} />
                        </div>
                      </div>
                      <div className="notes_date">
                        <span className="text-block">
                          <Skeleton variant="text" width={100} height={15} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {showLoader && (
                  <div className="page_overlay">
                    <BlockingLoader isActive={showLoader} />
                  </div>
                )}
                {logData && logData.length ? (
                  <>
                    <div
                      className="note-list calls-list activity-list"
                      ref={this.listInnerRef}
                      onScroll={(e) => this.handleScroll(e)}
                    >
                      {logData?.map((item) => (
                        <div className="item notes-item">
                          <div className="note_details">
                            <div className="tag-line tag-flex">
                              <span className={`${classes.multiselectStyle} tag tab_bordered`}>
                                {this.getMappedValue('direction', item)}
                              </span>

                              <div className="dropdown">
                                <a
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <CustomIcons
                                    iconName="tabledropdown"
                                    iconClass="icon"
                                  />
                                </a>

                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => this.handleEdit(e, item)}
                                    href="#"
                                  >
                                    Edit
                                  </a>

                                  <a
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.handleDeleteOpen(e, item)
                                    }
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="details">
                              <a
                                className={` link w-100`}
                                href="#"
                                onClick={(e) => this.handleLogDetails(item)}
                              >
                                <>
                                  {this.getMappedValue('type', item)}
                                  {/* {item.title ?? ""} */}
                                  {/* {item.title.length
                                    ? this.truncateDesc(item.title, 50)
                                    : ""} */}
                                  {/* {this.getMappedValue('created_by', item)} */}
                                </>

                                {this.getMappedValue('notes', item) && (<div className="flex_Rows">
                                  <div className="flex_Child mb-0">
                                    <span className="text">Note :</span>
                                    <span className="text ml-1">{truncateField(this.getMappedValue('notes', item))}</span>
                                  </div>
                                </div>
                                )
                                }
                              </a >
                            </div >
                          </div >
                          <div className="notes_date">
                            <span className="text-block">
                              <span className="sub">Date</span>
                              {/* <span className="t-box">
                                {this.getMappedValue('log_date', item)}
                              </span> */}
                              <span className="t-box">
                                {this.getCallDateForItem(item)}
                              </span>
                            </span>
                            <span className="text-block float-right">
                              <span className="sub">
                                <span className="t-box">
                                  {this.getMappedValue('count', item) > 0 ?
                                    <svg
                                      width="15"
                                      height="16"
                                      viewBox="0 0 15 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.2948 7.57838L8.16816 13.7051C7.4176 14.4556 6.39962 14.8773 5.33816 14.8773C4.27671 14.8773 3.25872 14.4556 2.50816 13.7051C1.7576 12.9545 1.33594 11.9365 1.33594 10.8751C1.33594 9.8136 1.7576 8.79561 2.50816 8.04505L8.63483 1.91838C9.1352 1.41801 9.81386 1.1369 10.5215 1.1369C11.2291 1.1369 11.9078 1.41801 12.4082 1.91838C12.9085 2.41876 13.1896 3.09741 13.1896 3.80505C13.1896 4.51269 12.9085 5.19134 12.4082 5.69172L6.27483 11.8184C6.02464 12.0686 5.68531 12.2091 5.3315 12.2091C4.97768 12.2091 4.63835 12.0686 4.38816 11.8184C4.13797 11.5682 3.99742 11.2289 3.99742 10.8751C3.99742 10.5212 4.13797 10.1819 4.38816 9.93172L10.0482 4.27838"
                                        stroke="#0077BE"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg> : ""
                                  }

                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="notes_date">
                            <span className="text-block">
                              <span className="sub">Created by</span>
                              <span className="t-box">
                                {item.created_by ?? "--"}
                              </span>
                              <span className="t-box">
                                | {this.formatDate(item?.created_at)} UTC
                              </span>
                            </span>
                          </div>
                        </div >

                      ))
                      }

                      {/* <Grid
                        container
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          align="center"
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <BlockingLoader
                            isActive={this.state.onload}
                          ></BlockingLoader>
                        </Grid>
                      </Grid> */}
                    </div >
                  </>
                ) : (
                  <div className="note-list popup-stretch-block">
                    <div className="empty-content">
                      <div className="img-box">
                        <img src="/assets/images/illustrations/Empty_Communication.png" />
                      </div>
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )
                }
              </>
            )}
          </div >
        </Drawer >
        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.state.confirmData.confirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        {
          this.state.open && (
            <DynamicEntityModal
              {...this.props}
              classesStyle={this.props.classesStyle}
              classes={this.props.classes}
              name="list create"
              key={createkey}
              ref={this.createModalRef}
              edit={this.state.edit}
              submit={this.handleAddLog}
              detailId={this.state.detailId}
              master_key={this.state.master_key}
              open={this.state.open}
              handleClose={this.handleClose}
              getDetails={this.getDetails}
              title={"Log"}
              detailsData={this.state.logDetailsData}
              handleDeleteAttachment={this.handleAttachmentDeleteOpen}
              maindata={this.props.data}
              max_date={this.state.maxDate}
              loader={true} 
              disable={this.state.disable}
              handledBy={true}
            />
          )
        }
        {/* <CommunicationModal
          key={createkey}
          edit={this.state.edit}
          detailId={this.state.detailId}
          open={this.state.open}
          handleClose={this.handleClose}
          {...this.props}
          maindata={this.props.data}
        /> */}
        {
          (this.state.openLogdetails && !this.state.open) && (
            <CommunicationDetailDrawer
              {...this.props}
              key={detailKey}
              maindata={this.props.data}
              detailId={this.state.logId}
              open={false}
              classesStyle={this.props.classesStyle}
              openLogdetails={this.state.openLogdetails}
              filterClose={this.filterClose}
            />
          )
        }
      </div >
    );
  }
}

export default CommunicationListDrawer;
