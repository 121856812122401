import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const GuestRoute = ({
  isAuthenticated,
  component: Component,
  classes,
  // layout: Layout,
  ...rest
}) => {
  let path = '';
  // let path = sessionStorage.getItem("nav_path") ?? "/user-dashboard";
  var user_type = localStorage.getItem("user_type")
  if (process.env.REACT_APP_PROJECT === "asiam" && user_type === "CommunitySupport"){
    path = "/query-user/list"
    sessionStorage.setItem("navId", "76756593-5d1f-446c-9de4-af2d44d138bd");
  }else{
    path = sessionStorage.getItem("nav_path") ?? "/user-dashboard";
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <>
            {Component ? (
              <Component {...props} {...rest} classes={classes} />
            ) : (
              <Redirect to="/" />
            )}
          </>
        ) : (
          <Redirect to={path} />
        )
      }
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default GuestRoute;
