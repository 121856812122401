import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { fetchFile } from "../../../../utils/commonUtils";
import { getMappedValue, getProjectItem } from "../../../../utils/config";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import SnackComp from "../../../snackbar/snack.component";
import { deleteAttachment } from "../../attachmentlist/attachment.apis";
import CommunicationModal from "../components/logModal";
// import Iconbutton from "../../../IconButton";
import "../log_style.css";
import { createLog, deleteLog, getLogDetails, updateLog } from "../log.apis";
import "../log_style.css";
import DynamicEntityModal from "../../../dynamicform/dynamicEntityCreateModal";
let refreshkey = 0;
let createkey = 1;

class CommunicationDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailId: "",
      open: props.open,
      disableConfirm: false,
      detailData: [],
      confirmData: {},
      isActive: true,
      master_key: "communication-log",
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
    };
  }
  getDetails = () => {
    this.setState({ isActive: true });
    getLogDetails(this.props.detailId)
      .then((res) => {
        this.setState({ detailData: res.data, isActive: false });
      })
      .catch((err) => { });
  };

  getMappedValue = (key) => {
    return getMappedValue("logDetailsFieldMapping", key, this.state.detailData) ?? "--";
  }

  componentDidMount = () => {
    if (this.props.openLogdetails) this.getDetails();
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      // createkey = createkey + 1;
    });
  };

  handleClose = (id) => {
    console.log("close detail", id);
    if (id) {
      this.getDetails();
      console.log("close detail", id);

    }
    this.setState({ open: false });
    // createkey = createkey + 1;
  };

  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };

  handleDeleteOpen = (e) => {
    this.setState({
      detailId: this.props.detailId,
      openAction: true,
      confirmData: {
        confirm: this.handleDelete,
        title: getMessageForKey("delete_log_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_log_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  handleDelete = () => {
    deleteLog(this.props.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.handleActionClose();

        setTimeout(() => {
          this.props.filterClose();
        }, 1000);
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  getCallDateForItem = (item) => {
    const date_time = this.getMappedValue('log_date') === '--' ? null : this.getMappedValue('log_date'); // item[this.state.callListFieldMapping['call_date']];
    const call_date = date_time
      ? moment.utc(date_time).format("DD-MM-YYYY")
      : "--";
    return call_date;
  }
  getCallTimeForItem = (item) => {
    const date_time = this.getMappedValue('log_date') === '--' ? null : this.getMappedValue('log_date');
    const call_time = date_time
      ? moment.utc(date_time).format("hh:mm A")
      : "--";

    return call_time;
  }
  handleDownload = (e, item) => {
    console.log("download", item);
    // this.downloadFile(item);
    fetchFile(item);
  };

  handleDeleteAttachment = () => {
    let idarray = [];
    idarray.push(this.state.attachmentId);
    let element = { "attachments_ids": idarray }
    deleteAttachment(element)
      .then((res) => res)
      .then((res) => {

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getDetails();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleConfirmAttachmentDelete = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDeleteAttachment();
  };

  handleAttachmentDeleteOpen = (e, item) => {
    this.setState({
      attachmentId: item.id,
      openAction: true,
      confirmData: {
        confirm: this.handleConfirmAttachmentDelete,
        title: getMessageForKey("delete_attachment_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_attachment_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  handleAddLog = (data) => {
    if (data) {
      // data = {
      //   ...data,
      //   entity: this.props.data.entity_name ?? "",
      //   entity_id: this.props.data.entity_id ?? "",
      // }
      data.append("entity", this.props.data.entity_name ?? "");
      data.append("entity_id", this.props.data.entity_id ?? "");

      if (this.state.edit) {
        updateLog(this.state.detailId, data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: (res && res?.message) ? res.message : "Log updated successfully",
                variant: "success",
              },
            });
            this.getDetails();
            this.handleClose();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      } else {
        createLog(data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });

            this.handleClose();

            this.getDetails();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      }
      // this.getCall();
    }
    else {
      this.setState({
        disableConfirm: false,
        snackData: {
          open: true,
          message: "Could not add call",
          variant: "error",
        },
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { detailData, isActive } = this.state;
    const created_at = this.getMappedValue('created_at') ? moment.utc(this.getMappedValue('created_at')) : null;

    const created_at_date = created_at
      ? created_at.format("DD-MM-YYYY")
      : "--";
    const created_at_time = created_at
      ? created_at.format("hh:mm A") + " UTC"
      : "--";


    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openLogdetails}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="note-popup">
            <div className="note-head">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a>
              <div className="righ-container">
                {/* <SearchBoxSecondary /> */}
                {/* <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                {/* <CreateModal {...this.props} /> */}
              </div>
            </div>
            <div className="note-list note-detail">
              {isActive ? (
                <div className="item">
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        <Skeleton variant="text" width={70} />
                      </p>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          <Skeleton variant="text" width={70} />
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          <Skeleton variant="text" width={360} />
                        </span>
                      </div>
                    </div>
                    <div className="details details_margin">
                      <div className="row-block">
                        <span className="text-block">
                          <span className="sub">
                            <Skeleton variant="text" width={200} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="item">
                  <div className="tag-line">
                    <span className={`${classes.tagactiveColor} tag`}>
                      {this.getMappedValue("type")}
                    </span>
                    <div className="dropdown">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <CustomIcons
                          iconName="tabledropdown"
                          iconClass="icon"
                        />
                      </a>

                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleEdit(e, detailData)}
                          href="#"
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleDeleteOpen()}
                          href="#"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="row-block">
                      <p className="text"> {this.getMappedValue('created_by')} </p>
                    </div>
                    {/* <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Title
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span>
                        </span>
                        <span className="text">{detailData.title ?? ""}</span>
                      </div>
                    </div> */}
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {`${this.getMappedValue("type")} Direction`}
                          {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('call_direction')}
                        </span>
                      </div>

                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Date
                          {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getCallDateForItem()}
                        </span>
                      </div>
                      {/* <div className="attribute">
                        <span className="sub">
                          Duration

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('duration')}
                        </span>
                      </div> */}
                    </div>
                    {/* <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                        Call Record
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span>
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {detailData.description ?? "--"}
                        </span>
                      </div>
                    </div> */}
                    <div className="row-block">
                      {/* <div className="attribute">
                        <span className="sub">
                          {`${this.getMappedValue("type")}  Answered By`}
                       
                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('answerd_by')}
                        </span>
                      </div> */}
                      <div className="attribute">
                        <span className="sub">
                          {`${this.getMappedValue("type")}  Handled By`}

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('handled_by')}
                        </span>
                      </div>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Notes
                          {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                        </span>
                        <span
                          className={` text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('notes')}
                        </span>
                      </div>
                    </div>
                    {(detailData.files && Object.keys(detailData.files).length > 0) && (
                      Object.keys(detailData.files).map((key, index) => {
                        return (
                          <div className="row-block">

                            {console.log("attachment index", index)}
                            {detailData.files[key].length > 0 && (
                              <div className="flex-column attach_Flex">
                                <p className="text  capitalize">
                                  {key}
                                </p>
                                {detailData.files[key].map((item) => {
                                  return (
                                    <div
                                      className="attachment-list"

                                    >
                                      {console.log("attachment item", item)}
                                      <div
                                        className={`${classes.groupActive} list-item`}
                                      >
                                        <span className="icon" onClick={(e) =>
                                          this.handleDownload(
                                            e,
                                            item
                                          )
                                        }>
                                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5 10.3359V13.0026C14.5 13.3562 14.3595 13.6954 14.1095 13.9454C13.8594 14.1955 13.5203 14.3359 13.1667 14.3359H3.83333C3.47971 14.3359 3.14057 14.1955 2.89052 13.9454C2.64048 13.6954 2.5 13.3562 2.5 13.0026V10.3359" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.00195L8.50033 10.3353L11.8337 7.00195" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.5 10.3359V2.33594" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                        </span>
                                        <p className={`${classes.tabButton} list-item`}>
                                          {item['display_name']}
                                        </p>
                                        <a href="#" className="close-icon" onClick={(e) => this.handleAttachmentDeleteOpen(e, item)}>
                                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.3536 2.35355C12.5488 2.15829 12.5488 1.84171 12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12.3536 2.35355ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536C1.84171 12.5488 2.15829 12.5488 2.35355 12.3536L1.64645 11.6464ZM2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L2.35355 1.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 1.64645L1.64645 11.6464L2.35355 12.3536L12.3536 2.35355L11.6464 1.64645ZM1.64645 2.35355L11.6464 12.3536L12.3536 11.6464L2.35355 1.64645L1.64645 2.35355Z" fill="#222222" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>)
                                })}

                                {/* <div
                              className="attachment-list"
                            // onClick={(e) =>
                            //   this.downloadAttachment(
                            //     e,
                            //     attachment
                            //   )
                            // }
                            >
                              <div
                                className={`${classes.groupActive} list-item`}
                              >
                                <span className="icon">
                                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 10.3359V13.0026C14.5 13.3562 14.3595 13.6954 14.1095 13.9454C13.8594 14.1955 13.5203 14.3359 13.1667 14.3359H3.83333C3.47971 14.3359 3.14057 14.1955 2.89052 13.9454C2.64048 13.6954 2.5 13.3562 2.5 13.0026V10.3359" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.16699 7.00195L8.50033 10.3353L11.8337 7.00195" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.5 10.3359V2.33594" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </span>
                                <p className={`${classes.tabButton} list-item`}>
                                  https://olivesafety.sandbox.crm.mykasafsa
                                </p>
                              </div>
                            </div> */}

                              </div>
                            )}

                          </div>)
                      })

                    )}


                  </div>
                  <div className="details details_margin">
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">Created by</span>
                        <span className="t-box">
                          {this.getMappedValue('created_by')}
                        </span>
                        <span className="t-box">{created_at_date}</span>
                        <span className="t-box">{created_at_time}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>

        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.state.confirmData.confirm}
          disableConfirm={this.state.disableConfirm}
        />
        {
          this.state.open && (
            <DynamicEntityModal
              {...this.props}
              key={createkey}
              edit={true}
              submit={this.handleAddLog}
              detailId={this.state.detailId}
              master_key={this.state.master_key}
              open={this.state.open}
              handleClose={this.handleClose}
              getDetails={this.getDetails}
              title={"Log"}
              detailsData={this.state.detailData}
              maindata={this.props.data}
            />
          )
        }
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default CommunicationDrawer;
