import { LogLevel } from "@azure/msal-browser";

export const msalConfig = () => {
  let auth_data = localStorage.getItem("loginCred")
    ? JSON.parse(atob(localStorage.getItem("loginCred")))
    : {};

  let baseurl = process.env.REACT_APP_BASE_URL;
  let mal = {
    auth: {
      clientId: auth_data?.clientId,
      //clientId: "3f3c0101-f61b-46af-af13-8d17bf2bc6bb",
      // authority: auth_data?.urlAuthorize,
      authority: "https://login.microsoftonline.com/common/",
      //redirectUri: `https://develop.api.nocn.tescalade.com/api/c/v1/email/callback/`,
      // redirectUri: "http://localhost:3000/common/verify/",
      redirectUri: "https://develop.nocn.tescalade.com/common/verify/",
      client_secret: auth_data?.clientSecret,
      navigateToLoginRequestUrl: false,
      prompt: "consent",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };
  return mal;
};

export const loginRequest = () => {
  let auth_data = localStorage.getItem("loginCred")
    ? JSON.parse(atob(localStorage.getItem("loginCred")))
    : {};
  let log = {
    scopes: [auth_data?.scopes],

    // scopes: [
    //   "https://graph.microsoft.com/Mail.ReadWrite",
    //   "https://graph.microsoft.com/mail.send",
    //   "https://graph.microsoft.com/offline_access",
    //   "openid", "profile",
    // ]
    //scopes: ["User.Read"],
  };
  return log;
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
