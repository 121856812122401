import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import CustomIcons from "../commonIcons";
class DatePickerField extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      value: "",
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({});
  };
  handleDateRngePickerChange = (value) => {
    const { values } = this.state;

    if (value !== undefined) {
      let date = [];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          date.push(new DateObject(item).format());
        });

        if (date) {
          this.props.onChange({ value: date.join(","), name: this.props.name });
        } else {
          this.props.onChange({ value: null, name: this.props.name });
        }
      }
    } else {
      this.props.onChange({ values: null, name: this.props.name });
    }
  };
  handleDatePickerChange = (value) => {
    const { values } = this.state;

    if (value !== undefined) {
      let date = new DateObject(value).format();

      if (date) {
        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ values: null, name: this.props.name });
    }
  };
  handleClear = () => {
    this.props.onChange({ value: null, name: this.props.name });
  };
  render() {
    const { selected } = this.props;
    const { value } = this.state;
    //
    return (
      <div className="fieldset">
        {this.props.label ? (
          <label
            className={
              this.props.error
                ? "form-label-error control-label"
                : "control-label"
            }
          >
            {this.props.label}
            {this.props.tooltip ? (
              <span className="tooltip-icon" title={this.props.tooltip}>
                <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
        <div
          className={
            this.props.required ? "mandatory input-group" : "input-group"
          }
        >
          {this.props.range ? (
            <DatePicker
              {...this.props}
              inputClass={
                this.props.error
                  ? `${this.props.classesStyle.fieldStyle} form-control formerror`
                  : `${this.props.classesStyle.fieldStyle} form-control `
              }
              className="custom-calendar"
              // render={<InputIcon/>}
              format="DD-MM-YYYY"
              value={
                this.props.value ? this.props.value.split(",") : ""
                // ? moment(this.props.value, "DD-MM-YYYY").format("DD-MM-YYYY")
                // : ""
              }
              range
              editable={false}
              title={this.props.label}
              onChange={this.handleDateRngePickerChange}
            />
          ) : (
            <DatePicker
              {...this.props}
              inputClass={
                this.props.error
                  ? `${this.props.classesStyle.fieldStyle} form-control formerror`
                  : `${this.props.classesStyle.fieldStyle} form-control `
              }
              className="custom-calendar"
              // render={<InputIcon/>}
              editable={false}
              format="DD-MM-YYYY"
              value={
                this.props.value ? this.props.value : ""
                // ? moment(this.props.value, "DD-MM-YYYY").format("DD-MM-YYYY")
                // : ""
              }
              title={this.props.label}
              onChange={this.handleDatePickerChange}
            />
          )}

          <span className="icon">
            {this.props.value && (
              <span
                style={{
                  position: "relative",
                  top: 3,
                  padding: 1,
                  cursor: "pointer",

                  fontSize: 14,
                }}
                onClick={(e) => this.handleClear()}
              >
                <CustomIcons iconName="close" iconClass="icon" />
              </span>
            )}
            <img src="/assets/images/icons/calendar.svg" alt="datepicker" />
          </span>
        </div>
      </div>
    );
  }
}

export default DatePickerField;
