import apis from "../../apis/api.actions";

export const getMasterData = (key, filed, model_id) =>
  apis.getDatas(
    `/attribute/options/${key}?filter=${filed}&model_id=${
      model_id ? model_id : ""
    }`
  );
export const getSearchMasterData = (key, filed, model_id, values) =>
  apis.getDatas(
    `/attribute/options/${key}?paginate=true&per_page=15&search=${values}&filter=${filed}&model_id=${
      model_id ? model_id : ""
    }`
  );
