import { Skeleton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import moment from "moment";
import React, { Component } from "react";
import BlockingLoader from "../../loader/blockingloader";
import "../drawer_style.css";
import { getConversations, getTagggedMails } from "./api.functions";
import EmailDetailsDrawer from "./components/emailDetail";
class EmailListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.searchData,
      activeMail: {},
      mailsList: [],
      conversations: [],
      loadingDetails: false,
      currentPage: 1,
      lastPage: null,
      isActive: false,
      showLoader: false,
    };
    this.listInnerRef = React.createRef();
  }

  componentDidMount = () => {
    this.getData(1);
  };
  componentWillReceiveProps = (props) => {
    this.setState({
      data: props.searchData,
    });
  };
  getSender = (senderData) => {
    const sender = senderData instanceof Array ? senderData : [senderData];
    return sender;
  };

  getSenderDisplayName = (senderData) => {
    const sender = this.getSender(senderData);
    //
    //
    //
    const response =
      sender[0]?.name !== undefined &&
      sender[0]?.name !== null &&
      sender[0]?.name.trim() !== ""
        ? sender[0]?.name
        : sender[0].emailAddress;
    //
    return response;
  };
  getData = (page) => {
    let sdata = {
      entity: this.state.data.entity_name,
      entity_id: this.state.data.entity_id,
      page: page,
    };
    this.setState({
       isActive: page === 1 ? true : false,
      showLoader: true,
      });
    getTagggedMails(sdata).then((res) => {
      const newMails = res.data.data.filter(
        (ar) =>
          !this.state.mailsList.find(
            (rm) => rm.id === ar.id && ar.folder_id === rm.folder_id
          )
      );
      let data = [...newMails, ...this.state.mailsList];
      this.setState({
        mailsList: data,
        isActive: false,
        currentPage: res.data.current_page,
        lastPage: res.data.last_page,
        showLoader: false,
      });
    });
    // .catch((error) => {
    //   this.setState({
    //     isActive: false,
    //     snackData: {
    //       open: true,
    //       message: error.response.data.message,
    //       variant: "error",
    //     },
    //   });
    // });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleEmailDetails = (mail) => {
    this.setState({ loadingDetails: true, openemaildetail: true });
    let data = {
      mail_id: mail.id,
      mailbox_id: mail.mailbox_id,
    };

    getConversations(data).then((res) => {
      // let results = res.data.data;

      this.setState({
        activeMail: mail,
        conversations: res.data,
        loadingDetails: false,
      });
    });
  };
  filterClose = () => {
    this.setState({ openemaildetail: false });
  };
  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;
      const { currentPage, lastPage } = this.state;
      //
      //
      if (!this.state.loading) {
        if (lastPage > currentPage) {
          if (scrollTop + clientHeight === scrollHeight) {
            this.setState(
              {
                isLazyLoading: true,
              },
              () => this.getData(currentPage + 1)
            );
          }
        }
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { filterData, mailsList, isActive, showLoader } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openemail}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head">
              <div className="dropdown">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Emails
                  {/* <CustomIcons iconName="expand" iconClass="icon" /> */}
                </a>

                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    All Mailbox
                  </a>
                </div> */}
              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                {/* <CreateModal {...this.props} /> */}
              </div>
            </div>
            <div className="activity-list conversation-wrapper">
              <ul
                className="list-block"
                ref={this.listInnerRef}
                onScroll={(e) => this.handleScroll(e)}
              >
                {isActive ? (
                  <>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                      <li
                        // onClick={(e) => this.fetchConversation(data)}
                        className={`${classes.highlightColor}`}
                      >
                        <div className="leftside">
                          <p className="title">
                            <Skeleton />
                          </p>
                          <p className="text bold">
                            {" "}
                            <Skeleton />
                          </p>
                          <p className="text">
                            <Skeleton />
                          </p>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                  {showLoader && (
                    <div className="page_overlay">
                      <BlockingLoader isActive={showLoader} />
                    </div>
                  )}
                    {mailsList.length !== 0 ? (
                      <>
                        {mailsList.map((mails) => (
                          <li onClick={(e) => this.handleEmailDetails(mails)}>
                            <div
                              className="leftside"
                              style={{ width: "calc(100% - 500px)" }}
                            >
                              {mails.is_unread && (
                                <span
                                  className={`${classes.emailactiveColor} unread`}
                                ></span>
                              )}
                              <div className="title">
                                {this.getSenderDisplayName(mails?.sender)}
                              </div>
                              <div className="text bold">{mails.subject}</div>
                              <div className="text shortenText">
                                {mails.mail_content}
                              </div>
                              <div className="tags">
                                {Object.keys(mails.tags).map((key) => (
                                  <span
                                    className={`${classes.multiselectStyle} tag`}
                                  >
                                    {key}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div className="rightside">
                              <div className="text">
                                {moment(mails.receivedDateTime).format(
                                  "DD-MMM"
                                )}
                              </div>
                              {mails.hasAttachments && (
                                <span className="attachment">
                                  <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.2948 7.36667L8.16816 13.4933C7.4176 14.2439 6.39962 14.6656 5.33816 14.6656C4.27671 14.6656 3.25872 14.2439 2.50816 13.4933C1.7576 12.7428 1.33594 11.7248 1.33594 10.6633C1.33594 9.60188 1.7576 8.5839 2.50816 7.83334L8.63483 1.70667C9.1352 1.20629 9.81386 0.925186 10.5215 0.925186C11.2291 0.925186 11.9078 1.20629 12.4082 1.70667C12.9085 2.20704 13.1896 2.8857 13.1896 3.59334C13.1896 4.30097 12.9085 4.97963 12.4082 5.48L6.27483 11.6067C6.02464 11.8569 5.68531 11.9974 5.3315 11.9974C4.97768 11.9974 4.63835 11.8569 4.38816 11.6067C4.13797 11.3565 3.99742 11.0172 3.99742 10.6633C3.99742 10.3095 4.13797 9.97019 4.38816 9.72L10.0482 4.06667"
                                      stroke="#222222"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </li>
                        ))}
                      </>
                    ) : (
                      <>
                        {this.state.isActive ? (
                          <>
                            {[1, 2, 3, 4].map((item) => (
                              <li>
                                <div className="leftside">
                                  <p className="title">
                                    <Skeleton />
                                  </p>
                                  <p className="text bold">
                                    {" "}
                                    <Skeleton />
                                  </p>
                                  <p className="text shortenText">
                                    <Skeleton />
                                  </p>
                                  <div className="tags">
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      <Skeleton width={30} />
                                    </span>
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      <Skeleton width={30} />
                                    </span>
                                  </div>
                                </div>
                                <div className="rightside">
                                  <p className="text">
                                    <Skeleton />
                                  </p>
                                </div>
                              </li>
                            ))}
                          </>
                        ) : (
                          <div className="activity-list popup-stretch-block">
                            <div className="empty-content">
                              <div className="img-box">
                                <img src="/assets/images/illustrations/no-email.png" />
                              </div>
                              <p className="text">
                                There are no mails to display.
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </Drawer>
        <EmailDetailsDrawer
          {...this.props}
          classesStyle={this.props.classesStyle}
          activeMail={this.state.activeMail}
          data={this.state.data}
          conversations={this.state.conversations}
          openemaildetail={this.state.openemaildetail}
          filterClose={this.filterClose}
          loadingDetails={this.state.loadingDetails}
          // loadingDetails={true}
        />
      </div>
    );
  }
}

export default EmailListDrawer;
