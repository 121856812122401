import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { getDateTimeFormat } from "../../utils/timezone";
import { get_Timeline } from "./common.apis";
import "./drawer_style.css";
import TimelineDetailsDrawer from "./timelineDetails";

// let detailKey = 0;
class TimelineListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      timelineData: [],
      isActive: true,
      detailKey: 0,
      icon_base_path: "",
      // opentimelinedetail: false,
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        group_by: "date",

        // location: props.data.location ?? "",
      },
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        group_by: "date",

        // location: props.data.location ?? "",
      },
    });
  };
  componentDidMount = () => {
    this.getDetails();
  };
  getDetails = () => {
    get_Timeline(this.state.formSearch)
      // .then((res) => res.data)
      .then((res) => {
        this.setState({
          timelineData: res.data.data,
          isActive: false,
          icon_base_path: res?.icon_base_path,
        });
      });
  };
  handleTimelineDetails = (item) => {
    // this.setState({ opentimelinedetail: true });
    this.setState({
      detailId: item.id,
      opentimelinedetail: true,
      detailKey: this.state.detailKey + 1,
    });
  };
  // , () => {
  //   detailKey = detailKey + 1;
  // }
  filterClose = () => {
    this.setState({ opentimelinedetail: false });
  };
  formatTime = (date_time) => {
    // return moment(new Date(date_time)).format("DD-MM-YYYY hh:mm A");
    return moment.utc(date_time).format(getDateTimeFormat("date_time"));
  };
  // getParsedMessage = (data) => {
  //   if (data) {
  //     const parsedMessage = parseKeysFromNotificationMessage(data['message'], data['message_data']);
  //     return parsedMessage;
  //   } else {
  //     return null;
  //   }
  // };

  render() {
    const { classes } = this.props;
    const { filterData, timelineData, isActive } = this.state;
    const today = moment(new Date()).format("DD-MM-YY");

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opentimeline}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head">
              <p className="head">Timeline</p>
            </div>
            {isActive ? (
              <>
                <div className="activity-list timeline-list">
                  {[1, 2, 3].map((item) => (
                    <>
                      <div className="item-head">
                        <span className="date">
                          <Skeleton variant="text" animation="wave" />
                        </span>
                      </div>

                      <div className="timeline-item">
                        <span className="icon-box">
                          <Skeleton variant="circular" width={40} height={40} />
                        </span>
                        <div className="item-box cursor">
                          <div className="">
                            <p className={`${classes.tabButton} type disabled`}>
                              <Skeleton variant="text" />
                            </p>

                            <div className="right-container">
                              <span className="datetime">
                                <Skeleton variant="text" />
                              </span>
                            </div>
                          </div>

                          <p className="text disabled">
                            <Skeleton variant="text" />
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <>
                {timelineData.length != 0 ? (
                  <div className="activity-list timeline-list">
                    {Object.keys(timelineData)?.map((item) => (
                      <>
                        <div className="item-head">
                          <span className="date">
                            {item === today ? "Today" : item}
                          </span>
                        </div>
                        {timelineData[item]?.map((item) => (
                          <div className="timeline-item">
                            <span className="icon-box">
                              {/* <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.60194 0.972376C1.14603 2.06124 1.25031 2.02204 1.13766 2.27379C1.10044 2.35695 1.09375 3.31997 1.09375 8.58646V14.801H0.546875H0V15.4005V16H8H16V15.4005V14.801H12.2656H8.53125V9.93256V5.06408H11.2031H13.875V8.35212V11.6402H14.3906H14.9062V7.973C14.9062 5.92202 14.894 4.26841 14.8785 4.22089C14.8315 4.07741 14.655 3.90545 14.5235 3.87519C14.2021 3.80122 7.82175 3.87922 7.73078 3.95821C7.49353 4.16417 7.51663 3.58919 7.5075 9.5174L7.49937 14.801H4.81219H2.125V8.90437V3.00769L4.80469 2.17028L7.48438 1.33286L7.49288 1.99952L7.50138 2.66617H8.01631H8.53125V1.53628C8.53125 -0.434688 8.89984 -0.381788 4.60194 0.972376ZM3.57628 3.91207C3.56778 3.9379 3.56469 4.21173 3.56947 4.52063L3.57812 5.08225L4.80469 5.09177L6.03125 5.10125V4.48321V3.86513H4.81153C3.84341 3.86513 3.58859 3.87483 3.57628 3.91207ZM3.58334 6.61426C3.57188 6.62755 3.5625 6.89732 3.5625 7.21373V7.78898H4.79688H6.03125V7.1895V6.59002H4.81772C4.15025 6.59002 3.59478 6.60092 3.58334 6.61426ZM9.96875 7.18906V7.78898H11.2039H12.4391L12.4305 7.19858L12.4219 6.60819L11.1953 6.59867L9.96875 6.58919V7.18906ZM3.56953 9.90531L3.57812 10.4957L4.80469 10.5052L6.03125 10.5147V9.91483V9.31492H4.79609H3.56094L3.56953 9.90531ZM9.96875 9.91483V10.5147L11.1953 10.5052L12.4219 10.4957L12.4305 9.90531L12.4391 9.31492H11.2039H9.96875V9.91483ZM3.57628 12.0504C3.56778 12.0763 3.56469 12.3501 3.56947 12.659L3.57812 13.2206L4.80469 13.2301L6.03125 13.2396V12.6216V12.0035H4.81153C3.84341 12.0035 3.58859 12.0132 3.57628 12.0504ZM9.96875 12.6211V13.2396L11.1953 13.2301L12.4219 13.2206V12.6211V12.0217L11.1953 12.0121L9.96875 12.0026V12.6211ZM13.875 13.2569V13.8201H14.3906H14.9062V13.2569V12.6938H14.3906H13.875V13.2569Z"
                                  fill="#0077BE"
                                />
                              </svg> */}

                              <img
                                src={`${this.state.icon_base_path}/${item.icon_path}`}
                                alt={item.icon_slug}
                              />
                            </span>
                            <div className="item-box cursor">
                              <div className="flex-block">
                                {(item.event_type &&
                                  item.event_type
                                    .toLowerCase()
                                    .includes("submodule_create_list")) ||

                                  (item.event_type &&
                                    item.event_type
                                      .toLowerCase()
                                      .includes("attachment_list")) ||

                                (item.event_type &&
                                  item.event_type
                                    .toLowerCase()
                                    .includes("submodule_create_list")) ||
                                item.event.toLowerCase().includes("update") ||
                                item.event.toLowerCase().includes("convert") ? (
                                  <p
                                    className={`${classes.tabButton} type`}
                                    onClick={(e) =>
                                      this.handleTimelineDetails(item)
                                    }
                                  >
                                    {item.event}
                                    <span className="icon">
                                      <svg
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.49968 14.6673C12.1816 14.6673 15.1663 11.6826 15.1663 8.00065C15.1663 4.31875 12.1816 1.33398 8.49968 1.33398C4.81778 1.33398 1.83301 4.31875 1.83301 8.00065C1.83301 11.6826 4.81778 14.6673 8.49968 14.6673Z"
                                          stroke="#0077BE"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M8.5 10.6673L11.1667 8.00065L8.5 5.33398"
                                          stroke="#0077BE"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M5.83301 8H11.1663"
                                          stroke="#0077BE"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    className={`${classes.tabButton} type disabled`}
                                  >
                                    {item.event}
                                  </p>
                                )}

                                <div className="right-container">
                                  <span className="datetime">
                                    {this.formatTime(item.date_time)}
                                  </span>
                                  {/* <div className="dropdown">
                          <a
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <CustomIcons
                              iconName="tabledropdown"
                              iconClass="icon"
                            />
                          </a>

                          <div className="dropdown-menu">
                            <a className="dropdown-item" href="#">
                              All Task
                            </a>
                            <a className="dropdown-item" href="#">
                              Upcoming Tasks
                            </a>
                            <a className="dropdown-item" href="#">
                              Overdue Tasks
                            </a>
                            <a className="dropdown-item" href="#">
                              Closed Tasks
                            </a>
                          </div>
                        </div> */}
                                </div>
                              </div>
                              {/* <p
                      className="text"
                      onClick={(e) => this.handleTimelineDetails(item)}
                    >
                      {item.event_message}
                    </p> */}
                              {(item.event_type &&
                                item.event_type
                                  .toLowerCase()
                                  .includes("submodule_create_list")) ||
                              (item.event_type &&
                                item.event_type
                                  .toLowerCase()
                                  .includes("submodule_create_list")) ||
                              item.event.toLowerCase().includes("update") ||
                              item.event.toLowerCase().includes("convert") ? (
                                <p
                                  className="text"
                                  onClick={(e) =>
                                    this.handleTimelineDetails(item)
                                  }
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item?.event_message,
                                    }}
                                  />
                                </p>
                              ) : (
                                <p className="text disabled">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item?.event_message,
                                    }}
                                  />
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="activity-list timeline-list popup-stretch-block">
                    <div className="empty-content">
                      <div className="img-box">
                        <img src="/assets/images/illustrations/no-timeline.png" />
                      </div>
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
        <TimelineDetailsDrawer
          {...this.props}
          key={this.state.detailKey}
          detailId={this.state.detailId}
          classesStyle={this.props.classesStyle}
          opentimelinedetail={this.state.opentimelinedetail}
          filterClose={this.filterClose}
        />
      </div>
    );
  }
}

export default TimelineListDrawer;
