import { Skeleton } from "@mui/material";
import React, { Component } from "react";
import DynamicFormSingle from "../../../../../common_components/dynamicform/dynamicformSingle";

class LeadConvertSelect extends Component {
  constructor(props) {
    super(props);

    const fieldData = props.fieldData;

    fieldData[props.masterKey] = fieldData[props.masterKey]
      .filter((item) => {
        if (item && item.lead_field_name) {
          return item;
        }
      })
      .sort((a, b) => (a.lead_field_name > b.lead_field_name ? 1 : -1));
    const leadData = props.leadData;
    leadData[props.masterKey] = leadData[props.masterKey]
      .filter((item) => {
        if (item && item.lead_field_name) {
          return item;
        }
      })
      .sort((a, b) => (a.lead_field_name > b.lead_field_name ? 1 : -1));

    this.state = {
      fieldData: fieldData, //fields from selected item
      leadData: leadData, //fields from lead
      masterkey: props.masterKey,
    };
  }
  componentDidMount = () => { };
  componentWillReceiveProps = (props) => {
    const fieldData = props.fieldData;
    fieldData[props.masterKey] = fieldData[props.masterKey]
      .filter((item) => {
        if (item && item.lead_field_name) {
          return item;
        }
      })
      .sort((a, b) => (a.lead_field_name > b.lead_field_name ? 1 : -1));

    const leadData = props.leadData;
    leadData[props.masterKey] = leadData[props.masterKey]
      .filter((item) => {
        if (item && item.lead_field_name) {
          return item;
        }
      })
      .sort((a, b) => (a.lead_field_name > b.lead_field_name ? 1 : -1));

    this.setState({
      fieldData: fieldData, //fields from selected item
      leadData: leadData, //fields from lead

      masterkey: props.masterKey,
    });
  };

  selectItemCheck = (sel) => {
    const { fieldData, leadData, masterkey } = this.state;

    fieldData[masterkey].forEach((item) => {
      if (item.model_field_name === sel.model_field_name) {
        item["selected"] = true;
      }
    });

    leadData[masterkey].forEach((item) => {
      if (item.model_field_name === sel.model_field_name) {
        item["selected"] = false;
      }
    });

    this.setState({ leadData, fieldData });
  };

  selectUserFieldCheck = (sel) => {
    const { fieldData, leadData, masterkey } = this.state;

    leadData[masterkey].forEach((item) => {
      if (item.model_field_name === sel.model_field_name) {
        item["selected"] = true;
      }
    });
    fieldData[masterkey].forEach((item) => {
      if (item.model_field_name === sel.model_field_name) {
        item["selected"] = false;
      }
    });
    this.setState({ leadData, fieldData });
  };

  userFieldSelectAll = () => {
    const { fieldData, leadData, masterkey } = this.state;
    leadData[masterkey].forEach((item) => {
      item["selected"] = true;

      fieldData[masterkey].forEach((field) => {
        if (item.model_field_name === field.model_field_name) {
          field["selected"] = false;
        }
      });
    });

    this.setState({ leadData, fieldData });
  };

  itemFieldSelectAll = () => {
    const { fieldData, leadData, masterkey } = this.state;
    fieldData[masterkey].forEach((item) => {
      item["selected"] = true;
    });
    leadData[masterkey].forEach((item) => {
      item["selected"] = false;
    });
    this.setState({ leadData, fieldData });
  };

  fetchSubmitData = () => {
    this.props.fetchValuesFromChild(this.state.fieldData, this.state.leadData);
  };

  getItemValue = (item) => {
    return item.data.attribute_type_slug == "multi_select" || item.data.attribute_type_slug == "search_multi_select"
      ? item.data?.api_value && item.data?.api_value.length == 0
        ? "--"
        : item.data?.api_value.join(", ")
      : item.data?.api_value
        ? item.data?.api_value
        : "--";
  };

  render() {
    const { classes, isActive } = this.props;
    const { masterkey, leadData, fieldData } = this.state;

    return (
      <>
        <div className="info-box">
          <p className="text">
            {/* {`Select an existing ${masterkey} or create a new one`} */}
            Choose the data to be retained in the newly selected record. Ensure
            all mandatory fields contain data and click on Convert to proceed.
          </p>
        </div>
        <div className="item_flex">
          <div className="list-head">
            <p className="text">
              Fields from Selected
              <span
                style={{ textTransform: "capitalize" }}
              >{` ${masterkey}`}</span>
            </p>
            <a
              className={`${classes.tabButton} link`}
              onClick={(e) => this.itemFieldSelectAll(e)}
            >
              ( Select all )
            </a>
          </div>
          <div className="list-head">
            <p className="text">Fields from Lead</p>
            <a
              className={`${classes.tabButton} link`}
              onClick={(e) => this.userFieldSelectAll(e)}
            >
              ( Select all )
            </a>
          </div>
        </div>

        <div className="leadconvert-selectlist">
          <div className="left-list">
            {fieldData[masterkey]?.length !== 0 &&
              fieldData[masterkey].map((item) => (
                <>
                  {leadData[masterkey].map(
                    (field, index) =>
                      field.model_field_name === item.model_field_name && (
                        <>
                          {isActive ? (
                            <div className="item">
                              <div className="item_lflex">
                                <div className="checkbox-fieldset">
                                  {/* <input
                                  type="radio"
                                  checked={item.selected}
                                  onChange={(e) => this.selectItemCheck(item)}
                                /> */}
                                  <label className="radio-label">
                                    <div className="fieldset">
                                      <label className="control-label">
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={80}
                                          height={15}
                                        />
                                      </label>
                                      <p className="textfield">
                                        {/*  {/* {item.data?.api_value ?? "--"} */}
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={120}
                                          height={20}
                                        />
                                        {/* // {this.getItemValue(item)} */}
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="item_rflex">
                                <div className="checkbox-fieldset">
                                  <label className="radio-label">
                                    <div className="fieldset">
                                      <label className="control-label">
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={80}
                                          height={15}
                                        />
                                      </label>
                                      <p className="textfield">
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={120}
                                          height={20}
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="item">
                                <div className="item_lflex">
                                  <div className="checkbox-fieldset">
                                    <input
                                      type="radio"
                                      checked={item.selected}
                                      onChange={(e) =>
                                        this.selectItemCheck(item)
                                      }
                                    />
                                    <label className="radio-label">
                                      <div className="fieldset">
                                        <label className="control-label">
                                          {item.attribute?.display_name}
                                        </label>
                                        <p className="textfield">
                                          {/* {item.data?.api_value ?? "--"} */}

                                          {this.getItemValue(item)}
                                        </p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="item_rflex">
                                  <div className="checkbox-fieldset">
                                    <input
                                      type="radio"
                                      checked={field.selected}
                                      onChange={(e) =>
                                        this.selectUserFieldCheck(field)
                                      }
                                    />

                                    {/* item['attributes'] */}
                                    <label className="radio-label">
                                      <DynamicFormSingle
                                        keyName={"attributes"}
                                        validation={item.attribute}
                                        item={field.attributes}
                                        // item={leadData[masterkey][index].attributes}
                                        value={field.value}
                                        // value={leadData[masterkey][index].value}
                                        index={index}
                                        editable={!field.selected}
                                        master_key={masterkey}
                                        formData={leadData}
                                        mode={"select"}
                                        {...this.props}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )
                  )}
                </>
              ))}
          </div>
        </div>
      </>
    );
  }
}

export default LeadConvertSelect;
