import apis from "../../../apis/api.actions";
import { parseQueryParams } from "../../../utils/formValidate";

export const getLogDetails = (key) =>
  apis.getDatas(`/communication-log/${key}`).then((res) => res);
export const getLog = (key) =>
  apis.getDatas(`/communication-log${parseQueryParams(key)}`).then((res) => res);
export const createLog = (data) =>
  apis.createData(`/communication-log`, data).then((res) => res);
export const updateLog = (id, data) =>
  apis.putPostData(`/communication-log/${id}`, data).then((res) => res);
// apis.updateData(`/communication-log/${id}`, data).then((res) => res);
export const deleteLog = (id) =>
  apis.deleteData(`/communication-log/${id}`).then((res) => res);
