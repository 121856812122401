import apis from "../../apis/api.actions";
import { addToCache, getFromCache } from "../../utils/cacheManager";
import { parseQueryParams } from "../../utils/formValidate";

export const dynFilter = (key) => {
  const cache_key = `dyn_filter_${key}`;
  if (getFromCache(cache_key)) {
    return Promise.resolve(getFromCache(cache_key));
  } else {
    return apis.getDatas(`/attribute/filters/${key}`).then((res) => {
      try {
        addToCache(cache_key, res);
        return res;
      } catch (exception) { }
    });
  }
};

export const getdynForm = (key) => {
  console.log("keyyy", key)
  const cache_key = `dyn_quick_create_${key}`;
  if (getFromCache(cache_key)) {
    return Promise.resolve({ data: getFromCache(cache_key) });
  } else {
    return apis
      .getDatas(`/attribute/keys/${key}?quick_create=true`)
      .then((res) => {
        try {
          addToCache(cache_key, res.data);
          return res;
        } catch (exception) { }
      });
  }
};

export const submitCreateAssignDyndata = (data) =>
  apis.createData("/create-assign-related-entity/", data).then((res) => res);

export const getdynData = (data) => apis.getDatas(data).then((res) => res);
export const submitDyndata = (data) =>
  apis.createData("/sync-relations/", data).then((res) => res);
export const searchableEntities = () =>
  apis.getDatas(`/searchable/entities`).then((res) => res);

// export const getManagelist = (key) =>
//   apis.getDatas(`/managed-list/${key}`).then((res) => res);

export const getManagelist = (key) => {
  const cache_key = `managed_list_${key}`;

  if (getFromCache(cache_key)) {
    return Promise.resolve(getFromCache(cache_key));
  } else {
    return apis.getDatas(`/managed-list/${key}`).then((res) => {
      try {
        addToCache(cache_key, res);
        return res;
      } catch (exception) { }
    });
  }
};

export const getManagelistRow = (url, data) =>
  apis.getDatas(`/${url}${parseQueryParams(data)}`).then((res) => res);

