import React, { Component } from "react";
import DetailsField from "../../detailsfield";
import moment from "moment";
import { Skeleton } from "@mui/material";
class OverviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
    };
  }
  handleManageEdit = () => {
    this.setState({ editable: true });
  };
  handleManageSave = () => {
    this.setState({ editable: false });
  };
  handleValue = (type,value) => {

    if(value && type == "boolean"){
          if(value == true){
          return "true";
          }
          else if(value == false){
          return "false";
          }
        }
    else if (value && value.trim().length > 0){

        let dataValue = value;

        if(type == "date" && value !== null){
            dataValue = moment(value).format("DD-MM-YYYY");
        }
        else if(type == "time" && value !== ""){
            dataValue = moment(value).format("HH:mm:ss");
        }
        else if(type == "date_time" && value !== ""){
            dataValue = moment(value).format("YYYY-MM-DD HH:mm:ss");
        }
      return dataValue;
    }
    else{
    return "--";
    }
  };

  render() {
    const { classes,overview, isActive} = this.props;
    const { editable } = this.state;

    return (
      <>
        {(overview && overview.data?.fields && overview.data?.fields.length > 0) && (
          <div className="card card-accordion">
            <div className="card-body">
              <div class="accordion">
                {isActive ? (
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button class="accordion-button disabled">
                        <Skeleton variant="text" animation="wave" width={80} height={15} />
                      </button>
                    </h2>
                    <div class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        <div className="row">
                          {[1, 2, 3, 4, 5].map((item) => (
                            <div class="fieldset dataset">
                              <label class="control-label"><Skeleton variant="text" animation="wave" width={60} height={16} /></label>
                              <div class="input-group">
                                <p class="text"><Skeleton variant="text" animation="wave" width={120} height={20} /></p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button disabled">
                        {overview.name}
                      </button>
                    </h2>
                    <div class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        <div className="row">
                          {overview.data?.fields?.map((item) => (
                            <DetailsField
                              {...this.props}
                              tooltip={item.tooltip}
                              label={item.label}
                              item={item}
                              value={
                                this.handleValue(item.attribute_type_slug,item.value)
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        )}
      </>


    );
  }
}

export default OverviewComponent;
