import React, { Component } from "react";
import DynamicFormSingle from "../../../../../common_components/dynamicform/dynamicformSingle";
class LeadConvertCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      masterkey: props.masterkey,
      leadData: props.leadData,
      fieldData: props.fieldData,
      mappingData: props.mappingData,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      masterkey: props.masterkey,
      leadData: props.leadData,
      fieldData: props.fieldData,
    });
  };

  fetchSubmitData = () => {
    this.props.fetchValuesFromChild(this.state.fieldData, this.state.leadData);
  };

  render() {
    const { classes } = this.props;
    const { masterkey, fieldData, mappingData } = this.state;
    //
    //

    return (
      <>
        <div className="info-box">
          <p className="text">
            The details from the selected lead are pre-populated against the
            corresponding fields in the new record. Ensure all mandatory fields
            contain data and click Convert to proceed.
          </p>
        </div>
        <div className="list-head">
          <p className="text">{this.props.title} </p>
        </div>
        <div className="leadconvert-selectlist leadconvert-createlist">
          <div className="leadconvert-create">
            {fieldData[masterkey]?.length !== 0 &&
              fieldData[masterkey].map((item, index) => (
                // <div className="item">
                <>
                  <DynamicFormSingle
                    keyName={"attribute"}
                    item={item.attribute}
                    value={item?.value}
                    index={index}
                    master_key={masterkey}
                    formData={fieldData}
                    mode={"create"}
                    menuprops="email_expand_lead"
                    {...this.props}
                  />
                  {/* </div> */}
                </>
              ))}
            {/* </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default LeadConvertCreate;
