import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { debounce } from "throttle-debounce";
import CustomIcons from "../commonIcons";
export default class AutoComplete extends React.Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      loading: false,
    };
    this.searchDebounceData = debounce(500, this.autocompleteSearch);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleChange = (e, value, reason) => {
    let data = { key: value?.key ?? "", value: value?.value ?? "" };
    this.props.onChange(data);
  };

  setopen = () => {
    this.setState(
      {
        loading:
          this.props.data?.length === 0 && this.props.getData ? true : false,
        open: true,
      },
      () => {
        if (this.props.data?.length === 0 && this.props.getData) {
          this.props.getData(this.props.valueLabel ?? "");
          setTimeout(() => {
            this.setState({ loading: false });
          }, 2000);
        }
      }
    );
  };
  setClose = () => {
    this.setState({ open: false });
  };
  autocompleteSearch = (value) => {
    this.props.getData(value);
  };
  handleInputChange = (e, value, reason) => {
    if (reason === "input") {
      if (value.toString().length > 1) {
        this.setState({ loading: true }, () => {
          this.searchDebounceData(value);
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1500);
        });
      }
      let data = { key: "", value: value };
      this.props.onChange(data);
    } else if (reason === "reset" && value) {
      let data = { key: "", value: value };
      this.props.onChange(data);
    }
  };

  render() {
    const { open, loading } = this.state;
    const { multiple, data, valueLabel, value } = this.props;

    return (
      <div className="fieldset selectbox-fieldset">
        {this.props.label ? (
          <label
            id="demo-multiple-chip-label"
            className={
              this.props.error
                ? "form-label-error control-label"
                : "control-label"
            }
          >
            {this.props.label}
            {this.props && this.props.has_tooltip ? (
              <span
                className="icon-padding"
                data-toggle="tooltip"
                title={this.props.item.tooltip_text}
              >
                <CustomIcons iconName="help" iconClass="header-action-icons" />
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
        <div
          className={
            this.props.required
              ? "mandatory input-group muiselect"
              : "input-group muiselect"
          }
        >
          <Autocomplete
            id="AutoComplete-demo"
            fullWidth
            open={open}
            required={this.props.required}
            onOpen={(e) => {
              this.setopen();
            }}
            onClose={(e) => {
              this.setClose();
            }}
            onInputChange={(event, newInputValue, reason) => {
              this.handleInputChange(event, newInputValue, reason);
            }}
            multiple={multiple ? true : false}
            onChange={this.handleChange}
            disabled={this.props.disabled}
            isOptionEqualToValue={(option, values) =>
              option.value === values.value
            }
            inputValue={valueLabel ? valueLabel : ""}
            getOptionLabel={(option) => option.value}
            options={this.props.data ?? []}
            loading={loading}
            error={this.props.error}
            className={
              this.props.disabled
                ? "form-control formerror disabled "
                : this.props.error
                  ? "form-control formerror"
                  : "form-control"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                // className={`form-control formerror`}
                placeholder={valueLabel ? "" : this.props.placeholder}
                error={this.props.error}
                variant="standard"
                value={valueLabel ? valueLabel : ""}
                InputProps={{
                  disableUnderline: true,
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
    );
  }
}
