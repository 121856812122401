import React from "react";
import CustomIcons from "./commonIcons";

export default function SearchBox(props) {
  const handleSearch = () => {
    if (props.handleSearch) {
      props.handleSearch();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.handleSearch();
    }
  };
  return (
    <div className="seach-con">
      <fieldset className="input-group" style={{ display: "flex" }}>
        <input
          {...props}
          className="form-control nobg inp-margin"
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {props.value && (
          <button
            onClick={(e) => props.handleClose()}
            className="btn no-margin"
          >
            <CustomIcons iconName="close" iconClass="icon" />
          </button>
        )}
        <button onClick={(e) => handleSearch()} className="btn no-margin">
          <CustomIcons iconName="search" iconClass="icon" />
        </button>
      </fieldset>
    </div>
  );
}
