import React, { Component } from "react";
import { EmailValidate } from "../../utils/formValidate";

import CheckboxField from "../CheckboxField";
import DatePickerField from "../datepicker";
import MultipleSelectChip from "../multiselct/multi_select";
import SelectField from "../selectfield";
import TextareaField from "../textareafield";
import TextField from "../textfield";
import { getMasterData } from "./api.functions";

class DynamicFormSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.props.formData,
      index: this.props.index,
      item: this.props.item,
      master_key: this.props.master_key,
      keyName: this.props.keyName,
      mode: this.props.mode ? this.props.mode : "create",
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      formData: this.props.formData,
      index: this.props.index,
      item: this.props.item,
      master_key: this.props.master_key,
      keyName: this.props.keyName,
      mode: this.props.mode ? this.props.mode : "create",
    });
  };

  handleChange = (e) => {
    const { formData, item, master_key, index, keyName } = this.state;
    //
    if (item.attribute_type_slug === "email") {
      let erroremail = !EmailValidate(e.target.value);
      if (
        e.target.value !== "" &&
        e.target.value !== null &&
        e.target.value !== undefined
      ) {
        formData[master_key][index][keyName]["error"] = erroremail;
      } else {
        formData[master_key][index][keyName]["error"] = false;
      }
    } else if (item.attribute_type_slug === "phone") {
      let regx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      let errorPhone = !e.target.value.match(regx);
      if (
        e.target.value !== "" &&
        e.target.value !== null &&
        e.target.value !== undefined
      ) {
        formData[master_key][index][keyName]["error"] = errorPhone;
      } else {
        formData[master_key][index][keyName]["error"] = false;
      }
    }
    formData[master_key][index][keyName]["api_value"] = e.target.value;
    //
    //   "api_value"
    // ])
    this.setState({
      formData,
    });
  };

  handleDatePickerChange = (e) => {
    const { formData } = this.state;
    formData[this.props.master_key][this.props.index][this.props.keyName][
      "api_value"
    ] = e.value;
    this.setState({
      formData,
    });
  };

  handlecheckChange = (e) => {
    const { formData } = this.state;
    formData[this.props.master_key][this.props.index][this.props.keyName][
      "api_value"
    ] = e.target.checked;
    this.setState({
      formData,
    });
  };

  handleSelectChange = (e) => {
    const { formData } = this.state;

    if (
      !this.props.item.options &&
      this.props.item.options !== null &&
      this.props.item.options !== null
    ) {
      getMasterData(
        this.state.mode == "select" ? "lead" : this.props.master_key,
        this.props.item.api_name
      ).then((res) => {
        formData[this.props.master_key][this.props.index][this.props.keyName][
          "options"
        ] = res.data ? res.data[this.props.item.api_name] : [];
        setTimeout(() => {
          this.setState({
            formData,
          });
        }, 150);
      });
    }
  };

  handleMultiSelectChange = (e) => {
    const { formData } = this.state;

    // if (
    //   !this.props.item.options &&
    //   this.props.item.options !== null &&
    //   this.props.item.options !== null
    // ) {
    getMasterData(
      this.state.mode == "select" ? "lead" : this.props.master_key,
      this.props.item.api_name
    ).then((res) => {
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "options"
      ] = res.data ? res.data[this.props.item.api_name] : [];
      setTimeout(() => {
        this.setState({
          formData,
        });
      }, 150);
    });
    // }
  };

  handleSelectdataChange = (e) => {
    const { formData } = this.state;
    if (e.target.value === "") {
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_id"
      ] = "";
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_value"
      ] = "";
    } else {
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_id"
      ] = e.target.value;
    }

    // formData[this.props.master_key][this.props.index][this.props.keyName][
    //   "api_id"
    // ] = e.target.value;
    this.setState({
      formData,
    });
  };
  handleMultydataChange = (value) => {
    const { formData } = this.state;
    if (value === "") {
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_value"
      ] = value;
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_id"
      ] = value;
    } else {
      formData[this.props.master_key][this.props.index][this.props.keyName][
        "api_id"
      ] = value;
    }

    this.setState({
      formData,
    });
  };
  handleAddNew = () => {};

  render() {
    const { item, value, validation } = this.props;
    const is_editable = item.is_readonly ? true : this.props.editable;
    //
    //

    switch (item.attribute_type_slug) {
      case "text":
      case "password":
      case "email":
        //
        return (
          // <input value={value?.api_name}></input>

          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={is_editable}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            error={item.error}
            value={item?.api_value ?? ""}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "concat":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={true}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            error={item.error}
            value={item?.api_value ?? ""}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "auto":
      case "auto_number":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={true}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            error={item.error}
            value={item?.api_value ?? ""}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "select":
      case "search_select":
        return (
          <SelectField
            {...this.props}
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleSelectdataChange}
            label={item.display_name}
            getData={this.handleSelectChange}
            error={item.error}
            disabled={is_editable}
            data={item.options}
            valueLabel={item?.api_value ?? ""}
            value={item.api_id}
            name={item.api_name}
            placeholder={item.placeholder_text}
            menuClass={this.props.menuprops}
          />
        );
      case "search_multi_select":
      case "multi_select":
        return (
          <MultipleSelectChip
            {...this.props}
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            handleSelect={this.handleMultydataChange}
            label={item.display_name}
            getData={this.handleMultiSelectChange}
            error={item.error}
            disabled={is_editable}
            className={`form-control`}
            data={item.options}
            key_name="value"
            key_id="key"
            valueLabel={item?.api_value ?? ""}
            value={item.api_id}
            name={item.api_name}
            placeholder={item.placeholder_text}
            menuClass={this.props.menuprops}
          />
        );
      case "date":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            error={item.error}
            value={item?.api_value ?? ""}
            name={item.api_name}
            disabled={is_editable}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
      case "date_time":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            error={item.error}
            value={item?.api_value ?? ""}
            name={item.api_name}
            timepicker={true}
            disabled={is_editable}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
      case "number":
      case "float":
      case "currency":
      case "decimal":
        return (
          <TextField
            {...this.props}
            type="number"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item?.api_value ?? ""}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
      case "checkbox":
        return (
          <CheckboxField
            {...this.props}
            type="checkbox"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handlecheckChange}
            label={item.display_name}
            checked={item?.api_value ?? ""}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item?.api_value ?? ""}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
      case "boolean":
        return (
          <CheckboxField
            {...this.props}
            type="checkbox"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handlecheckChange}
            label={item.display_name}
            checked={item?.api_value ?? ""}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item?.api_value ?? ""}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
      case "textarea":
        return (
          <TextareaField
            {...this.props}
            type="text"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            disabled={is_editable}
            name={item.api_name}
            value={item?.api_value ?? ""}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );

      default:
        return (
          <TextField
            {...this.props}
            type="text"
            key={item.id}
            required={validation ? validation.is_mandatory : item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            disabled={is_editable}
            name={item.api_name}
            value={item?.api_value ?? ""}
            maxlength={
              validation ? validation.character_limit : item.character_limit
            }
            placeholder={item.placeholder_text}
          />
        );
    }
  }
}

export default DynamicFormSingle;
