import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import SnackComp from "../../../snackbar/snack.component";
import NoteModal from "../components/noteModal";
// import Iconbutton from "../../../IconButton";
import { delete_Note, get_Note_Details } from "../note.apis";
import "../note_style.css";

let refreshkey = 0;
let createkey = 1;

class NoteDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailId: "",
      open: false,
      disableConfirm: false,
      detailData: [],
      confirmData: {},
      isActive: true,
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
    };
  }
  getDetails = () => {
    this.setState({ isActive: true });
    get_Note_Details(this.props.detailId)
      .then((res) => {
        this.setState({ detailData: res.data, isActive: false });
      })
      .catch((err) => {});
  };
  componentDidMount = () => {
    if (this.props.opennotedetails) this.getDetails();
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };
  handleClose = (id) => {
    if (id) {
      this.getDetails();
    }
    this.setState({ open: false });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleDeleteOpen = (e) => {
    this.setState({
      detailId: this.props.detailId,
      openAction: true,
      confirmData: {
        title: getMessageForKey("delete_note_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_note_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };
  handleDelete = () => {
    delete_Note(this.props.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.handleActionClose();

        setTimeout(() => {
          this.props.filterClose();
        }, 1000);
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  render() {
    const { classes } = this.props;
    const { detailData, isActive } = this.state;

    const created_at_date = detailData?.created_at
      ? moment.utc(detailData?.created_at).format("DD-MM-YYYY")
      : "--";
    const created_at_time = detailData?.created_at
      ? moment.utc(detailData?.created_at).format("hh:mm A")
      : "--";

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opennotedetails}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="note-popup">
            <div className="note-head">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a>
              <div className="righ-container">
                {/* <SearchBoxSecondary /> */}
                {/* <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                {/* <CreateModal {...this.props} /> */}
              </div>
            </div>
            <div className="note-list note-detail">
              {isActive ? (
                <div className="item">
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        <Skeleton variant="text" width={70} />
                      </p>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          <Skeleton variant="text" width={70} />
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          <Skeleton variant="text" width={360} />
                        </span>
                      </div>
                    </div>
                    <div className="details details_margin">
                      <div className="row-block">
                        <span className="text-block">
                          <span className="sub">
                            <Skeleton variant="text" width={200} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="item">
                  <div className="tag-line">
                    <span className={`${classes.tagactiveColor} tag`}>
                      Detailed Note View
                    </span>
                    <div className="dropdown">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <CustomIcons
                          iconName="tabledropdown"
                          iconClass="icon"
                        />
                      </a>

                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleEdit(e, detailData)}
                          href="#"
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleDeleteOpen()}
                          href="#"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="row-block">
                      <p className="text">{detailData.title ?? "--"}</p>
                    </div>
                    {/* <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Title
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span>
                        </span>
                        <span className="text">{detailData.title ?? ""}</span>
                      </div>
                    </div> */}
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Description
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span>
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {detailData.description ?? "--"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="details details_margin">
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">Created by</span>
                        <span className="t-box">
                          {detailData.created_by ?? "--"}
                        </span>
                        <span className="t-box">{created_at_date}</span>
                        <span className="t-box">{created_at_time}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>

        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        {this.state.open && (
          <NoteModal
            key={createkey}
            edit={true}
            detailId={this.state.detailId}
            open={this.state.open}
            handleClose={this.handleClose}
            {...this.props}
            maindata={this.props.maindata}
          />
        )}
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default NoteDetailDrawer;
