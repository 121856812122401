// import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import MenuItem from "@material-ui/core/MenuItem";
// import CloseIcon from "@material-ui/icons/Close";
// import CircularProgress from "@material-ui/core/CircularProgress";

// import TextField from "../textfield";
import { Checkbox, debounce } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import "./style.css";

const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" className="auto_checkbox" />
);
const checkedIcon = <CheckBoxIcon fontSize="small" className="auto_checkbox" />;

// let loading = false;
class MultiSearchSelectBox extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      // value: props.value ? props.value instanceof Array ? props.value : [props.value] : [],
      inputValue: "",
      loading: false,
      open: false,
      Data: props.Data,
      selectedItems: [],
    };
  }

  componentDidMount = () => {
    // if (
    //   this.props.value !== "" &&
    //   this.props.value !== undefined &&
    //   this.props.value !== null
    // )
    //   this.setState({
    //     value: this.props.value instanceof Array ? this.props.value : [this.props.value],
    //     loading: this.props.Data && !this.state.open ? false : true,
    //   });
    // else {
    //   this.setState({ loading: false });
    // }
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      value: props.value instanceof Array ? props.value : [props.value],
      inputValue: props.inputValue,
      Data: props.Data,
    });
  };

  handlemenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleChange = (e, newValue) => {
    if (newValue && newValue.length > 0) {
      const selectedItems = this.state.selectedItems;
      this.setState({
        selectedItems: newValue,
      });
      this.props.onChange(newValue);
    } else {
      let data = { id: null, name: "", Param1: false };
      this.props.onChange(data);
    }
  };

  getUpdatedData = debounce((newValue) => {
    this.setState({ loading: false });
    this.props.getData(newValue);
  }, 1000);

  handleInputChange = (e, newValu, reason) => {
    if (reason === "input") {
      if (newValu === "") {
        this.setState({
          // Data: [],
          // open: false
        });
      } else {
        this.setState(
          {
            value: newValu,
            loading: true,
          },
          () => {
            if (newValu === "" || newValu === undefined || newValu === null) {
              let data = { id: null, name: "", Param1: false };
              this.props.onChange(data);
            } else {
              let data = { id: null, name: newValu, Param1: false };
              this.props.onChange(data);
            }
            if (this.props.getData && newValu) {
              this.getUpdatedData(newValu);
            }
          }
        );
      }
    } else if (reason === "reset") {
      // this.setState({
      //   open: false
      // })
    } else if (reason === "clear") {
      this.setState({
        selectedItems: [],
      });
      this.props.onChange([]);
    }
  };

  handleOpen = () => {
    if (this.props.async) {
      let row = this.props.row ? this.props.row : {};
      this.setState(
        {
          // loading: true,
          Data: [],
          open: true,
        },
        () => {
          // if (this.props.getData && this.state.value) {
          //   this.props.getData(this.state.value);
          //   setTimeout(() => {
          //     this.setState({ loading: false });
          //   }, 2000);
          // }
        }
      );
    } else {
      if (this.props.getData) {
        this.setState(
          {
            loading: this.props.Data.length === 0 ? true : false,
            // Data: [],
            open: true,
          },
          () => {
            if (this.props.Data.length === 0 && this.props.getData) {
              this.props.getData();
              setTimeout(() => {
                this.setState({ loading: false });
              }, 2000);
            }
          }
        );
      } else {
        this.setState({ open: true });
      }
    }
  };

  // _handleKeyDown = (e) => {
  //
  //   if (this.props.onKeyDownEvent) {
  //     if (e.key === 'Enter') {
  //       this.props.onKeyDownEvent(e);
  //       this.setState({
  //         open: false
  //       });
  //     }
  //   }
  // }
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      // Data,
      label,
      margin,
      row,
      freewidth,
      placeholder,
      name,
      InputProps,
      noloader,
      defaultValue,
      required,
      InputLabelProps,
      readOnly,
      error,
      classes,
    } = this.props;

    const { value, open, loading, inputValue, Data } = this.state;

    // loading = open && Data.length === 0 ? true : false;

    return (
      <>
        {readOnly ? (
          <TextField
            {...this.props}
            id="select-pagesize"
            type="text"
            label={label ?? null}
            fullWidth={freewidth ? false : true}
            name={name}
            value={value}
            margin={margin}
            // variant="outlined"
            InputLabelProps={InputLabelProps}
            required={required}
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          // <Autocomplete
          //   {...this.props}
          //   onKeyDown={this._handleKeyDown}
          //   className="custom-autocomplete"
          //   options={Data}
          //   getOptionLabel={(option) => this.props.key_name ? option[this.props.key_name] : option.name}
          //   // getOptionLabel={option => option.label}

          //   onChange={(e, values) => this.handleChange(e, values)}
          //   inputValue={inputValue ? inputValue : ""}
          //   //   // variant="outlined"
          //   multiple
          //   disableCloseOnSelect
          //   renderOption={(props, option, { selected }) => (

          //     <li {...props}>
          //       <Checkbox
          //         icon={icon}
          //         checkedIcon={checkedIcon}
          //         style={{ marginRight: 8 }}
          //         checked={this.state.se}
          //       />
          //       {this.props.key_name ? option[this.props.key_name] : option.name}
          //     </li>
          //   )}
          //   open={open}
          //   fullWidth={freewidth ? false : true}
          //   onOpen={this.handleOpen}
          //   ListboxProps={{ style: { maxHeight: "15rem" } }}
          //   onClose={this.handleClose}
          //   defaultValue={defaultValue}
          //   loading={loading}
          //   noOptionsText={"No Results found"}
          //   onInputChange={(event, newInputValue, reason) => {
          //     this.handleInputChange(event, newInputValue, reason);
          //   }}
          //   disableClearable={
          //     value === "" || value === undefined || value === null
          //   }
          //   renderInput={(params) => (
          //     <TextField
          //       {...this.props}
          //       {...params}
          //       required={required}
          //       // value={value ? value : ""}
          //       name={name}
          //       // variant="outlined"
          //       label={label ?? null}
          //       // defaultValue={defaultValue}
          //       // placeholder={label}
          //       margin="dense"
          //       InputLabelProps={{ shrink: true, ...InputLabelProps }}
          //       error={error}
          //       fullWidth={freewidth ? false : true}
          //       InputProps={{
          //         ...params.InputProps,
          //         ...InputProps,
          //         readOnly: readOnly,

          //         endAdornment: (
          //           <React.Fragment>
          //             {!noloader && loading ? (
          //               <CircularProgress
          //                 //   color={commonStyle.buttonColor}
          //                 size={20}
          //               />
          //             ) : null}
          //             {params.InputProps.endAdornment}
          //           </React.Fragment>
          //         ),
          //       }}
          //     />
          //   )}
          // />
          <>
            <Autocomplete
              // {...this.props}
              classes={this.props.classes}
              filterOptions={(x) => x}
              limitTags={2}
              multiple
              id="checkboxes-tags-demo"
              className="custom-autocomplete"
              options={Data}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                this.props.key_name ? option[this.props.key_name] : option.name
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {this.props.key_name
                    ? option[this.props.key_name]
                    : option.name}
                  {/* {option.title} */}
                </li>
              )}
              onChange={(e, values) => this.handleChange(e, values)}
              inputValue={inputValue ? inputValue : ""}
              onInputChange={(event, newInputValue, reason) => {
                this.handleInputChange(event, newInputValue, reason);
              }}
              open={open}
              fullWidth={freewidth ? false : true}
              onOpen={this.handleOpen}
              ListboxProps={{ style: { maxHeight: "15rem" } }}
              onClose={this.handleClose}
              loading={loading}
              noOptionsText={"No Results found"}
              disableClearable={
                value === "" || value === undefined || value === null
              }
              // renderTags={(v) => <Chip key={"selected_count"} label={`Selected ${this.state.selectedItems.length} ${this.props.title}`} />}
              // renderTags={() => null}

              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...this.props}
                  {...params}
                  required={required}
                  // value={value ? value : ""}
                  name={name}
                  // variant="outlined"
                  label={label ?? null}
                  // defaultValue={defaultValue}
                  placeholder={
                    this.state.selectedItems.length > 0 ? "" : placeholder
                  }
                  margin="dense"
                  InputLabelProps={{ shrink: true, ...InputLabelProps }}
                  error={error}
                  fullWidth={freewidth ? false : true}
                  InputProps={{
                    ...params.InputProps,
                    ...InputProps,
                    readOnly: readOnly,

                    endAdornment: (
                      <React.Fragment>
                        {!noloader && loading ? (
                          <CircularProgress
                            //   color={commonStyle.buttonColor}
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </>
        )}
      </>
    );
  }
}

export default MultiSearchSelectBox;
