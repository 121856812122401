import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

let key = 0;
class ManageActionMenu extends React.Component {
  constructor(props) {
    super(props);
  }
  handleMenuClose = () => {
    this.props.handleMenuClose();
  };

  render() {
    const { anchorEl, menudata, classes, position, showMenu } = this.props;
    // key = key + 1;
    let data = [];
    data = menudata;

    return (
      <div>
        {data.length !== 0 && (
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.props.handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: position ? position : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: position === "right" ? "left" : "right",
            }}
            PaperProps={{
              style: {
                minWidth: 190,
                width: "fit-content",
                padding: 0,
                left: 0,
                right: 80,
              },
            }}
            MenuListProps={{
              style: {
                padding: 0,
              },
            }}
          >
            {data.map((item) => {
              if (showMenu && showMenu.indexOf(item.id) > -1) {
                return (
                  <div>
                    <Tooltip
                      title={item.Description ? item.Description : item.name}
                    >
                      <MenuItem
                        // className={classes.menuListItem}
                        onClick={(e) => this.props.handleMenu(item)}
                      >
                        <p
                        //   className={classes.menulistItemText}
                        >
                          {" "}
                          {item.name}
                        </p>
                      </MenuItem>
                    </Tooltip>
                  </div>
                );
              } else if (!showMenu) {
                return (
                  <div>
                    <Tooltip
                      title={item.Description ? item.Description : item.name}
                    >
                      <MenuItem
                        // className={classes.menuListItem}
                        onClick={(e) => this.props.handleMenu(item)}
                      >
                        <p
                        //   className={classes.menulistItemText}
                        >
                          {" "}
                          {item.name}
                        </p>
                      </MenuItem>
                    </Tooltip>
                  </div>
                );
              }
            })}
          </Menu>
        )}
      </div>
    );
  }
}

export default ManageActionMenu;
