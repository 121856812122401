import apis from "../../../apis/api.actions";
import { parseQueryParams } from "../../../utils/formValidate";

export const get_Note_Details = (key) =>
  apis.getDatas(`/note/${key}`).then((res) => res);
export const get_Note = (key) =>
  apis.getDatas(`/note${parseQueryParams(key)}`).then((res) => res);
export const create_Note = (data) =>
  apis.createData(`/note`, data).then((res) => res);
export const update_Note = (id, data) =>
  apis.updateData(`/note/${id}`, data).then((res) => res);
export const delete_Note = (id) =>
  apis.deleteData(`/note/${id}`).then((res) => res);
