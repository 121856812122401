import React from "react";
import CustomIcons from "./commonIcons";

export default function IconbuttonWithLabel(props) {
  return (
    <div className="icon">
      <button {...props}>
        {props.iconName && (
          <CustomIcons
            iconName={props.iconName}
            iconClass={props.iconClass ? props.iconClass : ""}
          />
        )}
        &nbsp;{props.label ? props.label : null}
      </button>
    </div>
  );
}
