import Drawer from "@mui/material/Drawer";
import moment from "moment";
import React, { Component } from "react";
import "../../drawer_style.css";

class FormsDetailsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = (date) => {
    // 26-02-2022T08:20:40Z
    // const dateNew = date.replace("T", " ").replace("Z", "");

    return moment(date, "DD-MM-YYYY HH:mm:ss").format("MMM DD, YYYY hh:mm A");
  };

  render() {
    const { classes, searchData } = this.props;
    const { filterData, isActive } = this.state;
    const provider_name = searchData.external_form_data["provider_name"];
    const form_name = searchData.external_form_data["form_name"];

    const form_title =
      provider_name + " - " + form_name != " - "
        ? provider_name + " - " + form_name
        : "Form Data";

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openformsdetail}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head">
              <div className="dropdown">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Webform Submission
                  {/* <CustomIcons iconName="expand" iconClass="icon" /> */}
                </a>

                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    All Note
                  </a>
                </div> */}
              </div>
              {/* <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a> */}
              <div className="righ-container">
                {/* <SearchBoxSecondary /> */}
              </div>
            </div>
            <div className="activity-list activity-detail forms-detail">
              <div className="item">
                <div className="details">
                  <div className="row-block">
                    {/* <p className="text">New Business Enquiries</p> */}
                    {<p className="text">{form_title}</p>}

                    {/* <div className="dropdown">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <CustomIcons iconName="tabledropdown" iconClass="icon" />
                      </a>

                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Delete
                        </a>
                      </div> 
                    </div> */}
                  </div>
                  {/* <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                      Provider
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="text">
                      Arkom
                      </span>
                    </div>
                  </div> */}
                  {Object.keys(searchData.external_form_data).map(
                    (key, index) =>
                      key != "provider_name" &&
                      key != "form_name" && (
                        <div className="row-block">
                          <div className="attribute">
                            <span className="sub">
                              {key != "date_submitted"
                                ? key
                                : "Form Submmited On"}
                              <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title={key}
                              >
                                {/* <CustomIcons
                                  iconName="help"
                                  iconClass="header-action-icons"
                                /> */}
                              </span>
                            </span>
                            <span className="text">
                              {searchData.external_form_data[key]
                                ? key != "date_submitted"
                                  ? searchData.external_form_data[key]
                                  : this.formatDate(
                                      searchData.external_form_data[key]
                                    ) + " UTC"
                                : "--"}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                  {/*                   
                  <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                      Email ID
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="text">
                      Arkom
                      </span>
                    </div>
                  </div>
                  <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                      Organization Name
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="text">
                      Organization Name
                      </span>
                    </div>
                  </div>
                  <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                      Description
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="text">
                      Ar aenean magna consectetur. Euismod id sem enim, sapien. Arcu, neque nisi, id in at. Nascetur urna.
                      </span>
                    </div>
                  </div>
                  <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                      Shared with
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="tags">
                        <span className={`${classes.multiselectStyle} tag`}>eBay</span>
                        <span className={`${classes.multiselectStyle} tag`}>The Walt Disney Company</span>
                        <span className={`${classes.multiselectStyle} tag`}>Filter tag</span>
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="row-block">
                    <span className="text-block">
                      <span className="sub">Submitted on</span>
                      <span className="t-box">25/05/2021</span>
                      <span className="t-box">04:50 PM </span>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default FormsDetailsDrawer;
