import React, { Component } from "react";
import { Link } from "react-router-dom";
import breadcrumbConfig from "../../utils/breadcrumbConfig";
import "./breadcrumb.css";

class BreadCrumbsComp extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { parent, parent_path } = this.props;

    return (
      //col-md-6 to col-md-12
      <div className="breadcrumb-wrapper">
        <ol className="breadcrumb">
          {this.props.type ? (
            breadcrumbConfig[this.props.type].map((url, index) => (
              <li
                key={index}
                className={"breadcrumb-item" + (url.isActive ? " active" : "")}
              >
                {parent && parent_path && parent === url.name ? (
                  <Link to={parent_path}>{url.name}</Link>
                ) : url.path ? (
                  <Link to={url.path}>{url.name}</Link>
                ) : (
                  <Link style={{ textDecoration: 'none', cursor: 'default' }}>{url.name}</Link>
                  // <span>{url.name}</span>
                )}
                {/* {url.path ? (
                  <Link to={url.path}>{url.name}</Link>
                ) : (
                  <Link>{url.name}</Link>
                )} */}
              </li>
            ))
          ) : (
            <></>
          )}
        </ol>
      </div>
    );
  }
}

export default BreadCrumbsComp;
