import apis from "../../../apis/api.actions";
import { parseQueryParams } from "../../../utils/formValidate";

export const getLeads = (data) =>
  apis.getDatas(`/leads${parseQueryParams(data)}`).then((res) => res);
export const createLead = (data) =>
  apis.createData("/leads", data).then((res) => res);
export const updateLead = (id, data) =>
  apis.updateData(`/leads/${id}`, data).then((res) => res);
export const getLead = (data) =>
  apis.getDatas(`/leads/${data}?grouped=true`).then((res) => res);
export const deleteLead = (id) =>
  apis.deleteData(`/leads/${id}`).then((res) => res);

export const dynCreate = (key, data) =>
  apis.createData(`/${key}`, data).then((res) => res);
export const createstage = (data) =>
  apis.createData(`/stages/`, data).then((res) => res);
export const updateStage = (data) =>
  apis.createData(`/leads/change-stage`, data).then((res) => res);
export const getdynData = (data, search = "") => apis.getDatas(`${data}&search=${search}`).then((res) => res);
export const submitDyndata = (data) =>
  apis.createData("/sync-relations/", data).then((res) => res);
export const accountSearch = (data) =>
  apis
    .getDatas(
      `/account/search-name?search_key=${data.keyword}&contact_id=${data.ContactId}&opportunity_id=${data.OpportunityId}`
    )
    .then((res) => res);
export const contactSearch = (data) =>
  apis
    .getDatas(
      `/contact/search-name?search_key=${data.keyword}&account_id=${data.AccountId}&opportunity_id=${data.OpportunityId}`
    )
    .then((res) => res);
export const opportunitySearch = (data) =>
  apis
    .getDatas(
      `/opportunity/search-name?search_key=${data.keyword}&account_id=${data.AccountId}&contact_id=${data.ContactId}`
    )
    .then((res) => res);

export const fetchConvertFields = (key, idlabel, id) =>
  apis
    .getDatas(`/lead/mapping?entity=${key}&${idlabel}=${id}`)
    .then((res) => res);

export const fetchConvertFieldsCreate = (key, idlabel, id) =>
  apis
    .getDatas(`/lead/mapping?entity=account,contact,opportunity`)
    .then((res) => res);
export const fetchConvertFieldDatas = (id) =>
  apis.getDatas(`/lead/${id}/mapping-data`).then((res) => res);
export const leadConvert = (data) =>
  apis.createData(`/lead/convert`, data).then((res) => res);
