import React from "react";
import { Link } from "react-router-dom";
import CustomIcons from "./commonIcons";
export default function DetailsField(props) {
  return (
    <div className="fieldset dataset">
      <label
        className={
          props.error ? "form-label-error control-label" : "control-label"
        }
      >
        {props.label}
        {(props && props.has_tooltip) ? (
          <span className="icon-padding" data-toggle="tooltip" title={props.tooltip_text}>
            <CustomIcons
              iconName="help"
              iconClass="header-action-icons"
            />
          </span>
        ) : ""
        }
      </label>

      <div className="input-group">
        {props.item.is_link ? (
          <Link
            to={props.item.url}
          >
            <p className="text link_text">{props.value}</p>
          </Link>
        ) : (
          <p className="text">{props.value}</p>
        )}
      </div>
    </div>
  );
}
