import { Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { Component } from "react";
import BreadCrumbsComp from "../../../../common_components/breadcrumbs/breadcrumbs.component";
import ButtonSml from "../../../../common_components/buttonsml";
import CustomIcons from "../../../../common_components/commonIcons";
import InformationComponent from "../../../../common_components/entity-detailed/components/information.component";
import OverviewComponent from "../../../../common_components/entity-detailed/components/overview.component";
import StatusComponent from "../../../../common_components/entity-detailed/components/status.component";
import TableSubComponent from "../../../../common_components/entity-detailed/components/tableSubmodule.component";
import BlockingLoader from "../../../../common_components/loader/blockingloader";
import SnackComp from "../../../../common_components/snackbar/snack.component";
import StatiForm from "../../../../common_components/staticform/static_form_builder";
import RightSideNav from "../../../../layout/classiclayout/components/navmenu/rightsidenav";
import { FormatError } from "../../../../utils/formValidate";
import "../../../core_components/common.style.css";
import {
  fetchConvertFieldDatas,
  getLead,
  submitDyndata,
  updateLead,
} from "../api.functions";
import "../leads.style.css";
import LeadConvert from "./lead-details/leadconvert";
let refreshkey = 0;
let list_id = "";
let editId = "";
class LeadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      header: {},
      tablehkey: 1,
      openConvert: false,
      dynCreateData: [],
      leadFieldData: {},
      convertType: "",
      createData: [],
      open: false,
      relation: {},
      isActive: false,
      showLoader: true,
      overview: [],
      addData: [],
      statgesData: [],
      snackData: { open: false },
      master_key: "",
      external_form_data: null,
      convertData: {},
      canSubmit: true,
      submoduleDataUrl: "",
      child_key: "",
      list_id: "",
    };
    this.childTable = React.createRef();
    this.child = React.createRef();
    if (this.props.leadData) {
      list_id = this.props.leadData.id;
    } else {
      list_id = this.props.match.params.id;
      editId = this.props.match.params.id1;
    }
  }
  componentDidMount = () => {
    if (list_id) this.getdetail(list_id);
    if (this.props.editable) this.setState({ editable: true });
  };
  setDetailData(res) {
    let datas = res.data.sections;

    this.setState(
      {
        originalData: res,
        overview: res?.data?.overview,
        header: res.data.header?.data,
        external_form_data: res.data?.external_form_data,
        statgesData: res.data.stages?.sort(function (a, b) {
          return a.sort_order - b.sort_order;
        }),
        isActive: false,
        master_key: res.model_key,
        convertData: {
          is_converted_account: res.data.is_converted_account,
          is_converted_contact: res.data.is_converted_contact,
          is_converted_opportunity: res.data.is_converted_opportunity,
          converted_entities: res.data.converted_entities,
        },
        createData: datas,
        // editable: false,
      },
      () => {
        refreshkey = refreshkey + 1;
      }
    );
  }

  getdetail = (id, flag) => {
    if (!flag) {
      this.setState({
        isActive: true,
        showLoader: false,
      });
    }
    getLead(id)
      .then((res) => res)
      .then((res) => {
        if (res.data) {
          this.setDetailData(res);
        }
      })
      .catch((error) => {
        // this.setState({
        //   isActive: false,
        //   snackData: {
        //     open: true,
        //     message: error?.response?.data?.message ?? "Could not fetch data",
        //     variant: "error",
        //   },
        // });
        var errorString = FormatError(error);
        if (sessionStorage.getItem("origin") === "report") {
          this.props.history.push({
            pathname: "/reports/lead",
            testflag: true,
            state: { errorString: errorString },
          });

          sessionStorage.removeItem("origin");
        } else {
          this.props.history.push({
            pathname: "/lead/list",
            testflag: true,
            state: { errorString: errorString },
          });
        }
      });
  };

  handleManageEdit = () => {
    this.setState({ editable: true });
  };

  handleCancel = () => {
    this.setState({
      editable: false,
      isActive: false,
      canSubmit: true,
      showLoader: false,
    });

    if (list_id) this.getdetail(list_id, true);
    // if (list_id)  this.setDetailData(this.state.originalData);
  };

  handleManageSave = () => {
    const childRef = this.child.current;

    this.setState({ isActive: false, canSubmit: false }, () => {
      childRef.handleSubmitAction();
    });
  };

  fetchLeadDatas = () => {
    let id = this.props.leadId;
  };

  handleSubmit = (data) => {
    if (!data) {
      this.setState({ canSubmit: true });
      return false;
    }
    updateLead(list_id, data)
      .then((res) => res)
      .then((res) => {
        this.setState(
          {
            open: false,
            editable: false,
            isActive: false,
            canSubmit: true,
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
          },
          () => {
            refreshkey = refreshkey + 1;
            this.getdetail(list_id);
          }
        );
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          canSubmit: true,
          errorData : error.response.data.error,
          snackData: {
            open: true,
            isActive: false,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  // handleCreate = (e, item) => {
  //   this.setState({
  //     // showLoader: true,
  //     submoduleDataUrl: e?.api_url ?? "",
  //     open: true,
  //     relation: item,
  //   });
  // };

  // handleDynSubmit = (data) => {
  //   if (data) {
  //     let form = {
  //       parent_entity: this.state.master_key,
  //       related_entity: this.state.relation.relation_model,
  //       parent_id: list_id,
  //       related_ids: data,
  //       relation_name: this.state.relation.relation_name,
  //     };

  //     submitDyndata(form)
  //       .then((res) => res)
  //       .then((res) => {
  //         this.setState({
  //           snackData: {
  //             open: true,
  //             message: res.message,
  //             variant: "success",
  //           },
  //           tablehkey: this.state.tablehkey + 1,
  //         });
  //         this.getdetail(list_id);
  //         this.handleClose();
  //       })
  //       .catch((error) => {
  //         var errorString = FormatError(error);
  //         //

  //         this.setState({
  //           snackData: {
  //             open: true,
  //             message: errorString,
  //             variant: "error",
  //           },
  //         });
  //       });
  //   }
  // };

  handleClose = () => {
    this.setState({
      openConvert: false,
      open: false,
      convertType: "",
    });
  };

  handleRefresh = () => {
    this.setState(
      {
        openConvert: false,
        open: false,
        convertType: "",
      },
      () => {
        this.getdetail(list_id);
      }
    );
  };

  tabChange = () => {
    this.props.history.push("/lead/list/");
  };

  updateData = (data) => {
    this.setState({ statgesData: data });
  };

  handleClickOpen = (value) => {
    this.setState({ showLoader: true });
    fetchConvertFieldDatas(this.props.match.params.id)
      .then((res) => res.data)
      .then((res) => {
        let leadAccount = res.entity.account;

        let leadContact = res.entity.contact;

        let leadOpportunity = res.entity.opportunity;

        this.setState({
          openConvert: true,
          showLoader: false,
          convertType: value,
          leadFieldData: {
            account: leadAccount,
            contact: leadContact,
            opportunity: leadOpportunity,
          },
        });
      })

      .catch((err) => {
        this.setState({
          submitted: false,
          showLoader: false,
          snackData: {
            open: true,

            variant: "error",
            message: err.response.data.message,
          },
          isActive: false,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      editable,
      openConvert,
      convertType,
      header,
      external_form_data,
      createData,
      statgesData,
      isActive,
      showLoader,
      overview,
      convertData,
    } = this.state;
    //
    const showConvert = true;

    const is_converted =
      convertData.is_converted_account ||
      convertData.is_converted_contact ||
      convertData.is_converted_opportunity;

    const converted_account_name = convertData?.converted_entities?.account
      ?.name
      ? convertData?.converted_entities?.account?.name
      : convertData?.converted_entities?.account?.display_id ?? "--";
    const converted_account_link = convertData?.converted_entities?.account?.id
      ? "/account/" + convertData?.converted_entities?.account?.id + "/1"
      : "";

    const converted_contact_first_name = convertData?.converted_entities
      ?.contact?.first_name
      ? convertData?.converted_entities?.contact?.first_name
      : "";
    //

    const converted_contact_last_name = convertData?.converted_entities?.contact
      ?.last_name
      ? convertData?.converted_entities?.contact?.last_name
      : "";
    //

    var converted_contact_name =
      converted_contact_first_name +
      (converted_contact_last_name.trim() != ""
        ? " " + converted_contact_last_name
        : "");
    //

    converted_contact_name =
      converted_contact_name.trim() === ""
        ? convertData?.converted_entities?.contact?.display_id ?? "--"
        : converted_contact_name;
    //

    const converted_contact_link = convertData?.converted_entities?.contact
      ?.display_id
      ? "/contact/" + convertData?.converted_entities?.contact?.id + "/1"
      : "";

    const converted_opportunity_name = convertData?.converted_entities
      ?.opportunity?.name
      ? convertData?.converted_entities?.opportunity?.name
      : convertData?.converted_entities?.opportunity?.display_id ?? "--";

    const converted_opportunity_link = convertData?.converted_entities
      ?.opportunity?.display_id
      ? "/opportunity/" +
        convertData?.converted_entities?.opportunity?.id +
        "/1"
      : "";

    return (
      <>
        <div className="detailBg inner-listdetails">
          <Grid className="tabview-list" container>
            <Grid className={`usertab ${"inactive-tab"}`}>
              <p onClick={(e) => this.tabChange(0, {})} className="tab-title">
                Leads
              </p>
            </Grid>

            <Grid className={`usertab ${"active-tab"}`}>
              <p className="tab-title">Lead Details</p>

              <div onClick={(e) => this.tabChange(e)}>
                <CustomIcons iconName="close" iconClass="tab-close" />
              </div>
            </Grid>
          </Grid>

          <BreadCrumbsComp type="lead_details" />
          <div className="content">
            {/* <Loader isActive={isActive} /> */}
            <div className="card card-content card-content-head">
              <div className="card-body">
                <div className="d-flex head-details">
                  {isActive ? (
                    <>
                      <div className="column-flex flexDisplay">
                        <Skeleton
                          variant="box"
                          animation="wave"
                          width={40}
                          height={40}
                        />
                        <div class="data-container">
                          <div class=" common-text">
                            <Skeleton
                              variant="text"
                              animation="wave"
                              width={80}
                              height={15}
                            />
                          </div>
                          <h4 class="title">
                            <Skeleton
                              variant="text"
                              animation="wave"
                              width={100}
                              height={24}
                            />
                          </h4>
                        </div>
                      </div>
                      <div class="flex-box-right">
                        <div class="item">
                          <Skeleton
                            variant="box"
                            animation="wave"
                            width={72}
                            height={32}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {header && header?.length !== 0 && (
                        <div className="column-flex flexDisplay">
                          <img
                            src="/assets/images/default/leads.png"
                            alt="lead"
                          />
                          <div className="data-container">
                            <div className=" common-text">
                              {header.title}
                              <>
                                {header.tooltip && (
                                  <span
                                    className="icon-padding"
                                    data-toggle="tooltip"
                                    // title="You will be able to manage the Account module & fields from here"
                                    title={header.tooltip}
                                  >
                                    <CustomIcons
                                      iconName="help"
                                      iconClass="header-action-icons"
                                    />
                                  </span>
                                )}
                              </>
                            </div>
                            <h4 className="title">{header.value}</h4>
                          </div>

                          {/* <SelectField
                    className={`${classes.linkColor} form-control`}
                  /> */}
                        </div>
                      )}
                      <div className="flex-box-right">
                        {/* <ButtonSml
                      {...this.props}
                      className={`${classes.secondaryBtn} btn btn-secondary`}
                      label="Follow"
                    /> */}
                        {editId !== "2" && (
                          <>
                            {!editable ? (
                              <ButtonSml
                                {...this.props}
                                className={`${classes.secondaryBtn} btn btn-secondary`}
                                label="Edit"
                                onClick={(e) => this.handleManageEdit(e)}
                              />
                            ) : (
                              <>
                                <ButtonSml
                                  {...this.props}
                                  className={`${classes.secondaryBtn} btn btn-secondary`}
                                  label="Cancel"
                                  onClick={(e) => this.handleCancel(e)}
                                />
                                <ButtonSml
                                  {...this.props}
                                  className={`${classes.primaryBtn} btn btn-primary`}
                                  disabled={!this.state.canSubmit}
                                  label="Save"
                                  onClick={(e) => this.handleManageSave(e)}
                                />
                              </>
                            )}
                          </>
                        )}
                        {showConvert && (
                          <div className="item flex-btn">
                            {!is_converted ? (
                              <ButtonSml
                                onClick={(e) => this.handleClickOpen("")}
                                label="Convert"
                                className={`${classes.primaryBtn} btn btn-primary`}
                              ></ButtonSml>
                            ) : (
                              <>
                                <button
                                  // onClick={is_converted ? null : (e) => this.handleClickOpen("")}
                                  className={`${classes.primaryBtn} btn btn-primary`}
                                  data-toggle="dropdown"
                                >
                                  Converted to
                                </button>
                                <div className="dropdown convert_dropdown">
                                  <a
                                    className={`${classes.btnprimary} btn btn-primary  min_width`}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <CustomIcons
                                      iconName="expand"
                                      iconClass="icon"
                                    />
                                  </a>

                                  <div className="dropdown-menu">
                                    <a
                                      className="dropdown-item disabled"
                                      href={converted_account_link}
                                    >
                                      Account :{" "}
                                      <span className={`${classes.linkColor} `}>
                                        {converted_account_name}
                                      </span>
                                    </a>
                                    <a
                                      className="dropdown-item disabled"
                                      href={converted_contact_link}
                                    >
                                      Contact :{" "}
                                      <span className={`${classes.linkColor} `}>
                                        {converted_contact_name}
                                      </span>
                                    </a>
                                    <a
                                      className="dropdown-item disabled"
                                      href={converted_opportunity_link}
                                    >
                                      Opportunity :{" "}
                                      <span className={`${classes.linkColor} `}>
                                        {converted_opportunity_name}
                                      </span>
                                    </a>
                                    {/* <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => this.handleClickOpen("account")}
                          >
                            Convert to Account
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => this.handleClickOpen("contact")}
                          >
                            Convert to Contact
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => this.handleClickOpen("opportunity")}
                          >
                            Convert to Oppurtunity
                          </a> */}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {/* <ButtonSml
                      {...this.props}
                      iconData={{
                        name: "headermenu",
                        className: "secondary-button-icon",
                      }}
                      // onClick={(e) => this.handleAddNew()}
                      className={`${classes.secondary} btn btn-secondary min_width`}
                    /> */}
                      </div>
                    </>
                  )}
                </div>
                {/* <div className="tag-container">
                  <div className="flexDisplay">
                    <div className={`${classes.multiselectStyle} item tags`}>
                      <p>Important</p>
                    </div>
                    <div className={`${classes.multiselectStyle} item tags`}>
                      <p>Information Technology</p>
                    </div>
                    <div className="item">
                      <CustomIcons iconName="add" iconClass="addTag" />
                      <p className={`${classes.linkColor} addTag`}>
                        Add more tag
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="card-details-section">
              {statgesData && statgesData?.length !== 0 && (
                <StatusComponent
                  key={this.state.refreshkey}
                  handleRefresh={this.getdetail}
                  updateData={this.updateData}
                  entityId={this.props.match.params.id}
                  entityName={this.state.master_key}
                  data={this.state.statgesData}
                  {...this.props}
                />
              )}
              {(isActive || (overview && overview?.length !== 0)) && (
                <OverviewComponent
                  overview={this.state.overview}
                  {...this.props}
                  isActive={isActive}
                />
              )}
              {/*<OverviewComponent {...this.props} />

              <ActivitiesComponent {...this.props} />

           <InformationComponent {...this.props} /> 

              <AttachmentComponent {...this.props} />

              {/* <TableComponent {...this.props} /> 

              <DuplicateComponent {...this.props} /> */}

              {showLoader && (
                <div className="page_overlay">
                  <BlockingLoader isActive={showLoader} />
                </div>
              )}

              <>
                {isActive ? (
                  <div className="card card-accordion">
                    <div className="card-body">
                      <div class="accordion">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button class="accordion-button disabled">
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={80}
                                height={15}
                              />
                            </button>
                          </h2>
                          {[1, 2, 3].map((item) => (
                            <div class="accordion-collapse collapse show">
                              <div class="accordion-body">
                                <div className="row border-one">
                                  <div className="row-title">
                                    <h4>
                                      <Skeleton
                                        variant="text"
                                        animation="wave"
                                        width={90}
                                        height={20}
                                      />
                                    </h4>
                                  </div>
                                  {[1, 2, 3, 4, 5, 6].map((item) => (
                                    <div class="fieldset">
                                      <label class="control-label">
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={80}
                                          height={15}
                                        />
                                      </label>
                                      <div class="input-group">
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          width={400}
                                          height={34}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {createData &&
                      createData.map((items) => (
                        <div className="card card-accordion">
                          <div className="card-body">
                            <div class="accordion">
                              <div class="accordion-item">
                                {items && items.type !== "sub_module" ? (
                                  <>
                                    <h2 class="accordion-header">
                                      <button class="accordion-button disabled">
                                        {items.name}
                                      </button>
                                    </h2>

                                    <InformationComponent
                                      key={refreshkey}
                                      ref={this.child}
                                      informData={items ? items : {}}
                                      {...this.props}
                                      handleSubmit={this.handleSubmit}
                                      editable={this.state.editable}
                                      master_key={this.state.master_key}
                                      isActive={isActive}
                                      errorData = {this.state.errorData}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <TableSubComponent
                                      key={this.state.tablehkey}
                                      handleCreate={this.handleCreate}
                                      {...this.props}
                                      tabData={items}
                                      handleReload={this.getdetail}
                                      master_key={this.state.master_key}
                                      list_id={list_id}
                                      getdetail={this.getdetail}
                                      handleClose={this.handleClose}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        <RightSideNav
          {...this.props}
          key={list_id}
          searchData={{
            entity_name: this.state.master_key,
            entity_id: list_id,
            external_form_data: external_form_data,
          }}
        />
        {openConvert && (
          <LeadConvert
            open={openConvert}
            handleClose={this.handleClose}
            leadData={this.state.leadFieldData}
            handleRefresh={this.handleRefresh}
            convertType={convertType}
            leadId={this.props.match.params.id}
            {...this.props}
          />
        )}
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </>
    );
  }
}

export default LeadDetails;
