import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { msalConfig } from "./utils/authConfig";
import { MainContext } from "./utils/store";

const msalInstance = new PublicClientApplication(msalConfig());

msalInstance.addEventCallback((event) => {
  //

  if (event.eventType === EventType.HANDLE_REDIRECT_START) {
    // Set the active account - this simplifies token acquisition
    // const authResult = event.payload as AuthenticationResult;
    // msalInstance.setActiveAccount(authResult.account);

    const res = msalInstance.getTokenCache();
  }
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//     // Set the active account - this simplifies token acquisition
//     const authResult = event.payload;
//     msalInstance.setActiveAccount(authResult.account);
//   }
// });

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MainContext>
        <Router>
          <App />
        </Router>
      </MainContext>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
