import apis from "../../../apis/api.actions";
import { parseQueryParams } from "../../../utils/formValidate";
export const getTagggedMails = (data) =>
  apis.getDatas(`/mails/tags/${parseQueryParams(data)}`).then((res) => res);
export const getConversations = (data) =>
  apis
    .getDatas(`/get-mail-threads/${parseQueryParams(data)}`)
    .then((res) => res);
export const getAttachmentLink = (data) =>
  apis
    .getDatas(
      `/get-mail-attachemnt/?mail_id=${data.mail_id}&mailbox_id=${data.mailbox_id}&attachment_service_id=${data.attachment_service_id}`
    )
    .then((res) => res);
