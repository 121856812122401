import React from "react";

export default function CheckboxField(props) {
  
  return (
    <div className="fieldset checkbox-box">
        <div className="input-group">
            <input
            className={`${props.classesStyle.formControl}`}
            type="checkbox"
            id={`filter-check${props.id}`}
            />{" "}
            {props.error ? (
                <label
                className={`${props.classesStyle.checkbox} form-label-error control-label`}
                for={`filter-check${props.id}`}
                >
                {props.label}{" "}
                </label>
                ) : (
                <label
                    className={`${props.classesStyle.checkbox}  control-label`}
                    for={`filter-check${props.id}`}
                    >
                    {props.label}{" "}
                </label>
            )}
            
        </div>{" "}
    </div>
  );
}
