export const getMessageForKey = (key, value = "") => {
  let messageDataArr = {
    user_activate_header: "Activate User",
    user_activate_msg:
      "Are you sure you want to activate " + getData(value) + "?",

    user_deactivate_header: "Deactivate User",
    user_deactivate_msg:
      "The user you have selected will no longer be able to access Tescalade CRM.<br/><br/>" +
      "If you reactivate them at a later date, they will be able to view all of their existing data.<br/><br/>" +
      "Are you sure you want to deactivate?",

    user_reset_password_header: "Reset Password",
    user_reset_password_msg:
      "Are you sure you want to reset the password for" + getData(value) + "?",

    user_role_change_header: "Change Role",
    user_role_change_msg:
      "Are you sure you want to change the assigned role for " +
      getData(value) +
      "?<br/><br/>",
    // + "Changing the user role will also change the permissions assigned to the user.",

    user_role_remove_header: "Remove Role",
    user_role_remove_msg:
      "Are you sure you want to remove the assigned role for " +
      getData(value) +
      "?<br/><br/>",

    user_remove_header: "Remove User",
    user_remove_msg:
      "Are you sure you want to remove the user from this group?",
    blacklist_remove_header: "Remove Domain",
    blacklist_remove_msg:
      "Are you sure you want to remove this domain from blacklist?",
    email_remove_header: "Remove Email",
    email_remove_msg: "Are you sure you want to remove this email from list?",
    visibility_remove_header: "Remove User",
    visibility_remove_msg:
      "Are you sure you want to remove this user from visibility?",

    role_activate_header: "Activate Role",
    role_activate_msg:
      "Are you sure you want to activate this" + getData(value) + "?",

    role_deactivate_header: "Deactivate Role ",
    role_deactivate_msg:
      "Are you sure you want to deactivate " +
      getData(value) +
      " ?<br /><br />" +
      "Note: Deactivating the role will affect the permissions assigned to the users in this role.",

    // role_user_remove_msg:
    //   "Are you sure you want to remove the " +
    //   getData(value) +
    //   "from this role?<br /><br />" +
    //   "Changing the user role will also change the permissions assigned to the user.",

    group_activate_header: "Activate Group",
    group_activate_msg:
      "Are you sure you want to activate " + getData(value) + "? ",

    group_deactivate_header: "Deactivate Group ",
    group_deactivate_msg:
      "Are you sure you want to deactivate " + getData(value) + "? ",

    // group_user_remove_msg:
    //   "Are you sure you want to remove the user from this group? ",

    // delete_option_header: "Delete Option",
    // delete_option_msg: "Do you want to delete " + getData(value) + "? ",

    // edit_option_header: "Are you sure ?",
    // edit_option_msg:
    //   "Changing option name will replace the value in all existing records. Are you sure you want to continue?",

    // convert_header: "Convert Lead",
    // convert_msg:
    //   "Are you sure you want to convert the lead " + getData(value) + "?",

    // submod_remove_header: "Remove",
    // submod_remove_msg:
    //   "Are you sure you want to remove " + getData(value) + "?",
    delete_call_header: "Delete call",
    delete_call_msg: "Are you sure you want to delete this call?",

    delete_log_header: "Delete Log",
    delete_log_msg: "Are you sure you want to delete this log?",

    delete_note_header: "Delete Note",
    delete_note_msg: "Are you sure you want to delete this note?",

    delete_attachment_header: "Delete Attachment",
    delete_attachment_msg: "Are you sure you want to delete this attachment?",

    delete_meeting_header: "Delete Meeting",
    delete_meeting_msg:
      "Are you sure you want to delete the meeting " + getData(value) + "?",

    delete_task_header: "Delete Task",
    delete_task_msg:
      "Are you sure you want to delete the task " + getData(value) + "?",

    notification_clear_all_header: "Clear Notifications",
    notification_clear_all_msg:
      "Are you sure you want to clear all the notifications?",
  };
  return messageDataArr[key];
};

export const getData = (value) => {
  return value ? " <strong>" + value + "</strong>" : "";
};

export const parseKeysFromNotificationMessage = (template, messageData) => {
  //
  //

  //   const data = {
  //     "message": "The lead %lead_name% is updated by <strong>%session_user%</strong>",
  //     "data": {
  //         "message_data": {
  //             "session_user": {
  //                 "value": "Thomas Xavier",
  //                 "id": "49f841da-e55d-49f9-84be-19719e08c17e",
  //                 "model": "User"
  //             },
  //             "lead_name": {
  //                 "value": "asd",
  //                 "id": "61dc6a90-79de-45a7-824d-4e79373e978c",
  //                 "model": "Lead"
  //             }
  //         },
  //         "title": "Lead Details Updated",
  //         "icon": "Notifications-Leads.png"
  //     }
  // };
  // var template = data["message"];
  // const messageData = data['data']["message_data"];

  var r = template.match(/\%[\w]+\%/g);
  r &&
    r.forEach((state) => {
      //

      var regex = new RegExp(state, "g");
      //
      var stateItem = state.split(/%|%/g)[1];
      //
      //
      // const linkHTML = `<a href='#'>${messageData[stateItem]["value"]}</a>`;
      if (messageData.hasOwnProperty(stateItem)) {
        template = template.replace(regex, messageData[stateItem]["value"]);
      } else {
        template = template.replace(regex, stateItem);
      }
      // template = template.replace(regex, linkHTML);
    });

  //
  return template ?? "";
};
