import React, { Component } from "react";
import SnackComp from "../../snackbar/snack.component";
import { updateStage } from "../api.functions";
class StatusComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      data: this.props.data,
      snackData: { open: false },
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      data: this.props.data,
    });
  };

  handleManageEdit = () => {
    this.setState({ editable: true });
  };
  handleManageSave = () => {
    this.setState({ editable: false });
  };
  handleSelectChange = (item) => (e) => {
    const { data } = this.state;
    //
    let datas = {
      entity_id: this.props.entityId,
      stage_id: e.target.value,
    };

    this.updateStagefunc(datas);
  };
  updateStagefunc = (data) => {
    updateStage(this.props.entityName, data)
      .then((res) => res)
      .then((res) => {
        this.setState(
          {
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
          },
          () => {
            this.props.handleRefresh(this.props.entityId);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackData: {
            open: true,
            message: err.response.data.message,
            variant: "error",
          },
        });
      });
  };
  handleStage = (id, item) => {
    const { data } = this.state;
    data.forEach((element) => {
      if (element.sort_order <= item.sort_order) {
        //
        element["is_selected"] = true;
      } else {
        element["is_selected"] = false;
      }
    });

    let datas = {
      entity_id: this.props.entityId,
      stage_id: id,
    };
    setTimeout(() => {
      this.props.updateData(data);
    }, 150);

    this.updateStagefunc(datas);
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  render() {
    const { classes, data, isActive } = this.props;
    const { editable } = this.state;
    //
    return (
      <div className="card card-status-block">
        <div className="card-body">
          <div className="block-container">
            {/* <div className="flexDisplay">
                <p className="item">
                  Created
                  :<span>(12/09/2021)</span>
                  <span> 13 days ago</span>
                </p>
                <p className="item">
                  Last Stage Changed
                  :<span>(12/09/2021)</span>
                  <span> 2 days ago</span>
                </p>
              </div> */}

            <div className="progress-wrapper">
              <div className="arrow-steps clearfix">
                {data &&
                  data.map((item) => (
                    <>
                      {item.is_selected ? (
                        <div className={`${classes.statusActive} step current`}>
                          {item.stages && item?.stages?.length <= 1 ? (
                            <p
                              onClick={(e) =>
                                this.handleStage(item?.stages[0]?.id, item)
                              }
                            >
                              {item.name}
                            </p>
                          ) : (
                            <select
                              value={item.current_stage_id}
                              onChange={this.handleSelectChange(item)}
                            >
                              {item.stages.map((sub) => (
                                <option value={sub.id}>{sub.name}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      ) : (
                        <div className={`step`}>
                          {item.stages && item?.stages?.length <= 1 ? (
                            <p
                              onClick={(e) =>
                                this.handleStage(item?.stages[0]?.id, item)
                              }
                            >
                              {item.name}
                            </p>
                          ) : (
                            <select
                              value={item.id}
                              onChange={this.handleSelectChange(item)}
                            >
                              <option value={item.id}>{item.name}</option>
                              {item.stages.map((sub) => (
                                <option value={sub.id}>{sub.name}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default StatusComponent;
