import React, { Component } from "react";

import { addToCache, getFromCache } from "../../utils/cacheManager";
import { EmailValidate } from "../../utils/formValidate";
import AudioPlayer from "../activityComponent/callslist/components/audioPlayer";
import AutoComplete from "../autocomplete/autocomplete";
import AutoCompleteMultiSelect from "../autocomplete/multiautocomplete";
import CheckboxField from "../CheckboxField";
import CurrencyField from "../currencyField";
import DatePickerField from "../datepicker";
import MultipleSelectChip from "../multiselct/multi_select";
import SelectField from "../selectfield";
import TextareaField from "../textareafield";
import TextField from "../textfield";
import { getMasterData, getSearchMasterData } from "./api.functions";

class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.props.formData,
      master_id: this.props.master_id,
      shouldCache: true,
    };
    this.handlesearchSelectdataChange =
      this.handlesearchSelectdataChange.bind(this);
  }
  componentWillReceiveProps = () => {
    this.setState({
      formData: this.props.formData,
    });
    if (this.props.errorData) {  
      this.handleError();
    }
    if(this.props.handledBy){
      this.handleSelectChange();
    }
};

handleError = () => {
      const errorData = Object.keys(this.props.errorData);
      let errorApiName;
      errorData.map((item) => (
        errorApiName = item
      ));
      const { formData } = this.state;
      if (this.props.item.api_name === errorApiName) {      

      if (formData[this.props.index]["api_value"] == ""){
        formData[this.props.index]["error"] = false;
      }
      else{
        formData[this.props.index]["error"] = true;
      }
      }
  };

  handleKeyDown = (e) => {
    if (e.key === " " && this.props.item.attribute_type_slug === "email") {
      e.preventDefault();
    }
  };

  handleChange = (e) => {
    const { formData } = this.state;

    if (e.target.value.trim() !== "") {
      if (this.props.item.attribute_type_slug === "email") {
        // if (e.target.value == "") {
        //   if (this.props.item.is_mandatory) {
        //     formData[this.props.index]["error"] = true;
        //     formData[this.props.index]["error_message"] = "";
        //   } else {
        //     formData[this.props.index]["error"] = false;
        //     formData[this.props.index]["error_message"] = "";
        //   }
        // } else {
        let erroremail = !EmailValidate(e.target.value);
        formData[this.props.index]["error"] = erroremail;
        formData[this.props.index]["error_message"] =
          "Please enter a valid " + this.props.item.display_name;
        // }

        // if (!this.props.item.is_mandatory && e.target.value == "") {
        //   let erroremail = !EmailValidate(e.target.value);
        //   formData[this.props.index]["error"] = false;
        //   formData[this.props.index]["error_message"] = "";
        // }
        // else {

        //   // if (this.props.item.is_mandatory && e.target.value == "") {
        //   //   formData[this.props.index]["error"] = true;
        //   //   formData[this.props.index]["error_message"] = "";
        //   // }
        //   // else {
        //   //   if (!this.props.item.is_mandatory && e.target.value = "") {
        //   //     let erroremail = !EmailValidate(e.target.value);
        //   //     formData[this.props.index]["error"] = erroremail;
        //   //     formData[this.props.index]["error_message"] = "Please enter a valid " + this.props.item.display_name;
        //   //   }

        //   // }

        // }
      }
      formData[this.props.index]["api_value"] = e.target.value;
    } else {
      if (this.props.item.attribute_type_slug === "email") {
        if (this.props.item.is_mandatory) {
          formData[this.props.index]["error"] = true;
          formData[this.props.index]["error_message"] = "";
        } else {
          formData[this.props.index]["error"] = false;
          formData[this.props.index]["error_message"] = "";
        }
      }
      formData[this.props.index]["error"] = false;
      formData[this.props.index]["api_value"] = "";
    }

    this.setState({
      formData,
    });
  };

  handleDatePickerChange = (e) => {
    const { formData } = this.state;

    formData[this.props.index]["api_value"] = e.value;
    formData[this.props.index]["api_value_val"] = true;
    if (e["formatted"] === undefined || !e.formatted) {
      this.setState({
        formData,
      });
    }

  };
  handlecheckChange = (e) => {
    const { formData } = this.state;
    formData[this.props.index]["api_value"] = e.target.checked;
    this.setState({
      formData,
    });
  };

  handleCurrencyDataChange = (e) => {
    const { formData } = this.state;

    formData[this.props.index][e.target.name] = e.target.value;
    formData[this.props.index]["api_id"] = e.target.value;

    this.setState({
      formData,
    });
  };

  handleSelectChange = (e) => {
    const { formData } = this.state;
    if (
      !this.props.item.options &&
      this.props.item.options !== null &&
      this.props.item.options !== null
    ) {
      const key = `${this.props.master_key}_${this.props.item.api_name}`;
      // const value = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];

      const value = getFromCache(key) ? getFromCache(key) : [];

      console.log("inside if value", value)

      // if (value && value.length > 0) {
        formData[this.props.index]["options"] = value;
        if(!this.props.edit){
          if(formData[this.props.index]["default_value"] !== null){
            formData[this.props.index]["api_id"] = formData[this.props.index]["default_value"]?.["key"];
          }
        }
        this.setState({
          formData,
        });
      // }

      getMasterData(
        this.props.master_key,
        this.props.item.api_name,
        this.state.master_id
      ).then((res) => {
        formData[this.props.index]["options"] = res.data
          ? res.data[this.props.item.api_name]
          : [];
        addToCache(key, formData[this.props.index]["options"]);
        setTimeout(() => {
          this.setState({
            formData,
          });
        }, 150);
      });
    }
  };

  handleSearchSelectChange = (value) => {
    const { formData } = this.state;

    if (!value) {
      formData[this.props.index]["options"] = [];
      this.setState({
        formData,
      });
    }
    else {
      const key = `${this.props.master_key}_${this.props.item.api_name}`;

      // formData[this.props.index]["api_value"] = value;
      getSearchMasterData(
        this.props.master_key,
        this.props.item.api_name,
        this.state.master_id,
        value
      ).then((res) => {
        formData[this.props.index]["options"] = res?.data?.data ?? [];
        addToCache(key, formData[this.props.index]["options"]);
        setTimeout(() => {
          this.setState({
            formData,
          });
        }, 150);
      });
    }

  };

  handleMultiSelectChange = (e) => {
    const { formData } = this.state;

    // if (
    //   !this.props.item.options &&
    //   this.props.item.options !== null &&
    //   this.props.item.options !== null
    // ) {
    const key = `${this.props.master_key}_${this.props.item.api_name}`;
    // const value = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
    const value = getFromCache(key) ? getFromCache(key) : [];

    formData[this.props.index]["options"] = value;
    this.setState({
      formData,
    });

    getMasterData(
      this.props.master_key,
      this.props.item.api_name,
      this.state.master_id
    ).then((res) => {
      formData[this.props.index]["options"] = res.data
        ? res.data[this.props.item.api_name]
        : [];
      addToCache(key, formData[this.props.index]["options"]);
      // localStorage.setItem(key, JSON.stringify(formData[this.props.index]["options"]))

      setTimeout(() => {
        this.setState({
          formData,
        });
      }, 150);
    });
    // }
  };

  handleSelectdataChange = (e) => {
    const { formData } = this.state;
    if (e.target.value === "") {
      formData[this.props.index]["api_id"] = "";
      formData[this.props.index]["api_value"] = "";
    } else {
      formData[this.props.index]["api_id"] = e.target.value;
    }
    // formData[this.props.index]["api_id"] = e.target.value;
    this.setState({
      formData,
    });
  };

  handleMultydataChange = (value) => {
    const { formData } = this.state;
    if (value === "") {
      formData[this.props.index]["api_value"] = "";
      formData[this.props.index]["api_id"] = value;
    } else {
      formData[this.props.index]["api_id"] = value;
    }
    this.setState({
      formData,
    });
  };

  handleMultySelectdataChange = (e) => {
    const { formData } = this.state;
    formData[this.props.index]["api_id"] = e.key;
    formData[this.props.index]["api_value"] = e.value;
    this.setState(
      {
        formData,
      },
      () => { }
    );
  };

  handlesearchSelectdataChange = (e) => {
    const { formData } = this.state;
    formData[this.props.index]["api_id"] = e.key;
    formData[this.props.index]["api_value"] = e.value;
    this.setState({
      formData,
    });
  };

  handlePhoneChange = (e) => {
    const { formData } = this.state;

    if (e.target.value.trim() !== "") {
      formData[this.props.index]["api_value"] = e.target.value;
    } else {
      formData[this.props.index]["error"] = false;
      formData[this.props.index]["api_value"] = "";
    }

    this.setState({
      formData,
    });
  };

  handleAddNew = () => { };

  render() {
    const { item } = this.props;
    const is_editable = item.is_readonly ? true : this.props.editable;

    switch (item.attribute_type_slug) {
      case "text":
      case "password":
      case "email":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={is_editable}
            maxlength={item.character_limit}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            placeholder={item.placeholder_text}
            onKeyDown={this.handleKeyDown}
          />
        );
        case "audio":
        return (
          <AudioPlayer
          {...this.props}
          />
        );
      case "phone":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={item.is_mandatory}
            onChange={this.handlePhoneChange}
            key={item.id}
            label={item.display_name}
            disabled={is_editable}
            maxlength={item.character_limit}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "concat":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={true}
            maxlength={item.character_limit}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "auto":
      case "auto_number":
        return (
          <TextField
            {...this.props}
            type={item.attribute_type_slug}
            required={item.is_mandatory}
            onChange={this.handleChange}
            key={item.id}
            label={item.display_name}
            disabled={true}
            maxlength={item.character_limit}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "currency":
        return (
          <CurrencyField
            {...this.props}
            type="number"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleCurrencyDataChange}
            label={item.display_name}
            getData={this.handleSelectChange}
            error={item.error}
            data={item.options}
            name={item.api_name}
            disabled={is_editable}
            value={item.api_id}
            valueLabel={item.api_value}
            placeholder={item.placeholder_text}
          />
        );
      case "select":
        return (
          <>
            <SelectField
              {...this.props}
              key={item.id}
              required={item.is_mandatory}
              onChange={this.handleSelectdataChange}
              label={item.display_name}
              getData={this.handleSelectChange}
              error={item.error}
              disabled={is_editable}
              data={item.options}
              valueLabel={item.api_value}
              value={item.api_id}
              name={item.api_name}
              placeholder={item.placeholder_text}
            />
          </>
        );
      case "search_select":
        return (
          <>
            <AutoComplete
              {...this.props}
              // key={item.id}
              required={item.is_mandatory}
              onChange={this.handlesearchSelectdataChange}
              label={item.display_name}
              getData={this.handleSearchSelectChange}
              error={item.error}
              disabled={is_editable}
              data={item.options}
              valueLabel={item.api_value}
              value={item.api_id}
              name={item.api_name}
              placeholder={item.placeholder_text}
            />
          </>
        );
      case "search_multi_select":
        return (
          <>
            <AutoCompleteMultiSelect
              {...this.props}
              // key={item.id}
              required={item.is_mandatory}
              onChange={this.handleMultySelectdataChange}
              label={item.display_name}
              getData={this.handleSearchSelectChange}
              multiple={true}
              error={item.error}
              disabled={is_editable}
              data={item.options}
              valueLabel={item.api_value}
              value={item.api_id}
              name={item.api_name}
              placeholder={item.placeholder_text}
            />
          </>
        );
      case "multi_select":
        return (
          <MultipleSelectChip
            {...this.props}
            key={item.id}
            required={item.is_mandatory}
            handleSelect={this.handleMultydataChange}
            label={item.display_name}
            getData={this.handleMultiSelectChange}
            error={item.error}
            disabled={is_editable}
            className={`form-control`}
            data={item.options}
            key_name="value"
            key_id="key"
            valueLabel={item.api_value}
            value={item.api_id}
            name={item.api_name}
            placeholder={item.placeholder_text}
            menuClass="common_width_list"
          />
        );
      case "date":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            disabled={is_editable}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "date_time":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            error={item.error}
            value={item.api_value}
            name={item.api_name}
            timepicker={true}
            disabled={is_editable}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );

      case "number":
      case "float":
      case "decimal":
        return (
          <TextField
            {...this.props}
            type="number"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "checkbox":
        return (
          <CheckboxField
            {...this.props}
            type="checkbox"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handlecheckChange}
            label={item.display_name}
            checked={item.api_value}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "boolean":
        return (
          <CheckboxField
            {...this.props}
            type="checkbox"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handlecheckChange}
            label={item.display_name}
            checked={item.api_value}
            error={item.error}
            name={item.api_name}
            disabled={is_editable}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "textarea":
        return (
          <TextareaField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            disabled={is_editable}
            name={item.api_name}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "files":
      case "file":
        return null;

      default:
        return (
          <TextField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleChange}
            label={item.display_name}
            error={item.error}
            disabled={is_editable}
            name={item.api_name}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
    }
  }
}

export default DynamicForm;
