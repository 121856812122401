/* eslint-disable no-unused-vars */
import TableCell from "@material-ui/core/TableCell";
import parseISO from "date-fns/parseISO";
import PropTypes from "prop-types";
import * as React from "react";
import { AiFillCheckSquare } from "react-icons/ai";
import * as CommonValues from "./utils/common-values";
/* eslint-enable no-unused-vars */

/* eslint-disable no-useless-escape */
const isoDateRegex =
  /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/;
/* eslint-enable no-useless-escape */

export default class MTableCell extends React.Component {
  getRenderValue() {
    const dateLocale =
      this.props.columnDef.dateSetting &&
      this.props.columnDef.dateSetting.locale
        ? this.props.columnDef.dateSetting.locale
        : undefined;
    if (
      this.props.columnDef.emptyValue !== undefined &&
      (this.props.value === undefined || this.props.value === null)
    ) {
      return this.getEmptyValue(this.props.columnDef.emptyValue);
    }
    if (this.props.columnDef.render) {
      if (this.props.rowData) {
        return this.props.columnDef.render(this.props.rowData, "row");
      } else if (this.props.value) {
        return this.props.columnDef.render(this.props.value, "group");
      }
    } else if (this.props.columnDef.type === "boolean") {
      const style = { textAlign: "center", verticalAlign: "middle", width: 48 };
      if (this.props.value) {
        // return (
        //   <AiFillCheckSquare
        //     className={this.props.classesStyle.checkboxstyle}
        //   />
        // );
        return "Yes";
      } else {
        // return null;
        return null;
      }
    } else if (this.props.columnDef.type === "date") {
      if (this.props.value) {
        return this.props.value;
      } else if (isoDateRegex.exec(this.props.value)) {
        return parseISO(this.props.value).toLocaleDateString(dateLocale);
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "time") {
      if (this.props.value instanceof Date) {
        return this.props.value.toLocaleTimeString();
      } else if (isoDateRegex.exec(this.props.value)) {
        return parseISO(this.props.value).toLocaleTimeString(dateLocale);
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "dateTime") {
      if (this.props.value) {
        return this.props.value;
      } else if (isoDateRegex.exec(this.props.value)) {
        return parseISO(this.props.value).toLocaleString(dateLocale);
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "currency") {
      return this.getCurrencyValue(
        this.props.columnDef.currencySetting,
        this.props.value
      );
    } else if (typeof this.props.value === "boolean") {
      if (this.props.value) {
        return (
          <AiFillCheckSquare
            className={this.props.classesStyle.checkboxstyle}
          />
        );
      } else {
        return null;
      }
    }

    return this.props.value;
  }

  getEmptyValue(emptyValue) {
    if (typeof emptyValue === "function") {
      return this.props.columnDef.emptyValue(this.props.rowData);
    } else {
      return emptyValue;
    }
  }

  getCurrencyValue(currencySetting, value) {
    if (currencySetting !== undefined) {
      return new Intl.NumberFormat(
        currencySetting.locale !== undefined ? currencySetting.locale : "en-US",
        {
          style: "currency",
          currency:
            currencySetting.currencyCode !== undefined
              ? currencySetting.currencyCode
              : "USD",
          minimumFractionDigits:
            currencySetting.minimumFractionDigits !== undefined
              ? currencySetting.minimumFractionDigits
              : 2,
          maximumFractionDigits:
            currencySetting.maximumFractionDigits !== undefined
              ? currencySetting.maximumFractionDigits
              : 2,
        }
      ).format(value !== undefined ? value : 0);
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value !== undefined ? value : 0);
    }
  }

  handleClickCell = (e) => {
    if (this.props.columnDef.disableClick) {
      e.stopPropagation();
    }
  };
  getstatWidth(columnDef) {
    let stateWidth = {};
    if (this.props.columStatWidth && this.props.columStatWidth.length !== 0) {
      this.props.columStatWidth.forEach((item) => {
        if (item.Name === columnDef.field) stateWidth = item;
      });
    }
    return stateWidth;
  }
  getStyle = () => {
    let width = "";
    let customWidth = "";
    let modifiedWidth = "";

    // let statWidth = this.getstatWidth(this.props.columnDef);

    // if (statWidth && statWidth.Width > 0) {
    //   customWidth = `calc((100% - (${
    //     this.props.scrollWidth - statWidth.Width
    //   }px)))`;
    //   let dif = parseInt(statWidth.Width) - parseInt(statWidth.MinWidth);
    //   if (this.props.columnDef.tableData.additionalWidth < 0) {
    //     if (Math.abs(this.props.columnDef.tableData.additionalWidth) <= dif) {
    //       modifiedWidth = `calc(${customWidth} + ${this.props.columnDef.tableData.additionalWidth}px)`;
    //     } else {
    //       modifiedWidth = `calc(${customWidth} + ${-Math.abs(dif)}px)`;
    //     }
    //   } else {
    //     modifiedWidth = `calc(${customWidth} + ${this.props.columnDef.tableData.additionalWidth}px)`;
    //   }
    //   width = CommonValues.reducePercentsInCalc(
    //     modifiedWidth,
    //     this.props.scrollWidth
    //   );
    // } else {
    if (this.props.columns.length > 2) {
      if (this.props.fitWidth !== 2) {
        if (this.props.columnDef.type === "boolean") {
          customWidth = `calc((100% - (${this.props.scrollWidth - 60}px)))`;
        } else if (this.props.columnDef.type === "numeric") {
          customWidth = `calc((100% -(${this.props.scrollWidth - 100}px)))`;
        } else if (
          this.props.columnDef.type === "date" ||
          this.props.columnDef.type === "date_time"
        ) {
          customWidth = `calc((100% - (${this.props.scrollWidth - 171}px)))`;
        } else {
          customWidth = `calc((100% - (0px)) / ${6})`;
        }
        // if (this.props.actions && this.props.actions.length !== 0) {
        //   customWidth = `calc((100% - (91px)) / ${this.props.columns.length})`;
        // } else {
        //   customWidth = this.props.columnDef.tableData.width;
        // }
      } else {
      }
      modifiedWidth = `calc(${customWidth} + ${this.props.columnDef.tableData.additionalWidth}px)`;

      width = CommonValues.reducePercentsInCalc(
        modifiedWidth,
        this.props.scrollWidth
      );
    } else {
      width = CommonValues.reducePercentsInCalc(
        this.props.columnDef.tableData.width,
        this.props.scrollWidth
      );
    }
    // }
    let cellStyle = {
      color: "inherit",
      width: this.props.columnDef.width ? this.props.columnDef.width : width,
      maxWidth:
        this.props.options.tableLayout &&
        this.props.options.tableLayout === "fixed"
          ? this.props.columnDef.maxWidth
          : width,
      minWidth: 20,
      whiteSpace: "nowrap",

      overflow: "hidden",
      textOverflow: "ellipsis",
      boxSizing: "border-box",
      fontSize: "inherit",
      fontFamily: "inherit",
      fontWeight: "inherit",
    };

    if (typeof this.props.columnDef.cellStyle === "function") {
      cellStyle = {
        ...cellStyle,
        ...this.props.columnDef.cellStyle(this.props.value, this.props.rowData),
      };
    } else {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle };
    }

    if (this.props.columnDef.disableClick) {
      cellStyle.cursor = "default";
    }

    return { ...this.props.style, ...cellStyle };
  };

  render() {
    const {
      icons,
      columnDef,
      rowData,
      errorState,
      cellEditable,
      onCellEditStarted,
      scrollWidth,
      ...cellProps
    } = this.props;
    const cellAlignment =
      columnDef.align !== undefined
        ? columnDef.align
        : ["numeric", "currency"].indexOf(this.props.columnDef.type) !== -1
        ? "right"
        : this.props.columnDef.type === "boolean"
        ? "center"
        : "left";

    let renderValue = this.getRenderValue();
    if (cellEditable) {
      renderValue = (
        <div
          style={{
            borderBottom: "1px dashed grey",
            cursor: "pointer",
            width: "max-content",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCellEditStarted(this.props.rowData, this.props.columnDef);
          }}
        >
          {renderValue}
        </div>
      );
    }

    return (
      <TableCell
        size={this.props.size}
        {...cellProps}
        style={this.getStyle()}
        align={cellAlignment}
        onClick={this.handleClickCell}
      >
        {this.props.children}
        {renderValue}
      </TableCell>
    );
  }
}

MTableCell.defaultProps = {
  columnDef: {},
  value: undefined,
};

MTableCell.propTypes = {
  columnDef: PropTypes.object.isRequired,
  value: PropTypes.any,
  rowData: PropTypes.object,
  errorState: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};
