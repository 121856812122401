import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import CustomIcons from "../commonIcons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectField(props) {
  let loader = "loading...";
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!props.data || props.data.length === 0 || props.async) {
      if (props.getData) props.getData();
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    let data = { target: { vaule: "", name: props.name } };
    props.onChange(data);
  };
  let dumData = [props.value];
  const valdata = props.data ? props.data : dumData ? dumData : [];

  return (
    <div className="fieldset selectbox-fieldset">
      {props.label ? (
        <label
          id="demo-multiple-chip-label"
          className={
            props.error ? "form-label-error control-label" : "control-label"
          }
        >
          {props.label}
          <span className="tooltip">
            <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
          </span>
        </label>
      ) : (
        ""
      )}
      <div
        className={
          props.required
            ? "mandatory input-group muiselect"
            : "input-group muiselect"
        }
      >
        {props.error ? (
          <Select
            {...props}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.value}
            open={open}
            fullWidth
            onOpen={handleOpen}
            onClose={handleClose}
            className={`${props.classesStyle.fieldStyle} form-control formerror`}
            onChange={props.onChange}
            title=""
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>}
            {valdata &&
              valdata.map((item) => (
                // <Tooltip
                //   key={item.key}
                //   value={item.key ?? item[props.key_id]}
                //   name={item.value}
                //   title={item.value ?? item[props.key_name]}
                // >
                <MenuItem className="filter_Menuitem" key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
                // </Tooltip>
              ))}
          </Select>
        ) : (
          <>
            {!props.value ? (
              <Select
                {...props}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value}
                open={open}
                fullWidth
                onOpen={handleOpen}
                onClose={handleClose}
                className={`${props.classes.fieldStyle} form-control `}
                onChange={props.onChange}
                displayEmpty={true}
                title=""
                renderValue={(value) => (
                  <span style={{ color: "grey" }}>{props.placeholder}</span>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>}
                {valdata &&
                  valdata.map((item) => (
                    // <Tooltip
                    //   key={item.key}
                    //   value={item.key ?? item[props.key_id]}
                    //   name={item.value}
                    //   title={item.value ?? item[props.key_name]}
                    // >
                    <MenuItem className="filter_Menuitem" key={item.key} value={item}>
                      {item.value}
                    </MenuItem>
                    // </Tooltip>
                  ))}
              </Select>
            ) : (
              <Select
                {...props}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value}
                open={open}
                fullWidth
                onOpen={handleOpen}
                onClose={handleClose}
                className={`${props.classesStyle.fieldStyle} form-control `}
                onChange={props.onChange}
                title=""
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                endAdornment={
                  // <InputAdornment position="end">
                  //   <button
                  //     onClick={(e) => handleClear()}
                  //     className="btn no-margin"
                  //     style={{
                  //       padding: 0,
                  //       position: "relative",
                  //       top: 1,
                  //       right: 20,
                  //     }}
                  //   >
                  //     <CustomIcons iconName="close" iconClass="icon" />
                  //   </button>
                  // </InputAdornment>
                  !props.Hideclear && (
                    <InputAdornment position="end">
                      <button
                        onClick={(e) => handleClear()}
                        className="btn no-margin"
                        style={{
                          padding: 0,
                          position: "absolute",
                          top: 8,
                          right: 30,
                        }}
                      >
                        <CustomIcons iconName="close" iconClass="icon" />
                      </button>
                    </InputAdornment>
                  )
                }
              >
                {(!valdata || valdata === 0) && <MenuItem>{loader}</MenuItem>}
                {valdata &&
                  valdata.map((item) => (
                    // <Tooltip
                    //   key={item.key}
                    //   value={item.key ?? item[props.key_id]}
                    //   name={item.value}
                    //   title={item.value ?? item[props.key_name]}
                    // >
                    <MenuItem className="filter_Menuitem" key={item.key} value={item}>
                      {item.value}
                    </MenuItem>
                    // </Tooltip>
                  ))}
              </Select>
            )}
          </>
        )}
      </div>
    </div>
  );
}
