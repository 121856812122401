import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { Component } from "react";
import ButtonSecondary from "../../common_components/buttonsecondary";
import ButtonSml from "../../common_components/buttonsml";
import DynamicFilter from "../../common_components/dynamicfilter/dynamicfilter";
import "./filter_style.css";
let refreshkey = 0;
class FiltComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: this.props.filterData,
      searchData: this.props.searchData,
      filterValues: this.props.filterValues,
      clearfilter: false,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      filterData: this.props.filterData,
      searchData: this.props.searchData,
      filterValues: { ...this.props.filterValues },
    });
  };

  handleFilterValues = (data, clear, name, ind) => {
    const { filterValues, filterData } = this.state;

    if (clear) {
      delete filterValues?.primary[name];
      filterData[ind]["refresh"] = refreshkey + 1;
      this.setState(
        {
          filterValues: {
            ...this.state.filterValues,
            primary: {
              ...this.state.filterValues.primary,
              ...data.primary,
            },
          },

          clearfilter: true,
          filterData,
        },
        () => {
          // this.props.handleClearFlag();
        }
      );
      refreshkey = refreshkey + 1;
    } else {
      this.setState(
        {
          filterValues: {
            ...this.state.filterValues,
            primary: {
              ...this.state.filterValues.primary,
              ...data.primary,
            },
          },
        },
        () => {
          // this.props.updateFilter(this.state.filterValues);
        }
      );
    }
  };
  filterClose = () => {
    this.props.filterClose(false, this.state.clearfilter);
  };
  handleDisable = () => {
    if (this.state.filterValues?.primary) {
      if (Object.keys(this.state.filterValues?.primary).length > 0) {
        return false;
      } else if (
        !Object.keys(this.state.filterValues?.primary).length > 0 &&
        this.state.clearfilter
      ) {
        return false;
      } else if (!Object.keys(this.state.filterValues?.primary).length > 0) {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const { classesStyle } = this.props;
    const { filterData } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openfilter}
          onClose={!this.state.clearfilter ? this.filterClose : ""}
          PaperProps={{
            style: {
              width: "325px",
              zIndex: 1000,
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="filter-popup">
            <div className="drawer-head">
              <div className="drawer-title">
                <h4>Filter</h4>

                {/* <a
                  href="#"
                  onClick={(e) =>
                    this.props.handleSearchFilter(e, this.state.filterValues)
                  }
                  className={`${classesStyle.linkColor}  link`}
                >
                  Apply
                </a> */}
              </div>
              {/* <FilterSearch {...this.props} placeholder="Search" /> */}
            </div>
            <div className="filterlist">
              <Grid container spacing={1}>
                {filterData &&
                  filterData.map((item, index) => (
                    <Grid item xs={12} sm={12}>
                      <DynamicFilter
                        key={item.id + item.refresh ?? 0}
                        filterValues={this.state.filterValues}
                        handleFilterValues={this.handleFilterValues}
                        item={item}
                        filterData={filterData}
                        index={index}
                        {...this.props}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
            <div className="drawer-footer">
              <ButtonSecondary
                {...this.props}
                type="button"
                onClick={(e) => this.props.filterClose(true)}
                className={`${classesStyle.secondaryBtn} btn btn-secondary btnsml`}
                label="Cancel"
              />
              <ButtonSml
                {...this.props}
                disabled={this.handleDisable()}
                type="submit"
                onClick={(e) => {
                  this.props.handleSearchFilter(e, this.state.filterValues);
                }}
                className={`${classesStyle.primaryBtn} btn btn-primary btnsml`}
                label="Apply"
              />
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default FiltComponent;
