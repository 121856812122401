import React, { useEffect, useState } from "react";
import CustomIcons from "./commonIcons";
import SelectField from "./selectfield";

export default function CurrencyField(props) {
  const handleKeyPress = (event) => {
    // if (event.key === "Enter") {
    //   props.handleSearch();
    // }
  };

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState({});
  const [valueData, setValueData] = useState();

  const [name, setName] = useState("");

  const [valueLabel, setValueLabel] = useState("");

  const [data, setData] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState();
  const [isLoading, setLoading] = useState(false);

  const [currencyValue, setCurrencyValue] = useState("");
  const [currencyData, setCurrencyData] = useState({
    currency: "",
    value: "",
  });

  useEffect(() => {
    // setValue(props.value);
    setName(props.name);
    setValueLabel(props.valueLabel);
    setCurrencyData({
      currency: props?.value?.original_currency ?? "",
      value: props?.valueLabel?.original_currency ?? "",
    });
    setValueData(props?.valueLabel?.original_value ?? "");
    // setData(props.data);
    // setLoading(false);
  }, []);

  //
  const handleOpen = () => {
    if (!props.data || props.data.length === 0) {
      if (props.getData) {
        setLoading(true);
        props.getData();
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    let data = { target: { value: "", name: props.name } };
    // props.onChange(data);
  };

  const handleSelectChange = (e) => {
    setCurrencyData({
      ...currencyData,
      currency: e.target.value,
    });
    const tempValue = {
      value: valueData,
      currency: e.target.value,
    };
    let data = { target: { value: tempValue, name: props.name } };

    props.onChange(data);
  };

  const handleChange = (event) => {
    const { value } = event.target;

    // check if value includes a decimal point
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }
    setValueData(event.target.value);

    const tempValue = {
      value: event.target.value,
      currency: currencyData.currency,
    };
    let data = { target: { value: tempValue, name: props.name } };

    props.onChange(data);
  };

  return (
    <div className="fieldset">
      <label
        className={
          props.error ? "form-label-error control-label" : "control-label"
        }
      >
        {props.label}
        {props && props.tooltip ? (
          <span
            className="icon-padding"
            data-toggle="tooltip"
            title={props.tooltip}
          >
            <CustomIcons iconName="help" iconClass="header-action-icons" />
          </span>
        ) : (
          ""
        )}
      </label>

      <div
        className={
          props.required
            ? "mandatory seach-con universal-searchbox"
            : "seach-con universal-searchbox"
        }
      >
        <>
          <fieldset className="input-group currency_Field">
            <div className="selectbox">
              <SelectField
                {...props}
                className="custom-select form-control "
                key={props.key}
                required={props.required}
                onChange={handleSelectChange}
                label={""}
                getData={props.getData}
                error={props.error}
                disabled={props.disabled}
                data={props.data}
                valueLabel={currencyData.value}
                value={currencyData.currency}
                name={props.name}
                placeholder="Currency"
              />
            </div>

            <input
              // {...props}
              name="valueData"
              title={props.title ? "" : ""}
              type="number"
              autoComplete="off"
              className={
                props.error
                  ? `${props.classes.fieldStyle} form-control formerror`
                  : `${props.classes.fieldStyle} form-control `
              }
              onChange={handleChange}
              value={valueData ?? ""}
              disabled={props.disabled}

              placeholder={props.placeholder}

            // value={valueData}
            />
          </fieldset>
        </>

        <span className="icon">
          <img
            src={props.endicon}
            alt={props.eniconTitle}
            onClick={props.endicononClick}
          />
        </span>
      </div>
    </div>

    //   < div className = "seach-con universal-searchbox" >
    //     <fieldset className="input-group" style={{ display: "flex" }}>
    //       <div className="selectbox">
    //         <Select
    //           className="custom-select form-control "
    //           value={selectedFilter}
    //           onChange={(e) => handleFilterChange(e)}
    //           open={open}
    //           fullWidth
    //           onOpen={handleOpen}
    //           onClose={handleClose}
    //         >
    //           {/* <option value={"all"}>All</option>
    //           {filters.map((item) => (
    //             <option value={item}>{item}</option>
    //           ))} */}
    //           <Tooltip key={"all"} value={"all"} name={"all"} title={"All"}>
    //             <MenuItem key={"all"} value={"all"} name={"all"}>
    //               All
    //             </MenuItem>
    //           </Tooltip>
    //           {filters.map((item) => (
    //             <Tooltip key={item} value={item} name={item} title={item}>
    //               <MenuItem
    //                 className="capitalize"
    //                 key={item}
    //                 value={item}
    //                 name={item}
    //               >
    //                 {item}
    //               </MenuItem>
    //             </Tooltip>
    //           ))}
    //         </Select>

    //         {/* <SelectField
    //           {...props}
    //           key=""
    //           required=""
    //           onChange=""
    //           getData=""
    //           error=""
    //           disabled=""
    //           data=""
    //           valueLabel="Oppurtunity"
    //           value="Oppurtunity"
    //           name="Oppurtunity"
    //           placeholder="All"
    //         /> */}
    //       </div>
    //       <input
    //         {...props}
    //         value={props.value}
    //         placeholder={props.placeholder}
    //         className="form-control nobg inp-margin"
    //         onKeyPress={(e) => handleKeyPress(e)}
    //       />
    //       {props.value && (
    //         <button
    //           onClick={(e) => props.handleClose()}
    //           className="btn no-margin"
    //         >
    //           <CustomIcons iconName="close" iconClass="icon" />
    //         </button>
    //       )}
    //       <button
    //         className="btn no-margin"
    //         onClick={(e) => props.handleSearch(e)}
    //       >
    //         <svg
    //           width="16"
    //           height="16"
    //           viewBox="0 0 16 16"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
    //             stroke="#222222"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //           <path
    //             d="M14.0016 13.9996L11.1016 11.0996"
    //             stroke="#222222"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //         </svg>
    //       </button>
    //     </fieldset>
    // </ >
  );
}
