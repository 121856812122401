/* eslint-disable no-unused-vars */
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@mui/material";
import Box from "@mui/material/Stack";
import "jspdf-autotable";
import * as React from "react";
import ButtonSecondary from "../../common_components/buttonsecondary";
import CustomIcons from "../../common_components/commonIcons";
import IconbuttonWithLabel from "../../common_components/iconBtnWithLabel";
import Iconbutton from "../../common_components/IconButton";
import SearchBox from "../../common_components/searchBox";
import ActiveFilter from "./activefilter";
import FiltComponent from "./filtComponenet";
import DataManager from "./utils/data-manager";
/* eslint-enable no-unused-vars */

export class MTableToolbar extends React.Component {
  dataManager = new DataManager();
  constructor(props) {
    super(props);
    this.state = {
      searchValue: this.props.searchValue,
      filterValues: this.props.filterValues,
      clearFlag: false,
      start: 0,
      end: 0,
      openfilter: false,
      filterkey: 1,
      Page: 0,
      columnsLimit: 4,
      columnsButtonAnchorEl: null,
      filterData: this.props.filterData,
    };
  }

  componentWillReceiveProps = (props) => {
    if (props.options?.totalCount < props.options?.pageSize) {
      // props.options.pageSize = props.options.totalCount;
    }
    this.setState({
      filterData: this.props.filterData,
      filterValues: { ...this.props.filterValues, ...this.state.filterValues },

      Page: props.options.currentPage ? props.options.currentPage : 0,
      RowsPerPage: props.options.pageSize,
      searchValue: this.props.searchValue,
    });
    if (this.props.options.currentPage === 1) {
      this.setState({
        start: 0,
        end: 0,
      });
    }
  };
  handleFilter = () => {
    this.setState({ openfilter: true, filterkey: this.state.filterkey + 1 });
  };
  filterClose = (flag, clear) => {
    const { filterValues } = this.state;
    this.setState({ openfilter: false }, () => {
      if (clear && !flag) {
        if (
          this.props.filterValues?.primary &&
          Object.keys(this.props.filterValues?.primary)?.length > 0
        ) {
          this.handleSearchFilter("s", this.state.filterValues);
        } else {
          this.setState({ filterValues });
        }
      } else {
        this.setState({ filterValues });
      }
      if (flag) {
        this.setState(
          { filterValues: { ...this.state.filterValues, primary: {} } },
          () => {
            this.handleSearchFilter("s", this.state.filterValues);
          }
        );
      }
    });
  };
  handleClearFlag = () => {
    this.setState({
      clearFlag: true,
    });
  };
  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  handleRefreshData = () => {
    if (this.props.handleRefreshData) {
      this.props.handleRefreshData();
    }
  };
  handleSearch = () => {
    let data = {
      search: this.state.searchValue,
      page: 0,
    };
    // if (this.state.searchValue) {
    this.props.handlepagination(data);
    // }
  };
  handleSeachClose = () => {
    let data = {
      search: "",
      page: 0,
    };
    this.props.handlepagination(data);
  };
  handleSearchData = (data) => {
    localStorage.setItem(this.props.master_key, JSON.stringify(data));
    this.setState({ searchData: data });
  };

  handleRemoveFilter = (index, flag) => {
    const { filterValues } = this.state;

    Object.keys(filterValues[flag]).forEach((key) => {
      if (key === index) {
        delete filterValues?.[flag][key];
      }
    });
    this.setState(
      {
        filterValues,
      },
      () => {
        this.handleActiveFilterValues("s", filterValues, flag);
      }
    );
  };
  handleClearAll = () => {
    localStorage.removeItem(this.props.master_key);
    this.setState({ filterValues: {} });
    this.props.handleClearSeach();
  };
  handleSearchFilter = (e, vals, submit) => {
    let data = { search: this.props.searchValue ? this.props.searchValue : "" };
    this.setState(
      { filterValues: { ...this.state.filterValues, ...vals } },
      () => {
        Object.keys(this.state.filterValues).forEach((keysflag) => {
          Object.keys(this.state.filterValues[keysflag]).forEach((keys) => {
            data = {
              ...data,
              // ...data,
              search: this.props.searchValue ?? "",
              [keys]:
                typeof this.state.filterValues[keysflag][keys] === "object"
                  ? this.state.filterValues[keysflag][keys]?.key ?? ""
                  : this.state.filterValues[keysflag][keys],
            };
          });
        });
        localStorage.setItem(this.props.master_key, JSON.stringify(vals));
        this.setState({
          clearFlag: true,
          filterValues: vals,
          openfilter: false,
        });

        this.props.handleTableFilter(data, vals);
      }
    );
  };
  handleActiveFilterValues = (e, dataitem, flag) => {
    // let data = dataitem[flag];
    this.setState(
      { filterValues: { ...this.state.filterValues, ...dataitem } },
      () => {
        let datas = {
          search: this.props.searchValue ?? "",
        };
        Object.keys(this.state.filterValues).forEach((keysflag) => {
          Object.keys(this.state.filterValues[keysflag]).forEach((keys) => {
            datas = {
              ...datas,
              // ...data,

              search: this.props.searchValue ?? "",
              [keys]:
                typeof this.state.filterValues[keysflag][keys] === "object"
                  ? this.state.filterValues[keysflag][keys]?.key ?? ""
                  : this.state.filterValues[keysflag][keys],
            };
          });
        });

        localStorage.setItem(
          this.props.master_key,
          JSON.stringify(this.state.filterValues)
        );
        this.props.handleTableFilter(datas, this.state.filterValues);
      }
    );
  };
  handlepageCount = () => {
    const { options } = this.props;

    let current =
      this.props.options.currentPage > 0
        ? this.props.options.currentPage - 1
        : 0;
    let pageSize = this.props.options.pageSize ?? 15;

    const start = current * pageSize;
    const end = start + pageSize;

    return (
      <div className="count-style" style={{ margin: "8px 11px", fontSize: 12 }}>
        Showing <b>{start + 1 + " "}</b> to <b> </b>
        <b>
          {end === 0
            ? pageSize
            : end > options.totalCount
            ? options.totalCount
            : end}
        </b>
        {" of "}
        <b>{options.totalCount}</b> {"items"}
      </div>
    );
  };
  updateFilter = (data) => {
    this.setState(
      { filterValues: { ...this.state.filterValues, ...data } },
      () => {}
    );
  };
  handlefitWidth = (id) => {
    this.props.handlefitWidth(id);
  };
  render() {
    const { classesStyle, classes,columns } = this.props;
    const { filterData, searchValue, columnsLimit } = this.state;

    let showFitToWidth = Object.keys(columns).length > columnsLimit;
    return (
      <>
        <Toolbar className="toolbar_listing">
          <Grid className="toolbar-container" container>
            <Menu
              anchorEl={this.state.columnsButtonAnchorEl}
              open={Boolean(this.state.columnsButtonAnchorEl)}
              onClose={() => this.setState({ columnsButtonAnchorEl: null })}
            >
              <MenuItem
                key={"text"}
                disabled
                style={{
                  opacity: 1,
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {`Add or Remove Columns`}
              </MenuItem>
              {this.props.columns &&
                this.props.columns.map((col) => {
                  if (!col.hidden || col.hiddenByColumnsButton) {
                    return (
                      <li key={col.tableData ? col.tableData.id : ""}>
                        <MenuItem
                          // className={classes.formControlLabel}
                          component="label"
                          htmlFor={`column-toggle-${
                            col.tableData ? col.tableData.id : ""
                          }`}
                          disabled={col.removable === false}
                        >
                          <Checkbox
                            checked={!col.hidden}
                            className={classesStyle.checkboxstyle}
                            id={`column-toggle-${
                              col.tableData ? col.tableData.id : ""
                            }`}
                            onChange={() =>
                              this.props.onColumnsChanged(col, !col.hidden)
                            }
                          />
                          <span>{col.title}</span>
                        </MenuItem>
                      </li>
                    );
                  }
                  return null;
                })}
            </Menu>
            <Grid className="toolbar-serchblock" item xs={9}>
              <Grid container spacing={1}>
                {this.props.options.search && (
                  <Grid xs={6} sm={3}>
                    <SearchBox
                      placeholder="Search"
                      onChange={this.handleSearchChange}
                      handleSearch={this.handleSearch}
                      handleClose={this.handleSeachClose}
                      value={searchValue}
                    />
                  </Grid>
                )}

                {filterData &&
                  filterData.active &&
                  filterData.active.map((item, index) => (
                    <Grid xs={6} sm={3}>
                      <ActiveFilter
                        key={item.id}
                        filterValues={this.state.filterValues}
                        handleFilterValues={this.handleActiveFilterValues}
                        handleClearFilterValues={this.handleRemoveFilter}
                        item={item}
                        filterData={
                          filterData && filterData.active
                            ? filterData.active
                            : []
                        }
                        index={index}
                        {...this.props}
                      />
                    </Grid>
                  ))}

                {this.props.options.dynamicFilter && (
                  <div style={{ marginRight: "8px" }}>
                    <IconbuttonWithLabel
                      label="Filter"
                      iconName="filter"
                      onClick={(e) => this.handleFilter()}
                      className={`${classesStyle.secondaryBtn} btn btn-secondary`}
                    />
                  </div>
                )}

                {/* {this.props.options.dynamicMenu && (
                  <div style={{ marginRight: "8px" }}>
                    <Iconbutton
                      // label="Filter"
                      iconClass="tableButtonIcons"
                      iconName="tablemenu"
                      className=" icon-only"
                    />
                  </div>
                )} */}

                {this.props.options.refresh && (
                  <div style={{ marginRight: "8px" }}>
                    <Iconbutton
                      // label="Filter"
                      title="Refresh"
                      iconName="refresh"
                      iconClass="tableButtonIcons"
                      className=" icon-only btn"
                      onClick={(e) => this.handleRefreshData()}
                    />
                  </div>
                )}
                {!this.props.inLineFitWidth && showFitToWidth  &&(
                  <div style={{ marginRight: "8px" }}>
                    <Iconbutton
                      // label="Filter"
                      title={
                        this.props.fitWidth === 2
                          ? "Expand Columns"
                          : "Collapse Columns"
                      }
                      iconName={
                        this.props.fitWidth === 2
                          ? "collapse_table"
                          : "expandcol_table"
                      }
                      iconClass="tableButtonIcons"
                      className=" icon-only btn"
                      onClick={(e) => this.handlefitWidth(this.props.fitWidth)}
                    />
                  </div>
                )}
                {/* <div style={{ marginRight: "0px" }}>
                  <this.props.components.Actions
                    actions={
                      this.props.actions &&
                      this.props.actions.filter((a) => a.position === "toolbar")
                    }
                    components={this.props.components}
                  />
                </div> */}

                {/* {this.props.options.print && (
                  <div style={{ marginRight: "8px" }}>
                    <Iconbutton
                      // label="Filter"
                      title="Print"
                      iconName="print"
                      iconClass="tableButtonIcons"
                      className=" icon-only"
                    />
                  </div>
                )} */}

                {/* {this.props.options.columnsButton && (
                  <div style={{ marginRight: "8px" }}>
                    <Tooltip title={"Show Colums"}>
                      <Iconbutton
                        // label="Filter"
                        title="Show Columns"
                        iconName="show_columns"
                        iconClass="tableButtonIcons"
                        className=" icon-only"
                        onClick={(event) =>
                          this.setState({
                            columnsButtonAnchorEl: event.currentTarget,
                          })
                        }
                      />
                    </Tooltip>
                  </div>
                )} */}
              </Grid>
            </Grid>
            <Grid align="right" item xs={3}>
              {this.props.options?.totalCount !== 0
                ? this.handlepageCount()
                : ""}
            </Grid>

            {this.state.filterValues &&
              Object.keys(this.state.filterValues).length !== 0 &&
              ((this.state.filterValues?.primary &&
                Object.keys(this.state.filterValues?.primary)?.length > 0) ||
                (this.state.filterValues?.active &&
                  Object.keys(this.state.filterValues?.active)?.length >
                    0)) && (
                <React.Fragment>
                  <Grid item xs={12} className="filterChipsContainer">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <div className="fiterInfo">Applied Filters : </div>
                      {Object.keys(this.state.filterValues).map((keyflag) => (
                        <>
                          {Object.keys(this.state.filterValues[keyflag])
                            ?.length > 0 &&
                            Object.keys(this.state.filterValues[keyflag]).map(
                              (keys) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  <div
                                    className={`${classesStyle.multiselectStyle} custom-chips`}
                                  >
                                    <div className="flexDisplay">
                                      <div className="chip-label">
                                        {typeof this.state.filterValues?.[
                                          keyflag
                                        ][keys] === "object"
                                          ? this.state.filterValues?.[keyflag][
                                              keys
                                            ]?.value
                                          : this.state.filterValues?.[keyflag][
                                              keys
                                            ]}
                                      </div>
                                      <a
                                        style={{ display: "contents" }}
                                        onClick={(e) =>
                                          this.handleRemoveFilter(keys, keyflag)
                                        }
                                      >
                                        <CustomIcons
                                          iconName="close"
                                          iconClass="chipClose"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </Box>
                              )
                            )}
                        </>
                      ))}
                      <div className="flexDisplay">
                        <div>
                          <ButtonSecondary
                            label="Clear All"
                            className={`${classesStyle.secondaryBtn} btn btn-secondary  btnsml`}
                            onClick={(e) => this.handleClearAll(e)}
                          />
                        </div>
                        <div></div>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
          </Grid>
        </Toolbar>
        <FiltComponent
          {...this.props}
          classesStyle={this.props.classesStyle}
          filterData={
            filterData && filterData.primary ? filterData.primary : []
          }
          key={this.state.filterkey}
          searchData={this.state.searchData}
          handlepagination={this.props.handlepagination}
          openfilter={this.state.openfilter}
          updateFilter={this.updateFilter}
          handleClearFlag={this.handleClearFlag}
          filterValues={this.state.filterValues}
          handleSearchData={this.handleSearchData}
          handleSearchFilter={this.handleSearchFilter}
          filterClose={this.filterClose}
        />
      </>
    );
  }
}

export const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 10%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    overflow: "hidden",
  },
  searchField: {
    minWidth: 150,
    paddingLeft: theme.spacing(2),
  },
  formControlLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});
MTableToolbar.defaultProps = {
  actions: [],
  columns: [],
  columnsButton: false,
  localization: {
    addRemoveColumns: "Add or remove columns",
    nRowsSelected: "{0} row(s) selected",
    showColumnsTitle: "Show Columns",
    showColumnsAriaLabel: "Show Columns",
    exportTitle: "Export",
    exportAriaLabel: "Export",
    exportCSVName: "Export as CSV",
    exportPDFName: "Export as PDF",
    searchTooltip: "Search",
    searchPlaceholder: "Search",
    searchAriaLabel: "Search",
    clearSearchAriaLabel: "Clear Search",
  },
  search: true,
  showTitle: true,
  searchText: "",
  showTextRowsSelected: true,
  toolbarButtonAlignment: "right",
  searchAutoFocus: false,
  searchFieldAlignment: "right",
  searchFieldVariant: "standard",
  selectedRows: [],
  title: "No Title!",
};

export default withStyles(styles)(MTableToolbar);
