import moment from "moment-timezone";
// import 'moment-timezone';

export const timezones = [
  {
    name: "New York",
    timezone: "America/New_York",
  },
  {
    name: "London",
    timezone: "Europe/London",
  },
  {
    name: "India",
    timezone: "Asia/Kolkata",
  },
];

export const getCurrentTimeforTimezone = (date, timezone) => {
  //
  const timezoneDate = moment().tz(timezone).format("MMM DD, hh:mm A");
  //
  // return moment(date).format("MMM DD, hh:mm A");
  return timezoneDate;
};

export const getDateTimeforTimezone = (date, timezone) => {
  const timezoneDate = date.tz(timezone);
  // const timezoneDate = date.tz('Asia/Kolkata');

  // return moment(date).format("MMM DD, hh:mm A");
  return timezoneDate;
};

export const getDateTimeFormat = (fieldType) => {
  switch (fieldType) {
    case "date":
      return "DD-MM-YYYY";
    case "time":
      return "hh:mm A";
    case "date_time":
      return "DD-MM-YYYY | hh:mm A";
  }
};

export const changeDateTimeFormat = (dateTime, inputFormat, outputFormat) => {
  return moment(dateTime, { inputFormat }).format({ outputFormat });
};
