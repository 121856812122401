import React, { createContext, useState } from "react";

export const CompleteContext = createContext();

export const MainContext = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);

  return (
    <CompleteContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </CompleteContext.Provider>
  );
};
