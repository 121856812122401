import apis from "../apis/api.actions";
import { parseQueryParams } from "../utils/formValidate";
export const getNavMenu = () =>
  apis.getDatas("/get-admin-menu/").then((res) => res);
export const getUserNavMenu = () =>
  apis.getDatas("/get-user-menu/").then((res) => res);
export const elasticSearch = (id, id1) =>
  apis.getDatas(`/search/?index=${id}&search_key=${id1}`).then((res) => res);
export const get_Activity_Type = (key) =>
  apis.getDatas(`/activity-type`).then((res) => res);

export const searchableEntities = () =>
  apis.getDatas(`/searchable/entities`).then((res) => res);
export const elasticSearchTable = (data) =>
  apis.getDatas(`/search/${parseQueryParams(data)}`).then((res) => res);
