import apis from "../../apis/api.actions";
import { addToCache, getFromCache } from "../../utils/cacheManager";
import { parseQueryParams } from "../../utils/formValidate";

const getBaseData = (url, key) => {
  const cache_key = `activity_data_${key}`;
  if (getFromCache(cache_key)) {
    return Promise.resolve(getFromCache(cache_key));
  } else {
    return apis.getDatas(url).then((res) => {
      try {
        addToCache(cache_key, res);
        return res;
      } catch (exception) {}
    });
  }
};

export const get_Activity_Details = (key) =>
  apis.getDatas(`/activity/${key}`).then((res) => res);
export const get_Activity = (key) =>
  apis.getDatas(`/activity${parseQueryParams(key)}`).then((res) => res);
export const create_Activity = (data) =>
  apis.createData(`/activity`, data).then((res) => res);
export const update_Activity = (id, data) =>
  apis.updateData(`/activity/${id}`, data).then((res) => res);
export const delete_Activity = (id) =>
  apis.deleteData(`/activity/${id}`).then((res) => res);

export const delete_Notifications = () =>
  apis.createData(`/notifications/clear-all`).then((res) => res);

export const get_Activity_Category = (key) =>
  getBaseData(
    `/activity-category?activityType=${key}`,
    `/activity-category?activityType=${key}`
  ).then((res) => res);
export const get_Activity_Priority = (key) =>
  getBaseData(
    `/activity-priority?activityType=${key}`,
    `/activity-priority?activityType=${key}`
  ).then((res) => res);
export const get_Activity_Reminder = (key) =>
  getBaseData(
    `/activity-reminder?activityType=${key}`,
    `/activity-reminder?activityType=${key}`
  ).then((res) => res);
export const get_Activity_Outcome = (key) =>
  getBaseData(
    `/activity-outcome?activityType=${key}`,
    `/activity-outcome?activityType=${key}`
  ).then((res) => res);
export const get_Activity_Status = (key) =>
  getBaseData(
    `/activity-status?activityType=${key}`,
    `/activity-status?activityType=${key}`
  ).then((res) => res);
export const get_Activity_Type = (key) =>
  getBaseData(`/activity-type`, `/activity-type`).then((res) => res);
export const get_Activity_Timezone = (key) =>
  getBaseData(
    `/activity/activity-timezone-list`,
    `/activity/activity-timezone-list`
  ).then((res) => res);
export const get_Activity_Attendies = (key) =>
  apis
    .getDatas(`/activity/activity-attendee-list${parseQueryParams(key)}`)
    .then((res) => res);

export const get_Timeline = (key) =>
  apis.getDatas(`/list-timelines${parseQueryParams(key)}`).then((res) => res);
export const getTimelineDetail = (id) =>
  apis.getDatas(`/timeline/${id}`).then((res) => res);

export const get_Notification = (key) =>
  apis.getDatas(`/notifications${parseQueryParams(key)}`).then((res) => res);

export const get_mark_Complete = (id, data) =>
  apis.createData(`/activity/${id}/mark-as-complete`, data).then((res) => res);
