const home = { name: "Home", path: "/admin/admin-dashboard", isActive: false };

const userManagement = {
  name: "User Management",
  path: "",
  isActive: true,
};

const userList = {
  name: "Users",
  path: "/admin/users/list/",
  isActive: true,
};

const userDetails = {
  name: "User Details",
  path: "",
  isActive: true,
};
const formsList = {
  name: "Web Forms",
  path: "/admin/forms/list",
  isActive: true,
};
const formDetails = {
  name: "Form Details",
  path: "",
  isActive: false,
};
const webappsList = {
  name: "Web Apps",
  path: "/admin/developer/apps",
  isActive: true,
};
const emailList = {
  name: "Emails",
  path: "/admin/email/list",
  isActive: true,
};
const emailDetails = {
  name: "Email Details",
  path: "",
  isActive: false,
};

const roleList = {
  name: "Roles",
  path: "/admin/roles/list/",
  isActive: true,
};

const roleDetails = {
  name: "Role Details",
  path: "",
  isActive: true,
};

const groupList = {
  name: "Groups",
  path: "/admin/groups/list/",
  isActive: true,
};

const groupDetails = {
  name: "Groups Details",
  // path: "/admin/user-management/group-details",
  path: "",
  isActive: true,
};

const productsManagement = {
  name: "Product Management",
  path: "",
  isActive: true,
};

const adminProductCompanyManagement = {
  name: "Product Company Management",
  path: "/admin/company/list/",
  isActive: true,
};

const productsList = {
  name: "Products",
  path: "/product/list/",
  isActive: true,
};
const adminProductsList = {
  name: "Products",
  path: "/admin/product/list/",
  isActive: true,
};
const adminProductDetails = {
  name: "Product Details",
  path: "",
  isActive: true,
};

const categoryList = {
  name: "Category",
  path: "/admin/category/list/",
  isActive: true,
};
const adminCompanyList = {
  name: "Company",
  path: "/admin/company/list/",
  isActive: true,
};
const adminCompanyDetails = {
  name: "Company Details",
  path: "",
  isActive: true,
};

const importsManagement = {
  name: "Data Import & Migration",
  path: "/admin/imports/",
  isActive: true,
};
const importSummary = {
  name: "Summary",
  path: "/summary/list/",
  isActive: true,
};
const importTemplates = {
  name: "Templates",
  path: "/template/list/",
  isActive: true,
};

const qualificationManagement = {
  name: "Qualifications",
  path: "/admin/qualifications/list/",
  isActive: true,
};
const adminQualificationList = {
  name: "List",
  path: "/admin/qualifications/list/",
  isActive: true,
};
// const qualificationDetail = {
//   name: "Detail",
//   path: "/admin-qualification-details/",
//   isActive: true,
// };
const subsidiaryManagement = {
  name: "Subsidiaries",
  path: "/subsidiaries/list/",
  isActive: true,
};

const subsidiariesList = {
  name: "Subsidiaries",
  path: "/subsidiaries/list",

  isActive: true,
};
const subsidiariesDetailList = {
  name: "Subsidiary Details",
  path: "",

  isActive: true,
};
const adminSubsidiaryManagement = {
  name: "Subsidiaries",
  path: "/admin/subsidiaries/list/",
  isActive: true,
};
const adminSubsidiaryList = {
  name: "List",
  path: "/admin/subsidiaries/list/",
  isActive: true,
};
const adminSubsidiaryDetails = {
  name: "Subsidiary Details",
  path: "",
  isActive: true,
};
const generalSettingsList = {
  name: "General Settings",
  path: "/admin/general-settings",

  isActive: true,
};
const picklistList = {
  name: "Picklist Management",
  path: "/admin/picklist",

  isActive: true,
};
const picklistListOptions = {
  name: "Picklist Options Management",
  path: "",

  isActive: true,
};
const picklistListOptionsDetails = {
  name: "Picklist Options Details",
  path: "",

  isActive: true,
};

const leadsList = {
  name: "Leads",
  path: "/lead/list",

  isActive: true,
};
const leadDetailsList = {
  name: "Lead Details",
  path: "",

  isActive: true,
};

const submoduleList = {
  name: "Submodule",
  path: "/submodule/details",

  isActive: true,
};

const queriesList = {
  name: "Queries",
  path: "/queries/list",

  isActive: true,
};
const queryDetailsList = {
  name: "Query Details",
  path: "",

  isActive: true,
};

const attachmentlist = {
  name: "Attachments",
  path: "/attachments/list",

  isActive: true,
};

const accountList = {
  name: "Accounts",
  path: "/accounts/list",

  isActive: true,
};
const accountDetailsList = {
  name: "Account Details",
  path: "",

  isActive: true,
};
const contactList = {
  name: "Contacts",
  path: "/contacts/list",

  isActive: true,
};
const contactDetailsList = {
  name: "Contact Details",
  path: "",

  isActive: true,
};
const callList = {
  name: "Calls",
  path: "/calls/list",

  isActive: true,
};
const callDetailsList = {
  name: "Call Details",
  path: "",

  isActive: true,
};
const opportunityList = {
  name: "Opportunities",
  path: "/opportunities/list",

  isActive: true,
};
const opportunityDetailsList = {
  name: "Opportunity Details",
  path: "",

  isActive: true,
};
const campaignList = {
  name: "Campaigns",
  path: "/campaigns/list",

  isActive: true,
};
const productList = {
  name: "Products",
  path: "/products/list",

  isActive: true,
};
const productDetailsList = {
  name: "Product Details",
  path: "",

  isActive: true,
};
const qualificationsList = {
  name: "Qualifications",
  path: "/qualifications/list",

  isActive: true,
};
const qualificationsDetailList = {
  name: "Qualification Details",
  path: "",

  isActive: true,
};
const adminQualificationDetails = {
  name: "Qualification Details",
  path: "",
  isActive: true,
};
const topSearchList = {
  name: "Universal Search",
  path: "/top-search",

  isActive: true,
};
const userEmailList = {
  name: "Email",
  path: "/email/list",

  isActive: true,
};
const calendarList = {
  name: "Calendar",
  path: "/calendar",

  isActive: true,
};
const notesList = {
  name: "Notes",
  path: "/notes/list",

  isActive: true,
};
const notesDetailList = {
  name: "Note Details",
  path: "",

  isActive: true,
};
const reportsList = {
  name: "Reports",
  path: "/reports/list",

  isActive: true,
};
const reportsDetails = {
  name: "Report Details",
  path: "",

  isActive: true,
};
const profileList = {
  name: "Profile",
  path: "/profile",

  isActive: true,
};

const queryuserList = {
  name: "Users",
  path: "/query-user/list",

  isActive: true,
};
const queryuserDetailsList = {
  name: "User Details",
  path: "",

  isActive: true,
};

const donationList = {
  name: "Donations",
  path: "/donations/list",

  isActive: true,
};
const donationDetailsList = {
  name: "Donation Details",
  path: "",

  isActive: true,
};
const importSummaryList = {
  name: "Import Summary",
  path: "/importsummary/list/"
}

module.exports = {
  user_list: [userManagement, userList],
  user_details: [userManagement, userList, userDetails],
  role_list: [userManagement, roleList],
  role_details: [userManagement, roleList, roleDetails],
  group_list: [userManagement, groupList],
  group_details: [userManagement, groupList, groupDetails],
  products_list: [productsManagement, productsList],
  category_list: [productsManagement, categoryList],
  company_list: [productsManagement, adminCompanyList],
  admin_product_company_details: [
    adminProductCompanyManagement,
    adminCompanyList,
    adminCompanyDetails,
  ],
  admin_product_details: [
    productsManagement,
    adminProductsList,
    adminProductDetails,
  ],
  data_import: [importsManagement],
  admin_qualification_list: [
    qualificationManagement,
    // adminQualificationList,
  ],
  // admin_qualification_detail: [
  //
  //   qualificationManagement,
  //   qualificationDetail,
  // ],
  admin_subsidiary_list: [adminSubsidiaryManagement],
  admin_subsidiary_details: [
    adminSubsidiaryManagement,
    // adminSubsidiaryList,
    adminSubsidiaryDetails,
  ],
  general_settings: [generalSettingsList],
  picklist_management: [picklistList],
  picklist_options_management: [picklistList, picklistListOptions],
  picklist_options_details: [
    picklistList,
    picklistListOptions,
    picklistListOptionsDetails,
  ],
  web_forms: [formsList],
  form_details: [formsList, formDetails],
  web_apps: [webappsList],
  email_list: [emailList],
  email_details: [emailList, emailDetails],

  leads: [leadsList],
  lead_details: [leadsList, leadDetailsList],

  queries:[queriesList],
  query_details: [queriesList, queryDetailsList],

  submodules: [submoduleList],

  attachments: [attachmentlist],

  accounts: [accountList],
  account_details: [accountList, accountDetailsList],

  contacts: [contactList],
  contact_details: [contactList, contactDetailsList],

  calls: [callList],
  call_details: [callList, callDetailsList],

  opportunities: [opportunityList],
  opportunity_details: [opportunityList, opportunityDetailsList],

  campaigns: [campaignList],
  products: [productList],
  product_details: [productList, productDetailsList],

  qualifications: [qualificationsList],
  qualification_details: [qualificationsList, qualificationsDetailList],
  admin_qualification_details: [
    qualificationManagement,
    // adminQualificationList,
    adminQualificationDetails,
  ],

  subsidiaries: [subsidiariesList],
  subsidiary_details: [subsidiariesList, subsidiariesDetailList],

  top_search: [topSearchList],
  user_email: [],
  calendar: [],

  notes: [notesList],
  note_details: [notesList, notesDetailList],

  reports: [reportsList],
  reports_details: [reportsList, reportsDetails],
  profile: [profileList],

  query_user: [queryuserList],
  query_user_details: [queryuserList, queryuserDetailsList],

  donation: [donationList],
  donation_details: [donationList, donationDetailsList],
  importsummarylist: [donationList, importSummaryList]
};
