import { Checkbox, Input } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setValues(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setTimeout(() => {
      props.handleSelect(value);
    }, 250);
  };
  const handleSort = (slc) => {
    let sortdata = [];
    if (slc && props.data) {
      slc.forEach((item) => {
        props.data.forEach((dataitem) => {
          if (item === dataitem[props.key_id]) {
            sortdata.push(dataitem[props.key_name]);
          }
        });
      });
    }

    return sortdata;
  };
  const hamdleChecked = (val) => {
    let checked = false;

    if (values) {
      values.forEach((item) => {
        if (item === val[props.key_id]) {
          checked = true;
        }
      });
    }
    return checked;
  };
  const handleOpen = () => {
    // if (!props.data || props.data.length === 0) {
    if (props.getData) props.getData();
    // }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleValue = () => {
    let data = [];
    if (props.value) {
      props.value.forEach((item) => {
        if (typeof item === "object" && item !== null) {
          data.push(item.id);
        } else {
          data.push(item);
        }
      });
    }
    setValues(data);
  };
  useEffect(() => {
    handleValue();
  }, [props.value]);
  //

  return (
    <div className="fieldset">
      <label
        id="demo-multiple-chip-label"
        className={
          props.error ? "form-label-error control-label" : "control-label"
        }
      >
        {props.label}
        <span className="tooltip">
          <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
        </span>
      </label>
      <div
        className={
          props.required
            ? "mandatory input-group muiselect"
            : "input-group muiselect"
        }
      >
        {values.length === 0 ? (
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={values}
            fullWidth
            reuired={props.reuired}
            open={open}
            disabled={props.disabled}
            onChange={handleChange}
            onOpen={handleOpen}
            className={
              props.disabled ? "form-control  disabled " : "form-control "
            }
            onClose={handleClose}
            displayEmpty={true}
            renderValue={(value) => (
              <span style={{ color: "grey" }}>{props.placeholder}</span>
            )}
            input={
              <Input
                className={`${props.classes.fieldStyle} form-control `}
                fullWidth
                id="select-multiple-chip"
                placeholder={props.placeholder}
                // label={props.label}
                InputProps={{
                  className: `${props.classes.fieldStyle} form-control`,
                }}
              />
            }
            MenuProps={MenuProps}
          >
            {props.data &&
              props.data.map((item) => (
                // <Tooltip followCursor={true} disableInteractive={true} placement='top-end' arrow={true}  title={item[props.key_name]} key={item[props.key_id]}
                // value={item[props.key_id]}>

                <MenuItem
                  key={item[props.key_id]}
                  value={item[props.key_id]}
                  style={{ whiteSpace: "normal" }}
                  className={props.menuClass}
                // style={getStyles(t, values, theme)}
                >
                  <Checkbox checked={hamdleChecked(item)} />

                  {item[props.key_name]}
                </MenuItem>
                //</Tooltip>
              ))}
          </Select>
        ) : (
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={values}
            fullWidth
            reuired={props.reuired}
            open={open}
            disabled={props.disabled}
            onChange={handleChange}
            onOpen={handleOpen}
            className={
              props.disabled ? "form-control  disabled " : "form-control "
            }
            onClose={handleClose}
            input={
              <Input
                className={`${props.classes.fieldStyle} form-control `}
                fullWidth
                id="select-multiple-chip"
                placeholder={props.placeholder}
                // label={props.label}
                InputProps={{
                  className: `${props.classes.fieldStyle} form-control`,
                }}
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {handleSort(selected).map((value) => (
                  <Chip
                    className={`${props.classes.multiselectStyle} `}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {props.data &&
              props.data.map((item) => (
                // <Tooltip followCursor={true} disableInteractive={true} placement='top-end' arrow={true} title={item[props.key_name]}
                // key={item[props.key_id]}
                // value={item[props.key_id]}>

                <MenuItem
                  key={item[props.key_id]}
                  value={item[props.key_id]}
                  style={{ whiteSpace: "normal" }}
                  className={props.menuClass}
                // style={getStyles(t, values, theme)}
                >
                  <Checkbox checked={hamdleChecked(item)} />

                  {item[props.key_name]}
                </MenuItem>
                // </Tooltip>
              ))}
          </Select>
        )}
      </div>
      {/* <FormControl>
        <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
        
      </FormControl> */}
    </div>
  );
}
