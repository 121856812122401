import React, { Component } from "react";
import { getMasterData } from "../dynamicform/api.functions";
import CheckboxField from "../filterinputs/checkboxfield";
import DatePickerField from "../filterinputs/datepicker";
import SelectField from "../filterinputs/selectfield";

class DynamicFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValues: props.filterValues,
      filterData: this.props.filterData,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      filterData: this.props.filterData,
      // filterValues: { ...this.state.filterValues, ...this.props.filterValues },
      // filterValues: props.filterValues,
    });
  };

  updateData = (data) => {
    this.setState({ filterValues: data });
  };
  handleDatePickerChange = (e) => {
    const { filterValues } = this.state;

    if (e.value) {
      this.setState(
        {
          filterValues: {
            // ...this.state.filterValues,
            primary: { [e.name]: e.value },
          },
        },
        () => {
          setTimeout(() => {
            this.props.handleFilterValues(this.state.filterValues);
          }, 200);
        }
      );
    } else {
      delete filterValues?.primary[e.name];
      this.setState({ filterValues }, () => {
        this.props.handleFilterValues(
          this.state.filterValues,
          true,
          e.name,
          this.props.index
        );
      });
    }
  };
  handlecheckChange = (e) => {
    const { filterData } = this.state;
    filterData[this.props.index]["api_value"] = e.target.checked;
    this.setState({
      filterData,
    });
  };
  handleSelectChange = (e) => {
    const { filterData } = this.state;
    if (
      !this.props.item.options &&
      this.props.item.options !== null &&
      this.props.item.options !== null
    ) {
      getMasterData(this.props.master_key, this.props.item.api_name).then(
        (res) => {
          filterData[this.props.index]["options"] =
            res.data[this.props.item.api_name];
          this.setState({
            filterData,
          });
        }
      );
    }
  };
  handleSelectDataChange = (e, index) => {
    const { filterValues } = this.state;

    if (e.target.value) {
      this.setState(
        {
          filterValues: {
            // ...this.state.filterValues,
            primary: {
              // ...this.state.filterValues?.primary,
              [e.target.name]: e.target.value,
            },
          },
        },
        () => {
          setTimeout(() => {
            this.props.handleFilterValues(this.state.filterValues);
          }, 200);
        }
      );
    } else {
      delete filterValues?.primary[e.target.name];
      this.setState({ filterValues }, () => {
        this.props.handleFilterValues(
          this.state.filterValues,
          true,
          e.target.name,
          this.props.index
        );
      });
    }
  };
  handleMultydataChange = (value) => {
    this.setState(
      {
        filterValues: {
          // ...this.state.filterValues,

          primary: {
            // ...this.state.filterValues?.primary,
            [this.props.item.api_name]: value,
          },
        },
      },
      () => {
        setTimeout(() => {
          this.props.handleFilterValues(this.state.filterValues);
        }, 200);
      }
    );
  };
  handleMultiSelectChange = (e) => {
    const { formData } = this.state;

    if (
      !this.props.item.options &&
      this.props.item.options !== null &&
      this.props.item.options !== null
    ) {
      getMasterData(this.props.master_key, this.props.item.api_name).then(
        (res) => {
          formData[this.props.index]["options"] = res.data
            ? res.data[this.props.item.api_name]
            : [];
          setTimeout(() => {
            this.setState({
              formData,
            });
          }, 150);
        }
      );
    }
  };
  render() {
    const { item } = this.props;
    const { filterValues } = this.state;

    //   return (
    //       <>

    //       <CheckboxField
    //         id="1"
    //         {...this.props}
    //         type="text"
    //         key=""
    //         required=""
    //         onChange=""
    //         label="Branch Manager"
    //         error=""
    //         disabled=""
    //         name=""
    //         value=""
    //         maxlength=""
    //         placeholder=""

    //       />
    //    <RadioBtnField
    //         {...this.props}
    //         id="1"
    //         type="text"
    //         key=""
    //         required=""
    //         onChange=""
    //         label="All"
    //         error=""
    //         disabled=""
    //         name=""
    //         value=""
    //         maxlength=""
    //         placeholder=""
    //       />
    //      <DatePickerField
    //         {...this.props}
    //         type="text"
    //         key=""
    //         required=""
    //         onChange=""

    //         error=""
    //         value=""
    //         name=""
    //         disabled=""
    //         maxlength=""
    //         placeholder=""
    //       />
    //      <DateRangeField
    //         {...this.props}
    //         type="text"
    //         key=""
    //         required=""
    //         onChange=""

    //         error=""
    //         value=""
    //         name=""
    //         disabled=""
    //         maxlength=""
    //         placeholder=""
    //       />
    //       <SelectField
    //         {...this.props}
    //         key="{localeData.default_currency}"
    //         name=""
    //         placeholder=""
    //         data=""
    //         disabled=""
    //         value=""
    //         onChange=""
    //       />
    //  <MultipleSelect
    //         className={`form-control`}
    //         // key={localeData.applied_filter}
    //         name=""
    //         id=""
    //         placeholder=" "
    //         key_name=""
    //         key_id=""
    //         disabled=""
    //         handleSelect=""
    //         data=""
    //         // onChange={this.handleChange}
    //         // value={locale_information.default_currency}
    //         tooltip="You will be able to manage the Account module & fields from here "
    //       />
    //       <RangeSlider
    //         {...this.props}
    //         className={`form-control`}
    //         startplaceholder="Min"
    //         endplaceholder="Max"
    //       />
    //       </>
    //     );
    switch (item.attribute_type_slug) {
      case "select":
        return (
          <SelectField
            {...this.props}
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleSelectDataChange}
            label={item.display_name}
            getData={this.handleSelectChange}
            error={item.error}
            disabled={this.props.editable}
            data={item.options}
            valueLabel={item.api_value}
            value={
              filterValues?.primary
                ? filterValues?.primary[item.api_name]
                  ? filterValues?.primary[item.api_name]
                  : ""
                : ""
            }
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
        );
      case "multi_select":
        return (
          <SelectField
            {...this.props}
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleSelectDataChange}
            label={item.display_name}
            getData={this.handleSelectChange}
            error={item.error}
            disabled={this.props.editable}
            data={item.options}
            valueLabel={item.api_value}
            value={
              filterValues?.primary
                ? filterValues?.primary[item.api_name]
                  ? filterValues?.primary[item.api_name]
                  : ""
                : ""
            }
            name={item.api_name}
            placeholder={item.placeholder_text}
          />
          // <MultipleSelectChip
          //   {...this.props}
          //   key={item.id}
          //   required={item.is_mandatory}
          //   handleSelect={this.handleMultydataChange}
          //   label={item.display_name}
          //   getData={this.handleMultiSelectChange}
          //   error={item.error}
          //   data={item.options}
          //   key_name="value"
          //   key_id="key"
          //   valueLabel={item.api_value}
          //   value={item.api_id}
          //   name={item.api_name}
          //   placeholder={item.placeholder_text}
          // />
        );
      case "date":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            range
            error={item.error}
            value={
              filterValues?.primary
                ? filterValues?.primary[item.api_name]
                  ? filterValues?.primary[item.api_name]
                  : ""
                : ""
            }
            removeButton={true}
            name={item.api_name}
            disabled={this.props.editable}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );
      case "date_time":
        return (
          <DatePickerField
            {...this.props}
            type="text"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handleDatePickerChange}
            label={item.display_name}
            timePicker={true}
            error={item.error}
            removeButton={true}
            range
            value={
              filterValues?.primary
                ? filterValues?.primary[item.api_name]
                  ? filterValues?.primary[item.api_name]
                  : ""
                : ""
            }
            name={item.api_name}
            disabled={this.props.editable}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );

      case "checkbox":
        return (
          <CheckboxField
            {...this.props}
            type="checkbox"
            key={item.id}
            required={item.is_mandatory}
            onChange={this.handlecheckChange}
            label={item.display_name}
            checked={item.api_value}
            error={item.error}
            name={item.api_name}
            disabled={this.props.editable}
            value={item.api_value}
            maxlength={item.character_limit}
            placeholder={item.placeholder_text}
          />
        );

      default:
        return null;
    }
  }
}

export default DynamicFilter;
