import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
// import Iconbutton from "../../IconButton";
// import SearchBoxSecondary from "../../secondarysearchBox";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import BlockingLoader from "../../../loader/blockingloader";
import SnackComp from "../../../snackbar/snack.component";
import AttachmentModal from "../components/attachmentModal";
import "../attachment_style.css";
import { deleteAttachment, getAttachment } from "../attachment.apis";
import CreateAttachments from "../create_attachments";
import { createNewAttachments } from "../../../../page_container/core_components/attachment/api.functions";
import { fetchFile } from "../../../../utils/commonUtils";

let refreshkey = 0;
let createkey = 0;
let detailKey = 0;

class AttachmentListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opencreate: false,
      attchmentData: [],
      open: false,
      noteId: "",
      detailId: "",
      isActive: true,
      edit: false,
      disableConfirm: false,
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        page: 1,
        per_page: 15,
      },
      lastPage: 1,
      Sroll_Top: 1,
      onload: false,
      currentPage: 1,
      confirmData: {},
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
      showLoader: false
    };
    this.listInnerRef = React.createRef();

  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
      },
    });
  };
  componentDidMount = () => {
    if (this.props.openattachment) this.getAttachment(true);
  };
  getAttachment = () => {
    this.setState({
      // isActive: 
      showLoader: true
    });
    getAttachment(this.state.formSearch)
      .then((res) => {
        //
        this.setState(
          {
            attchmentData: res.data?.data,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            showLoader: false,
          },
          () => { }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false, showLoader: false, });
      });
  };
  getpagedNote = () => {
    const { attchmentData } = this.state;
    getAttachment(this.state.formSearch)
      .then((res) => {
        let glbData = [...res.data?.data];
        glbData?.forEach((item) => {
          attchmentData?.forEach((elm, ind) => {
            if (item.id === elm.id) {
              delete attchmentData[ind];
            }
          });
          attchmentData.push(item);
        });

        this.setState(
          {
            attchmentData,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            onload: false,
          },
          () => {
            if (this.state.Sroll_Top > 1) {
              this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleDeleteOpen = (e, item) => {
    this.setState({
      detailId: item.id,
      openAction: true,
      confirmData: {
        title: getMessageForKey("delete_attachment_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_attachment_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  handleDownload = (e, item) => {
    console.log("download", item);
    // this.downloadFile(item);
    fetchFile(item);
  };



  // downloadFile = async (data) => {

  //   const api = data.file_path;
  //   console.log("file path", api);

  //   const result = await fetch(api, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': "octet/stream",
  //     },
  //   });
  //   const filename = `${data.display_name}`;

  //   const blob = await result.blob();
  //   // const href = window.URL.createObjectURL(blob)

  //   var a = window.document.createElement("a");
  //   a.href = window.URL.createObjectURL(blob, {
  //     type: "octet/stream",
  //   });
  //   a.download = filename;
  //   document.body.appendChild(a);
  //   a.click();
  // };

  // exportFile = (fileData) => {
  //
  //   let blob = fileData.blob();
  //
  //   const filename = `${this.state.fileName}`;
  //   if (window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveBlob(blob, filename);
  //   } else {
  //     var a = window.document.createElement("a");
  //     a.href = window.URL.createObjectURL(blob, {
  //       type: "octet/stream",
  //     });
  //     a.download = filename;
  //     document.body.appendChild(a);
  //     a.click();
  //   }
  // };

  // handleManageActions = (event, rowData) => {
  //   this.setState({
  //     noteId: rowData.id,
  //     actionButton: true,
  //     anchorEl: event.currentTarget,
  //   });
  // };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleDelete = () => {
    let idarray = [];
    idarray.push(this.state.detailId);
    let element = { "attachments_ids": idarray }
    deleteAttachment(element)
      .then((res) => res)
      .then((res) => {

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getAttachment();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  // handleCommunicationDetails = (item) => {
  //   this.setState({ opencommunicationdetails: true, noteId: item.id }, () => {
  //     detailKey = detailKey + 1;
  //   });
  // };
  // filterClose = () => {
  //   this.setState({ opencommunicationdetails: false });
  //   this.getNote();
  // };
  handleClickOpen = () => {
    console.log()
    this.setState(
      {
        edit: false,
        opencreate: true,
      },
      () => {
        createkey = createkey + 1;
      }
    );
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };
  // handleClose = (id) => {
  //   if (id && !this.state.edit) {
  //     this.setState({ formSearch: { ...this.state.formSearch, page: 1 } });
  //     this.getAttachment();
  //   } else if (id && this.state.edit) {
  //     this.getpagedNote();
  //   }
  //   this.setState({ open: false });
  // };
  handleClose = () => {
    this.setState({
      opencreate: false,
    });
  };
  truncateDesc = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  formatDate = (date) => {
    const formattedDate = date
      ? moment.utc(date).format("DD-MM-YYYY | hh:mm A")
      : "--";
    return formattedDate;
  };
  handleSearch = (page, scrolTop) => {
    this.setState(
      {
        onload: true,
        formSearch: {
          ...this.state.formSearch,
          page: page,
          // per_page: this.state.formSearch.per_page + 10,
        },
        Sroll_Top: scrolTop,
      },
      () => {
        this.getpagedNote();
      }
    );
  };
  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;

      const { currentPage, lastPage } = this.state;
      this.listInnerRef.current.scrollTop = scrollTop;
      if (!this.state.onload) {
        if (lastPage > currentPage) {
          if (Math.trunc(scrollTop) + clientHeight === scrollHeight) {
            setTimeout(() => {
              this.handleSearch(currentPage + 1, scrollHeight);
            }, 1000);
          }
        }
      }
    }
  };
  handleSubmit = (selected_files) => {

    const form = new FormData();

    form.append("entity", this.props.data.entity_name ?? "");
    form.append("entity_id", this.props.data.entity_id ?? "");

    selected_files.forEach(
      (file, index) => {
        console.log("file", `${file.display_name}.${file.extension}`)
        if (file.display_name && file.display_name !== "") {
          form.append(
            `attachments[${index}]`,
            file.file,
            `${file.display_name}.${file.extension}`
          );
        }
        else {
          form.append(
            `attachments[${index}]`,
            file.file,
            `${file.file.name}`
          );
        }
      }
    );
    createNewAttachments(form)
      // .then((res) => res)
      .then((res) => {
        this.setState({
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getAttachment();
        this.handleClose(false);
      })
      .catch((error) => {
        const errorString = FormatError(error);
        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  render() {
    const { classes } = this.props;
    const { attchmentData, isActive, showLoader } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openattachment}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        >
          <div className="note-popup">
            <div className="note-head">
              <div className="dropdown drop_left">
                <a
                  style={{ "pointer-events": "none" }}
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Attachments

                </a>


              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                <div className="item ">
                  <button
                    onClick={this.handleClickOpen}
                    className={`${this.props.classes.primaryBtn} btn btn-primary  btnsml`}
                  >
                    Upload File
                  </button>
                </div>
                <CreateAttachments
                  {...this.props}
                  key={createkey}
                  edit={this.state.edit}
                  detailId={this.state.detailId}
                  open={this.state.opencreate}
                  handleClose={this.handleClose}
                  maindata={this.props.data}
                  submit={this.handleSubmit}
                />
              </div>
            </div>
            {isActive ? (
              <>
                <div className="note-list">
                  {[1, 2, 3, 4, 5, 6].map((item) => (
                    <div className="item notes-item">
                      <div className="note_details">
                        {/* <div className="skeltron-head">
                          <Skeleton variant="text" animation="wave" />
                        </div> */}
                        <div className="details">
                          <Skeleton variant="text" height={32} />
                        </div>
                      </div>
                      {/* <div className="notes_date">
                        <span className="text-block">
                          <Skeleton variant="text" width={100} height={15} />
                        </span>
                      </div> */}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {showLoader && (
                  <div className="page_overlay">
                    <BlockingLoader isActive={showLoader} />
                  </div>
                )}
                {attchmentData && attchmentData.length ? (
                  <>
                    <div
                      className="note-list calls-list activity-list"
                      ref={this.listInnerRef}
                      onScroll={(e) => this.handleScroll(e)}
                    >
                      {attchmentData?.map((item) => (
                        <div className="item notes-item">
                          <div className="note_details">
                            <div className="tag-line tag-flex attachment_Flex">
                              <a
                                className={` link`}
                                href="#"
                                onClick={(e) => this.handleDownload(e, item)}
                              >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M4.66699 6.66602L8.00033 9.99935L11.3337 6.66602" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M8 10V2" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </a>
                              <p className="text">{item.display_name} </p>
                              <div className="dropdown">
                                <a
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <CustomIcons
                                    iconName="tabledropdown"
                                    iconClass="icon"
                                  />
                                </a>

                                <div className="dropdown-menu">
                                  {/* <a
                                    className="dropdown-item"
                                    href="#"
                                  >
                                    Tag
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => this.handleEdit(e, item)}
                                    href="#"
                                  >
                                    Edit
                                  </a> */}

                                  <a
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.handleDeleteOpen(e, item)
                                    }
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => this.handleDownload(e, item)}
                                    href="#"
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="notes_date">
                            <span className="text-block">
                              <span className="sub">Created by</span>
                              <span className="t-box">
                                {item.created_by.name ?? "--"}
                              </span>
                              <span className="t-box">
                                | {this.formatDate(item?.created_at)} UTC
                              </span>
                            </span>
                          </div>
                        </div>
                      ))}

                      {/* <Grid
                        container
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          align="center"
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <BlockingLoader
                            isActive={this.state.onload}
                          ></BlockingLoader>
                        </Grid>
                      </Grid> */}
                    </div>
                  </>
                ) : (
                  <div className="note-list popup-stretch-block">
                    <div className="empty-content">
                      <div className="img-box">
                        <img src="/assets/images/illustrations/Empty_Attachmants.png" />
                      </div>
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default AttachmentListDrawer;
