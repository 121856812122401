import { Skeleton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import moment from "moment";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import CustomIcons from "../../../commonIcons";
import "../../drawer_style.css";
import { getAttachmentLink } from "../api.functions";

class EmailDetailsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: props.conversations,
      activeMail: props.activeMail,
      activeMessageId: props.activeMail.id,
      activeSubject: "",
      snackData: {},
      isLoading: props.loadingDetails,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      conversations: props.conversations,
      activeMail: props.activeMail,
      activeMessageId: props.activeMail.id,
      isLoading: props.loadingDetails,
    });
  };
  getSenderDisplayName = (senderData) => {
    const sender = this.getSender(senderData);
    //
    //
    //
    const response =
      sender[0]?.name !== undefined &&
      sender[0]?.name !== null &&
      sender[0]?.name.trim() !== ""
        ? sender[0]?.name
        : sender[0].emailAddress;
    //
    return response;
  };
  selectMessage = (data) => {
    this.setState({
      activeMessageId: data.id,
      activeSubject: data.subject,
    });
  };
  getSender = (senderData) => {
    const sender = senderData instanceof Array ? senderData : [senderData];
    return sender;
  };

  getSenderEmail = (senderData) => {
    const sender = senderData instanceof Array ? senderData : [senderData];
    //
    const response =
      sender[0]?.name !== undefined &&
      sender[0]?.name !== null &&
      sender[0]?.name.trim() !== ""
        ? sender[0].emailAddress
        : "";
    return response;
  };

  getSubject = (activeSubject) => {
    return activeSubject !== undefined &&
      activeSubject !== null &&
      activeSubject.trim() !== ""
      ? activeSubject
      : "";
  };
  downloadAttachment = (e, data) => {
    e.preventDefault();

    let datas = {
      mailbox_id: this.state.activeMail.mailbox_id,
      mail_id: this.state.activeMail.id,
      attachment_service_id: data.attachment_service_id,
    };
    getAttachmentLink(datas)
      .then((res) => {
        this.setState({ fileName: data.name }, () => {
          this.downloadFile(res.data);
        });
      })
      .catch((error) => {
        this.setState({
          isActive: false,
          snackData: {
            open: true,
            message: error.response.data.message,
            variant: "error",
          },
        });
      });
  };

  downloadFile = async (data) => {
    const config = {
      headers: { Authorization: `Bearer ${data.token}` },
    };

    const api = data.link;

    const result = await fetch(api, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
    const filename = `${this.state.fileName}`;

    const blob = await result.blob();
    // const href = window.URL.createObjectURL(blob)

    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {
      type: "octet/stream",
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  };

  render() {
    const { classes } = this.props;
    const {
      filterData,
      isLoading,
      conversations,
      activeMessageId,
      activeMail,
    } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.openemaildetail}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a>
              <div class="righ-container"></div>
              {/* <div className="righ-container">
                <button
                  className={`${classes.secondaryBtn} btn btn-secondary btnsml min_width`}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 8.85026V12.8503C12 13.2039 11.8595 13.543 11.6095 13.7931C11.3594 14.0431 11.0203 14.1836 10.6667 14.1836H3.33333C2.97971 14.1836 2.64057 14.0431 2.39052 13.7931C2.14048 13.543 2 13.2039 2 12.8503V5.51693C2 5.16331 2.14048 4.82417 2.39052 4.57412C2.64057 4.32407 2.97971 4.18359 3.33333 4.18359H7.33333"
                      stroke="#222222"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 2.18359H14V6.18359"
                      stroke="#222222"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.66699 9.51693L14.0003 2.18359"
                      stroke="#222222"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div> */}
            </div>
            <div className="activity-list emaildetail_list conversation-content">
              <div className="conversation-wrapper">
                <div className="list-block">
                  {isLoading ? (
                    <>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                        <div className="item">
                          <Skeleton />

                          <Skeleton />

                          <Skeleton />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {conversations && (
                        <>
                          {conversations?.length !== 0 ? (
                            <div className="list-block">
                              {conversations?.map((messages) => (
                                <>
                                  {activeMessageId === messages.id ? (
                                    <>
                                      <div className="item">
                                        <div className="flex-row">
                                          <p className="name">
                                            {" "}
                                            <span className="text">
                                              {this.getSenderDisplayName(
                                                messages?.sender
                                              )}
                                            </span>
                                            <span className="email">
                                              {this.getSenderEmail(
                                                messages?.sender
                                              )}
                                            </span>
                                          </p>
                                          <div className="right-container">
                                            {messages.attachment && (
                                              <>
                                                {messages?.attachment
                                                  ?.length !== 0 && (
                                                  <a className="link" href="#">
                                                    <svg
                                                      width="17"
                                                      height="17"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M14.7167 7.9752L8.59004 14.1019C7.83947 14.8524 6.82149 15.2741 5.76004 15.2741C4.69858 15.2741 3.6806 14.8524 2.93004 14.1019C2.17947 13.3513 1.75781 12.3333 1.75781 11.2719C1.75781 10.2104 2.17947 9.19243 2.93004 8.44187L9.0567 2.3152C9.55708 1.81483 10.2357 1.53372 10.9434 1.53372C11.651 1.53372 12.3297 1.81483 12.83 2.3152C13.3304 2.81558 13.6115 3.49423 13.6115 4.20187C13.6115 4.90951 13.3304 5.58816 12.83 6.08854L6.6967 12.2152C6.44652 12.4654 6.10719 12.6059 5.75337 12.6059C5.39955 12.6059 5.06022 12.4654 4.81004 12.2152C4.55985 11.965 4.4193 11.6257 4.4193 11.2719C4.4193 10.9181 4.55985 10.5787 4.81004 10.3285L10.47 4.6752"
                                                        stroke="#222222"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </a>
                                                )}
                                              </>
                                            )}
                                            <p className="date">
                                              {" "}
                                              {moment(
                                                messages?.receivedDateTime
                                              ).format("DD/MM/YYYY, HH:mm A")}
                                            </p>

                                            {/* <a className="link" href="#">
                                    <svg
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.9987 9.27523C8.36689 9.27523 8.66536 8.97675 8.66536 8.60856C8.66536 8.24037 8.36689 7.94189 7.9987 7.94189C7.63051 7.94189 7.33203 8.24037 7.33203 8.60856C7.33203 8.97675 7.63051 9.27523 7.9987 9.27523Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.9987 4.60854C8.36689 4.60854 8.66536 4.31006 8.66536 3.94187C8.66536 3.57368 8.36689 3.27521 7.9987 3.27521C7.63051 3.27521 7.33203 3.57368 7.33203 3.94187C7.33203 4.31006 7.63051 4.60854 7.9987 4.60854Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.9987 13.9419C8.36689 13.9419 8.66536 13.6434 8.66536 13.2752C8.66536 12.907 8.36689 12.6086 7.9987 12.6086C7.63051 12.6086 7.33203 12.907 7.33203 13.2752C7.33203 13.6434 7.63051 13.9419 7.9987 13.9419Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </a> */}
                                          </div>
                                        </div>
                                        <div className="flex-row">
                                          {/* <p className="email">
                                  {this.getSenderEmail(messages?.sender)}
                                   {messages?.sender[0]?.emailAddress ?? ""} 
                                </p> */}

                                          {/* <div className="tags">
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      Lead
                                    </span>
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      Contact
                                    </span>
                                  </div> */}
                                        </div>
                                        <div className="flex-row">
                                          <div className="add-list">
                                            <span className="sub">
                                              <b>To </b>
                                            </span>
                                            <span className="sub">
                                              {messages?.recipient?.map(
                                                (res, ind) => (
                                                  <>
                                                    <span>
                                                      {res?.name
                                                        ? res?.name
                                                        : res?.emailAddress
                                                        ? res?.emailAddress
                                                        : ""}
                                                    </span>
                                                  </>
                                                )
                                              )}
                                            </span>
                                            {messages?.ccRecipients && (
                                              <>
                                                {messages?.ccRecipients
                                                  .length !== 0 && (
                                                  <>
                                                    <span className="sub">
                                                      {messages?.ccRecipients?.map(
                                                        (res, ind) => (
                                                          <>
                                                            {res?.name !==
                                                              null &&
                                                            res?.name !==
                                                              undefined &&
                                                            res?.name !== "" ? (
                                                              <>
                                                                {" "}
                                                                <span>,</span>
                                                                <span>
                                                                  {res?.name}
                                                                </span>
                                                                {/* <span>{"Hello"}</span> */}
                                                              </>
                                                            ) : res?.emailAddress !==
                                                                null &&
                                                              res?.emailAddress !==
                                                                undefined &&
                                                              res?.emailAddress !==
                                                                "" ? (
                                                              <>
                                                                {" "}
                                                                <span>,</span>
                                                                <span>
                                                                  {
                                                                    res?.emailAddress
                                                                  }
                                                                </span>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </span>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex-row">
                                          <p className="text">
                                            {" "}
                                            {messages?.subject !== undefined &&
                                            messages?.subject !== null &&
                                            messages?.subject.trim() !== ""
                                              ? messages?.subject
                                              : "(no subject)"}
                                          </p>
                                        </div>
                                        <div className="flex-row">
                                          <p className="text l-height">
                                            <React.Fragment>
                                              {" "}
                                              {ReactHtmlParser(messages?.body)}
                                            </React.Fragment>
                                          </p>
                                        </div>
                                        {/* <div className="flex-column">
                                  <a className="link" href="#">
                                    <svg
                                      width="54"
                                      height="21"
                                      viewBox="0 0 54 21"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        y="0.0131378"
                                        width="53.126"
                                        height="20.3201"
                                        rx="10.1601"
                                        fill="#F2F2F2"
                                      />
                                      <circle
                                        cx="15.0815"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="15.0815"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="15.0815"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="15.0815"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="26.7221"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="26.7221"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="26.7221"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="26.7221"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="38.3588"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="38.3588"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="38.3588"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                      <circle
                                        cx="38.3588"
                                        cy="10.1721"
                                        r="3.42914"
                                        fill="#B4B4B4"
                                      />
                                    </svg>
                                  </a>
                                  <div className="sub-item">
                                    <p className="text ">
                                      On Sat, 6 Mar 2021 at 09:03, Zaheer Thaha
                                      &lt;zaheer.thaha@quinoid.com&gt; wrote:
                                    </p>
                                    <div className="mail-content">
                                      <p className="text l-height">
                                        Hello Adarsh <br />
                                        Dylan had emailed asking for the
                                        prototype integration, will you be
                                        respon <br />
                                        the navigation from myConnect to myClub
                                        - Alex might have to set it up. <br />-
                                        ZT
                                      </p>
                                      <p className="text l-height">
                                        On Fri, 5 Mar, 2021, 8:43 pm Alin
                                        George,
                                        &lt;alin.george@olivegroup.io&gt; wrote:{" "}
                                        <br />
                                        Hi Arjun,
                                      </p>
                                      <p className="text l-height">
                                        Good Job! Looks Great. <br />
                                        Best,
                                      </p>
                                      <p className="sub-text">Rajagopal</p>
                                      <p className="sub-text">
                                        Director of Business Acceleration &#38;
                                        UX
                                      </p>
                                      <p className="sub-text">
                                        <span>Skype</span> alingeorge.
                                      </p>
                                      <p className="sub-text">
                                        <span>Mobile</span> 91 9446510646
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                                        {messages.attachment && (
                                          <>
                                            {messages.attachment.length !==
                                              0 && (
                                              <div className="flex-column">
                                                <p className="text bold">
                                                  Attachments
                                                </p>
                                                {messages.attachment.map(
                                                  (attachment) => (
                                                    <div
                                                      className="attachment-list"
                                                      onClick={(e) =>
                                                        this.downloadAttachment(
                                                          e,
                                                          attachment
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        className={`${classes.groupActive} list-item`}
                                                      >
                                                        <CustomIcons
                                                          iconName="download"
                                                          iconClass="icon"
                                                        />
                                                        <p className="link">
                                                          {attachment.name}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      {/* <div className="item_footer">
                              <div className="footer_btn">
                                <button className={`${classes.secondaryBtn} btn btn-secondary  `}
                                >
                                  <span className="icon">
                                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.36084 1.59894L0.825903 5.63394L5.36084 9.66894M1.42362 5.63394H10.1719C11.8287 5.63394 13.1719 6.97709 13.1719 8.63394V11.5336" stroke="#222222" stroke-width="1.11422" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </span>
                                  Reply
                                </button>
                              </div> 
                              <div className="footer_btn">
                                <button className={`${classes.secondaryBtn} btn btn-secondary  `}
                                >
                                  <span className="icon">
                                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.36084 1.59894L0.825903 5.63394L5.36084 9.66894M4.41188 5.63394H9.94681C11.6037 5.63394 13.1719 6.97709 13.1719 8.63394V11.5336M4.41188 5.63394L8.94681 1.59894M4.41188 5.63394L8.94681 9.66894" stroke="#222222" stroke-width="1.11422" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </span>
                                  Reply all
                                </button>
                              </div> 
                              <div className="footer_btn">
                                <button className={`${classes.secondaryBtn} btn btn-secondary  `}
                                >
                                  <span className="icon">
                                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.63916 1.59894L13.1741 5.63394L8.63916 9.66894M12.5764 5.63394H3.82812C2.17127 5.63394 0.828125 6.97709 0.828125 8.63394V11.5336" stroke="#222222" stroke-width="1.11422" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </span>
                                  Forward
                                </button>
                              </div>          
                            </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="item "
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) =>
                                          this.selectMessage(messages)
                                        }
                                      >
                                        <div className="flex-row">
                                          <p className="name">
                                            {" "}
                                            {this.getSenderDisplayName(
                                              messages?.sender
                                            )}
                                          </p>
                                          <div className="right-container">
                                            {messages.attachment && (
                                              <>
                                                {messages?.attachment
                                                  ?.length !== 0 && (
                                                  <a className="link" href="#">
                                                    <svg
                                                      width="17"
                                                      height="17"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M14.7167 7.9752L8.59004 14.1019C7.83947 14.8524 6.82149 15.2741 5.76004 15.2741C4.69858 15.2741 3.6806 14.8524 2.93004 14.1019C2.17947 13.3513 1.75781 12.3333 1.75781 11.2719C1.75781 10.2104 2.17947 9.19243 2.93004 8.44187L9.0567 2.3152C9.55708 1.81483 10.2357 1.53372 10.9434 1.53372C11.651 1.53372 12.3297 1.81483 12.83 2.3152C13.3304 2.81558 13.6115 3.49423 13.6115 4.20187C13.6115 4.90951 13.3304 5.58816 12.83 6.08854L6.6967 12.2152C6.44652 12.4654 6.10719 12.6059 5.75337 12.6059C5.39955 12.6059 5.06022 12.4654 4.81004 12.2152C4.55985 11.965 4.4193 11.6257 4.4193 11.2719C4.4193 10.9181 4.55985 10.5787 4.81004 10.3285L10.47 4.6752"
                                                        stroke="#222222"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </a>
                                                )}
                                              </>
                                            )}
                                            <p className="date">
                                              {" "}
                                              {moment(
                                                messages?.receivedDateTime
                                              ).format("DD/MM/YYYY, HH:mm A")}
                                            </p>

                                            {/* <a className="link" href="#">
                                    <svg
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.9987 9.27523C8.36689 9.27523 8.66536 8.97675 8.66536 8.60856C8.66536 8.24037 8.36689 7.94189 7.9987 7.94189C7.63051 7.94189 7.33203 8.24037 7.33203 8.60856C7.33203 8.97675 7.63051 9.27523 7.9987 9.27523Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.9987 4.60854C8.36689 4.60854 8.66536 4.31006 8.66536 3.94187C8.66536 3.57368 8.36689 3.27521 7.9987 3.27521C7.63051 3.27521 7.33203 3.57368 7.33203 3.94187C7.33203 4.31006 7.63051 4.60854 7.9987 4.60854Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.9987 13.9419C8.36689 13.9419 8.66536 13.6434 8.66536 13.2752C8.66536 12.907 8.36689 12.6086 7.9987 12.6086C7.63051 12.6086 7.33203 12.907 7.33203 13.2752C7.33203 13.6434 7.63051 13.9419 7.9987 13.9419Z"
                                        stroke="#222222"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </a> */}
                                          </div>
                                        </div>
                                        <div className="flex-row">
                                          <p className="email">
                                            {this.getSenderEmail(
                                              messages?.sender
                                            )}
                                          </p>
                                          {/* <div className="tags">
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      Lead
                                    </span>
                                    <span
                                      className={`${classes.multiselectStyle} tag`}
                                    >
                                      Contact
                                    </span>
                                  </div> */}
                                        </div>
                                        <div className="flex-row">
                                          <p className="text">
                                            {" "}
                                            {messages?.subject !== undefined &&
                                            messages?.subject !== null &&
                                            messages?.subject.trim() !== ""
                                              ? messages?.subject
                                              : "(no subject)"}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          ) : (
                            <div className="list-block list-block_stretch">
                              <div className="full-width">
                                <img src="/assets/images/illustrations/no-email.png" />
                                <p className="text">
                                  Select a conversation to view
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default EmailDetailsDrawer;
