import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import React, { useReducer } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router";
import "./App.css";
import { refreshToken } from "./config";
import Routes from "./routes";
import Unexpectederror from "./route_components/unexpectederror";
import style from "./theme_style/theme.style";
import "./theme_style/theme_style.css";
import { loginReducer } from "./utils/reducers";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return <Unexpectederror error={error} reset={resetErrorBoundary} />;
};
export const Logout = () => {
  refreshToken(null);
  localStorage.clear();
  localStorage.removeItem("user_type");
  localStorage.removeItem("crm_token");
  localStorage.removeItem("crm_theme");
  window.open("/", "_self");
};
function App(props) {
  const handleReset = () => {
    History.push("/");
  };

  const history = useHistory();

  let token = localStorage.getItem("crm_token");

  if (!token) {
    refreshToken(null);
    localStorage.clear();
    localStorage.removeItem("user_type");
    localStorage.removeItem("crm_token");
    localStorage.removeItem("crm_theme");
    localStorage.removeItem("my_profile");
  }
  let intial = {
    isAuthenticated:
      token && token !== undefined && token !== null ? true : false,
  };

  const [state, dispatch] = useReducer(loginReducer, intial);

  const Logout = () => {
    refreshToken(null);
    localStorage.removeItem("crm_token");
    localStorage.clear();
    localStorage.removeItem("user_type");

    localStorage.removeItem("crm_theme");
    sessionStorage.clear();

    dispatch({ type: "LOGOUT" });
    history.push("/");
  };
  window.addEventListener("storage", (e) => {
    if (e.key === "crm_token" && e.oldValue && !e.newValue) {
      dispatch({ type: "LOGOUT" });
      history.push("/");
    }
  });

  const IsLogged = () => {
    refreshToken(localStorage.getItem("crm_token"));
    dispatch({ type: "LOGIN" });
  };

  refreshToken(localStorage.getItem("crm_token"));
  let user_type = localStorage.getItem("user_type");
  const { classes } = props;

  if (!navigator.onLine) {
    return (
      <Alert severity="warning">
        Your device is offline, please check the network connection
      </Alert>
    );
  }

  return (
    <div className="App">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => handleReset}
      >
        <Routes
          classes={classes}
          login={IsLogged}
          user_type={user_type}
          logout={Logout}
          isAuthenticated={state.isAuthenticated}
        />
      </ErrorBoundary>
    </div>
  );
}
export default withStyles(style)(App);
