import React, { Component } from "react";
import DataTable from "../../libraries/tablecomp/datatable";
import { FormatError } from "../../utils/formValidate";
import ActionDialog from "../actiondialogs/actiondialog.component";
import CommonActionMenu from "../commonActionMenu";
import CustomIcons from "../commonIcons";
import SnackComp from "../snackbar/snack.component";
import { delete_submodule, getDynTable } from "./dynapi.functions";
let list_id = "";

export default class DynTableComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      columns: [],
      data: [],
      fitWidth: 2,
      snackData: {},
      master_key: "",
      isActive: false,
      openAction: false,
      disableConfirm: false,
      actionData: {
        title: `Remove ${this.props.tableData.name}`,
        message: "Are you sure you want to remove?",
        primary_action: "Yes",
        secondary_action: "No",
      },

      mainId: "",
      total: 0,
      per_page: 0,
      anchorEl: null,
      actionButton: false,
      formSearch: {},
      showTable: false,
    };
    if (this.props.leadData) {
      list_id = this.props.leadData.id;
    } else {
      list_id = this.props.match.params.id;
    }
  }
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  componentWillReceiveProps = () => {
    this.setState({
      actionData: {
        title: `Remove ${this.props.tableData.name}`,
        message: "Are you sure you want to remove?",
        primary_action: "Yes",
        secondary_action: "No",
      },
    });
  };

  componentDidMount = () => {
    if (this.props.tableData) this.getData(this.props.tableData.api_url);
  };

  handleData(data) {
    if (this.props.handleData) {
      this.props.handleData(data);
    }
  }
  getData = (id) => {
    this.setState({ isActive: true });
    getDynTable(id)
      .then((res) => res)
      .then((res) => {
        this.setState(
          {
            totalCount: res.data?.total ?? res.data?.length,
            data: res.data?.data ?? res.data,
            total: res.data?.total,
            per_page: res.data?.per_page,
            columns: res?.columns,
            isActive: false,
            master_key: res.model_key,
            showTable: true,
          },
          () => {
            this.handleData(this.state.data);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            isActive: false,
            snackData: {
              open: true,
              message:
                error?.response?.data?.message ??
                "Failed to perform the operation",
              variant: "error",
            },
          },
          () => {
            this.handleData([]);
          }
        );
      });
  };

  handleDeleteOpen = () => {
    this.setState({
      openAction: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      actionButton: false,
    });
  };

  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };

  handleManageActions = (event, rowData) => {
    this.setState({
      mainId: rowData.id,
      actionButton: true,
      anchorEl: event.currentTarget,
    });
  };

  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleSelectedMenu = (item) => {
    this.handleDeleteOpen();

    this.handleMenuClose();
  };

  handleDelete = () => {
    let form = {
      parent_entity: this.props.master_key,
      related_entity: this.props.tableData?.relation_model,
      parent_id: list_id,
      related_ids:
        this.state.mainId instanceof Array
          ? this.state.mainId
          : [this.state.mainId],
      relation_name: this.props.tableData.relation_name,
    };
    //
    delete_submodule(form)
      .then((res) => res)
      .then((res) => {
        this.handleActionClose();
        this.setState(
          {
            disableConfirm: false,
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
          },
          () => {
            this.getData(this.props.tableData.api_url);
            this.props.handleReload(list_id);
          }
        );
      })
      .catch((error) => {
        this.handleActionClose();
        var errorString = FormatError(error);

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleLink = (data) => {
    //
    //
    // let user_type = localStorage.getItem("user_type");
    // const url =
    //   this.props.tableData.url_slug && this.props.tableData.url_slug.length > 0
    //     ? `${this.props.tableData.url_slug}/${data.id}/1`
    //     : user_type !== "user"
    //     ? `${this.props.tableData.admin_url_slug}/${data.id}`
    //     : null;
    // // if (url) {
    // //.history.push(url);
    // // }
    // window.open(url);
    // return url;
    //
  };

  handleLinkCallback = (data) => {
    // let user_type = localStorage.getItem("user_type");
    //
    // const url =
    //   this.props.tableData.url_slug && this.props.tableData.url_slug.length > 0
    //     ? `${this.props.tableData.url_slug}/${data.id}/1`
    //     : user_type !== "user"
    //     ? `${this.props.tableData.admin_url_slug}/${data.id}`
    //     : null;
    //
    // this.props.history.push(url);
    // if (this.props.history.location.pathname != this.props.location.pathname) {
    // window.open(url, "_self");
    // }
  };

  handlePageData = () => {
    if (
      (this.state.per_page !== 0 &&
        this.state.total !== 0 &&
        this.state.per_page < this.state.total) ||
      this.state.total >= 7
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleFitWidth = (data) => {
    this.setState({ fitWidth: data });
  };
  render() {
    const { columns, data, showTable, actionButton, anchorEl } = this.state;
    const user_type = localStorage.getItem("user_type");
    const urlLink =
      this.props.tableData.url_slug && this.props.tableData.url_slug.length > 0
        ? `${this.props.tableData.url_slug}`
        : user_type.toLowerCase() !== "user"
          ? `${this.props.tableData.admin_url_slug}`
          : null;

    let fixedColumns;
    if( this.props.tableData.name == "User Queries" ){
      fixedColumns = "";
    } else{
      fixedColumns = 1;
    }

    return (
      <>
        {showTable ? (
          <div className="card card-table tab-table-container">
            <div className="card-body ">
              <DataTable
                {...this.props}
                columns={columns}
                emptyContent={{
                  emptyImage: "",
                  emptyMessage: "",
                }}
                emptyBlock={true}
                dyn_columns={true}
                tablelinkAction={
                  urlLink
                    ? {
                      link: "",
                      // action: this.handleLink,
                      id: "id",
                      name: "first_name",
                      pathname: urlLink,
                      // this.props.tableData.url_slug &&
                      //   this.props.tableData.url_slug.length > 0
                      //   ? `${this.props.tableData.url_slug}`
                      //   : user_type !== "user"
                      //     ? `${this.props.tableData.admin_url_slug}`
                      //     : null,
                      // this.props.tableData.url_slug &&
                      //   this.props.tableData.url_slug.length > 0
                      //   ? `${this.props.tableData.url_slug}`
                      //   : null,
                      params1:
                        this.props.tableData.url_slug &&
                          this.props.tableData.url_slug.length > 0
                          ? "1"
                          : "",
                      handleLinkCallback: this.handleLinkCallback,
                    }
                    : null
                }
                data={data}
                options={{
                  tableLayout: "fixed",
                  search: false,
                  dynamicMenu: false,
                  toolbar: false,
                  paging: false,
                  fixedColumns: {
                    left: "",
                    right: fixedColumns,
                  },
                  sorting: false,
                  selection: false,
                  columnResizable: true,
                  currentPage: this.state.formSearch.page,
                  pageSize: this.state.formSearch.per_page,
                  totalCount: this.state.totalCount,
                }}
                localization={{
                  header: {
                    actions: (
                      <CustomIcons
                        iconName="settings"
                        iconClass="table-action-icon-header"
                      />
                    ),
                  },
                }}
                actions={[
                  {
                    icon: (props) => (
                      <CustomIcons
                        iconName="menu"
                        iconClass="table-action-icons"
                      />
                    ),
                    tooltip: "Actions",
                    position: "row",
                    onClick: (event, rowData) => {
                      this.handleManageActions(event, rowData);
                    },
                  },
                ]}
                searchValue={this.state.formSearch.search}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <ActionDialog
          {...this.props}
          open={this.state.openAction}
          data={this.state.actionData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        <CommonActionMenu
        {...this.props}
        key={this.state.mainId}
        anchorEl={anchorEl}
        open={actionButton}
        menudata={[
          {
            id: 4,
            name: "Remove",
          },
        ]}
        showMenu={[4]}
        handleMenuClose={this.handleMenuClose}
        handleMenu={this.handleSelectedMenu}
        />       
      </>
    );
  }
}
