import axios from "axios";

export const compareDictionaries = (d1, d2) => {
    // quick check for the same object
    if (d1 === d2)
        return true;

    // check for null
    if (d1 == null || d2 == null)
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count = 0;
    for (var key in d1) {
        // check if the key exists
        if (!(key in d2))
            return false;

        // check that the values are the same
        if (d1[key] !== d2[key])
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    var count2 = 0;
    for (key in d2)
        count2++;

    // return if they're the same size
    return (count === count2);
}

export const fetchFile = (item) => {
    let headers = new Headers();

    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');

    // headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
    // headers.append('Access-Control-Allow-Credentials', 'true');

    headers.append('GET', 'OPTIONS');
    // console.log("file path", item.file_path);
    // window.URL = window.URL || window.webkitURL;

    // var xhr = new XMLHttpRequest(),
    //   a = document.createElement('a'), file;

    // xhr.open('GET', item.file_path, true);
    // xhr.responseType = 'blob';
    // xhr.onload = function () {
    //   file = new Blob([xhr.response], { type: 'application/octet-stream' });
    //   a.href = window.URL.createObjectURL(file);
    //   a.download = item.display_name;  // Set to whatever file name you want
    //   // Now just click the link you created
    //   // Note that you may have to append the a element to the body somewhere
    //   // for this to work in Firefox
    //   a.click();
    // };
    // xhr.send();
    axios({
        url: item.file_path,
        method: "GET",
        headers: headers,
        responseType: "blob" // important
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${item.display_name}.${item.extension}`
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}


export const fetchFiles = (item, file) => {
    let headers = new Headers();
    headers.append('GET', 'OPTIONS');
   
    axios({
        url: file,
        method: "GET",
        headers: headers,
        responseType: "blob" // important
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${item.original_file}`
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}

export const truncateField = (str, n = 50) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

