import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import parse from "html-react-parser";
import React, { Component } from "react";

class ActionDialog extends Component {
  handleConfirm = () => {
    this.props.handleConfirm(this.props.data);
  };
  handleClose = () => {
    this.props.handleClose(this.props.data);
  };
  render() {
    const { open, classes } = this.props;

    return (
      <div>
        <Dialog
          className="reset-modal removeuser"
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle>{this.props.data.title} </DialogTitle>
          <DialogContent>
            <span>{parse("<p>" + this.props.data.message + "</p>")}</span>
          </DialogContent>
          <DialogActions>
            <button
              className={`${classes.secondaryBtn} btn btn-secondary`}
              type="button"
              onClick={(e) => this.handleClose(e)}
            >
              {this.props.data.secondary_action}
            </button>
            <button
              className={`${classes.primaryBtn} btn btn-primary`}
              type="button"
              disabled={this.props.disableConfirm ?? false}
              onClick={(e) => this.handleConfirm(e)}
            >
              {this.props.data.primary_action}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ActionDialog;
