import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../utils/formValidate";
import { getMessageForKey } from "../../../utils/messages";
import ActionDialog from "../../actiondialogs/actiondialog.component";
import CustomIcons from "../../commonIcons";
import BlockingLoader from "../../loader/blockingloader";
import SnackComp from "../../snackbar/snack.component";
import { delete_Activity, get_Activity } from "../common.apis";
import "../drawer_style.css";
import ActivityDetailDrawer from "./components/activity_details";
import CreateModal from "./components/create_model";

let refreshkey = 0;
let createkey = 0;
let detailKey = 0;
class ActivityListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityData: [],
      open: false,
      isActive: true,
      activityId: "",
      detailId: "",
      edit: false,
      disableConfirm: false,
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        activityType: props.data.slug,
        page: 1,
        per_page: 15,
        // location: props.data.location ?? "",
      },
      heading:"Current",
      onload: false,
      Sroll_Top: 1,
      lastPage: "",
      currentPage: "",
      confirmData: {},
      slug: this.props.data.slug ?? "",
      // actionData: {
      //   title:
      //     props.data.slug == "meeting"
      //       ? "Delete  Meeting ?"
      //       : "Delete Task ?",
      //   message: "Are you sure to delete?",
      //   primary_action: "Yes",
      //   secondary_action: "No",
      // },

      openAction: false,
      snackData: { open: false, message: "", variant: "" },
    };
    this.listInnerRef = React.createRef();
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        activityType: props.data.slug,

        // location: props.data.location ?? "",
      },
    });
  };
  componentDidMount = () => {
    if (this.props.opentasks) this.getActivicty();
  };
  getActivicty = () => {
    this.setState({ isActive: true });
    get_Activity(this.state.formSearch)
      .then((res) => {
        this.setState({
          activityData: res.data?.data,
          isActive: false,
          lastPage: res?.data?.last_page,
          currentPage: res?.data?.current_page,
        });
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  getpagedactivity = () => {
    const { activityData } = this.state;
    get_Activity(this.state.formSearch)
      .then((res) => {
        let glbData = [...res.data?.data];
        glbData?.forEach((item) => {
          activityData?.forEach((elm, ind) => {
            if (item.id === elm.id) {
              delete activityData[ind];
            }
          });
          activityData.push(item);
        });

        this.setState(
          {
            activityData,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            onload: false,
          },
          () => {
            if (this.state.Sroll_Top > 1) {
              this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };
  handleDelete = () => {
    this.setState({ isActive: true });
    delete_Activity(this.state.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getActivicty();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleTaskDetails = (item) => {
    this.setState({ opentaskdetails: true, activityId: item.id }, () => {
      detailKey = detailKey + 1;
    });
  };
  filterClose = (id) => {
    if (id) {
      this.getActivicty();
    }
    this.setState({ opentaskdetails: false });
  };
  handleClickOpen = () => {
    this.setState(
      {
        edit: false,
        open: true,
      },
      () => {
        createkey = createkey + 1;
      }
    );
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };
  handleDeleteAction = (e, item) => {
    let msg_header = "delete_" + this.state.slug + "_header";
    let msgBody = "delete_" + this.state.slug + "_msg";
    this.setState({
      detailId: item.id,
      confirmData: {
        title: getMessageForKey(msg_header) ?? "",
        id: 1,
        message: getMessageForKey(msgBody, item.name) ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
      openAction: true,
    });
  };
  handleClose = (id) => {
    if (id && !this.state.edit) {
      this.setState({ formSearch: { ...this.state.formSearch, page: 1 } });
      this.getActivicty();
    } else if (id && this.state.edit) {
      this.getpagedactivity();
    }
    this.setState({ open: false });
  };
  formatDate = (date) => {
    const formattedDate = date
      ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY | hh:mm A")
      : "--";
    return formattedDate;
  };

  handleSearch = (page, scrolTop) => {
    this.setState(
      {
        onload: true,
        formSearch: {
          ...this.state.formSearch,
          page: page,
          // per_page: this.state.formSearch.per_page + 10,
        },
        Sroll_Top: scrolTop,
      },
      () => {
        this.getpagedNote();
      }
    );
  };

  handleSelect = (taskoptions) => {

    let heading;
    if(taskoptions == 'past'){
      heading = "Past";
    }
    else{
      heading = "Current";
    }
    this.setState({ isActive: true });
    this.setState({  
        formSearch: {...this.state.formSearch, viewType:taskoptions},
        heading:heading,
  },
  () => {
    this.getActivicty();
  }
  );
  };

  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;
      const { currentPage, lastPage } = this.state;
      this.listInnerRef.current.scrollTop = scrollTop;
      if (!this.state.onload) {
        if (lastPage > currentPage) {
          if (Math.trunc(scrollTop) + clientHeight === scrollHeight) {
            setTimeout(() => {
              this.handleSearch(currentPage + 1, scrollHeight);
            }, 1000);
          }
        }
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { activityData, formSearch, isActive,heading } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opentasks}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
        >
          <div className="activity-popup">
            <div className="activity-head">
              <div className="dropdown">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {formSearch.activityType == "meeting" ? `${heading} Meetings` : `${heading} Tasks`}
                  <CustomIcons iconName="expand" iconClass="icon" />
                </a>

                <div className="dropdown-menu">
                <a className="dropdown-item" href="#" onClick={(e) => this.handleSelect('current')} >
                    Current {formSearch.activityType == "meeting" ? "Meetings" : "Tasks"} 
                  </a>
                  <a className="dropdown-item" href="#" onClick={(e) => this.handleSelect('past')} >
                    Past {formSearch.activityType == "meeting" ? "Meetings" : "Tasks"} 
                  </a>
                </div>
              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                <div className="item flex-btn">
                  <button
                    onClick={this.handleClickOpen}
                    className={`${this.props.classes.primaryBtn} btn btn-primary  btnsml`}
                  >
                    Create
                  </button>
                </div>
                {this.state.open && (
                  <CreateModal
                    key={createkey}
                    edit={this.state.edit}
                    detailId={this.state.detailId}
                    open={this.state.open}
                    handleClose={this.handleClose}
                    {...this.props}
                    maindata={this.props.data}
                  />
                )}
              </div>
            </div>
            {isActive ? (
              <>
                <div className="activity-list">
                  {[1, 2, 3].map((item) => (
                    <div className="item">
                      <div className="skeltron-head">
                        {/* <span className={`${classes.tagactiveColor} tag`}>
                          <Skeleton   variant="text" />
                        </span> */}

                        <Skeleton variant="text" animation="wave" />

                        {/* <Skeleton   variant="text" /> */}
                      </div>
                      <div className="details"></div>
                      <div className="skeltron-body">
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {activityData.length ? (
                  <div
                    className="activity-list"
                    ref={this.listInnerRef}
                    onScroll={(e) => this.handleScroll(e)}
                  >
                    {activityData?.map((item) => (
                      <div className="item">
                        <div className="tag-line tag-flex">
                          <span className={`${classes.tagactiveColor} tag`}>
                            {item.activity_status?.name}
                          </span>
                          <span className={`${classes.linkColor} att-item`}>
                            {item?.activity_priority?.name}
                          </span>
                          <div className="dropdown">
                            <a
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <CustomIcons
                                iconName="tabledropdown"
                                iconClass="icon"
                              />
                            </a>

                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={(e) => this.handleEdit(e, item)}
                                href="#"
                              >
                                Edit
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={(e) =>
                                  this.handleDeleteAction(e, item)
                                }
                                href="#"
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="details">
                          <a
                            className="link"
                            href="#"
                            onClick={(e) => this.handleTaskDetails(item)}
                          >
                            {item.name}
                          </a>

                          <div className="attributes attribute-column">
                            <div className="attribute-list">
                              <span className="date">
                                <span className="icon">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.6667 2.66797H3.33333C2.59695 2.66797 2 3.26492 2 4.0013V13.3346C2 14.071 2.59695 14.668 3.33333 14.668H12.6667C13.403 14.668 14 14.071 14 13.3346V4.0013C14 3.26492 13.403 2.66797 12.6667 2.66797Z"
                                      stroke="#0077BE"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M10.666 1.33203V3.9987"
                                      stroke="#0077BE"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.33398 1.33203V3.9987"
                                      stroke="#0077BE"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M2 6.66797H14"
                                      stroke="#0077BE"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                {this.formatDate(item.start_date_time)}
                              </span>
                            </div>
                            <div className="attribute-list">
                              <span className="sub">Category</span>
                              <span className="dot">:</span>
                              <span className="text">
                                {item?.activity_category?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Grid
                      container
                      style={{
                        position: "relative",
                      }}
                    >
                      <Grid
                        align="center"
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        <BlockingLoader
                          isActive={this.state.onload}
                        ></BlockingLoader>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div className="activity-list popup-stretch-block">
                    <div className="empty-content">
                      {formSearch.activityType == "meeting" ? (
                        <div className="img-box">
                          <img src="/assets/images/illustrations/no-meeting.png" />
                        </div>
                      ) : (
                        <div className="img-box">
                          <img src="/assets/images/illustrations/no-tasks.png" />
                        </div>
                      )}
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        <ActivityDetailDrawer
          {...this.props}
          key={detailKey}
          maindata={this.props.data}
          detailId={this.state.activityId}
          classesStyle={this.props.classesStyle}
          opentaskdetails={this.state.opentaskdetails}
          filterClose={this.filterClose}
          activityType={formSearch.activityType}
        />
      </div>
    );
  }
}

export default ActivityListDrawer;
