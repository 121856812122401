import moment from "moment";
import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import CustomIcons from "./commonIcons";
class DatePickerField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleHeader: "",
      value: props.value,
      formatted: false
    };
    this.handleValidate();

  }
  componentWillReceiveProps = (props) => {

    let date_format = "DD-MM-YYYY";
    let date_time_format = "DD-MM-YYYY | hh:mm A";
    var date = props.value;
    console.log("props name", this.props.name);
    console.log("props value", props.value);
    if (date) {
      if (moment.utc(date, moment.ISO_8601, true).isValid()) {
        console.log("valid iso date", date);
        // date = moment.utc(props.value, this.props.timepicker ? date_time_format : date_format);
        date = moment.utc(date, moment.ISO_8601, true);
      }
      else {
        date = moment.utc(props.value, this.props.timepicker ? date_time_format : date_format);

      }
      if (!date.isValid()) {
        date = moment(props.value).format(this.props.timepicker ? date_time_format : date_format);
        //
      } else {
        date = date.format(this.props.timepicker ? date_time_format : date_format);

      }
      console.log("formatted date", date);
      console.log("date instanceof Date", date instanceof Date);
      // console.log("date is valid", date.isValid() ? true : false);
      console.log("date !== props.value ", date !== props.value);
      this.handleValidate();
      if ((!(date instanceof Date) && date !== props.value) || (date instanceof Date && date.isValid() && date !== props.value)) {
        console.log("")
        this.props.onChange({
          value: date,
          name: this.props.name,
          formatted: true
        });
      }


    }

    this.setState({
      value: date,
    });
    // set default value

  };

  handleDatePickerChange = (value) => {
    const { values } = this.state;
    // console.log("date value", value);
    let date = "";
    if (value !== undefined) {
      if (value instanceof DateObject)
        date = new DateObject(value).format("DD-MM-YYYY");

      if (date) {

        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ value: null, name: this.props.name });
    }
  };

  handleDateTimePickerChange = (value) => {
    const { values } = this.state;
    let date = "";
    if (value !== undefined) {
      if (value instanceof DateObject) {
        //
        date = new DateObject(value).format("DD-MM-YYYY | hh:mm A");
      }
      if (date) {
        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ value: null, name: this.props.name });
    }
  };

  handleTimePickerChange = (value) => {
    let date = "";
    this.setState({ values: value });
    if (value !== undefined) {
      // if (value instanceof DateObject)
      date = new DateObject(value);

      if (date) {
        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ value: null, name: this.props.name });
    }
  };
  handleClear = () => {
    this.props.onChange({ value: null, name: this.props.name });
  };

  handleValidate = () => {
    let date_time_format = "DD-MM-YYYY | hh:mm A";

    let dateValidationFormat = "YYYY/MM/DD";
    console.log("this.props.max_date", moment(this.props.max_date));

    const max_date = this.props.max_date && moment(this.props.max_date).isValid() ? moment(this.props.max_date).format(dateValidationFormat) : (moment(this.props.max_date, "DD-MM-YYYY HH:mm:ss").format(
      dateValidationFormat
    )
      ? moment(this.props.max_date, date_time_format).format(
        dateValidationFormat
      )
      : "");
    const min_date = this.props.min_date && moment(this.props.min_date).isValid() ? moment(this.props.min_date).format(dateValidationFormat) : (moment(this.props.min_date, "DD-MM-YYYY HH:mm:ss")
      .add(1, "minutes")
      .format(dateValidationFormat)
      ? moment(this.props.min_date, date_time_format).format(
        dateValidationFormat
      )
      : "");

    this.setState({
      min_date: min_date,
      max_date: max_date
    });

    console.log("validate date", this.state.min_date, this.state.max_date);
  };

  render() {
    const { classes, selected } = this.props;
    const { value, min_date, max_date } = this.state;

    let date_format = "DD-MM-YYYY";
    let date_time_format = "DD-MM-YYYY | hh:mm A";
    let time_format = "hh:mm A";
    var date = value;
    if (date) {
      if (moment.utc(date, moment.ISO_8601, true).isValid()) {
        // date = moment.utc(props.value, this.props.timepicker ? date_time_format : date_format);
        date = moment.utc(date, moment.ISO_8601, true);
      }
      else {
        date = moment.utc(value, this.props.timepicker ? date_time_format : date_format);

      }
      // date = moment.utc(this.props.value, this.props.timepicker ? date_time_format : date_format);

      if (!date.isValid()) {

        date = moment(this.props.value);
        //
      } else {
        date = date.format(this.props.timepicker ? date_time_format : date_format);
      }
      // console.log("render date", this.props.value, date.format(this.props.timepicker ? date_time_format : date_format), date.format(this.props.timepicker ? date_time_format : date_format) !== this.props.value)

      // set default value
      if (!this.props.value) this.props.onChange({ value: date, name: this.props.name });
    }


    const iconCalendar = {
      background: "#fff url(/assets/images/icons/calendar.svg) no-repeat scroll",
      backgroundPosition: "right 8px bottom 8px"
    };

    return (
      <div className="fieldset">
        {this.props.label ? (
          <label
            className={
              this.props.error
                ? "form-label-error control-label"
                : "control-label"
            }
          >
            {this.props.label}
            {this.props && this.props.has_tooltip ? (
              <span className="tooltip-icon" title={this.props.tooltip_text}>
                <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
        <div
          className={
            this.props.required ? "mandatory input-group" : "input-group"
          }
        >
          {this.props.timepicker ? (
            <DatePicker
              {...this.props}
              title={this.props.title ? "" : ""}
              portal
              inputClass={
                this.props.error
                  ? `${this.props.classes.fieldStyle} form-control formerror  `
                  : `${this.props.classes.fieldStyle} form-control `
              }
              className="custom-calendar "
              style={iconCalendar}
              // render={<InputIcon/>}
              format={date_time_format}
              value={date ? date : ""}
              plugins={[<TimePicker hideSeconds />]}
              editable={false}
              // title={this.props.label}
              onChange={this.handleDateTimePickerChange}
              minDate={min_date}
              maxDate={max_date}
              preventOpenOnFocus={true}
            />
          ) : this.props.disableDayPicker ? (
            <DatePicker
              {...this.props}
              title={this.props.title ? "" : ""}
              portal
              format={time_format}
              plugins={[<TimePicker hideSeconds format={time_format} />]}
              inputClass={
                this.props.error
                  ? `${this.props.classes.fieldStyle} form-control formerror `
                  : `${this.props.classes.fieldStyle} form-control `
              }
              className="custom-calendar"
              style={iconCalendar}
              // render={<InputIcon/>}

              value={this.props.value}
              preventOpenOnFocus={true}
              disableDayPicker={this.props.disableDayPicker}
              editable={false}
              // title={this.props.label}
              onChange={this.handleTimePickerChange}
              minDate={min_date}
              maxDate={max_date}
            />
          ) : (
            <DatePicker
              {...this.props}
              title={this.props.title ? "" : ""}
              portal
              inputClass={
                this.props.error
                  ? `${this.props.classes.fieldStyle} form-control formerror`
                  : `${this.props.classes.fieldStyle} form-control `
              }
              className="custom-calendar"
              style={iconCalendar}
              // render={<InputIcon/>}
              preventOpenOnFocus={true}
              format={date_format}
              value={date ? date : ""}
              // value={
              //   this.props.value
              //     ? moment(this.props.value, date_format).format(date_format)
              //     : ""
              // }
              editable={false}
              // title={this.props.label}
              onChange={this.handleDatePickerChange}
              minDate={min_date}
              maxDate={max_date}
            />
          )}
          <span className="icon">
            {this.props.value && !this.props.disabled && (
              <span
                style={{
                  position: "absolute",
                  top: 10,
                  right: 22,
                  padding: 1,
                  cursor: "pointer",

                  fontSize: 14,
                }}
                onClick={(e) => this.handleClear()}
              >
                <CustomIcons iconName="close" iconClass="icon" />
              </span>
            )}
            {/* <img src="/assets/images/icons/calendar.svg" alt="datepicker" /> */}
          </span>
          {/* <span className="icon">
            <img src="/assets/images/icons/calendar.svg" alt="datepicker" />
          </span> */}
        </div>
      </div>
    );
  }
}
export default DatePickerField;
