import { css } from "@emotion/react";
import { Box } from "@mui/system";
import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import ClipLoader from "react-spinners/ClipLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import PuffLoader from "react-spinners/PuffLoader";
import "./loader.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class BlockingLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isActive,
      color: "#4A90E2",
      loaderType: "bounce",
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.isActive,
      color: "#4A90E2",
    });
  };

  render() {
    const { open, color } = this.state;
    //
    const { loaderType } = this.props;

    switch (loaderType) {
      case "spinner":
        return (
          <div>
            {open && (
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <LinearProgress loading={open}/> */}

                {/* <PropagateLoader color={color} loading={true} css={override} size={20} /> */}
                {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}
                <ClipLoader color={color} loading={true} size={50} />
              </Box>
            )}
          </div>
        );
      case "puff":
        return (
          <div>
            {open && (
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <LinearProgress loading={open}/> */}

                {/* <PropagateLoader color={color} loading={true} css={override} size={20} /> */}
                {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}
                <PuffLoader
                  color={color}
                  loading={true}
                  css={override}
                  size={60}
                />
              </Box>
            )}
          </div>
        );
      case "bounce":
        return (
          <div>
            {open && (
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <LinearProgress loading={open}/> */}

                {/* <PropagateLoader color={color} loading={true} css={override} size={20} /> */}
                {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}
                <BounceLoader
                  color={color}
                  loading={true}
                  css={override}
                  size={60}
                />
              </Box>
            )}
          </div>
        );
      case null:
      case undefined:
        return (
          <div>
            {open && (
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <LinearProgress loading={open}/> */}

                <PropagateLoader
                  color={color}
                  loading={true}
                  css={override}
                  size={20}
                />
                {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}
              </Box>
            )}
          </div>
        );
      default:
        return (
          <div>
            {open && (
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <LinearProgress loading={open}/> */}

                <PropagateLoader
                  color={color}
                  loading={true}
                  css={override}
                  size={20}
                />
                {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}
              </Box>
            )}
          </div>
        );
    }
    // return (
    //   <div>
    //     {open && (
    //       <Box sx={{ width: "100%", height: "100%" }}>
    //         {/* <LinearProgress loading={open}/> */}

    //         <PropagateLoader color={color} loading={true} css={override} size={20} />
    //         {/* <BeatLoader color={color} loading={true} css={override} size={30} /> */}

    //       </Box>
    //     )}
    //   </div>
    // );
  }
}

export default BlockingLoader;
