import MainTheme from "./dynamic_theme/maintheme.style";
let themeFlag = false;
let DynTheme = MainTheme();
if (Object.keys(DynTheme).length !== 0) themeFlag = true;

const style = (theme) => ({
  header: {
    backgroundColor: themeFlag
      ? DynTheme.header?.background?.default.color
      : "#FFFFFF !important",
  },
  sideNav: {
    backgroundColor: themeFlag
      ? DynTheme.menu_item?.background?.default.color
      : "#1D181A !important",
  },
  footer: {
    backgroundColor: themeFlag
      ? DynTheme.footer?.background?.default.color
      : "#FFFFFF !important",
  },
  wrapper: {
    backgroundColor: themeFlag
      ? DynTheme.body?.background?.default.color
      : "#EDF0F4 !important",
  },
  tableHeader: {
    backgroundColor: themeFlag
      ? DynTheme.tableheader_cell?.background?.default.color
      : "#f0f7fb !important",
  },
  btnprimary: {
    backgroundColor: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.15) 28.65%, rgba(0, 0, 0, 0.15) 100%)",
    borderColor: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
    "&:hover": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.hover.color
        : "#0077BE !important",
    },
    "&:focus": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.hover.color
        : "#0077BE !important",
    },
    "&:disabled": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.disabled.color
        : "#0077BE !important",
    },
  },
  pattern: {
    backgroundColor: themeFlag
      ? DynTheme.login_pattern?.background?.default.color
      : "#0077BE !important",
  },
  checkbox:{},
  formControl: {
    "&$checked": {
      "&+": {
        checkbox: {
          "&:after": {
            borderColor: themeFlag
              ? `${DynTheme.primary_button?.background?.active.color}`
              : "#0077BE !important",
          },
          "&:before": {
            borderColor: themeFlag
              ? `${DynTheme.primary_button?.background?.active.color}`
              : "#0077BE !important",
          },
        },
      },
    },
  },

  activeColor: {
    backgroundColor: themeFlag
      ? DynTheme.menu_item?.background?.hover.color
      : "#292426 !important",
    "&:hover": {
      backgroundColor: themeFlag
        ? `${DynTheme.menu_item?.background?.hover.color}`
        : "#292426 !important",
    },
    "&:active": {
      backgroundColor: themeFlag
        ? `${DynTheme.menu_item?.background?.hover.color}`
        : "#292426 !important",
    },
  },
  submenuActive: {
    backgroundColor: themeFlag
      ? DynTheme.menu_item?.background?.active.color
      : "#0077BE !important",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.15) 28.65%, rgba(0, 0, 0, 0.15) 100%)",
    "&:hover": {
      backgroundColor: themeFlag
        ? `${DynTheme.menu_item?.background?.active.color}`
        : "#0077BE !important",
    },
  },
  linkColor: {
    color: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
    "&:hover": {
      color: themeFlag
        ? `${DynTheme.primary_button?.background?.active.color}`
        : "#0077BE !important",
    },
    "&:focus": {
      color: themeFlag
        ? `${DynTheme.primary_button?.background?.active.color}`
        : "#0077BE !important",
    },
  },
  border_active: {
    borderColor: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
  },
  svgActiveColor: {
    fill: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
    "&:hover": {
      fill: themeFlag
        ? `${DynTheme.primary_button?.background?.active.color}`
        : "#0077BE !important",
    },
    "&:focus": {
      fill: themeFlag
        ? `${DynTheme.primary_button?.background?.active.color}`
        : "#0077BE !important",
    },
  },
  tabButton: {
    color: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
  },
  primaryBtn: {
    backgroundColor: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.15) 28.65%, rgba(0, 0, 0, 0.15) 100%)",
    borderColor: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
    "&:hover": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.hover.color
        : "#0077BE !important",
    },
    "&:focus": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.hover.color
        : "#0077BE !important",
    },
    "&:disabled": {
      backgroundColor: themeFlag
        ? DynTheme.primary_button?.background?.disabled.color
        : "#0077BE !important",
      borderColor: themeFlag
        ? DynTheme.primary_button?.background?.disabled.color
        : "#0077BE !important",
    },
  },
  secondaryBtn: {
    backgroundColor: themeFlag
      ? `${DynTheme.secondary_button?.background?.active.color}`
      : "#ffffff !important",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%) ",
    borderColor: DynTheme.secondary_bordercolor,
    "&:hover": {
      backgroundColor: themeFlag
        ? `${DynTheme.secondary_button?.background?.hover.color}`
        : "#ffffff !important",
    },
    "&:focus": {
      backgroundColor: themeFlag
        ? `${DynTheme.secondary_button?.background?.active.color}`
        : "#ffffff !important",
    },
  },
  headerBtn: {
    background: DynTheme.headerbtn_color,
  },
  checkboxstyle: {
    color: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
    "&$checked": {
      color: themeFlag
        ? DynTheme.primary_button?.background?.active.color
        : "#0077BE !important",
    },
  },
  iconStyle: {
    backgroundColor: themeFlag
      ? DynTheme.primary_button?.background?.active.color
      : "#0077BE !important",
  },
  groupActive: {
    backgroundColor: themeFlag
      ? DynTheme.group_item?.background?.active.color
      : "#F0F7FB !important",
  },
  fieldStyle: {
    backgroundColor: themeFlag
      ? DynTheme.input_field?.background?.default.color
      : "#FFFFFF !important",
    borderColor: themeFlag
      ? `${DynTheme.input_field?.border?.default.color}`
      : "#E0E2E7 !important",
    "&:focus": {
      backgroundColor: themeFlag
        ? `${DynTheme.input_field?.background?.hover.color}`
        : "#ffffff !important",
      borderColor: themeFlag
        ? `${DynTheme.input_field?.border?.active.color}`
        : "#5EB0FB !important",
    },
    "&:disabled": {
      backgroundColor: themeFlag
        ? `${DynTheme.input_field?.background?.disabled.color}`
        : "#FAFAFA !important",
      borderColor: themeFlag
        ? `${DynTheme.input_field?.border?.disabled.color}`
        : "#EDEEF1 !important",
    },
  },
  multiselectStyle: {
    backgroundColor: themeFlag
      ? DynTheme.multiselect_item?.background?.default.color
      : "#F0F7FB  !important",
    borderColor: themeFlag
      ? `${DynTheme.multiselect_item?.border?.default.color}`
      : "#0077BE  !important",
    color: themeFlag
      ? `${DynTheme.multiselect_item?.font?.default.color}`
      : "#0077BE  !important",
  },
  tagBg: {
    backgroundColor: themeFlag
      ? DynTheme.multiselect_item?.background?.default.color
      : "#FFFFFF !important",
  },
  tertiaryBtn: {
    backgroundColor: themeFlag
      ? DynTheme.multiselect_item?.background?.default.color
      : "#FFFFFF !important",
    borderColor: themeFlag
      ? `${DynTheme.multiselect_item?.border?.default.color}`
      : "#E0E2E7 !important",
    color: themeFlag
      ? `${DynTheme.multiselect_item?.font?.default.color}`
      : "#E0E2E7 !important",
    "&:hover": {
      backgroundColor: themeFlag
        ? DynTheme.multiselect_item?.background?.default.color
        : "#FFFFFF !important",
      borderColor: themeFlag
        ? `${DynTheme.multiselect_item?.border?.default.color}`
        : "#E0E2E7 !important",
      color: themeFlag
        ? `${DynTheme.multiselect_item?.font?.default.color}`
        : "#E0E2E7 !important",
    },
    "&:focus": {
      backgroundColor: themeFlag
        ? DynTheme.multiselect_item?.background?.default.color
        : "#FFFFFF !important",
      borderColor: themeFlag
        ? `${DynTheme.multiselect_item?.border?.default.color}`
        : "#E0E2E7 !important",
      color: themeFlag
        ? `${DynTheme.multiselect_item?.font?.default.color}`
        : "#E0E2E7 !important",
    },
  },
  tagactiveColor: {
    backgroundColor: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
  },
  emailactiveColor:{
    backgroundColor: themeFlag
      ? `${DynTheme.primary_button?.background?.active.color}`
      : "#0077BE !important",
  },
  highlightColor:{
    backgroundColor: themeFlag
      ? `${DynTheme.email_unread?.background?.active.color}`
      : "#F8FAFB !important",
  },
  statusActive: {
    backgroundColor: themeFlag
      ? `${DynTheme.status_component?.background?.active.color}`
      : "#0077BE !important",
    color : themeFlag
      ? `${DynTheme.status_component?.color?.active.color}`
      : "#FFFFFF !important",
      "&:before": {
        borderLeftColor: themeFlag
        ? `${DynTheme.status_component?.border?.before.color}`
        : "#FFFFFF !important",
      },
      "&:after": {
        borderLeftColor: themeFlag
        ? `${DynTheme.status_component?.border?.after.color}`
        : "#0077BE !important",
      },
  },

  pointer: { cursor: "pointer" },
});
export default style;
