import React from "react";
import { Switch } from "react-router";
import SubModuleDetail from "./common_components/entity-detailed/components/detailedcomponent";
import LeadDetails from "./page_container/core_components/leads/components/leadDetails";
import AdminRoute from "./route_components/adminRoute";
import AsyncComponent from "./route_components/async.component";
import GuestRoute from "./route_components/GuestRoute";
import UserRoute from "./route_components/UserRoute";
const Login = AsyncComponent(() =>
  import("./page_container/core_components/auth/login")
);
const ResetPassword = AsyncComponent(() =>
  import("./page_container/core_components/auth/resetpassword")
);

const ForgotPassword = AsyncComponent(() =>
  import("./page_container/core_components/auth/forgotpassword")
);

const AdminProducts = AsyncComponent(() =>
  import("./page_container/admin_details/products/components/AdminProducts")
);
const AdminProductDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/products/components/AdminProductsDetails"
  )
);
const AdminProductViewDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/products/components/AdminProductsViewDetails"
  )
);

const Category = AsyncComponent(() =>
  import("./page_container/admin_details/product-category/product-category")
);
const AdminProductCompany = AsyncComponent(() =>
  import(
    "./page_container/admin_details/product-company/components/AdminProductCompany"
  )
);
const AdminProductCompanyDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/product-company/components/AdminProductCompanyDetails"
  )
);
const AdminProductCompanyViewDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/product-company/components/AdminProductCompanyViewDetails"
  )
);

const ImportComponent = AsyncComponent(() =>
  import("./page_container/admin_details/imports/imports")
);

const ImportSummaryDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/imports/summary/components/details/importDetails"
  )
);
const ImportSummaryListComponent = AsyncComponent(() =>
import(
  "./page_container/admin_details/imports/summary/components/details/importSummary"
)
);

const ImportSummaryTemplateDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/imports/templates/components/details/templateDetails"
  )
);

const RoleDetails = AsyncComponent(() =>
  import("./page_container/admin_details/role_details/role_details.component")
);
const UserDetails = AsyncComponent(() =>
  import("./page_container/admin_details/user_details/user_details.component")
);
const GroupDetails = AsyncComponent(() =>
  import("./page_container/admin_details/group_details/group_details.component")
);

const ClassicLayout = AsyncComponent(() =>
  import("./layout/classiclayout/layout")
);
const AdminLayout = AsyncComponent(() =>
  import("./layout/adminlayout/adminlayout")
);
const Dashboard = AsyncComponent(() =>
  import("./page_container/core_components/dashboard/dashboard")
);
const Lead = AsyncComponent(() =>
  import("./page_container/core_components/leads/components/lead")
);
const Query = AsyncComponent(() =>
  import("./page_container/core_components/queries/components/query")
);
const QueryDetails = AsyncComponent(() =>
  import("./page_container/core_components/queries/components/queryDetails")
);
const LeadViewDetails = AsyncComponent(() =>
  import("./page_container/core_components/leads/components/leadViewDetails")
);
const Account = AsyncComponent(() =>
  import("./page_container/core_components/accounts/components/Account")
);
const AccountDetails = AsyncComponent(() =>
  import("./page_container/core_components/accounts/components/accountDetails")
);
const AccountViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/accounts/components/accountViewDetails"
  )
);
const Opportunity = AsyncComponent(() =>
  import(
    "./page_container/core_components/opportunities/components/Opportunity"
  )
);
const OpportunityDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/opportunities/components/opportunityDetails"
  )
);
const OpportunityViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/opportunities/components/opportunityViewDetails"
  )
);
const Contact = AsyncComponent(() =>
  import("./page_container/core_components/contacts/components/Contact")
);
const ContactDetail = AsyncComponent(() =>
  import("./page_container/core_components/contacts/components/contactDetails")
);
const ContactViewDetail = AsyncComponent(() =>
  import(
    "./page_container/core_components/contacts/components/contactViewDetails"
  )
);

const Product = AsyncComponent(() =>
  import("./page_container/core_components/products/components/Product")
);
const ProductUserDetails = AsyncComponent(() =>
  import("./page_container/core_components/products/components/productDetails")
);
const ProductUserViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/products/components/productViewDetails"
  )
);

const Call = AsyncComponent(() =>
  import(
    "./page_container/core_components/calls/components/Call"
  )
);
const CallDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/calls/components/callDetails"
  )
);
const CallViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/calls/components/callViewDetails"
  )
);
const Reports = AsyncComponent(() =>
  import("./page_container/core_components/reports/components/Report")
);
const ReportEntity = AsyncComponent(() =>
  import("./page_container/core_components/reports/components/ReportEntity")
);

const Qualification = AsyncComponent(() =>
  import(
    "./page_container/core_components/qualifications/components/Qualification"
  )
);
const QualificationDetail = AsyncComponent(() =>
  import(
    "./page_container/core_components/qualifications/components/qualificationDetails"
  )
);
const QualificationViewDetail = AsyncComponent(() =>
  import(
    "./page_container/core_components/qualifications/components/qualificationViewDetails"
  )
);

const Campaign = AsyncComponent(() =>
  import("./page_container/core_components/campaigns/campaigns.component")
);
const Subsidiary = AsyncComponent(() =>
  import("./page_container/core_components/subsidiaries/components/Subsidiary")
);
const SubsidiaryDetail = AsyncComponent(() =>
  import(
    "./page_container/core_components/subsidiaries/components/subsidiaryDetails"
  )
);
const SubsidiaryViewDetail = AsyncComponent(() =>
  import(
    "./page_container/core_components/subsidiaries/components/subsidiaryViewDetails"
  )
);
const QueryUser = AsyncComponent(() =>
  import(
    "./page_container/core_components/query_users/components/QueryUser"
  )
);
const QueryUserDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/query_users/components/queryuserDetails"
  )
);
const QueryUserViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/query_users/components/queryuserViewDetails"
  )
);

const Donation = AsyncComponent(() =>
  import(
    "./page_container/core_components/donations/components/donation"
  )
);
const DonationDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/donations/components/donationDetails"
  )
);
const DonationViewDetails = AsyncComponent(() =>
  import(
    "./page_container/core_components/donations/components/donationViewDetails"
  )
);

const AttachmentComponent = AsyncComponent(() =>
  import(
    "./page_container/core_components/attachment/components/attachment.component"
  ))
const TopSearch = AsyncComponent(() =>
  import("./page_container/core_components/top_search/topsearch.component")
);
const UserEmail = AsyncComponent(() =>
  import("./page_container/core_components/email/email.component")
);
const Calendar = AsyncComponent(() =>
  import("./page_container/core_components/calendar/calendar.component")
);
const Profile = AsyncComponent(() =>
  import("./page_container/core_components/profile/profile.component")
);
const Notes = AsyncComponent(() =>
  import("./page_container/core_components/notes/components/Note")
);
const NoteDetail = AsyncComponent(() =>
  import("./page_container/core_components/notes/components/noteDetails")
);

const AdminDashboard = AsyncComponent(() =>
  import("./page_container/admin_details/dashboard/dashboard")
);

const UserComponent = AsyncComponent(() =>
  import("./page_container/admin_details/user_details/user.component")
);
const RoleComponent = AsyncComponent(() =>
  import("./page_container/admin_details/role_details/role.component")
);
const GroupComponent = AsyncComponent(() =>
  import("./page_container/admin_details/group_details/group.component")
);

const AdminQualificationsComponent = AsyncComponent(() =>
  import(
    "./page_container/admin_details/qualifications/components/AdminQualifications"
  )
);
const AdminQualificationDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/qualifications/components/AdminQualificationsDetails"
  )
);
const AdminQualificationViewDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/qualifications/components/AdminQualificationsViewDetails"
  )
);
const AdminSubsidiariesComponent = AsyncComponent(() =>
  import(
    "./page_container/admin_details/subsidiaries/components/AdminSubsidiaries"
  )
);
const AdminSubsidiariesDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/subsidiaries/components/AdminSubsidiariesDetails"
  )
);
const AdminSubsidiariesViewDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/subsidiaries/components/AdminSubsidiariesViewDetails"
  )
);

const GeneralSettingsComponent = AsyncComponent(() =>
  import(
    "./page_container/admin_details/general_settings/generalsettings.component"
  )
);

const AdminPicklistComponent = AsyncComponent(() =>
  import(
    "./page_container/admin_details/picklist_management/components/AdminPicklist"
  )
);
const AdminPicklistDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/picklist_management/components/AdminPicklistDetails"
  )
);
const AdminPicklistOptionsDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/picklist_management/components/AdminPicklistOptionsDetails"
  )
);
const AdminPicklistOptionsViewDetails = AsyncComponent(() =>
  import(
    "./page_container/admin_details/picklist_management/components/AdminPicklistOptionsViewDetails"
  )
);

const FormComponent = AsyncComponent(() =>
  import("./page_container/admin_details/forms/forms.component")
);
const FormDetails = AsyncComponent(() =>
  import("./page_container/admin_details/forms/form_details.component")
);
const WebAppsComponent = AsyncComponent(() =>
  import("./page_container/admin_details/web_apps/webapps.component")
);

const EmailComponent = AsyncComponent(() =>
  import("./page_container/admin_details/email/email.component")
);
const EmailDetails = AsyncComponent(() =>
  import("./page_container/admin_details/email/email_details.component")
);
const VeriFy = AsyncComponent(() => import("./common/verify"));
const Error404 = AsyncComponent(() => import("./route_components/404error"));
const Layout = (props) => <ClassicLayout>{props.children}</ClassicLayout>;
export default function Routes(props) {
  let activeLayout;

  activeLayout = ClassicLayout;
  let adminLayout = AdminLayout;

  return (
    <Switch>
      <GuestRoute {...props} path="/login" exact component={Login} />
      <GuestRoute
        {...props}
        path="/reset-password"
        exact
        component={ResetPassword}
      />
      <GuestRoute
        {...props}
        path="/forgot-password"
        exact
        component={ForgotPassword}
      />
      <GuestRoute {...props} path="/" exact component={Login} />
      <UserRoute
        {...props}
        path="/common/verify/"
        component={VeriFy}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/user-dashboard"
        component={Dashboard}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/lead/list/"
        component={Lead}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/lead/:id/:id1"
        component={LeadDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/queries/list/"
        component={Query}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/query/:id/:id1"
        component={QueryDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/submodule/details"
        component={SubModuleDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/leads/view/:id/:id1"
        component={LeadViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/accounts/list/"
        component={Account}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/account/:id/:id1"
        component={AccountDetails}
        layout={activeLayout}
      />{" "}
      <UserRoute
        {...props}
        path="/accounts/view/:id/:id1"
        component={AccountViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/opportunities/list/"
        component={Opportunity}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/opportunity/:id/:id1"
        component={OpportunityDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/opportunities/view/:id/:id1"
        component={OpportunityViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/contacts/list/"
        component={Contact}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/contact/:id/:id1"
        component={ContactDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/contacts/view/:id/:id1"
        component={ContactViewDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/calls/list"
        component={Call}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/calls/:id"
        component={CallDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/calls/view/:id/:id1"
        component={CallViewDetails}
        layout={activeLayout}
      />


      <UserRoute
        {...props}
        path="/products/list/"
        component={Product}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/product/:id/:id1"
        component={ProductUserDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/products/view/:id/:id1"
        component={ProductUserViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/qualifications/list/"
        component={Qualification}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/qualification/:id/:id1"
        component={QualificationDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/qualifications/view/:id/:id1"
        component={QualificationViewDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/subsidiaries/list/"
        component={Subsidiary}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/subsidiary/:id/:id1"
        component={SubsidiaryDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/subsidiaries/view/:id/:id1"
        component={SubsidiaryViewDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/campaigns/list/"
        component={Campaign}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/top-search/:id/:id1"
        component={TopSearch}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/email/list/"
        component={UserEmail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/calendar/"
        component={Calendar}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/profile/"
        component={Profile}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/notes/list"
        component={Notes}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/notes/:id/:id1"
        component={NoteDetail}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/reports/list"
        component={Reports}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/reports/:entity"
        component={ReportEntity}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/query-user/list"
        component={QueryUser}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/query-user/:id"
        component={QueryUserDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/query-user/view/:id/:id1"
        component={QueryUserViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/donations/view/:id/:id1"
        component={DonationViewDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/donations/list"
        component={Donation}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/donations/:id"
        component={DonationDetails}
        layout={activeLayout}
      />
      <UserRoute
        {...props}
        path="/attachments/list/"
        component={AttachmentComponent}
        layout={activeLayout}
      />

      <UserRoute
        {...props}
        path="/importsummary/list/"
        component={ImportSummaryListComponent}
        layout={activeLayout}
      />

      <AdminRoute
        {...props}
        path="/admin/admin-dashboard"
        component={AdminDashboard}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/users/list/"
        component={UserComponent}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/roles/list/"
        component={RoleComponent}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/groups/list/"
        component={GroupComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/error"
        {...props}
        component={Error404}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/user-management/user-details/:id"
        {...props}
        component={UserDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/role-management/role-details/:id"
        {...props}
        component={RoleDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/group-management/group-details/:id"
        {...props}
        component={GroupDetails}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/product/list/"
        component={AdminProducts}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/product-management/:id/:id1"
        {...props}
        component={AdminProductDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/product/view/:id/:id1"
        {...props}
        component={AdminProductViewDetails}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/category/list/"
        component={Category}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/company/list/"
        component={AdminProductCompany}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/company-management/:id/:id1"
        {...props}
        component={AdminProductCompanyDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/company/view/:id/:id1"
        {...props}
        component={AdminProductCompanyViewDetails}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/imports/"
        component={ImportComponent}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/summary/import-details/:id"
        component={ImportSummaryDetails}
        layout={adminLayout}
      />
      <AdminRoute
        {...props}
        path="/admin/summary/template-details/:id"
        component={ImportSummaryTemplateDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/user-management/user-details/:id"
        {...props}
        component={UserDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/role-management/role-details/:id"
        {...props}
        component={RoleDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/group-management/group-details/:id"
        {...props}
        component={GroupDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/qualifications/list"
        {...props}
        component={AdminQualificationsComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/qualification-management/:id/:id1"
        {...props}
        component={AdminQualificationDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/qualification/view/:id/:id1"
        {...props}
        component={AdminQualificationViewDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/subsidiaries/list"
        {...props}
        component={AdminSubsidiariesComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/subsidiary-management/:id/:id1"
        {...props}
        component={AdminSubsidiariesDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/subsidiary/view/:id/:id1"
        {...props}
        component={AdminSubsidiariesViewDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/submodule/details"
        {...props}
        component={SubModuleDetail}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/general-settings"
        {...props}
        component={GeneralSettingsComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/picklist"
        {...props}
        component={AdminPicklistComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/picklist-management/:id/:entity"
        {...props}
        component={AdminPicklistDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/picklistoptions-management/:id/:entity/:mode"
        {...props}
        component={AdminPicklistOptionsDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/picklistoptions/view/:id/:entity/:mode"
        {...props}
        component={AdminPicklistOptionsViewDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/forms/list/"
        {...props}
        component={FormComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/forms/list-details/:id"
        {...props}
        component={FormDetails}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/developer/apps"
        {...props}
        component={WebAppsComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/email/list/"
        {...props}
        component={EmailComponent}
        layout={adminLayout}
      />
      <AdminRoute
        path="/admin/email/list-details/:id"
        {...props}
        component={EmailDetails}
        layout={adminLayout}
      />
      <UserRoute component={Error404} {...props} layout={activeLayout} />
      <AdminRoute component={Error404} {...props} layout={adminLayout} />
    </Switch>
  );
}
