import React from "react";
import CustomIcons from "./commonIcons";

export default function ButtonSecondary(props) {
  return (
    <div className="item">
      <button {...props}>
        {props.iconData && (
            <CustomIcons
              iconName={props.iconData.name}
              iconClass={props.iconData.className}
            />
          )}
        {props.label}
      </button>
    </div>
  );
}
