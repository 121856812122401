import { React, useRef, useState } from "react";
import CustomIcons from "./commonIcons";
export default function TextField(props) {

  const handleClear = () => {
    let data = { target: { value: "", name: props.name } };
    props.onChange(data);
  };


  // const [inputVal, setInputVal] =useState("")
  // const inputRef = useRef("32px")
  // const handleInputHeight = () => {
  // const scrollHeight = inputRef.current.scrollHeight;
  // inputRef.current.style.height = scrollHeight + "px";
  // };

  // const handleInputChange = () => {
  //   setInputVal(inputRef.current.value)
  //   handleInputHeight()
  // }

  return (
    <div className="fieldset">
      <label
        className={
          props.error ? "form-label-error control-label" : "control-label"
        }
      >
        {props.label}
        {props && props.tooltip ? (
          <span
            className="icon-padding"
            data-toggle="tooltip"
            title={props.tooltip}
          >
            <CustomIcons iconName="help" iconClass="header-action-icons" />
          </span>
        ) : (
          ""
        )}
      </label>

      <div className={props.required ? "mandatory input-group" : "input-group"}>
        {props.multiline ? (
          <textarea
            {...props}
            title={props.title ? (
                ""
              ) : (
                ""
              )}
            autoComplete="off"
            className={
              props.error
                ? `${props.classes.fieldStyle} form-control formerror textareastyle`
                : `${props.classes.fieldStyle} form-control textareastyle`
            }
          // ref={inputRef}  
          // value={inputVal}
          // onChange={handleInputChange}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     inputRef.current.style.height = "40px";
          //   }
          // }}
          />
        ) : (
          <>
            <fieldset className="input-group">
              <input
                {...props}
                title={props.title ? (
                ""
                ) : (
                  ""
                )}
                autoComplete="off"
                className={
                  props.error
                    ? `${props.classes.fieldStyle} form-control formerror`
                    : `${props.classes.fieldStyle} form-control `
                }
                value={props.value ?? ""}
              />
              {!props.hideClear && props.value && !props.disabled && (
                <button
                  onClick={(e) => handleClear()}
                  className="btn no-margin"
                  style={{
                    padding: 0,
                    position: "absolute",
                    top: 8,
                    right: 10,
                  }}
                >
                  <CustomIcons iconName="close" iconClass="icon" />
                </button>
              )}
            </fieldset>
          </>
        )}
        <span className="icon">
          <img
            src={props.endicon}
            alt={props.eniconTitle}
            onClick={props.endicononClick}
          />
        </span>
      </div>
    </div>
  );
}
