import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import SnackComp from "../../../snackbar/snack.component";
import {
  delete_Activity,
  get_Activity_Details,
  get_mark_Complete,
} from "../../common.apis";
import "../../drawer_style.css";
import CreateModal from "./create_model";
let createkey = 1;
class ActivityDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: [],
      detailId: "",
      open: false,
      isActive: true,
      confirmData: {},
      openAction: false,
      snackData: {},
      slug: props?.data?.slug ?? "",
      formSearch: {
        entity: props?.data?.entity_name ?? "",
        entity_id: props?.data?.entity_id ?? "",
        activityType: props?.data?.slug,

        // location: props.data.location ?? "",
      },
      // actionData: {
      //   title:
      //     props.maindata.slug == "meeting"
      //       ? "Delete  Meeting ?"
      //       : "Delete Task ?",
      //   message: "Are you sure to delete?",
      //   primary_action: "Yes",
      //   secondary_action: "No",
      // },
      disableConfirm: false,
    };
  }

  getDetails = () => {
    this.setState({ isActive: true });
    get_Activity_Details(this.props.detailId)
      .then((res) => {
        this.setState({ detailData: res.data, isActive: false });
      })
      .catch((err) => { });
  };

  componentDidMount = () => {
    if (this.props.opentaskdetails) this.getDetails();
  };

  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };

  handleClose = (id) => {
    if (id) {
      this.getDetails();
    }
    this.setState({ open: false });
  };

  handleDeleteAction = (e, item) => {
    let msg_header = "delete_" + this.props.activityType + "_header";
    let msgBody = "delete_" + this.props.activityType + "_msg";

    this.setState({
      detailId: item.id,
      confirmData: {
        title: getMessageForKey(msg_header) ?? "",
        id: 1,
        message:
          getMessageForKey(msgBody, this.state.detailData.name ?? "") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
      openAction: true,
    });
  };

  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };

  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleDelete = () => {
    delete_Activity(this.props.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
          openAction: false,
        });
        this.props.filterClose(true);
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  handleMark = () => {
    let data = {
      is_complete: true,
    };
    get_mark_Complete(this.state.detailData.id, data)
      .then((res) => res)
      .then((res) => {
        this.setState({
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
          openAction: false,
        });
        this.getDetails();
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  render() {
    const { classes, activityType } = this.props;
    const { detailData, isActive } = this.state;
    const attendees = detailData.attendees;
    const external_attendees = detailData.external_attendees;
    const created_at_date = detailData?.created_at
      ? moment.utc(detailData?.created_at).format("DD-MM-YYYY")
      : "--";
    const start_date_time = detailData?.start_date_time
      ? moment
        .utc(detailData?.start_date_time, "YYYY-MM-DD HH:mm:ss")
        .format("DD-MM-YYYY | hh:mm A")
      : "--";
    const end_date_time = detailData?.end_date_time
      ? moment
        .utc(detailData?.end_date_time, "YYYY-MM-DD HH:mm:ss")
        .format("DD-MM-YYYY | hh:mm A")
      : "--";
    const created_at_time = detailData?.created_at
      ? moment.utc(detailData?.created_at).format("hh:mm A")
      : "--";
    const activityTypeName = activityType == "meeting" ? "Meeting" : "Task";

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opentaskdetails}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: this.props.fromCal ? 0 : "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="activity-drawer"
        // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="activity-popup">
            <div className="activity-head">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  {!this.props.fromCal && (
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                        stroke="#0077BE"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </span>
                {this.props.fromCal ? "Close" : "Back"}
              </a>
              <div className="righ-container">
                {/* <SearchBoxSecondary /> */}
                {/* <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                {/* <CreateModal {...this.props} /> */}
              </div>
            </div>
            <div className="activity-list activity-detail">
              {isActive ? (
                <div className="item">
                  <div className="tag-line">
                    <span className={` tag`}>
                      <Skeleton variant="text" width={80} height={20} />
                    </span>
                  </div>
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        <Skeleton variant="text" width={200} />
                      </p>
                    </div>
                    {[1, 2, 3].map((item) => (
                      <div className="row-block">
                        <div className="attribute">
                          <span className="sub">
                            <Skeleton variant="text" width={70} />
                          </span>
                          <span className={` text`}>
                            <Skeleton variant="text" width={90} />
                          </span>
                        </div>
                        <div className="attribute">
                          <span className="sub">
                            <Skeleton variant="text" width={70} />
                          </span>
                          <span className={` text`}>
                            <Skeleton variant="text" width={90} />
                          </span>
                        </div>
                      </div>
                    ))}
                    {[1, 2, 3, 4, 5].map((item) => (
                      <div className="row-block">
                        <div className="attribute">
                          <span className="sub">
                            <Skeleton variant="text" width={70} />
                          </span>
                          <span className={` text`}>
                            <Skeleton variant="text" width={360} />
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">
                          {" "}
                          <Skeleton variant="text" width={200} />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="item">
                  <div className="tag-line">
                    <span className={`${classes.tagactiveColor} tag`}>
                      {detailData.activity_status?.name
                        ? detailData.activity_status?.name
                        : "--"}
                    </span>
                    <div className="dropdown">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <CustomIcons
                          iconName="tabledropdown"
                          iconClass="icon"
                        />
                      </a>

                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => this.handleEdit(e, detailData)}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) =>
                            this.handleDeleteAction(e, detailData)
                          }
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        {detailData.name ? detailData.name : "--"}&nbsp;{" "}
                      </p>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Category
                          {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                        </span>
                        <span className={`${classes.tabButton} text`}>
                          {detailData.activity_category?.name
                            ? detailData.activity_category?.name
                            : "--"}
                        </span>
                      </div>
                      {this.props.maindata.slug === "meeting" ? (
                        <div className="attribute">
                          <span className="sub">
                            {activityTypeName} Host
                            {/* <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                              >
                                <CustomIcons
                                  iconName="help"
                                  iconClass="header-action-icons"
                                />
                              </span> */}
                          </span>
                          <span className={`${classes.tabButton} text`}>
                            {detailData.activity_owner?.name}
                          </span>
                        </div>
                      ) : (
                        <div className="attribute">
                          <span className="sub">
                            {activityTypeName} Owner
                            {/* <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                              >
                                <CustomIcons
                                  iconName="help"
                                  iconClass="header-action-icons"
                                />
                              </span> */}
                          </span>
                          <span className={`${classes.tabButton} text`}>
                            {detailData.activity_owner?.name}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Priority
                          {/* <span
                              className="icon-padding"
                              data-toggle="tooltip"
                              title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                            >
                              <CustomIcons
                                iconName="help"
                                iconClass="header-action-icons"
                              />
                            </span> */}
                        </span>
                        <span className={`${classes.tabButton} text`}>
                          {detailData.activity_priority?.name
                            ? detailData.activity_priority?.name
                            : "--"}
                        </span>
                      </div>
                      <div className="attribute">
                        <span className="sub">
                          Time Zone
                          {/* <span
                                className="icon-padding"
                                data-toggle="tooltip"
                                title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                              >
                                <CustomIcons
                                  iconName="help"
                                  iconClass="header-action-icons"
                                />
                              </span> */}
                        </span>
                        <span className={`${classes.tabButton} text`}>
                          {/* {detailData?.timezone?.name ?? "--"} */}
                          {"UTC"}
                        </span>
                      </div>
                    </div>
                    {this.props.maindata.slug === "meeting" ? (
                      <>
                        <div className="row-block">
                          <div className="attribute">
                            <span className="sub">
                              Start Date & Time
                              {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                            </span>
                            <span className={`${classes.tabButton} text`}>
                              {start_date_time}
                            </span>
                          </div>
                          <div className="attribute">
                            <span className="sub">
                              End Date & Time
                              {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                            </span>
                            <span className={`${classes.tabButton} text`}>
                              {end_date_time}
                            </span>
                          </div>
                        </div>
                        <div className="row-block">
                          <div className="attribute">
                            <span className="sub">
                              {activityTypeName} Reminder
                              {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                            </span>
                            <span className={`${classes.tabButton} text`}>
                              {detailData.host_reminder?.name
                                ? detailData.host_reminder?.name
                                : "--"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row-block">
                          <div className="attribute">
                            <span className="sub">
                              Due Date & Time
                              {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                            </span>
                            <span className={`${classes.tabButton} text`}>
                              {start_date_time}
                            </span>
                          </div>
                          <div className="attribute">
                            <span className="sub">
                              {activityTypeName} Reminder
                              {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                            </span>
                            <span className={`${classes.tabButton} text`}>
                              {detailData.host_reminder?.name
                                ? detailData.host_reminder?.name
                                : "--"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="row-block">
                      <div className="attribute">
                        {this.props.maindata.slug === "meeting" ? (
                          <span className="sub">
                            Attendees
                            {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                          </span>
                        ) : (
                          <span className="sub">
                            Assignees
                            {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                          </span>
                        )}
                        <div className="tags">
                          {attendees &&
                            attendees.length > 0 &&
                            attendees.map((attendee) => (
                              <>
                                <span
                                  className={`${classes.multiselectStyle} tag`}
                                >
                                  {attendee.name}{" "}
                                </span>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        {this.props.maindata.slug === "meeting" ? (
                          <span className="sub">
                            External Attendees
                            {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                          </span>
                        ) : (
                          <span className="sub">
                            External Assignees
                            {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                          </span>
                        )}
                        <div className="tags">
                          {external_attendees &&
                            external_attendees.length > 0 &&
                            external_attendees.map((attendee) => (
                              <>
                                <span
                                  className={`${classes.multiselectStyle} tag`}
                                >
                                  {attendee.email}{" "}
                                </span>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {this.props.maindata.slug === "meeting" ? (
                      <div className="row-block">
                        <div className="attribute">
                          <span className="sub">
                            {activityTypeName} Location
                            {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                          </span>
                          <span className={`${classes.tabButton} text`}>
                            {detailData?.location &&
                              detailData?.location.length > 0
                              ? detailData?.location
                              : "--"}
                          </span>
                        </div>
                        <div className="attribute">
                          <span className="sub">
                            Online {activityTypeName} Link
                            {/* <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span> */}
                          </span>
                          <span className={`${classes.tabButton} text`}>
                            {detailData?.meeting?.meeting_link ?? "--"}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Description
                          {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                        </span>
                        <span className="text pre_wrap">
                          {detailData?.description ?? "--"}
                        </span>
                      </div>
                    </div>
                    {/* <div className="row-block">
                    <div className="attribute">
                      <span className="sub">
                        Call Record
                        <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span>
                      </span>
                      <span className="text">
                        <span className="icon">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM10.2516 8.10781L6.83906 10.5906C6.82035 10.6041 6.7983 10.6121 6.77533 10.6138C6.75236 10.6155 6.72935 10.6109 6.70884 10.6004C6.68834 10.5899 6.67112 10.574 6.65908 10.5543C6.64705 10.5347 6.64066 10.5121 6.64062 10.4891V5.52656C6.64055 5.50348 6.64687 5.48082 6.65888 5.46111C6.67089 5.4414 6.68812 5.4254 6.70867 5.41488C6.72922 5.40436 6.75228 5.39974 6.7753 5.40153C6.79831 5.40331 6.82038 5.41144 6.83906 5.425L10.2516 7.90625C10.2677 7.91765 10.2808 7.93275 10.2899 7.95029C10.299 7.96783 10.3037 7.98729 10.3037 8.00703C10.3037 8.02678 10.299 8.04624 10.2899 8.06377C10.2808 8.08131 10.2677 8.09641 10.2516 8.10781Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        Arjun365826.rar
                      </span>
                    </div>
                  </div> */}
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Status
                          {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                        </span>
                        <div className="dropdown">
                          <a
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`${classes.tabButton} text disabled`}
                          >
                            {detailData?.activity_status?.name ?? "--"}
                            {/* <span className="icon">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 11 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.37773 7.50426L0.877729 1.00426C0.825825 0.929249 0.795431 0.841466 0.789838 0.750418C0.784245 0.659371 0.803666 0.568528 0.845998 0.487726C0.88833 0.406924 0.951961 0.339243 1.03 0.29201C1.10804 0.244777 1.19751 0.219792 1.28873 0.219761L10.2887 0.219761C10.38 0.2197 10.4695 0.244618 10.5476 0.291815C10.6257 0.339011 10.6895 0.406686 10.7318 0.487504C10.7742 0.568322 10.7937 0.659202 10.7881 0.750293C10.7826 0.841384 10.7522 0.929214 10.7002 1.00426L6.20023 7.50426C6.15402 7.57048 6.09249 7.62456 6.02089 7.66191C5.94929 7.69925 5.86973 7.71875 5.78898 7.71875C5.70823 7.71875 5.62867 7.69925 5.55707 7.66191C5.48547 7.62456 5.42395 7.57048 5.37773 7.50426Z"
                                fill="#0077BE"
                              />
                            </svg>
                          </span> */}
                          </a>

                          {/* <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            Ongoing
                          </a>
                          <a className="dropdown-item" href="#">
                            Completed
                          </a>
                        </div> */}
                        </div>
                      </div>
                      {/* <ButtonSml
                      label="Mark as Complete"
                      onClick={(e) => this.handleMark(e)}
                      className={`${classes.primaryBtn} btn btn-primary`}
                    /> */}
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Outcome
                          {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                        </span>
                        <div className="dropdown">
                          <a
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`${classes.tabButton} text disabled`}
                          >
                            {detailData?.activity_outcome?.name ?? "--"}
                            {/* <span className="icon">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 11 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.37773 7.50426L0.877729 1.00426C0.825825 0.929249 0.795431 0.841466 0.789838 0.750418C0.784245 0.659371 0.803666 0.568528 0.845998 0.487726C0.88833 0.406924 0.951961 0.339243 1.03 0.29201C1.10804 0.244777 1.19751 0.219792 1.28873 0.219761L10.2887 0.219761C10.38 0.2197 10.4695 0.244618 10.5476 0.291815C10.6257 0.339011 10.6895 0.406686 10.7318 0.487504C10.7742 0.568322 10.7937 0.659202 10.7881 0.750293C10.7826 0.841384 10.7522 0.929214 10.7002 1.00426L6.20023 7.50426C6.15402 7.57048 6.09249 7.62456 6.02089 7.66191C5.94929 7.69925 5.86973 7.71875 5.78898 7.71875C5.70823 7.71875 5.62867 7.69925 5.55707 7.66191C5.48547 7.62456 5.42395 7.57048 5.37773 7.50426Z"
                                fill="#0077BE"
                              />
                            </svg>
                          </span> */}
                          </a>

                          {/* <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            All Task
                          </a>
                          <a className="dropdown-item" href="#">
                            Upcoming Tasks
                          </a>
                          <a className="dropdown-item" href="#">
                            Overdue Tasks
                          </a>
                          <a className="dropdown-item" href="#">
                            Closed Tasks
                          </a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          {activityTypeName} Outcome Remarks
                          {/* <span
                          className="icon-padding"
                          data-toggle="tooltip"
                          title="Ar aenean magna consectetur. Euismod id sem enim, sapien."
                        >
                          <CustomIcons
                            iconName="help"
                            iconClass="header-action-icons"
                          />
                        </span> */}
                        </span>
                        <span className="text">
                          {detailData?.outcome_desc ?? "--"}
                        </span>
                      </div>
                    </div>
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">Created by</span>
                        <span className="t-box">
                          {detailData.created_by?.name
                            ? detailData.created_by?.name
                            : "--"}
                        </span>
                        <span className="t-box">{created_at_date}</span>
                        <span className="t-box">{created_at_time} </span>
                        {/* <span className="t-box">04:50 PM </span> */}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>
        <ActionDialog
          {...this.props}
          // key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        {this.state.open && (
          <CreateModal
            key={createkey}
            edit={true}
            detailId={this.state.detailId}
            open={this.state.open}
            handleClose={this.handleClose}
            {...this.props}
            maindata={this.props.maindata}
          />
        )}
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default ActivityDetailDrawer;
