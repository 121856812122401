import { IconButton } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { Component, default as React } from "react";
import {
  EmailValidate,
  FormatError,
  FormValidate,
} from "../../../../utils/formValidate";
import {
  getDateTimeFormat,
  getDateTimeforTimezone,
} from "../../../../utils/timezone";
import ButtonSml from "../../../buttonsml";
import CustomIcons from "../../../commonIcons";
import DatePickerField from "../../../datepicker";
import BlockingLoader from "../../../loader/blockingloader";
import SearchSelectBox from "../../../searchSelectBox/searchSelectBox";
import SelectField from "../../../selectfield";
import SnackComp from "../../../snackbar/snack.component";
import TextareaField from "../../../textareafield";
import TextField from "../../../textfield";
import {
  create_Activity,
  get_Activity_Attendies,
  get_Activity_Category,
  get_Activity_Details,
  get_Activity_Outcome,
  get_Activity_Priority,
  get_Activity_Reminder,
  get_Activity_Status,
  get_Activity_Timezone,
  update_Activity,
} from "../../common.apis";
import "../../drawer_style.css";

let meeting = [
  "name",
  "activity_category_id",
  "activity_priority_id",
  "start_date_time",
  "activity_status_id",
  "end_date_time",
  "activity_owner",
  "timezone_id",
];
let task = [
  "name",
  "activity_category_id",
  "activity_priority_id",
  "start_date_time",
  "activity_status_id",
  "activity_owner",
  "timezone_id",
];
// const start_date = new Date(); // get current date
// const end_date = start_date.setHours(start_date.getHours(),start_date.getMinutes()+1,0,0);
//
export default class Create_model extends Component {
  constructor(props) {
    super(props);
    this.myProfile = JSON.parse(localStorage.getItem("my_profile"));
    //
    this.state = {
      formData: {
        entity_id: props.maindata.entity_id,
        entity: props.maindata.entity_name,
        activity_type_id: props.maindata.id,
        activity_type_slug: props.maindata.slug,
        start_date_time: this.getDateTime(
          new Date(),
          null,
          moment.tz.guess()
        ).format("DD-MM-YYYY | hh:mm A"),
        end_date_time: this.getDateTime(new Date(), null, moment.tz.guess())
          .add(30, "minutes")
          .format("DD-MM-YYYY | hh:mm A"),
        timeZone: moment.tz.guess(),
        external_attendees: [],
      },
      outCome: [],
      statusData: [],
      priority: [],
      reminder: [],
      Category: [],

      snackData: { open: false },
      timeZone: [],
      attendies: [],
      showLoader: false,
      canSubmit: true,
      attendee_search: "",
      error: {
        activity_category_id: false,
        activity_priority_id: false,
        activity_status_id: false,
        name: false,
        timezone_id: false,
      },
    };
  }
  componentWillReceiveProps = (props) => {
    // var formData = {
    //   // ...this.state.formData,
    //   entity_id: props.maindata.entity_id,
    //   entity: props.maindata.entity_name,
    //   activity_type_id: props.maindata.id,
    //   activity_type_slug: props.maindata.slug,
    // };
    // if (!this.props.open) {
    //   formData["activity_owner"] = this.myProfile?.id ?? "";
    //   formData["activity_owner_name"] = this.myProfile?.first_name
    //     ? this.myProfile?.last_name
    //       ? this.myProfile?.first_name + " " + this.myProfile?.last_name
    //       : this.myProfile?.first_name
    //     : "";
    // }
    this.setState({
      formData: {
        entity_id: props.maindata.entity_id,
        entity: props.maindata.entity_name,
        activity_type_id: props.maindata.id,
        activity_type_slug: props.maindata.slug,
        start_date_time: this.getDateTime(new Date()).format(
          "DD-MM-YYYY | hh:mm A"
        ),
        end_date_time: this.getDateTime(new Date())
          .add(30, "minutes")
          .format("DD-MM-YYYY | hh:mm A"),
      },
    });
  };
  componentDidMount = () => {
    if (this.props.open) {
      this.getDataDetails();
    }
  };
  handleRemoveAttendee = (removeId) => {
    let attendeeIds = this.state.formData.user_id;
    let temArray = attendeeIds.filter((item) => {
      return item != removeId;
    });
    this.setState({
      formData: {
        ...this.state.formData,
        user_id: temArray,
      },
    });
  };
  handleRemoveExternalAttendee = (removeId) => {
    let externalAttendeeIds = this.state.formData.external_attendees;
    let temArray = externalAttendeeIds.filter((item) => {
      return item != removeId;
    });
    this.setState({
      formData: {
        ...this.state.formData,
        external_attendees: temArray,
      },
    });
  };

  getOutCome = () => {
    get_Activity_Outcome(this.props.maindata.slug).then((res) => {
      this.setState({ outCome: res.data?.data });
    });
  };
  getStatus = () => {
    get_Activity_Status(this.props.maindata.slug).then((res) => {
      this.setState({ statusData: res.data?.data });
    });
  };
  getPriority = () => {
    get_Activity_Priority(this.props.maindata.slug).then((res) => {
      this.setState({ priority: res.data?.data });
    });
  };
  getReminder = () => {
    get_Activity_Reminder(this.props.maindata.slug).then((res) => {
      this.setState({ reminder: res.data?.data });
    });
  };
  getCategory = () => {
    get_Activity_Category(this.props.maindata.slug).then((res) => {
      this.setState({ Category: res.data?.data });
    });
  };
  getTimezone = () => {
    get_Activity_Timezone(this.props.maindata.slug).then((res) => {
      //
      //
      //   //
      //   return data.timezone_name == moment.tz.guess()
      // }));
      const defaultTimezone = res.data.filter((data) => {
        return data.timezone_name == moment.tz.guess();
      });

      this.setState({
        timeZone: res.data,
        formData: {
          ...this.state.formData,
          timezone_id:
            defaultTimezone.length > 0 ? defaultTimezone[0].timezone_id : null,
          default_timezone_id:
            defaultTimezone.length > 0 ? defaultTimezone[0].timezone_id : null,
          timzone_name:
            defaultTimezone.length > 0
              ? defaultTimezone[0].timezone_name
              : null,
          default_timzone_name:
            defaultTimezone.length > 0
              ? defaultTimezone[0].timezone_name
              : null,
        },
      });
    });
  };

  getAttendies = (searchKey = "") => {
    if (this.state.attendies.length == 0) {
      this.setState({
        showLoader: true,
      });
    }

    get_Activity_Attendies({ search: searchKey }).then((res) => {
      this.setState({ attendies: res.data });
      if (!this.props.edit) {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  getDetails = () => {
    this.setState({
      showLoader: true,
    });

    get_Activity_Details(this.props.detailId)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            showLoader: false,
          },
          () => this.setCurrentData(res)
        );
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  getAttendees = (attendees) => {
    const attendee_ids = attendees.map((attendee) => {
      return attendee.id;
    });
    this.myProfile?.id ?? attendee_ids.push(this.myProfile.id);
    return attendee_ids;
  };

  setCurrentData = (res) => {
    //
    this.setState({
      formData: {
        ...this.state.formData,

        id: res.id,

        start_date_time: this.getDateTime(
          res.start_date_time,
          "YYYY-MM-DD HH:mm:ss",
          res.timezone.name
        ).format("DD-MM-YYYY | hh:mm A"),
        end_date_time: res.end_date_time
          ? this.getDateTime(
            res.end_date_time,
            "YYYY-MM-DD HH:mm:ss",
            res.timezone.name
          ).format("DD-MM-YYYY | hh:mm A")
          : null,
        activity_type_id: res.activity_type?.id,
        activity_type_name: res.activity_type?.name,
        activity_category_id: res.activity_category?.id,
        activity_category_name: res.activity_category?.name,
        activity_owner: res.activity_owner?.id,
        activity_owner_name: res.activity_owner?.name,
        activity_priority_id: res.activity_priority?.id,
        activity_priority_name: res.activity_priority?.name,
        activity_status_id: res.activity_status?.id,
        activity_status_name: res.activity_status?.name,
        host_reminder: res.host_reminder?.id,
        host_reminder_name: res.host_reminder?.name,
        entity_id: res.entity_id,
        entity: res.entity_name,
        user_id: res.attendees.map((attendee) => {
          return attendee.id;
        }),
        user_value: res.attendees,
        external_attendees: res.external_attendees.map((attendee) => {
          return attendee.email;
        }),
        external_attendees_value: res.external_attendees,
        timezone_id: res.timezone.id,
        timzone_name: res.timezone.name,
        name: res.name,

        location: res.location,
        meeting_link: res.meeting?.meeting_link,
        activity_outcome_id: res.activity_outcome?.id,
        activity_outcome_name: res.activity_outcome?.name,
        outcome_desc: res.outcome_desc,
        description: res.description,
        min_end_date: this.getDateTime(
          res.start_date_time,
          "YYYY-MM-DD HH:mm:ss",
          res.timezone.name
        ).format("DD-MM-YYYY | hh:mm A"),
        max_end_date: res.end_date_time
          ? this.getDateTime(
            res.end_date_time,
            "YYYY-MM-DD HH:mm:ss",
            res.timezone.name
          ).format("DD-MM-YYYY | hh:mm A")
          : null,
      },
    });
  };

  getDataDetails = () => {
    this.setState({
      showLoader: true,
    });
    this.getAttendies();
    this.getOutCome();
    this.getStatus();
    this.getPriority();
    this.getReminder();
    this.getCategory();
    this.getTimezone();

    // this.setState({
    //   showLoader: false,
    // });
    if (this.props.open && this.props.edit) {
      this.getDetails();
    } else if (this.props.open && !this.props.edit) {
      this.getHost();
    }
  };

  getHost = () => {
    let profile = JSON.parse(localStorage.getItem("my_profile"));
    //
    let firstName = profile?.first_name ?? "";
    let lastName = profile?.last_name ?? "";
    let name = firstName + " " + lastName;
    let uuid = profile?.id ?? "";
    let data = {
      activity_owner: uuid,
      activity_owner_name: name,
    };
    this.setHost(data);
  };

  setHost = (data) => {
    //
    this.setState({
      formData: {
        ...this.state.formData,
        activity_owner: data.activity_owner,
        activity_owner_name: data.activity_owner,
      },
    });
  };
  handleClose = () => {
    this.setState({
      showLoader: false,
    });
    this.props.handleClose();
  };
  handleChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };
  handleMultichange = (value) => {
    this.setState({ formData: { ...this.state.formData, user_id: value } });
  };

  getDateTime = (dateString, dateFormat, timezone = "utc") => {
    return timezone == "utc"
      ? moment.utc(dateString, dateFormat)
      : getDateTimeforTimezone(moment.utc(dateString, dateFormat), timezone);
  };

  handleDateChange = (e) => {
    //
    const end_date = this.getDateTime(e.value, getDateTimeFormat("date_time"))
      .add(30, "minutes")
      .format("DD-MM-YYYY | hh:mm A");
    if (e.name == "start_date_time" && this.props.maindata.slug == "meeting") {
      // const end_date = moment.utc(e.value, getDateTimeFormat("date_time")).add(1, "minutes").format("DD-MM-YYYY | hh:mm A");

      //
      this.setState({
        formData: {
          ...this.state.formData,
          ["end_date_time"]: end_date,
          ["min_end_date"]: end_date,
          [e.name]: e.value,
        },
      });
    } else {
      var end_date_time = e.value;
      if (e.name == "end_date_time") {
        end_date_time = end_date;
      }
      this.setState({
        formData: {
          ...this.state.formData,
          ["end_date_time"]: end_date_time,
          [e.name]: e.value,
        },
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let error = FormValidate(
      this.state.formData,
      this.props.maindata.slug === "meeting" ? meeting : task
    );

    this.setState({
      error: {
        ...this.state.error,
        ...error,
      },
    });

    let submitData = this.state.formData;
    //
    const originalStartDate = submitData["start_date_time"];
    const originalEndDate = submitData["end_date_time"];

    // submitData['start_date_time'] = moment(submitData['start_date_time'], getDateTimeFormat("date_time")).format("DD-MM-YYYY HH:mm:ss");
    submitData["start_date_time"] = this.getDateTime(
      submitData["start_date_time"],
      getDateTimeFormat("date_time")
    ).format("DD-MM-YYYY HH:mm:ss");
    // moment(, getDateTimeFormat("date_time")).format("DD-MM-YYYY HH:mm:ss");
    submitData["end_date_time"] = submitData["end_date_time"]
      ? this.getDateTime(
        submitData["end_date_time"],
        getDateTimeFormat("date_time")
      ).format("DD-MM-YYYY HH:mm:ss")
      : null;
    delete submitData["min_end_date"];
    delete submitData["max_end_date"];
    if (this.props.maindata.slug == "meeting") {
      if (
        !this.getDateTime(
          submitData["start_date_time"],
          getDateTimeFormat("date_time")
        ).isValid()
      ) {
        this.setState({
          formData: submitData,
          error: {
            ...this.state.error,
            start_date_time: true,
          },
          snackData: {
            open: true,
            message: "Please enter valid Start Date/Time",
            variant: "error",
          },
        });
        return;
      }
      if (
        !this.getDateTime(
          submitData["end_date_time"],
          getDateTimeFormat("date_time")
        ).isValid()
      ) {
        this.setState({
          formData: submitData,
          error: {
            ...this.state.error,
            end_date_time: true,
          },
          snackData: {
            open: true,
            message: "Please enter valid End Date/Time",
            variant: "error",
          },
        });
        return;
      }

      if (submitData["start_date_time"] >= submitData["end_date_time"]) {
        submitData["start_date_time"] = originalStartDate;
        submitData["end_date_time"] = originalEndDate;
        this.setState({
          formData: submitData,
          error: {
            ...this.state.error,
            start_date_time: true,
            end_date_time: true,
          },
          snackData: {
            open: true,
            message: "Start Date/Time must be earlier than End Date/Time",
            variant: "error",
          },
        });
        return;
      }
    } else {
      // submitData['end_date_time'] = null;
      if (
        !this.getDateTime(
          submitData["start_date_time"],
          getDateTimeFormat("date_time")
        ).isValid()
      ) {
        this.setState({
          formData: submitData,
          snackData: {
            open: true,
            message: "Please enter valid Due Date/Time",
            variant: "error",
          },
        });
        return;
      }
    }

    this.setState({
      canSubmit: false,
    });

    //

    if (Object.keys(error).length === 0) {
      this.props.edit
        ? update_Activity(this.props.detailId, submitData)
          .then((res) => {
            this.setState({
              canSubmit: true,
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });
            this.props.handleClose(true);
          })
          .catch((err) => {
            submitData["start_date_time"] = originalStartDate;
            submitData["end_date_time"] = originalEndDate;
            this.setState({
              canSubmit: true,
              formData: submitData,
              snackData: {
                open: true,
                message: err.response.data.message,
                variant: "error",
              },
            });
          })
        : create_Activity(submitData)
          .then((res) => {
            this.setState({
              canSubmit: true,
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });
            this.props.handleClose(true);
          })
          .catch((err) => {
            submitData["start_date_time"] = originalStartDate;
            submitData["end_date_time"] = originalEndDate;
            const errorMsg = FormatError(err);
            this.setState({
              canSubmit: true,
              formData: submitData,
              snackData: {
                open: true,
                message: errorMsg,
                variant: "error",
              },
            });
          });
    } else {
      //
      this.setState({
        canSubmit: true,
        snackData: {
          open: true,
          variant: "error",
          message: "Please fill all required fields",
        },
      });
    }
    //
  };

  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  getAttendeesSearchChange = (e) => {
    //
    const attendees = this.state.formData.user_id ?? [];
    //
    console.log("attendee search", e);
    if (e.id) {
      if (attendees.indexOf(e.id) < 0) {
        attendees.push(e.id);
        this.setState({
          formData: {
            ...this.state.formData,
            user_id: attendees,
          },
          attendee_search: "",
        });
      }
    } else {
      this.setState({
        attendee_search: e.name,
      });
    }

    // this.setState({
    //   attendee_search: e.name ?? ""
    // }, () => {
    //   this.getAttendies();
    // });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      //
      // e.Handled = true;
      e.preventDefault();

      const error = !EmailValidate(e.target.value.trim());

      if (!error) {
        const attendees = this.state.formData.external_attendees ?? [];
        if (attendees.indexOf(e.target.value.trim()) < 0) {
          attendees.push(e.target.value.trim());

          this.setState({
            formData: {
              ...this.state.formData,
              external_attendees: attendees,
            },
            attendee_search: "",
          });
        }
      }
    }
  };

  render() {
    let act_Type = this.props.maindata.display_name;
    const {
      formData,
      outCome,
      statusData,
      priority,
      reminder,
      Category,

      snackData,
      timeZone,
      attendies,
      showLoader,
    } = this.state;
    //
    return (
      <>
        <Dialog
          className=" activity-twocolumn"
          open={this.props.open}
        // onClose={this.handleClose}
        >
          <DialogTitle>
            {" "}
            <div className="row">
              <div className="col-12 p-0">
                <p className="m-0">
                  {this.props.edit ? `Edit ${act_Type}` : `Create ${act_Type}`}
                </p>
              </div>
              <div className="col-2 pr-0">
                <IconButton
                  className="closeButtonStyle"
                  onClick={this.handleClose}
                >
                  <CustomIcons iconName="close" />
                </IconButton>
              </div>
            </div>{" "}
          </DialogTitle>
          {showLoader ? (
            <DialogContent className="activity-dialogcontent-loader">
              <BlockingLoader isActive={showLoader}></BlockingLoader>
            </DialogContent>
          ) : (
            <form onSubmit={this.handleSubmit} noValidate>
              <DialogContent className="activity-dialogcontent">
                <div className="activity-wrap row">
                  <div className="activity-form col-md-8 col-xs-12">
                    <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="flex-box">
                          <label className="control-label">{`${act_Type} Title`}</label>
                          <div className="input-flex">
                            <SelectField
                              {...this.props}
                              data={Category}
                              // placeholder={`${act_Type} Category`}
                              placeholder="Category"
                              // label={`${act_Type} Category`}
                              // tooltip={`${act_Type} Category`}
                              name="activity_category_id"
                              valueLabel={formData.activity_category_name}
                              getData={this.getCategory}
                              required
                              error={this.state.error["activity_category_id"]}
                              key_id="id"
                              key_name="name"
                              onChange={this.handleChange}
                              value={formData.activity_category_id}
                            />
                            <TextField
                              {...this.props}
                              placeholder={`${act_Type} Title`}
                              // label={`${act_Type} Title`}
                              // tooltip={`${act_Type} Title`}
                              name="name"
                              error={this.state.error["name"]}
                              required
                              onChange={this.handleChange}
                              value={formData.name}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <SelectField
                          {...this.props}
                          data={statusData}
                          placeholder={`${act_Type} Status`}
                          name="activity_status_id"
                          error={this.state.error["activity_status_id"]}
                          onChange={this.handleChange}
                          valueLabel={formData.activity_status_name}
                          value={formData.activity_status_id}
                          label={`${act_Type} Status`}
                          // tooltip={`${act_Type} Status`}
                          getData={this.getStatus}
                          key_id="id"
                          key_name="display_name"
                          required
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <SelectField
                          {...this.props}
                          data={priority}
                          placeholder={`${act_Type} Priority`}
                          label={`${act_Type} Priority`}
                          error={this.state.error["activity_priority_id"]}
                          // tooltip={`${act_Type} Priority`}
                          name="activity_priority_id"
                          valueLabel={formData.activity_priority_name}
                          key_id="id"
                          required
                          getData={this.getPriority}
                          key_name="display_name"
                          onChange={this.handleChange}
                          value={formData.activity_priority_id}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        {this.props.maindata.slug === "meeting" ? (
                          <SelectField
                            {...this.props}
                            data={attendies}
                            placeholder={`${act_Type} Host`}
                            label={`${act_Type} Host`}
                            error={this.state.error["activity_owner"]}
                            // tooltip={`${act_Type} Owner`}
                            key_id="userid"
                            required
                            key_name="username"
                            getData={this.getAttendies}
                            name="activity_owner"
                            onChange={this.handleChange}
                            // valueLabel={formData.activity_owner_name}
                            // value={formData.activity_owner}
                            valueLabel={formData.activity_owner_name}
                            value={formData.activity_owner}
                          />
                        ) : (
                          <SelectField
                            {...this.props}
                            data={attendies}
                            error={this.state.error["activity_owner"]}
                            placeholder={`${act_Type} Owner`}
                            label={`${act_Type} Owner`}
                            // tooltip={`${act_Type} Owner`}
                            key_id="userid"
                            required
                            key_name="username"
                            getData={this.getAttendies}
                            name="activity_owner"
                            onChange={this.handleChange}
                            valueLabel={formData.activity_owner_name}
                            value={formData.activity_owner}
                          />
                        )}
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <SelectField
                          {...this.props}
                          data={timeZone}
                          error={this.state.error["timezone_id"]}
                          placeholder="Time Zone"
                          name="timezone_id"
                          required
                          onChange={this.handleChange}
                          value={
                            formData.timezone_id ?? formData.default_timezone_id
                          }
                          getData={this.getTimezone}
                          label="Time Zone"
                          key_id="timezone_id"
                          key_name="timezone_displayname"
                          tooltip="Time zone"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        {this.props.maindata.slug === "meeting" ? (
                          <DatePickerField
                            {...this.props}
                            name="start_date_time"
                            error={this.state.error["start_date_time"]}
                            label="Start Date & Time"
                            placeholder="Start Date & Time"
                            onChange={this.handleDateChange}
                            timepicker
                            required
                            max_date={formData.max_start_date}
                            value={formData.start_date_time}
                          />
                        ) : (
                          <DatePickerField
                            {...this.props}
                            name="start_date_time"
                            error={this.state.error["start_date_time"]}
                            label="Due Date & Time"
                            placeholder="Due Date & Time"
                            onChange={this.handleDateChange}
                            timepicker
                            required
                            value={formData.start_date_time}
                          />
                        )}
                      </div>

                      {this.props.maindata.slug === "meeting" && (
                        <div className="col-md-6 col-xs-12">
                          <DatePickerField
                            {...this.props}
                            label="End Date & Time"
                            error={this.state.error["end_date_time"]}
                            placeholder="End Date & Time"
                            name="end_date_time"
                            required
                            timepicker
                            onChange={this.handleDateChange}
                            value={formData.end_date_time}
                            min_date={formData.min_end_date}
                          />
                        </div>
                      )}

                      <div className="col-md-6 col-xs-12">
                        <SelectField
                          {...this.props}
                          data={reminder}
                          placeholder={`${act_Type} Reminder`}
                          error={this.state.error["host_reminder"]}
                          key_id="id"
                          key_name="display_name"
                          valueLabel={formData.host_reminder_name}
                          getData={this.getReminder}
                          label={`${act_Type} Reminder`}
                          // tooltip={`${act_Type} Reminder`}
                          name="host_reminder"
                          onChange={this.handleChange}
                          value={formData.host_reminder}
                        />
                      </div>

                      {/* <TextField
                          {...this.props}
                          placeholder="Link"
                          onChange={this.handleChange}
                          value={formData.meeting_link}
                          label={`${act_Type} Link`}
                          name="meeting_link"
                          tooltip=""
                        /> */}
                      {/* </> */}
                      {/* )} */}
                      {/* <SelectField
                      {...this.props}
                      data={statusData}
                      placeholder={`${act_Type} Status`}
                      name="activity_status_id"
                      onChange={this.handleChange}
                      required
                      valueLabel={formData.activity_status_name}
                      value={formData.activity_status_id}
                      label={`${act_Type} Status`}
                      tooltip={`${act_Type} Status`}
                      getData={this.getStatus}
                      key_id="id"
                      key_name="display_name"
                    /> */}
                      {/* <SelectField
                      {...this.props}
                      data={outCome}
                      key_id="id"
                      key_name="display_name"
                      placeholder={`${act_Type} Outcome`}
                      onChange={this.handleChange}
                      getData={this.getOutCome}
                      valueLabel={formData.activity_outcome_name}
                      name="activity_outcome_id"
                      value={formData.activity_outcome_id}
                      label={`${act_Type} Outcome`}
                      tooltip={`${act_Type} Outcome`}
                    /> */}
                      {/* <TextareaField
                      {...this.props}
                      placeholder="Description"
                      onChange={this.handleChange}
                      value={formData.description}
                      label={`${act_Type} Description`}
                      name="description"
                      tooltip=""
                      {...this.props}
                    /> */}

                      {this.props.maindata.slug === "meeting" && (
                        <>
                          <div className="col-md-6 col-xs-12">
                            <TextField
                              {...this.props}
                              placeholder="Link"
                              error={this.state.error["meeting_link"]}
                              onChange={this.handleChange}
                              value={formData.meeting_link}
                              label={`Online ${act_Type} Link`}
                              name="meeting_link"
                              tooltip=""
                            />
                          </div>
                          <div className="col-md-12 col-xs-12">
                            <TextField
                              {...this.props}
                              placeholder="Location"
                              error={this.state.error["location"]}
                              onChange={this.handleChange}
                              value={formData.location}
                              label={`${act_Type} Location`}
                              name="location"
                              tooltip=""
                            />
                          </div>
                        </>
                      )}

                      <div className="col-md-12 col-xs-12">
                        <TextareaField
                          {...this.props}
                          placeholder="Description"
                          error={this.state.error["description"]}
                          onChange={this.handleChange}
                          value={formData.description}
                          label={`${act_Type} Description`}
                          name="description"
                          tooltip=""
                          {...this.props}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="assignee-block col-md-4 col-xs-12">
                    <div className="activity-item">
                      {this.props.maindata.slug === "meeting" ? (
                        <>
                          <p className="head">Attendees</p>
                          <SearchSelectBox
                            {...this.props}
                            id="select-pagesize"
                            required
                            async={true}
                            // readOnly
                            name="user_value"
                            defaultValue={""}
                            placeholder="Add Attendees"
                            fullWidth
                            getData={this.getAttendies}
                            value={this.state.attendee_search ?? ""}
                            onKeyDownEvent={this._handleKeyDown}
                            onChange={this.getAttendeesSearchChange}
                            error={this.state.error["user_value"]}
                            key_id="userid"
                            key_name="username"
                            margin="normal"
                            Data={attendies.map((data) => {
                              return {
                                id: data.userid,
                                username: data.username,
                              };
                            })}
                          />
                          {/* <MultipleSelectChip
                            {...this.props}
                            data={attendies}
                            placeholder="Attendees"
                            error={this.state.error['user_value']}

                            name="user_value"
                            handleSelect={this.handleMultichange}
                            label=""
                            key_id="userid"
                            getData={this.getAttendies}
                            key_name="username"
                            value={formData.user_id}
                            // tooltip="Attendees"
                          /> */}
                        </>
                      ) : (
                        <>
                          <p className="head">Assignee</p>
                          {/* <MultipleSelectChip
                            {...this.props}
                            data={attendies}
                            placeholder="Assignee"
                            error={this.state.error['user_value']}

                            name="user_value"
                            handleSelect={this.handleMultichange}
                            label=""
                            key_id="userid"
                            getData={this.getAttendies}
                            key_name="username"
                            value={formData.user_id}
                            // tooltip="Attendees"
                          /> */}
                          <SearchSelectBox
                            {...this.props}
                            id="select-pagesize"
                            required
                            async={true}
                            // readOnly
                            name="user_value"
                            defaultValue={""}
                            placeholder="Assignee"
                            fullWidth
                            getData={this.getAttendies}
                            value={this.state.attendee_search ?? ""}
                            onKeyDownEvent={this._handleKeyDown}
                            onChange={this.getAttendeesSearchChange}
                            error={this.state.error["user_value"]}
                            key_id="userid"
                            key_name="username"
                            margin="normal"
                            Data={attendies.map((data) => {
                              return {
                                id: data.userid,
                                username: data.username,
                              };
                            })}
                          />
                        </>
                      )}

                      <ul className="assignee-list">
                        {attendies.map((item) => (
                          <>
                            {formData.user_id &&
                              formData.user_id?.length !== 0 &&
                              formData.user_id.map((ids, index) => (
                                <>
                                  {item.userid === ids && (
                                    <li>
                                      <div className="img-box">
                                        <img src="/assets/images/avatar-sm-default.svg" />
                                      </div>
                                      <p className="text">{item.username}</p>
                                      <span
                                        className="close_icon"
                                        onClick={(e) =>
                                          this.handleRemoveAttendee(ids)
                                        }
                                      >
                                        {formData.activity_owner !== ids && (
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12.3536 2.35355C12.5488 2.15829 12.5488 1.84171 12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12.3536 2.35355ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536C1.84171 12.5488 2.15829 12.5488 2.35355 12.3536L1.64645 11.6464ZM2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L2.35355 1.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 1.64645L1.64645 11.6464L2.35355 12.3536L12.3536 2.35355L11.6464 1.64645ZM1.64645 2.35355L11.6464 12.3536L12.3536 11.6464L2.35355 1.64645L1.64645 2.35355Z"
                                              fill="#222222"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </li>
                                  )}
                                </>
                              ))}
                          </>
                        ))}
                        <ul className="assignee-list">
                          {formData.external_attendees?.map((item) => (
                            <li>
                              <div className="img-box">
                                <img src="/assets/images/guest-icon.svg" />
                              </div>
                              <p className="text">{item}</p>
                              <span
                                className="close_icon"
                                onClick={(e) =>
                                  this.handleRemoveExternalAttendee(item)
                                }
                              >
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.3536 2.35355C12.5488 2.15829 12.5488 1.84171 12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12.3536 2.35355ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536C1.84171 12.5488 2.15829 12.5488 2.35355 12.3536L1.64645 11.6464ZM2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L2.35355 1.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 1.64645L1.64645 11.6464L2.35355 12.3536L12.3536 2.35355L11.6464 1.64645ZM1.64645 2.35355L11.6464 12.3536L12.3536 11.6464L2.35355 1.64645L1.64645 2.35355Z"
                                    fill="#222222"
                                  />
                                </svg>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </ul>
                      {/* <p className="head">External</p> */}
                    </div>
                  </div>
                </div>
                <div className="activity-wrap row">
                  <div className="activity-form col-md-12 col-xs-12 pt-0">
                    <div className="row">
                      <div className="col-md-4 col-xs-12">
                        <SelectField
                          {...this.props}
                          data={outCome}
                          key_id="id"
                          key_name="display_name"
                          error={this.state.error["activity_outcome_id"]}
                          placeholder={`${act_Type} Outcome`}
                          onChange={this.handleChange}
                          getData={this.getOutCome}
                          valueLabel={formData.activity_outcome_name}
                          name="activity_outcome_id"
                          value={formData.activity_outcome_id}
                          label={`${act_Type} Outcome`}
                        // tooltip={`${act_Type} Outcome`}
                        />
                      </div>
                      <div className="col-md-8 col-xs-12">
                        <TextField
                          {...this.props}
                          placeholder={`${act_Type} Outcome Remarks`}
                          error={this.state.error["outcome_desc"]}
                          onChange={this.handleChange}
                          value={formData.outcome_desc}
                          label={`${act_Type} Outcome Remarks`}
                          name="outcome_desc"
                          tooltip=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonSml
                  {...this.props}
                  type="button"
                  onClick={this.handleClose}
                  className={`${this.props.classes.secondaryBtn} btn btn-secondary`}
                  label="Cancel"
                />
                <ButtonSml
                  {...this.props}
                  disabled={!this.state.canSubmit}
                  type="submit"
                  className={`${this.props.classes.primaryBtn} btn btn-primary`}
                  label={this.props.edit ? "Update" : "Create"}
                />
              </DialogActions>
            </form>
          )}{" "}
          <SnackComp
            snackData={this.state.snackData}
            handleClose={this.onSnackclose}
          />
        </Dialog>
      </>
    );
  }
}
