import { IconButton } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Component } from "react";
import { DynFormValidate } from "../../utils/formValidate";
import AirbBrakeErrorBoundary from "../airbrakeErrorBoundary";
import ButtonSml from "../buttonsml";
import CustomIcons from "../commonIcons";
import SnackComp from "../snackbar/snack.component";
import DynamicForm from "./dynamicform";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
class Createuser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackData: {},
      submit: false,
      excludeKeys: props.excludeKeys ? props.excludeKeys : null,
      formData: this.props.formData,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formData: props.formData,
      excludeKeys: props.excludeKeys ? props.excludeKeys : null,
      submit: false,
    });
  };
  handleClose = () => {
    this.props.handleClose();
    this.setState({ submit: false });
  };
  handleSubmit = (e) => {
    this.setState({ submit: true });
    e.preventDefault();
    let data = DynFormValidate(this.state.formData, this.state.excludeKeys);

    let flag = false;
    var error = "";
    data.forEach((item) => {
      if(!this.props.errorData){
      if (item.error) {
        flag = true;
        //
        if (error == "") {
          error = item.error_message
            ? item.error_message
            : "Please fill all required fields";
        } else {
          error =
            error + ", " + item.error_message
              ? item.error_message
              : "Please fill all required fields";
        }
      }
    }
    });
    if (!flag) {
      let datas = {};
      data.forEach((item) => {
        datas = {
          ...datas,
          [item.api_name]: item.api_id ? item.api_id : item.api_value,
        };
      });
      this.props.submit(datas);
    } else {
      this.setState({
        submit: false,
        snackData: {
          open: true,

          variant: "error",
          // message: "Please fill all required fields",
          message: error ? error : "Please fill all required fields",
        },
      });
    }
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  render() {
    const { title, classes, open } = this.props;
    const { formData, excludeKeys } = this.state;
    return (
      <AirbBrakeErrorBoundary>
        <div>
          {/* <BootstrapDialog className={formData.length > 7 ? "twocolumnwidth" : ""} open={open} onClose={this.handleClose}> */}
          <BootstrapDialog
            className={formData.length > 7 ? "twocolumnwidth" : ""}
            open={open}
          >
            <DialogTitle>
              {" "}
              <div className="row">
                <div className="col-10">
                  <p className="dialogHeader">{title}</p>
                </div>
                <div className="col-2">
                  <IconButton
                    className="closeButtonStyle"
                    onClick={this.props.handleClose}
                  >
                    <CustomIcons iconName="close" />
                  </IconButton>
                </div>
              </div>{" "}
            </DialogTitle>
            <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
              <DialogContent className="quickcreate-form">
                <div>
                  <Grid container spacing={2}>
                    {formData &&
                      formData.map((item, index) => (
                        <>
                          {
                            (!excludeKeys || (excludeKeys && !excludeKeys.includes(item.api_name))) && (
                              < Grid item xs={12} sm={formData.length > 7 ? 6 : 12} >
                                <DynamicForm
                                  {...this.props}
                                  key={item.id}
                                  item={item}
                                  index={index}
                                  formData={formData}
                                  master_key={this.props.master_key}
                                />
                              </Grid>
                            )
                          }
                        </>
                      ))}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonSml
                  {...this.props}
                  type="button"
                  onClick={this.props.handleClose}
                  className={`${classes.secondaryBtn} btn btn-secondary`}
                  label="Cancel"
                  title="Cancel"
                />
                <ButtonSml
                  {...this.props}
                  disabled={this.state.submit}
                  type="submit"
                  className={`${classes.primaryBtn} btn btn-primary`}
                  label="Save"
                  title="Save"
                />
              </DialogActions>
            </form>
          </BootstrapDialog>
          <SnackComp
            snackData={this.state.snackData}
            handleClose={this.onSnackclose}
          />
        </div>
      </AirbBrakeErrorBoundary >
    );
  }
}

export default Createuser;
