import React, { Component } from "react";
import { getAudio } from "../call.apis";
import "../calls_style.css";


class AudioPlayer extends Component{
    constructor(props){
        super(props);
        this.state = {
            open: false,
            audio:"",            
        }
    }
    componentDidMount = () => {
        this.getDetails();
    };
    getDetails = () =>{
      let id = ""
      this.props.master_id ? id=this.props.master_id : id=this.props.detailId
        if (id){
            getAudio(id).then((res) => res)
            .then((res) => {
              this.setState({
                audio: res?.data?.download_resource_url,
                
              });
            })
        }
    }

    render() {
        return (
          <React.Fragment>
            {this.state.audio ? 
            <div className="fieldset">
              <label
                  className={
                    this.props.error ? "form-label-error control-label" : "control-label"
                  }
                >
                  {this.props.item?.display_name ? this.props.item?.display_name:""}
              </label>
              {this.state.audio ?
                    <audio src={this.state.audio} controls type="audio/wav" />
                    : "" 
              }
            </div>
             : ""}
          </React.Fragment>
        );
      }
}
export default AudioPlayer;