import React, { Component } from "react";
import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiOutlineCheck,
  AiOutlineClockCircle,
  AiOutlineClose,
  AiOutlineDownload,
  AiOutlineExpandAlt,
  AiOutlinePrinter,
  AiOutlineQuestionCircle,
  AiOutlineShrink,
  AiOutlineUnorderedList,
  AiOutlineUser,
  AiTwotoneStop,
} from "react-icons/ai";
import { BiEditAlt, BiListUl, BiLockAlt } from "react-icons/bi";
import {
  BsExclamationCircle,
  BsPlusLg,
  BsThreeDots,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FiMoreVertical, FiTrash } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import {
  MdFilterList,
  MdLogout,
  MdMoreVert,
  MdOutlineCalendarToday,
  MdOutlineDashboard,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineKeyboardArrowDown,
  MdOutlineMail,
  MdOutlineUnfoldMore,
  MdOutlineViewColumn,
  MdPeople,
} from "react-icons/md";
import { VscGear, VscInbox } from "react-icons/vsc";
class CustomIcons extends Component {
  render() {
    const { iconName, iconClass } = this.props;
    return (
      <span class={iconClass}>
        {" "}
        {iconName === "show_columns" && <MdOutlineViewColumn />}{" "}
        {iconName === "dashoutline" && <MdOutlineDashboard />}{" "}
        {iconName === "peopleicon" && <MdPeople />}{" "}
        {iconName === "expand" && <MdOutlineExpandMore />}{" "}
        {iconName === "expandless" && <MdOutlineExpandLess />}{" "}
        {iconName === "search" && <IoSearchOutline />}{" "}
        {iconName === "expandcol" && <AiOutlineExpandAlt />}{" "}
        {iconName === "collapse" && <AiOutlineShrink />}{" "}
        {iconName === "expandcol_table" && (
          <>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.64438" y="8.5097" width="12.6649" height="5.12069" rx="0.5" stroke="#222222"/>
              <path d="M1.76856 3.78982L14.3093 3.78985" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.03894 2.34045V5.41383" stroke="#222222" stroke-linecap="round"/>
              <path d="M3.34531 5.74855L1.48226 3.8855L3.34531 2.02246" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5668 5.74855L14.4299 3.8855L12.5668 2.02246" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </>
        )}{" "}
        {iconName === "collapse_table" && (
          <>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.41068 10.2903L2.54763 8.42725L4.41068 6.56421" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.09562 8.42733L12.9043 8.42726" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.62836 3.52295V13.0885" stroke="black" stroke-linecap="round"/>
              <path d="M14.4687 3.52295V13.0885" stroke="black" stroke-linecap="round"/>
              <path d="M11.6007 10.2903L13.4638 8.42725L11.6007 6.56421" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </>
        )}{" "}
        {iconName === "notification" && (
          <>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.13547 11.9935C8.03291 12.1703 7.88571 12.317 7.7086 12.419C7.53149 12.5211 7.33069 12.5748 7.1263 12.5748C6.92191 12.5748 6.72111 12.5211 6.544 12.419C6.36689 12.317 6.21969 12.1703 6.11714 11.9935M12.9596 9.66016H1.29297C1.7571 9.66016 2.20222 9.47578 2.53041 9.14759C2.85859 8.8194 3.04297 8.37428 3.04297 7.91016V4.99349C3.04297 3.91052 3.47318 2.87191 4.23895 2.10614C5.00472 1.34036 6.04334 0.910156 7.1263 0.910156C8.20927 0.910156 9.24788 1.34036 10.0137 2.10614C10.7794 2.87191 11.2096 3.91052 11.2096 4.99349V7.91016C11.2096 8.37428 11.394 8.8194 11.7222 9.14759C12.0504 9.47578 12.4955 9.66016 12.9596 9.66016Z"
                stroke="#222222"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </>
        )}{" "}
        {iconName === "notificationfull" && (
          <>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7922_49514)">
                <path
                  d="M8.13547 12.9935C8.03291 13.1703 7.88571 13.317 7.7086 13.419C7.53149 13.5211 7.33069 13.5748 7.1263 13.5748C6.92191 13.5748 6.72111 13.5211 6.544 13.419C6.36689 13.317 6.21969 13.1703 6.11714 12.9935M12.9596 10.6602H1.29297C1.7571 10.6602 2.20222 10.4758 2.53041 10.1476C2.85859 9.8194 3.04297 9.37428 3.04297 8.91016V5.99349C3.04297 4.91052 3.47318 3.87191 4.23895 3.10614C5.00472 2.34036 6.04334 1.91016 7.1263 1.91016C8.20927 1.91016 9.24788 2.34036 10.0137 3.10614C10.7794 3.87191 11.2096 4.91052 11.2096 5.99349V8.91016C11.2096 9.37428 11.394 9.8194 11.7222 10.1476C12.0504 10.4758 12.4955 10.6602 12.9596 10.6602Z"
                  stroke="#222222"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="11.0308" cy="3.83553" r="3.09334" fill="#C92C2C" />
              </g>
              <defs>
                <clipPath id="clip0_7922_49514">
                  <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(0.125 0.742188)"
                  />
                </clipPath>
              </defs>
            </svg>
          </>
        )}{" "}
        {/* {iconName === "add" && <MdAdd />} */}{" "}
        {iconName === "more" && <MdMoreVert />}{" "}
        {iconName === "exclamation" && <BsExclamationCircle />}{" "}
        {iconName === "menu" && <BsThreeDots />}{" "}
        {iconName === "headermenu" && <BsThreeDotsVertical />}{" "}
        {iconName === "settings" && <VscGear />}{" "}
        {iconName === "add" && <BsPlusLg />}{" "}
        {iconName === "expandIcon" && <AiFillPlusCircle />}{" "}
        {iconName === "collapseIcon" && <AiFillMinusCircle />}{" "}
        {iconName === "nochild" && <AiTwotoneStop />}{" "}
        {iconName === "help" && <AiOutlineQuestionCircle />}{" "}
        {iconName === "dropdown" && <MdOutlineKeyboardArrowDown />}{" "}
        {iconName === "close" && <AiOutlineClose />}{" "}
        {iconName === "filter" && <MdFilterList />}{" "}
        {iconName === "tablemenu" && <AiOutlineUnorderedList />}{" "}
        {iconName === "print" && <AiOutlinePrinter />}{" "}
        {iconName === "refresh" && <HiOutlineRefresh />}{" "}
        {iconName === "delete" && <FiTrash />}{" "}
        {iconName === "edit" && <BiEditAlt />}{" "}
        {iconName === "tabledropdown" && <FiMoreVertical />}{" "}
        {iconName === "nodatafound" && <VscInbox />}{" "}
        {iconName === "calendar" && <MdOutlineCalendarToday />}{" "}
        {iconName === "download" && <AiOutlineDownload />}
        {iconName === "check" && <AiOutlineCheck />}
        {iconName === "time" && <AiOutlineClockCircle />}
        {iconName === "showmore" && <MdOutlineUnfoldMore />}
        {iconName === "profile" && <AiOutlineUser />}
        {iconName === "lock" && <BiLockAlt />}
        {iconName === "mail" && <MdOutlineMail />}
        {iconName === "list" && <BiListUl />}
        {iconName === "logout" && <MdLogout />}
      </span>
    );
  }
}
export default CustomIcons;
